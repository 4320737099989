import { createContext, useContext } from "react";

import { IElementModel, IElementType, ILayoutComponent } from "./types";

interface IElementTypesContext {
  elementTypes: Record<string, IElementType>;
  availableElementTypes: Record<string, IElementType>;
  getElementType: (element: IElementModel) => IElementType;
  getLayout: (name?: string) => ILayoutComponent;
}

/*
 * This context is used to provide the registered element types to the <Element> component.
 */
const ElementTypesContext = createContext<IElementTypesContext>({
  elementTypes: {},
  availableElementTypes: {},
  getElementType: () => {
    throw new Error("Not implemented");
  },
  getLayout: () => {
    throw new Error("Not implemented");
  },
});

export function useElementTypesContext() {
  const context = useContext<IElementTypesContext>(ElementTypesContext);
  if (context === undefined) {
    throw new Error(
      "useElementTypesContext must be used within a ElementTypesContext.Provider",
    );
  }
  return context;
}

export const ElementTypesConsumer = ElementTypesContext.Consumer;

export default ElementTypesContext;
