import { createSelector } from "reselect";

import { selectorScoper } from "core/utils/redux";
import { buildSimpleUncontrolledInputModule } from "core/utils/buildSimpleUncontrolledInputModule";
import { AutocompleteInput } from "../../types";

export const uncontrolledSelectors = (
  path: string[],
  element: AutocompleteInput,
  selectors: ReturnType<typeof buildSimpleUncontrolledInputModule>["selectors"],
) => {
  const { config } = element;
  const { filter } = element.config;

  const scopeSelector = selectorScoper<
    AutocompleteInput["config"]["defaultValue"]
  >(path);

  const fixedFilter = (state: any) => filter?.(state);
  const searchInputValue = (state: any) =>
    scopeSelector(state).searchInputValue;
  const loadingOptions = (state: any) => scopeSelector(state).loadingOptions;
  const allOptions = (state: any) => scopeSelector(state).options;
  const optionsError = (state: any) => scopeSelector(state).optionsError;
  const options = createSelector(
    [allOptions, selectors.value],
    (all: string[] | null, val) =>
      all
        ? val
          ? config.isMulti
            ? all.filter((o) => !val?.includes(o))
            : all.filter((o) => o !== val)
          : all
        : [],
  );
  const rawOptions = (state: any) => scopeSelector(state).rawOptions;
  const valueObject = (state: any) => scopeSelector(state).valueObject;

  const rawValueObject = createSelector(
    [rawOptions, selectors.value],
    (raw: string[] | null, currentValue) => {
      if (config.reference) {
        const arrayifiedCurrentValue = Array.isArray(currentValue)
          ? currentValue
          : [currentValue];
        const rawValueObjects = arrayifiedCurrentValue.map(
          (v) =>
            raw?.find((rawRow) => rawRow[config.reference?.columnName] === v) ??
            null,
        );
        return Array.isArray(currentValue)
          ? rawValueObjects
          : rawValueObjects[0];
      } else {
        return null;
      }
    },
  );

  const newSelectors = {
    searchInputValue,
    options,
    loadingOptions,
    optionsError,
    valueObject,
    rawOptions,
    rawValueObject,
    fixedFilter,
  };

  return newSelectors;
};
