import React, { memo, useEffect } from "react";
import { Controller } from "react-hook-form";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import Button from "../../common/Button";
import IconButton from "../../common/IconButton";
import { useFilterContext } from "./FilterContext";
import FilterRule from "./FilterRule";
import { useStyles } from "./style";
import {
  FilterGroupCombinator,
  IFilterGroupWithPath,
  IFilterRuleWithPath,
} from "./types";

import { useHookFormContext } from "../../common/HookForm";
import { useTableTranslation } from "../translation";
import { isFilterGroup } from "./utils";

interface IProps {
  group: IFilterGroupWithPath;
  removable?: boolean;
}

const FilterGroup = memo<IProps>(
  ({ group: { path, combinator, filters }, removable }) => {
    const { control, getValues, setValue } = useHookFormContext();
    const {
      addFilterGroup,
      addFilterRule,
      removeFilter,
      fields,
    } = useFilterContext();
    const {
      groupButtonDescription,
      groupButtonLabel,
      ruleButtonDescription,
      ruleButtonLabel,
      deleteGroupButtonDescription,
      ...translation
    } = useTableTranslation();

    const {
      marginRight,
      flexWrapper,
      ruleGap,
      groupCombinator,
      groupWrapper,
      groupButtons,
    } = useStyles();

    const name = !path.length
      ? "['initial'].combinator"
      : `[${path}].combinator`;

    /*
     * Since `react-hook-form` minimizes the number of re-renders
     * there is a need to trigger `setValue` when new `Group` has been added or deleted
     */
    useEffect(() => {
      setValue(name, combinator);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, combinator]);

    const renderCombinator = (
      <Controller
        as={
          <TextField
            select={true}
            className={`${marginRight} ${groupCombinator}`}
            margin="dense"
          >
            {Object.entries(FilterGroupCombinator).map(
              ([key, value]: string[]) => (
                <MenuItem key={key} value={value}>
                  <Tooltip title={translation[`${value}Description`] || value}>
                    <div>{translation[`${value}Label`] || value}</div>
                  </Tooltip>
                </MenuItem>
              ),
            )}
          </TextField>
        }
        name={name}
        control={control}
        defaultValue={combinator}
      />
    );

    const handleAddGroup = () => addFilterGroup({ path, values: getValues() });

    const handleAddRule = () =>
      addFilterRule({ path, field: fields[0], values: getValues() });

    const handleRemove = () => removeFilter({ path, values: getValues() });

    const renderButtons = (
      <div className={`${flexWrapper} ${groupButtons} `}>
        <Button
          onClick={handleAddGroup}
          className={marginRight}
          label={groupButtonLabel}
          color="secondary"
          iconRight="add"
          tooltip={groupButtonDescription}
          disableRipple={true}
        />
        <Button
          onClick={handleAddRule}
          className={removable ? marginRight : ""}
          label={ruleButtonLabel}
          color="secondary"
          iconRight="add"
          tooltip={ruleButtonDescription}
          disableRipple={true}
        />
        {removable && (
          <IconButton
            onClick={handleRemove}
            icon="delete_outline"
            color="error.main"
            tooltip={deleteGroupButtonDescription}
            disableRipple={true}
          />
        )}
      </div>
    );

    const renderFilters = (
      <div className={ruleGap}>
        {filters?.map(
          (
            groupOrRule: IFilterGroupWithPath | IFilterRuleWithPath,
            i: number,
          ) =>
            isFilterGroup(groupOrRule) ? (
              <FilterGroup
                key={`${JSON.stringify(groupOrRule)}-${i}`}
                group={groupOrRule}
                removable={true}
              />
            ) : (
              !groupOrRule.hidden && (
                <FilterRule
                  key={`${JSON.stringify(groupOrRule)}-${i}`}
                  {...groupOrRule}
                />
              )
            ),
        )}
      </div>
    );

    return (
      <div>
        <div className={`${flexWrapper} ${groupWrapper} `}>
          {renderCombinator}
          {renderButtons}
        </div>
        <div style={{ marginLeft: "8px", borderLeft: "1px solid black" }}>
          {renderFilters}
        </div>
      </div>
    );
  },
);

FilterGroup.displayName = "FilterGroup";

export default FilterGroup;
