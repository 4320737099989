import { selectorScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IState } from "./types";

const scopeSelector = selectorScoper<IState>([
  "staticPages",
  constants.MODULE_NAME,
]);

const ui = (state: any) => scopeSelector(state).ui;
const error = (state: any) => scopeSelector(state).error;
const loading = (state: any) => scopeSelector(state).loading;

export const selectors = {
  ui,
  error,
  loading,
};
