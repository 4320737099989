import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IFileForm } from "../types";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  UPLOAD_FILE_SUCCESS: scopeActionType("UPLOAD_FILE_SUCCESS"),
  UPLOAD_FILE_ERROR: scopeActionType("UPLOAD_FILE_ERROR"),
  UPLOAD_FILE: scopeActionType("UPLOAD_FILE"),
  GET_FILE_TYPES_SUCCESS: scopeActionType("GET_FILE_TYPEs_SUCCESS"),
  GET_FILE_TYPES_ERROR: scopeActionType("GET_FILE_TYPEs_ERROR"),
  GET_FILE_TYPES: scopeActionType("GET_FILE_TYPES"),
  GET_FILE_GROUPS_SUCCESS: scopeActionType("GET_FILE_GROUPS_SUCCESS"),
  GET_FILE_GROUPS_ERROR: scopeActionType("GET_FILE_GROUPS_ERROR"),
  GET_FILE_GROUPS: scopeActionType("GET_FILE_GROUPS"),
};

export const actions = {
  uploadFileSuccess: createAction(types.UPLOAD_FILE_SUCCESS, (uiList: any) => ({
    uiList,
  })),
  uploadFileError: createAction(types.UPLOAD_FILE_ERROR, (error: any) => ({
    error,
  })),
  uploadFile: createAction(types.UPLOAD_FILE, (file: IFileForm) => ({
    file,
  })),
  getFileTypesSuccess: createAction(
    types.GET_FILE_TYPES_SUCCESS,
    (fileTypes: any) => ({
      fileTypes,
    }),
  ),
  getFileTypesError: createAction(types.GET_FILE_TYPES_ERROR, (error: any) => ({
    error,
  })),
  getFileTypes: createAction(types.GET_FILE_TYPES, () => ({})),
  getFileGroupsSuccess: createAction(
    types.GET_FILE_GROUPS_SUCCESS,
    (fileGroups: any) => ({
      fileGroups,
    }),
  ),
  getFileGroupsError: createAction(
    types.GET_FILE_GROUPS_ERROR,
    (error: any) => ({
      error,
    }),
  ),
  getFileGroups: createAction(types.GET_FILE_GROUPS, () => ({})),
};
