import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  CodeInputConfig,
  CodeInputTranslationKeys,
  codeInputSelectorTypes,
} from "./types";
import { CodeInputEditor, defaultElement, editorMetadata } from "./editor";
import { ElementGroup, IRawElementType } from "core";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_code_input",
  group: ElementGroup.Inputs,
  editable: true,
  component: Component,
  configType: CodeInputConfig,
  translationKeys: CodeInputTranslationKeys,
  editorComponent: CodeInputEditor,
  selectorTypes: codeInputSelectorTypes,
  defaultElement,
  editorMetadata,
};

export default elementType;
