import { IDefaultElement } from "core";
import { UntransformedLineChartConfig } from "../types";
import { defaultExpressionValue } from "elementTypes/common/Echarts/editor/Filter";

export const defaultElement: IDefaultElement<UntransformedLineChartConfig> = {
  config: {
    dataSource: {
      viewName: "",
      columns: {
        valueColumnNames: [],
        labelColumnName: "",
      },
    },
    hideLegend: false,
    hideLabel: false,
    filter: defaultExpressionValue,
  },
  i18n: {
    en: {
      title: "",
    },
  },
};
