import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    justifyContent: "space-around",
  },
  labelClass: {
    margin: 0,
  },
}));
