import React, {
  ChangeEvent,
  DragEvent,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Tooltip,
} from "@material-ui/core";

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import SearchIcon from "@material-ui/icons/Search";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";

import Fuse from "fuse.js";

import IconButton from "elementTypes/common/IconButton";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { useElementTypesContext } from "../../../ElementTypesContext";
import { useEditorContext } from "../../EditorContext";
import { IElementType } from "../../../types";
import { useEditorTranslation } from "../../translation";
import { useStyles } from "../styles";
import { useTranslation } from "../../../session";
import { TextField, Typography } from "@material-ui/core";
import { capitalizeFirstLetter } from "utils/string";
import { useTranslator } from "../../../session/translation/createUseTranslation";

type ItemProps = {
  element: IElementType<any>;
  handleDragStart: (text: string) => void;
  handleDrop: () => void;
};

const ElementItem = memo<ItemProps>(
  ({ element: { name, editorMetadata }, handleDragStart, handleDrop }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { expand, expandOpen, itemClass } = useStyles();
    const { showMoreTooltip } = useEditorTranslation();
    const { description, title } = useTranslation(editorMetadata?.i18n);
    const handleExpandClick = () =>
      setExpanded((prevExpanded: boolean) => !prevExpanded);

    const onDragStart = (e: DragEvent<HTMLElement>) => {
      e.dataTransfer.setData("text/plain", name);
      handleDragStart(name);
    };

    const onDrop = (e: DragEvent<HTMLElement>) => {
      handleDrop();
      e.preventDefault();
    };

    return (
      <Box
        marginY={0.5}
        draggable={true}
        unselectable="on"
        onDragStart={onDragStart}
        onDragEnd={onDrop}
        minHeight={38}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className="add-element"
        data-element-type-name={name}
      >
        <Box clone height="100%">
          <CardHeader
            className={itemClass}
            subheader={title ?? name}
            action={
              <>
                {description && (
                  <IconButton
                    icon="expand_more"
                    onClick={handleExpandClick}
                    tooltip={showMoreTooltip}
                    className={classNames(expand, {
                      [expandOpen]: expanded,
                    })}
                  />
                )}
              </>
            }
          />
        </Box>
        {description && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <>
              <CardContent>
                <Typography>{description}</Typography>
              </CardContent>
              <Divider />
            </>
          </Collapse>
        )}
      </Box>
    );
  },
);

export const ElementCreator = memo(() => {
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState<"group" | "alphabetically">(
    "group",
  );

  const {
    availableElementTypes,
    elementTypes: allElementTypes,
  } = useElementTypesContext();

  const {
    searchInputLabel,
    titleTooltipGroup,
    titleTooltipAlphabetically,
    noItemsLabel,
  } = useEditorTranslation();
  const { setDraggableElement } = useEditorContext();

  const { translate } = useTranslator();

  const { flexCenter, inputClass } = useStyles();

  const noResults = useMemo(
    () => (
      <Card variant="outlined">
        <CardContent>{`${noItemsLabel} ${searchValue}`}</CardContent>
      </Card>
    ),
    [noItemsLabel, searchValue],
  );

  const handleDragStart = useCallback(
    (newValue: string) => {
      const elementType = allElementTypes[newValue];
      const defaultSize = elementType?.editorMetadata?.defaultSize;
      const minSize = elementType?.editorMetadata?.minSize;
      const { width, height } = defaultSize ??
        minSize ?? { width: 1, height: 1 };
      setDraggableElement({
        i: newValue as string,
        w: width,
        h: height,
      });
    },
    [setDraggableElement, allElementTypes],
  );

  const handleDrop = useCallback(() => {
    setDraggableElement(null);
  }, [setDraggableElement]);

  const searchData = useMemo(
    () =>
      Object.entries(availableElementTypes).map(([name, type]) => {
        const i18n = type.editorMetadata?.i18n;
        const translated = i18n && translate(i18n);
        return {
          name,
          title: translated?.title ?? "",
          description: translated?.description ?? "",
        };
      }),
    [availableElementTypes, translate],
  );

  const fuse = useMemo(
    () =>
      new Fuse(searchData, {
        keys: [
          { name: "title", weight: 0.7 },
          { name: "description", weight: 0.2 },
        ],
        includeScore: true,
        // https://fusejs.io/concepts/scoring-theory.html#distance-threshold-and-location
      }),
    [searchData],
  );

  const filteredItems = useMemo(
    () =>
      searchValue.trim()
        ? fuse
            .search(searchValue)
            // only take values with a good score (close to 0)
            .filter((result) => result.score! < 0.5)
            .map((result) => [
              result.item.name,
              availableElementTypes[result.item.name],
              result.score,
            ])
        : Object.entries(availableElementTypes).map(([name, type]) => [
            name,
            type,
            0,
          ]),
    [availableElementTypes, fuse, searchValue],
  ) as [string, IElementType<any>, number][];

  const getItemsAlphabetically = useCallback(
    () => (
      <Box mt={0.5} clone className="elements-alphabetically">
        {filteredItems.length ? (
          <Card variant="outlined">
            {filteredItems.map(([elemName, elem]) => (
              <ElementItem
                key={elemName}
                element={elem}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
              />
            ))}
          </Card>
        ) : (
          noResults
        )}
      </Box>
    ),
    [filteredItems, noResults, handleDragStart, handleDrop],
  );

  const getItemsByGroup = useCallback(
    () =>
      // Group by group name
      Object.entries(
        filteredItems
          .map(([, elem]) => elem)
          .reduce((array, ele) => {
            const key = String(ele["group"] ?? "various");

            (array[key] ? array[key] : (array[key] = null || [])).push(ele);
            return array;
          }, {}),
      )
        // Sort group alphabetically
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([groupName, elems]) => (
          <Box mt={0.5} clone key={groupName} className="element-group">
            <Card variant="outlined">
              <CardHeader
                title={capitalizeFirstLetter(groupName)}
                titleTypographyProps={{ align: "center" }}
              />
              {(elems as IElementType[]).map((elem) => (
                <ElementItem
                  key={elem.name}
                  element={elem}
                  handleDragStart={handleDragStart}
                  handleDrop={handleDrop}
                />
              ))}
            </Card>
          </Box>
        )),
    [filteredItems, handleDragStart, handleDrop],
  );

  const renderedItems = useMemo(() => {
    const itemsByGroup = getItemsByGroup();
    return filterValue === "alphabetically" ? (
      getItemsAlphabetically()
    ) : itemsByGroup.length ? (
      itemsByGroup
    ) : (
      <Box mt={0.5} clone>
        {noResults}
      </Box>
    );
  }, [filterValue, getItemsAlphabetically, getItemsByGroup, noResults]);

  const handleSearchTextfieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setSearchValue(e.target.value);

  const handleFilterChange = (
    _event: React.MouseEvent<HTMLElement>,
    filter: "group" | "alphabetically" | null,
  ) => {
    if (filter) {
      setFilterValue(filter);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        id="editor-elements"
      >
        <Card variant="outlined">
          <CardContent className={flexCenter}>
            <TextField
              placeholder={searchInputLabel}
              value={searchValue}
              onChange={handleSearchTextfieldChange}
              inputProps={{ "aria-label": "search" }}
              InputProps={{ startAdornment: <SearchIcon /> }}
              className={inputClass}
              id="editor-elements-search"
            />
            <ToggleButtonGroup
              size="small"
              value={filterValue}
              exclusive={true}
              onChange={handleFilterChange}
            >
              <ToggleButton value="group">
                <Tooltip title={titleTooltipGroup}>
                  <GroupWorkIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="alphabetically">
                <Tooltip title={titleTooltipAlphabetically}>
                  <SortByAlphaIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </CardContent>
        </Card>
      </Box>
      <>{renderedItems}</>
    </>
  );
});
