import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  listIconWidth: {
    minWidth: "44px",
  },
  listIcon: {
    color: theme.palette.divider,
    justifyContent: "center",
    marginLeft: "-10px",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  topMargin: {
    padding: 0,
    marginTop: theme.spacing(),
  },
  draggingItem: {
    border: `1px solid ${theme.palette.divider}`,
  },
}));
