import React, { memo, useMemo, useState } from "react";
import { FixedSizeList, areEqual } from "react-window";
import { dissoc } from "ramda";

import { IFixedRow } from "core";
import { Section, useEditorTranslation, useObjectViewList } from "core/editor";
import { UntransformedTableConfig } from "../../../types";
import { useTableEditorTranslation } from "../../../translation";
import IconButton from "elementTypes/common/IconButton";
import Button from "elementTypes/common/Button";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { ReferenceDialogContent } from "./ReferenceDialogContent";
import { ReferenceItem } from "./ReferenceItem";

const initialReference = {
  fieldName: "",
  targetView: "",
  referencedColumnName: "",
};

export type Reference = typeof initialReference & { isNew?: boolean };

type IRow = IFixedRow<Reference>;
type Props = {
  dataSource: UntransformedTableConfig["dataSource"];
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const References = memo<Props>(({ dataSource, changeConfigValue }) => {
  const { references = {}, viewName } = dataSource;

  const {
    createReferenceTitle,
    editReferenceTitle,
    referencesTitle,
  } = useTableEditorTranslation();
  const {
    cancelButton,
    createButton,
    deleteButton,
    updateButton,
  } = useEditorTranslation();

  const { viewList, getViewByName } = useObjectViewList();

  const [reference, setReference] = useState<Reference | null>(null);

  const fields = useMemo(
    () =>
      getViewByName(viewName)?.fields?.filter(
        (f) =>
          !Object.keys(references).some((ref) => ref === f.name) ||
          f.name === reference?.fieldName,
      ),
    [viewName, references, getViewByName, reference],
  );

  const handleOpenDialog = () =>
    setReference({ ...initialReference, isNew: true });
  const handleClose = () => setReference(null);

  const handleReferenceChange = (
    nextRef: UntransformedTableConfig["dataSource"]["references"],
  ) => {
    changeConfigValue("dataSource", {
      ...dataSource,
      references: nextRef,
    });
  };

  const onSubmit = (data: { [k: string]: any }) => {
    handleReferenceChange({
      ...dissoc(reference?.fieldName ?? "", references),
      [data.fieldName]: {
        viewName: data.targetView,
        identifierName: data.referencedColumnName,
      },
    });

    handleClose();
  };

  const handleDeleteClick = (name: string) =>
    handleReferenceChange(dissoc(name, references));

  const handleReferenceDelete = () => {
    if (reference) {
      handleDeleteClick(reference.fieldName);
      handleClose();
    }
  };

  const items = useMemo(
    () =>
      Object.entries(
        references,
      ).map(
        ([
          fieldName,
          { viewName: targetView, identifierName: referencedColumnName },
        ]) => ({ fieldName, targetView, referencedColumnName }),
      ),
    [references],
  );
  const itemSize = 65;

  const Row = memo<IRow>(({ data, index, style }) => {
    const item = data[index];
    return (
      <ReferenceItem
        {...item}
        style={style}
        onClick={setReference}
        onDelete={handleDeleteClick}
      />
    );
  }, areEqual);

  return (
    <>
      <Section
        title={referencesTitle}
        defaultOpened={Boolean(Object.keys(references).length)}
        headerAction={
          <IconButton
            id="editor-table-reference-add"
            icon="add"
            tooltip="Add Reference"
            onClick={handleOpenDialog}
          />
        }
      >
        {!!items.length && (
          <FixedSizeList
            height={itemSize * Math.min(items.length, 10)}
            itemCount={items.length}
            itemSize={itemSize}
            width="100%"
            itemData={items}
          >
            {Row}
          </FixedSizeList>
        )}
      </Section>
      <DialogWrapper
        isForm={true}
        keepMounted={false}
        open={Boolean(reference)}
        title={reference?.isNew ? createReferenceTitle : editReferenceTitle}
        submitTitle={reference?.isNew ? createButton : updateButton}
        cancelTitle={cancelButton}
        handleClose={handleClose}
        handleSubmit={onSubmit}
        submitDisabled={true}
        fullWidth
        subActions={
          <>
            {!reference?.isNew && (
              <Button
                label={deleteButton}
                color="error.main"
                onClick={handleReferenceDelete}
              />
            )}
          </>
        }
      >
        {reference && (
          <ReferenceDialogContent
            {...reference}
            getView={getViewByName}
            viewList={viewList?.filter((view) => view.name !== viewName) ?? []}
            fields={fields ?? []}
          />
        )}
      </DialogWrapper>
    </>
  );
});
