import { IStaticRouteConfig } from "core/router/types";

import { SettingsPage } from "./component";
import * as reduxModule from "./reduxModule";

export const route: IStaticRouteConfig = {
  auth: true,
  Component: SettingsPage,
  reduxModule,
};
