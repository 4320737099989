import React from "react";

import { Filter } from "elementTypes/common/Echarts/editor";
import { SetupComponent, TranslationComponent } from "./components";

export const EchartsBarChartEditor = React.memo(() => {
  return (
    <>
      <SetupComponent />
      <TranslationComponent />
      <Filter />
    </>
  );
});
