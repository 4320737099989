import React, { memo } from "react";

import { Echarts, IOptionEchartProps } from "../common/Echarts";
import { EchartsCustomProps } from "./types";
import { LoadingComponent } from "layouts/common/Loading";

const EchartsCustomChart = memo<EchartsCustomProps>(
  ({
    element: {
      i18n: { title },
      config: { showBackground },
    },
    option,
    loading,
  }) => {
    const echartsOption: IOptionEchartProps = {
      ...option,
      title: {
        text: title,
        x: "center",
        ...(typeof option.title === "object" ? option.title : {}),
      },
    };

    if (loading) {
      return <LoadingComponent />;
    }

    return <Echarts option={echartsOption} showBackground={showBackground} />;
  },
);

export default EchartsCustomChart;
