import React, { memo, useCallback } from "react";

import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { UntransformedBoolFieldConfig } from "../types";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";

export const BoolFieldEditor = memo(() => {
  const {
    elementModel: {
      id,
      config: { value },
      config,
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedBoolFieldConfig>();

  const wrappedChangeValue = useCallback(
    (newValue: string | boolean) => changeConfigValue("value", newValue),
    [changeConfigValue],
  );

  const { configTitle } = useEditorTranslation();

  return (
    <Section title={configTitle} wrapped={true}>
      <TableColumnEditor
        id={id}
        value={value}
        onChange={wrappedChangeValue}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["boolean"]}
      />
      <CustomExpressionEditor
        value={value}
        config={config}
        disableSwitcher
        onChange={wrappedChangeValue}
      />
    </Section>
  );
});
