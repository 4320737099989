import React from "react";

import { FormControlLabel, MenuItem, Switch } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedStorageImageConfig } from "../../types";
import { useEditorStorageImageTranslation } from "../translation";

export const StylingComponent = React.memo(() => {
  const {
    elementModel: {
      config: { variant, isWrapped },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedStorageImageConfig>();
  const translation = useEditorStorageImageTranslation();

  const changeVariant = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("variant", e.target.value),
    [changeConfigValue],
  );

  const toggleIsWrapped = React.useCallback(
    () => changeConfigValue("isWrapped", !isWrapped),
    [changeConfigValue, isWrapped],
  );

  return (
    <Section title={translation.stylingSectionTitle} wrapped={true}>
      <TextField
        label="Variant"
        select={true}
        value={variant}
        onChange={changeVariant}
        fullWidth={true}
      >
        <MenuItem key={"none"} value={"none"}>
          No special sizing
        </MenuItem>
        <MenuItem key={"contain"} value={"contain"}>
          Scale to see whole image
        </MenuItem>
        <MenuItem key={"stretch"} value={"stretch"}>
          Stretch
        </MenuItem>
        <MenuItem key={"xAxis"} value={"xAxis"}>
          Adjust to width
        </MenuItem>
        <MenuItem key={"yAxis"} value={"yAxis"}>
          Adjust to height
        </MenuItem>
      </TextField>

      <FormControlLabel
        control={
          <Switch checked={Boolean(isWrapped)} onChange={toggleIsWrapped} />
        }
        label={translation.isWrappedLabel}
      />
    </Section>
  );
});
