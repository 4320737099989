import React, { memo } from "react";

import { DatePicker, DateTimePicker, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { ConnectedReduxModuleProps } from "core";
import { getDateValue } from "utils/date";
import { ReduxModule } from "./reduxModule";
import { DateTimeInput } from "./types";

const DefaultDateTimeInput = memo<
  ConnectedReduxModuleProps<ReduxModule, DateTimeInput>
>(
  ({
    value,
    element: {
      config: { nullable, showDatePart = true, showTimePart = true },
      i18n: { label },
    },
    changeValue,
    disabled,
    errors,
  }) => {
    const handleChange = (date: MaterialUiPickersDate | null) =>
      changeValue(date ? date.toISOString() : null);

    const errorProps = {
      ...(errors && {
        error: true,
        helperText: errors,
      }),
    };

    if (showDatePart && showTimePart) {
      return (
        <DateTimePicker
          value={getDateValue(value)}
          label={label}
          onChange={handleChange}
          disabled={disabled}
          fullWidth={true}
          variant="dialog"
          clearable={nullable}
          {...errorProps}
        />
      );
    }

    if (showDatePart) {
      return (
        <DatePicker
          value={getDateValue(value)}
          label={label}
          onChange={handleChange}
          disabled={disabled}
          fullWidth={true}
          variant="dialog"
          clearable={nullable}
          {...errorProps}
        />
      );
    }

    return (
      <TimePicker
        value={value}
        label={label}
        onChange={handleChange}
        disabled={disabled}
        fullWidth={true}
        variant="dialog"
        clearable={nullable}
        {...errorProps}
      />
    );
  },
);

export default DefaultDateTimeInput;
