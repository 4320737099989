export const menu = [
  {
    name: "home",
    title: "Home",
    path: "/admin",
    icon: "dashboard",
  },
  {
    name: "applications",
    title: "Applications",
    path: "/admin/apps",
    icon: "apps",
  },
  {
    name: "authentication",
    title: "Authentication",
    path: "",
    icon: "lock_open",
    menu: [
      {
        name: "users",
        title: "Users",
        path: "/admin/users",
        icon: "people",
      },
      {
        name: "settings",
        title: "Settings",
        path: "/admin/settings",
        icon: "settings",
      },
    ],
  },
  {
    name: "audit",
    title: "Audit",
    path: "",
    icon: "security",
    menu: [
      {
        name: "audit-tables",
        title: "Tables",
        path: "/admin/audits/tables",
        icon: "table_chart",
      },
      {
        name: "audit-users",
        title: "Users",
        path: "/admin/audits/users",
        icon: "people",
      },
    ],
  },
  {
    name: "fileManagement",
    title: "File Management",
    path: "/admin/files",
    icon: "folder",
  },
];
