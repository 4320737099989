import React, { memo } from "react";

import { ConfigEditor, Styling } from "./components";

export const TextInputEditor = memo(() => (
  <>
    <ConfigEditor />
    <Styling />
  </>
));
