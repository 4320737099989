import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      height: "100%",
      width: "100%",
      // min width is mandatory for working in the expression editor with flex
      minWidth: 0,
      paddingTop: theme.spacing(1.25),
      paddingBottom: 1,
      paddingLeft: 3,
      paddingRight: 3,
    },
    adaptToMuiStyles: {
      "& .CodeMirror pre.CodeMirror-line": {
        paddingLeft: theme.spacing(1.75) - 3,
      },
    },
    codeMirror: {
      height: "100%",
      width: "100%",
      borderRadius: theme.shape.borderRadius,
      overflow: "auto",
      "& .CodeMirror": {
        background: "unset",
        height: "100%",
        width: "100%",
      },
      "& .CodeMirror.CodeMirror-fullscreen": {
        position: "fixed",
        zIndex: 1300,
        background: "white",
        top: 96,
        bottom: 40,
      },
    },
    fieldset: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: theme.palette.background.paper,
      pointerEvents: "none",
      padding: 0,
      borderRadius: theme.shape.borderRadius,
      borderWidth: 1,
      borderColor:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)",
      "& legend": {
        marginLeft: theme.spacing(),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        transform: "translateY(-4px)",
      },
      "&:hover, &$focused": {
        borderColor: theme.palette.text.primary,
      },
    },
    focused: {},
  }),
);
