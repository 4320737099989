import React from "react";

import { useCreateInitialGridElement } from "utils/hooks/useCreateInitialElement";
import { FormEditorComponent } from "./component";
import { FormConfig } from "../types";

export const FormEditor = React.memo(() => {
  useCreateInitialGridElement<FormConfig>("form_grid");

  return <FormEditorComponent />;
});
