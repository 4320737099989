import * as t from "io-ts";
import { IElement, UntransformedConfig } from "core/types";
import {
  EChartsTranslationKeys,
  EchartsContainerMultiConfig,
} from "../common/Echarts";

export const BarChartConfig = t.intersection([
  EchartsContainerMultiConfig,
  t.partial({
    hideLabel: t.boolean,
    hideLegend: t.boolean,
  }),
]);

export type BarChartConfig = t.TypeOf<typeof BarChartConfig>;
export type UntransformedBarChartConfig = UntransformedConfig<BarChartConfig>;

export type BarChart = IElement<BarChartConfig, {}, EChartsTranslationKeys>;
