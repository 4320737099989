import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerPaper: {
    padding: 8,
    marginBottom: `${theme.spacing()}px`,
  },
  formPaper: {
    padding: 16,
  },
  footer: {
    textAlign: "center",
  },
  dividerFooter: {
    marginBottom: 16,
    marginTop: 8,
  },
}));

export default useStyles;
