import React, { memo } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";
import { ArrayTextInput } from "./types";

export const DefaultArrayTextInput = memo<
  ConnectedReduxModuleProps<ReduxModule, ArrayTextInput>
>(
  ({
    value,
    element: {
      i18n: { label },
      config: { nullable },
    },
    changeValue,
    disabled,
  }) => {
    const handleInputChange = (_: any, newData: string[] | null) =>
      changeValue(newData);

    // TODO smart detection of pasted / current value (detect / select separator)
    return (
      <Autocomplete
        renderInput={(params: any) => <TextField {...params} label={label} />}
        disableClearable={nullable === false}
        onChange={handleInputChange}
        value={value}
        disabled={disabled}
        multiple
        freeSolo
        options={[]}
      />
    );
  },
);

export default DefaultArrayTextInput;
