import { buildSimpleUncontrolledInputModule } from "core/utils/buildSimpleUncontrolledInputModule";
import { IReduxModuleFactoryArgs } from "core";
import { NumberInput } from "../../types";
import { uncontrolledChangeValue } from "./actions";

const INITIAL_STATE = {
  value: null,
  errors: null,
};

export function uncontrolledReduxModuleFactory({
  element,
  path,
}: IReduxModuleFactoryArgs<NumberInput>) {
  const uncontrolledModule = buildSimpleUncontrolledInputModule(
    element,
    path,
    INITIAL_STATE,
  );

  return {
    ...uncontrolledModule,
    actions: {
      ...uncontrolledModule.actions,
      changeValue: uncontrolledChangeValue(
        uncontrolledModule.actions.changeValue,
      ),
    },
  };
}
