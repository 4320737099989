import React, { memo, useCallback, useMemo } from "react";

import classNames from "classnames";

import MenuItem from "@material-ui/core/MenuItem";

import { LANGUAGES, Language } from "core/types";
import {
  Button,
  ButtonProps,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TranslateIcon from "@material-ui/icons/Translate";
import { ColorVariant, useStyles } from "./styles";

type Props = {
  language: Language;
  changeLanguage: (lang: Language) => void;
  colorVariant?: ColorVariant;
  className?: string;
  fullWidth?: boolean;
  buttonVariant?: ButtonProps["variant"];
};

export const Switcher = memo<Props>(
  ({
    language,
    changeLanguage,
    className,
    colorVariant = "light",
    fullWidth,
    buttonVariant = "text",
  }) => {
    const classes = useStyles({ color: colorVariant });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = useCallback(
      (value: string) => () => {
        changeLanguage(LANGUAGES.find((l: Language) => l.code === value)!);
        handleClose();
      },
      [changeLanguage],
    );

    const options = useMemo(
      () =>
        LANGUAGES.map(({ label: labelOption, code: valueOption }) => (
          <MenuItem key={valueOption} onClick={handleChange(valueOption)}>
            {labelOption.toUpperCase()}
          </MenuItem>
        )),
      [handleChange],
    );

    return (
      <>
        <Button
          variant={buttonVariant}
          onClick={handleClick}
          startIcon={<TranslateIcon />}
          endIcon={<ArrowDropDownIcon />}
          className={classNames(
            className,
            classes.button,
            "language-switch-switcher",
          )}
          fullWidth={fullWidth}
        >
          {language.label}
        </Button>
        <Popper
          open={!!anchorEl}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal
          className={classes.popper}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: "top" }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={!!anchorEl}>{options}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  },
);
