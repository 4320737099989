import { createContext, useContext } from "react";
import { ITableToolsContext } from "./types";

export const TableToolsContext = createContext<ITableToolsContext>({
  applyFilter: () => {
    throw new Error("Not Implemented");
  },
  handleToggleDialogFilter: () => {
    throw new Error("Not Implemented");
  },
  changeSearchValue: () => {
    throw new Error("Not Implemented");
  },
  fields: [],
  filter: null,
  openDialogFilter: false,
  searchInputValue: "",
  loading: false,
  filterDisabled: false,
});

export const TableToolsProvider = TableToolsContext.Provider;
export const TableToolsConsumer = TableToolsContext.Consumer;

export function useTableToolsContext() {
  const context = useContext(TableToolsContext);
  if (context === undefined) {
    throw new Error(
      "useTableContext must be used within a useTableContext.Provider",
    );
  }
  return context;
}
