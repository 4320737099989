import { path } from "ramda";
import { createSelector } from "reselect";

import { FieldPath, FormDataSource } from "elementInterfaces";
import { Actions, Selectors, Types } from "./types";
import { Form } from "../types";

export function buildDataSourceInterface(
  actions: Actions,
  types: Types,
  selectors: Selectors,
  element: Form,
) {
  const {
    config: {
      type,
      dataSource: { multiReference },
    },
  } = element;

  const createFieldValueSelector = (
    fieldPath: FieldPath,
    { defaultValue = null } = {},
  ) =>
    createSelector(
      [selectors.data],
      (data) => path(fieldPath, data) ?? defaultValue,
    );

  const createFieldOriginalValueSelector = (
    fieldPath: FieldPath,
    { defaultValue = null } = {},
  ) =>
    createSelector(
      [selectors.originalData],
      (data) => path(fieldPath, data) ?? defaultValue,
    );

  const createFieldErrorSelector = (fieldPath: FieldPath) =>
    createSelector([selectors.errors], (errors) => {
      let error = path(fieldPath, errors);

      if (!error) {
        for (const key in errors) {
          // TODO refactor this to cover the case that is described here -> https://github.com/cybertec-postgresql/cypex-gui/pull/1017#discussion_r525948881
          if (
            key
              .split(",")
              .map((k) => k.trim())
              .includes(String(fieldPath[0]))
          ) {
            error = errors[key];
          }
        }
      }

      return error;
    });

  const createFieldTouchedSelector = (fieldPath: FieldPath) =>
    createSelector(
      [selectors.touched],
      (touched) => path(fieldPath, touched) ?? false,
    );

  return FormDataSource.implement({
    createFieldValueSelector,
    createFieldOriginalValueSelector,
    createFieldErrorSelector,
    createFieldTouchedSelector,
    changeFieldValue: actions.changeFieldValue,
    changeFieldTouched: actions.changeFieldTouched,
    addSubForm: actions.addSubForm,
    removeSubForm: actions.removeSubForm,
    save: actions.save,
    reset: actions.reset,
    hasChanges: selectors.hasChanges,
    isValid: selectors.isValid,
    types: {
      DATA_READY: types.LOAD_SUCCESS,
    },
    isReadOnly: type === "detail",
    references: multiReference ?? {},
  });
}
