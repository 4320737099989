import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import Button from "elementTypes/common/Button";
import {
  actions as editSettingsActions,
  selectors as editSettingsSelectors,
} from "../reduxModule";
import useStyles from "../styles";
import { ISettingsConfig } from "../../../reduxModule/types";

export const Form = memo(() => {
  const { formPaper, footer, dividerFooter } = useStyles();
  const config = useSelector(editSettingsSelectors.config);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isSubmitted, isValid, dirty },
  } = useForm<ISettingsConfig>({
    mode: "onBlur",
    defaultValues: {
      logoPath: config?.logoPath,
    },
  });

  const submit = (data: ISettingsConfig) => {
    const result: ISettingsConfig = { ...data };
    if (data.logoPath === "") {
      // if logoPath is empty, delete it
      result.logoPath = null;
    }
    dispatch(editSettingsActions.editConfig(result));
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(submit)}>
      <Paper variant="outlined" className={formPaper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="logoPath"
              label="Logo path"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({})}
            />
          </Grid>

          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              disabled={isSubmitting || isSubmitted || !isValid || !dirty}
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Edit"
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
