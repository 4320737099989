import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import { actions as sessionActions } from "core/session/reduxModule/actions";
import { actions as routerActions } from "core/router/reduxModule/actions";
import { actions, types } from "./actions";

import AdminService from "services/admin";

const services = AdminService.getInstance();

function* addUiSaga(action: ReturnType<typeof actions["addUser"]>) {
  const token = yield select(sessionSelectors.token);

  try {
    yield call(services.saveUser, token, action.payload.user);
    yield put(
      sessionActions.enqueueSnackbar({
        message: "User created successfully",
        options: {
          variant: "success",
        },
      }),
    );
    yield put(routerActions.push("/admin/users"));
  } catch (error) {
    yield put(actions.addUserError(error.message ?? error.toString()));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "User was not created",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

function* getRolesSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const rolesList = yield call(services.getAllRoles, token);

    yield put(actions.getRolesSuccess(rolesList));
  } catch (error) {
    yield put(actions.getRolesError(error.message ?? error.toString()));
  }
}

export function* saga() {
  yield all([
    yield takeLatest(types.ADD_USER, addUiSaga),
    yield takeLatest(types.GET_ROLES, getRolesSaga),
  ]);
  yield put(actions.getRoles());
}
