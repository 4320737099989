import Component from "./container";
import { DateTimeFieldConfig } from "./types";
import { DateTimeFieldEditor, defaultElement, editorMetadata } from "./editor";
import { IRawElementType } from "core";

const elementType: IRawElementType = {
  name: "default_date_time_field",
  component: Component,
  editable: true,
  configType: DateTimeFieldConfig,
  editorComponent: DateTimeFieldEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
