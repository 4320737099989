import { IReduxModuleFactoryArgs } from "core";
import { getElementDisplay } from "core/utils/element";
import { MultipleSubForm } from "../types";
import { controlledReduxModuleFactory } from "./controlledReduxModule";

export function reduxModuleFactory({
  element,
}: IReduxModuleFactoryArgs<MultipleSubForm>) {
  if (!element.config.dataSource) {
    throw new Error(
      `Uncontrolled mode not implemented for multiple_sub_form. In element ${getElementDisplay(
        element,
      )}`,
    );
  }

  return controlledReduxModuleFactory(element);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
