import Component from "./container";
import {
  CircularProgressConfig,
  CircularProgressTranslationKeys,
} from "./types";
import {
  CircularProgressEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { IRawElementType } from "core";

const elementType: IRawElementType = {
  name: "default_circular_progress",
  component: Component,
  configType: CircularProgressConfig,
  translationKeys: CircularProgressTranslationKeys,
  editable: true,
  defaultElement,
  editorComponent: CircularProgressEditor,
  editorMetadata,
};

export default elementType;
