import { IReduxModuleFactoryArgs } from "core";
import { DateTimeInput } from "../types";
import { controlledReduxModuleFactory } from "./controlledReduxModule";
import { uncontrolledReduxModuleFactory } from "../../default_date_time_input/reduxModule/uncontrolledReduxModule";

export function reduxModuleFactory(
  args: IReduxModuleFactoryArgs<DateTimeInput>,
) {
  const { element } = args;
  return element.config.dataSource
    ? controlledReduxModuleFactory(element)
    : uncontrolledReduxModuleFactory(args);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
