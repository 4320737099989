import FileSaver from "file-saver";

export const exportToJsonFile = (
  data: Record<string, unknown>,
  exportFileName = "data.json",
) => {
  const dataString = JSON.stringify(data, null, 2);
  const blob = new Blob([dataString], { type: "application/json" });

  FileSaver.saveAs(blob, exportFileName);
};
