import React, { memo, useCallback, useMemo, useState } from "react";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedSliderInputConfig } from "../../types";
import { useSliderInputEditorTranslation } from "../translation";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";

const colorOptions = ["primary", "secondary"];
const orientationOptions = ["vertical", "horizontal"];
const colorAutocompleteOptions = colorOptions.map((colorOption) => ({
  value: colorOption,
  label: colorOption,
}));
const orientationAutocompleteOptions = orientationOptions.map(
  (orientationOption) => ({
    value: orientationOption,
    label: orientationOption,
  }),
);

const findOptionByValue = (arr: SelectOption[], value?: string) =>
  arr.find((o: SelectOption) => o.value === value);

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: { color, orientation },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedSliderInputConfig>();
  const translation = useSliderInputEditorTranslation();
  const [searchColorInputValue, setSearchColorInputValue] = useState<string>(
    "",
  );
  const [
    searchOrientationInputValue,
    setSearchOrientationInputValue,
  ] = useState<string>("");

  const handleChange = useCallback(
    (prop, newValue) => changeConfigValue(prop, newValue),
    [changeConfigValue],
  );

  const selectedColorValue = useMemo(
    () => findOptionByValue(colorAutocompleteOptions, color),
    [color],
  );
  const selectedOrientationValue = useMemo(
    () => findOptionByValue(orientationAutocompleteOptions, orientation),
    [orientation],
  );

  const onChange = (inputName: "color" | "orientation") => (
    value: IAutocompleteValue,
  ) => {
    inputName === "color"
      ? setSearchColorInputValue("")
      : setSearchOrientationInputValue("");
    handleChange(inputName, value);
  };

  return (
    <Section title={translation.stylingTitle} wrapped={true}>
      <BaseAutocomplete
        options={colorAutocompleteOptions}
        onChange={onChange("color")}
        valueObject={selectedColorValue ?? ""}
        name="color"
        label={translation.colorInputLabel}
        isLoading={false}
        searchInputValue={searchColorInputValue}
        onInputChange={setSearchColorInputValue}
        virtualizedList={true}
        isClearable={true}
      />
      <BaseAutocomplete
        options={orientationAutocompleteOptions}
        onChange={onChange("orientation")}
        valueObject={selectedOrientationValue ?? ""}
        name="orientation"
        label={translation.orientationInputLabel}
        isLoading={false}
        searchInputValue={searchOrientationInputValue}
        onInputChange={setSearchOrientationInputValue}
        virtualizedList={true}
        isClearable={true}
      />
    </Section>
  );
});
