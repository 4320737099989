import { makeStyles } from "@material-ui/core";

interface Props {
  value: string;
}

export const useStyles = makeStyles((theme) => ({
  rowField: {
    display: "inline-flex",
    alignItems: "center",
  },
  colorField: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: theme.palette.divider,
    backgroundColor: ({ value }: Props) => value,
    borderRadius: "2px",
    marginRight: "5px",
  },
}));
