import React, { memo, useCallback } from "react";

import { buildCustomExpressionValue } from "core";
import {
  Section,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import IconButton from "../../../common/IconButton";
import { UntransformedAutocompleteInputConfig } from "../../types";
import { useAutocompleteEditorTranslation } from "../translation";

const INITIAL_VALUE = (field?: string) =>
  buildCustomExpressionValue(`{
  combinator: "AND",
  filters: [
    {
      operator: "eq",
      field: "${field ?? ""}",
      value: null,
    },
  ],
}`);

export const Filter = memo(() => {
  const {
    elementModel: {
      config: { filter, reference },
      config,
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedAutocompleteInputConfig>();
  const translation = useAutocompleteEditorTranslation();

  const { getViewByName } = useObjectViewList();

  const getDefaultColumn = useCallback(
    (nextViewName: string) =>
      getViewByName(nextViewName)?.identifyingField?.name,
    [getViewByName],
  );

  const changeFilter = useCallback(
    (newFilter?: string) => changeConfigValue("filter", newFilter),
    [changeConfigValue],
  );

  const changeFixedFilterExpression = (newFilter?: string) => () =>
    changeFilter(newFilter);

  if (!reference) {
    return null;
  }

  return (
    <Section
      title={translation.filterTitle}
      wrapped={Boolean(filter)}
      headerAction={
        <IconButton
          tooltip={filter ? translation.removeFilter : translation.createFilter}
          icon={filter ? "delete_outline" : "add"}
          onClick={changeFixedFilterExpression(
            filter
              ? undefined
              : INITIAL_VALUE(getDefaultColumn(reference.viewName)),
          )}
        />
      }
    >
      {filter && (
        <CustomExpressionEditor
          config={config}
          value={filter}
          onChange={changeFilter}
          disableSwitcher
        />
      )}
    </Section>
  );
});
