import CodeMirror, { Editor, Hint, Hints, Pos, Token } from "codemirror";
import matchSorter from "match-sorter";

import { IAutocompleteKey, Type } from "core/runtime-typing";

CodeMirror.registerHelper("hint", "javascript", scriptHint);

const PROP_SEPARATORS = [".", "[", "]"];

const JS_IDENTIFIER_REGEX = /^[$_\w][$_\w\d]*$/;

export interface IAugmentedHint extends Hint {
  isBuiltin: boolean;
  type: Type;
}

export function scriptHint(editor: Editor, options: any): Hints {
  const cursor = editor.getCursor();
  const startToken = editor.getTokenAt(cursor);
  const hints: Array<IAugmentedHint> = [];
  const result: Hints = {
    list: hints,
    from: Pos(cursor.line, startToken.start),
    to: Pos(cursor.line, startToken.end),
  };

  // examples used to explain functionality in the code below:
  // (1) a[0].
  // (2) b["c"].d
  // (3) e["f
  // (4) "Hello"
  // (5) g.h.i.j.k
  // the cursor starts at the end of each example

  let isDot = false;
  if (startToken.string === ".") {
    // see example (1)
    startToken.type = "property";
    isDot = true;
  }

  // get the context chain to check the properties on the current object
  // needed if type is property
  // if type is string, it could be a property key
  if (startToken.type === "property" || startToken.type === "string") {
    // current token
    let currentToken = startToken;
    const context: Token[] = [];
    while (
      currentToken.type === "property" ||
      // also follow if string or number to make ["key"] and [0] usable
      currentToken.type === "string" ||
      currentToken.type === "number"
    ) {
      // first, get the accessor token (".", "[" or "]")
      if (isDot && currentToken === startToken) {
        // if starting token is ".", don't try to find the accessor as
        // this already is the accessor
        // do nothing
      } else {
        // this token is just used to get the next token
        currentToken = editor.getTokenAt(Pos(cursor.line, currentToken.start));

        if (!PROP_SEPARATORS.includes(currentToken.string)) {
          // no autocompletion for strings if not used as object key
          // see example (4)
          return result;
        }
      }
      // currentToken can now be at
      // (1) a[0].
      //      ^ ^^
      // (2) b["c"].d
      //      ^   ^^
      // (3) e["f
      //      ^

      // get the actual property we care for
      // (3) e["f
      //     ^
      currentToken = editor.getTokenAt(Pos(cursor.line, currentToken.start));
      if (currentToken.string === "]") {
        // example (1)
        // `a[0].`
        //     ^---- t is here
        //    ^----- t will move here
        currentToken = editor.getTokenAt(Pos(cursor.line, currentToken.start));
      }
      context.push(currentToken);
    }

    // use the context chain to access the actual value
    let currentType = options.globalScope;
    // walk through the context in reverse - start with outermost
    // example (5) g.h.i.j.k
    //             ^----------start here
    for (let i = context.length - 1; i >= 0; i--) {
      currentToken = context[i];
      const key =
        currentToken.type === "string"
          ? JSON.parse(currentToken.string)
          : currentToken.string;
      currentType = currentType.getKeyType(key);
      if (!currentType) {
        break;
      }
    }

    // currentValue now is the value at
    // (1) a[0]
    // (2) b["c"]
    // (3) e
    // (5) g.h.i.j

    // only continue if this value is not undefined
    if (currentType) {
      const record = currentType.getAutocompleteRecord();
      const keys = getTokenMatchedKeys(Object.keys(record), startToken);

      let resultHints: Array<IAugmentedHint>;
      if (startToken.type === "string") {
        // wrap string in quotation marks and a closing bracket
        resultHints = keys.map((k) => getHint(record, `"${k}"]`, k));
      } else if (startToken.string === ".") {
        resultHints = keys.map((k) =>
          getHint(
            record,
            JS_IDENTIFIER_REGEX.test(k) ? `.${k}` : `["${k}"]`,
            k,
          ),
        );
      } else {
        resultHints = keys.map((k) => getHint(record, k, k));
      }

      hints.push(...resultHints);
    } else {
      // else do not do anything
      return result;
    }
  } else {
    // just show the root scope keys

    const record = options.globalScope.getAutocompleteRecord();
    const keys = getTokenMatchedKeys(Object.keys(record), startToken);
    // If the type is the global scope, we filter out builtins, so we don't get toString() and other keys like that
    hints.push(
      ...keys
        .filter((k) => !record[k].isBuiltin)
        .map((k) => getHint(record, k, k)),
    );
  }

  if (options.select) {
    CodeMirror.on(result, "select", options.select);
  }

  if (options.pick) {
    CodeMirror.on(result, "pick", options.pick);
  }

  if (options.close) {
    CodeMirror.on(result, "close", options.close);
    // this is needed to close the left box when typing `"` while the hints are open
    // the signal "update" is not documented in the official CodeMirror docs,
    // but it's used here:
    // https://github.com/codemirror/CodeMirror/blob/0ec092019c98ce39584f3cc814732e348b69d570/addon/hint/show-hint.js#L133
    CodeMirror.on(result, "update", options.close);
  }

  // Sort the hints
  result.list = hints.sort((a, b) =>
    a.isBuiltin && !b.isBuiltin
      ? 1
      : !a.isBuiltin && b.isBuiltin
      ? -1
      : a.displayText! < b.displayText!
      ? -1
      : a.displayText! > b.displayText!
      ? 1
      : 0,
  );

  return result;
}

const getHint = (
  record: Record<string, IAutocompleteKey>,
  text: string,
  key: string,
): IAugmentedHint => ({
  text,
  displayText: key,
  className: record[key].isBuiltin
    ? "autocomplete-hint-builtin"
    : "autocomplete-hint-custom",
  ...record[key],
});

// if the type is string, extract the actual value (remove wrapping quotation marks)
function normalizeTokenText({ string, type }: Token): string {
  return type === "string" ? string.replace(/"/g, "") : string;
}

function getTokenMatchedKeys(keys: string[], token: Token): string[] {
  let result = matchSorter(keys, normalizeTokenText(token));
  if (!result.length) {
    result = keys;
  }
  return result;
}
