import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      cursor: "pointer",
    },
  }),
);

export default useStyles;
