import React, { memo, useMemo } from "react";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

import { MuiIcon } from "elementTypes/common/MuiIcon";
import useStyles from "../../styles";
import { MenuItem, itemTitle } from "./MenuItem";
import { menu } from "./menu";

import { AdminMenuConnectedProps } from ".";

type Props = AdminMenuConnectedProps & {
  open: boolean;
  toggleMenu: () => void;
};

export const AdminMenuComponent = memo<Props>(
  ({ ui, open, toggleMenu, menuItemSelected }) => {
    const {
      listNavContainer,
      menu: menuClass,
      toggleButtonTitle,
    } = useStyles();

    const appMenu = menu.map((item) => (
      <MenuItem
        key={item.name}
        {...item}
        title={item.title}
        menuOpen={open}
        menuItemSelected={menuItemSelected}
      />
    ));

    const listIcon = useMemo(
      () => (
        <ListItemIcon>
          <MuiIcon
            icon={open ? "arrow_back_ios" : "arrow_forward_ios"}
            fontSize="default"
          />
        </ListItemIcon>
      ),
      [open],
    );

    const toggleBtn = (
      <ListItem button={true} onClick={toggleMenu}>
        {listIcon}
        {itemTitle(open ? "Collapse Menu" : "", toggleButtonTitle)}
      </ListItem>
    );

    return (
      <Paper className={menuClass} elevation={0} square>
        <div className={listNavContainer}>
          <List component="nav">{appMenu}</List>
          {ui && (
            <div>
              <Divider />
              {open ? (
                toggleBtn
              ) : (
                <Tooltip title="Open Menu" placement="right">
                  {toggleBtn}
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </Paper>
    );
  },
);
