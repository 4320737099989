import * as t from "io-ts";
import {
  IElement,
  UntransformedConfig,
  arrayChild,
  customExpression,
} from "core/types";

import { types } from "core/runtime-typing";
import { SelectorTypes } from "core/types/element";

export const AdvancedConditionalChildren = t.type({
  content: arrayChild("*", { positioned: true }),
});

export const AdvancedConditionalConfig = t.type({
  value: customExpression(t.boolean),
});

export const advancedConditionalSelectors: SelectorTypes<AdvancedConditionalConfig> = {
  visible: types.boolean(),
};

export type UntransformedAdvancedConditionalConfig = UntransformedConfig<
  AdvancedConditionalConfig
>;

export type AdvancedConditionalConfig = t.TypeOf<
  typeof AdvancedConditionalConfig
>;

export type AdvancedConditionalChildren = t.TypeOf<
  typeof AdvancedConditionalChildren
>;

export type AdvancedConditional = IElement<
  AdvancedConditionalConfig,
  AdvancedConditionalChildren
>;
