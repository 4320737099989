import React, { memo, useMemo } from "react";
import { Controller } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";

import { IObjectView, IObjectViewField } from "core";
import { getTranslatedText } from "core/utils/element";
import { useSessionContext } from "core/session";
import { useHookFormContext } from "elementTypes/common/HookForm";
import {
  Autocomplete,
  customRenderOption,
} from "elementTypes/common/Autocomplete";

import { Reference } from "./References";
import Grid from "@material-ui/core/Grid";
import { MuiIcon } from "elementTypes/common/MuiIcon";

type Props = Reference & {
  onClick?: () => void;
  getView: (name: string) => IObjectView | undefined;
  viewList: IObjectView[];
  fields: IObjectViewField[];
};

export const ReferenceDialogContent = memo<Props>(
  ({
    fieldName,
    targetView,
    referencedColumnName,
    getView,
    viewList,
    fields,
  }) => {
    const { control, errors, getValues, setValue } = useHookFormContext();
    const { language } = useSessionContext();

    const fieldOptions = useMemo(
      () =>
        fields.map((f) => ({
          value: f.name,
          label: `${getTranslatedText(language, f.i18n, "title")}`,
        })),
      [fields, language],
    );

    const views = useMemo(
      () =>
        viewList?.map((view) => ({
          value: view.name,
          label: `${
            getTranslatedText(language, view.i18n, "title") ?? view.name
          }`,
        })),
      [viewList, language],
    );

    const values = getValues();

    const currentView = values.targetView?.length
      ? values.targetView
      : targetView;

    const targetFields = useMemo(
      () =>
        getView(currentView)?.fields?.map((field) => ({
          value: field.name,
          label: `${getTranslatedText(language, field.i18n, "title")}`,
        })),
      [getView, currentView, language],
    );

    const onTargetViewChange = ([nextTargetView]: string[]) => {
      setValue("targetView", nextTargetView);
      const view = getView(nextTargetView);
      const identifierField =
        view?.identifyingField?.name ?? view?.fields[0]?.name;

      setValue("referencedColumnName", identifierField);
      return nextTargetView;
    };

    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item md={12}>
          <FormControl fullWidth>
            <Controller
              as={
                <Autocomplete
                  label={"Source Field"}
                  options={fieldOptions}
                  error={errors.fieldName?.type}
                  isClearable={false}
                  customRenderOption={customRenderOption}
                />
              }
              name="fieldName"
              control={control}
              defaultValue={fieldName}
              rules={{
                required: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item md justify="center" container>
          <MuiIcon icon="arrow_downward" fontSize="large" />
        </Grid>

        <Grid item md={12}>
          <FormControl fullWidth>
            <Controller
              as={
                <Autocomplete
                  label={"Target View"}
                  options={views}
                  error={errors.targetView?.type}
                  isClearable={false}
                  customRenderOption={customRenderOption}
                />
              }
              name="targetView"
              control={control}
              defaultValue={targetView}
              rules={{
                required: true,
              }}
              onChange={onTargetViewChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              as={
                <Autocomplete
                  label={"Identifying Target Field"}
                  options={targetFields ?? ([] as any)}
                  error={errors.referencedColumnName?.type}
                  isClearable={false}
                  customRenderOption={customRenderOption}
                />
              }
              name="referencedColumnName"
              control={control}
              defaultValue={referencedColumnName}
              rules={{
                required: true,
              }}
              disabled={!currentView.length}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  },
);
