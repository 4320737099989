import React, { memo, useContext, useMemo } from "react";

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import { Theme, useTheme } from "@material-ui/core/styles";

import { IMenuItem } from "core";
import { SessionContext } from "core/session";
import logoDarkPath from "../Logo/Logo.svg";
import logoLightPath from "../Logo/LogoLight.svg";
import { GenerateMenuList } from "./MenuList";

export type MenuProps = {
  openState: boolean;
  uiName: string;
  menu: IMenuItem[];
  classes: any;
  menuItemSelected: string;
  logo?: {
    path?: string;
    url?: string;
  };
};

type MenuList = Pick<MenuProps, "menu" | "uiName" | "menuItemSelected"> & {
  className?: string;
};

export const MenuList = memo<MenuList>(
  ({ className, menu, uiName, menuItemSelected }) => {
    const { language } = useContext(SessionContext);
    return (
      <div className={className}>
        {GenerateMenuList(menu, uiName, menuItemSelected, language)}
      </div>
    );
  },
);

export const Menu = memo<MenuProps>(
  ({ classes, logo, menu, openState, uiName, menuItemSelected }) => {
    const theme = useTheme<Theme>();
    const logoPath =
      theme.palette.type === "light" ? logoDarkPath : logoLightPath;
    const currentMenu = useMemo(() => menu.sort((a, b) => a.order - b.order), [
      menu,
    ]);

    return (
      <Drawer
        variant="persistent"
        anchor={"left"}
        open={openState}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <div className={classes.drawerHeader}>
            {logo && (
              <a href={logo.url} className={classes.drawerLogo}>
                <img
                  src={logo!.path ?? logoPath}
                  className={classes.logoImg}
                  alt="Cypex"
                />
              </a>
            )}
            <Divider />
          </div>
          <MenuList
            className={classes.rootMenu}
            menu={currentMenu}
            uiName={uiName}
            menuItemSelected={menuItemSelected}
          />
        </div>
      </Drawer>
    );
  },
);
