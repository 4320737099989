import React, { memo } from "react";

import { Section, useEditorTranslation } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import { UntransformedTextFieldConfig } from "../../types";

type Props = {
  id: string;
  config: UntransformedTextFieldConfig;
  onChange: (
    type: keyof UntransformedTextFieldConfig,
  ) => (value: string) => void;
};

export const Text = memo<Props>(({ id, config, onChange }) => {
  const { text } = useEditorTranslation();
  return (
    <Section title={text} wrapped={true}>
      <TableColumnEditor
        id={id}
        value={config.text}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text", "number", "date", "dateTime", "time"]}
        onChange={onChange("text")}
      />
      <CustomExpressionEditor
        value={config.text}
        config={config}
        onChange={onChange("text")}
        disableSwitcher
      />
    </Section>
  );
});
