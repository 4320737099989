import { connect } from "react-redux";

import { IElementComponentProps } from "core";
import { connectErrorHandlerUtils } from "core/utils/react-redux";
import Component from "./component";
import { StorageImage } from "./types";

const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<{}, StorageImage>,
) => ({
  path: element.config.path(state),
});

export type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(
  connectErrorHandlerUtils.enhanceMapStateToProps(mapStateToProps),
)(connectErrorHandlerUtils.enhanceComponent(Component));
