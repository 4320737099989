import React from "react";

import { FormattingComponent, SetupComponent } from "./components";

export const DateTimeFieldEditor = React.memo(() => {
  return (
    <>
      <SetupComponent />
      <FormattingComponent />
    </>
  );
});
