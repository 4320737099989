import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import { ReduxModule, createReducerManager } from "./reducerManager";
import { RouterService } from "./router/RouterService";
import { StorageService, storageService } from "./StorageService";
import { GetElementType } from "./types";
import { Services } from "services";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

interface IOptions {
  services?: any;
  reduxModules?: ReduxModule[];
}

export type AllServices = {
  router: RouterService;
  storage: StorageService;
} & Services;

export default function buildStore(
  getElementType: GetElementType,
  routerService: RouterService,
  { services }: IOptions = {},
) {
  const reducerManager = createReducerManager(getElementType);

  const allServices: AllServices = {
    router: routerService,
    storage: storageService,
    ...services,
  };

  const sagaMiddleware = createSagaMiddleware({
    context: {
      reducerManager,
      services: allServices,
    },
  });

  interface IStoreExtensions {
    reducerManager: typeof reducerManager;
  }

  const store = createStore<any, any, IStoreExtensions, {}>(
    reducerManager.reduce,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );

  store.reducerManager = reducerManager;
  reducerManager.initialize(store, sagaMiddleware);

  sagaMiddleware.run(reducerManager.saga);

  return store;
}

export type ExtendedStore = ReturnType<typeof buildStore>;
