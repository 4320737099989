import React, { memo } from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import { LoadingComponent } from "layouts/common/Loading";
import { UserInfo } from "./components";
import { selectors } from "./reduxModule";
import { ErrorMessage } from "layouts/common/ErrorMessage";
import BackButton from "elementTypes/common/BackButton";

export const ViewUsersPage = memo(() => {
  const { marginTop, paper, marginBottom } = useStyles();
  const loading = useSelector(selectors.loading);
  const user = useSelector(selectors.user);
  const error = useSelector(selectors.error);

  if (error) {
    return (
      <>
        <ErrorMessage error={error} />
        <BackButton />
      </>
    );
  }

  if (loading || !user) {
    return <LoadingComponent />;
  }

  return (
    <section className={marginTop}>
      <Paper variant="outlined" className={paper}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Typography variant="h5" className={marginBottom}>
              {user.email}
            </Typography>
          </Grid>
        </Grid>
        <UserInfo label="Id" icon="format_list_numbered" value={user.id} />
        <UserInfo
          label="Username"
          icon="account_circle"
          value={`${user.userName ? user.userName : "-"}`}
        />
        <UserInfo
          label="Active"
          icon="lock_open"
          value={`${user.isActive ? "Yes" : "No"}`}
        />
        <UserInfo
          label="Administrator"
          icon="supervisor_account"
          value={`${user.isAdmin ? "Yes" : "No"}`}
        />
        <UserInfo label="Role" icon="assignment_ind" value={user.role} />
        <UserInfo label="Language" icon="language" value={user.language} />
      </Paper>
      <BackButton />
    </section>
  );
});
