import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Align } from "../Popover";

type Props = {
  align: Align;
};

export const useStyles = makeStyles(() =>
  createStyles({
    actionsRoot: ({ align }: Props) => ({
      justifyContent: align,
    }),
  }),
);
