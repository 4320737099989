import { ConnectedProps, connect } from "react-redux";

import { selectors as routerSelectors } from "core/router/reduxModule";
import { selectors as sessionSelectors } from "core/session/reduxModule";

import { AdminMenuComponent } from "./component";

export type AdminMenuConnectedProps = ConnectedProps<typeof connector>;

const mapStateToProps = (state: any) => ({
  ui: sessionSelectors.ui(state),
  menuItemSelected: routerSelectors.location(state).pathname,
});

const connector = connect(mapStateToProps);

export const AdminMenu = connector(AdminMenuComponent);
