import { Type } from "./Type";
import { TypeError } from "./TypeError";

export class LiteralType extends Type {
  public name = this.value;

  constructor(public readonly value: any, description?: string) {
    super(description);
  }

  public validate(value: any): true | TypeError {
    return value === this.value ? true : new TypeError(value, this);
  }
}
