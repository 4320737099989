import { createContext, useContext } from "react";

import { IFilterField, UpdateParams } from "./types";

type IFilterContext = {
  fields: IFilterField[];
  addFilterGroup: (params: UpdateParams) => void;
  addFilterRule: (params: UpdateParams & { field: IFilterField }) => void;
  removeFilter: (params: UpdateParams) => void;
};

export const FilterContext = createContext<IFilterContext>({
  fields: [],
  addFilterGroup: () => {
    throw new Error("Not Implemented");
  },
  addFilterRule: () => {
    throw new Error("Not Implemented");
  },
  removeFilter: () => {
    throw new Error("Not Implemented");
  },
});

export const FilterProvider = FilterContext.Provider;
export const FilterConsumer = FilterContext.Consumer;

export function useFilterContext() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error(
      "useFilterContext must be used within a FilterContext.Provider",
    );
  }
  return context;
}
