import { handleActions } from "core/utils/redux";
import { ActionTypes, IState } from "./types";
import { types } from "./actions";

const INITIAL_STATE: IState = {
  error: null,
  loading: false,
  rolesList: {
    loading: false,
    error: null,
    data: null,
  },
  viewList: {
    loading: false,
    error: null,
    data: null,
  },
};

export const reducer = handleActions<IState>(INITIAL_STATE, {
  [types.GENERATE_UI]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [types.GET_ROLES]: (state) => ({
    ...state,
    rolesList: {
      ...state.rolesList,
      loading: true,
      error: null,
    },
  }),
  [types.GET_ROLES_SUCCESS]: (
    state,
    action: ActionTypes["getRolesSuccess"],
  ) => ({
    ...state,
    rolesList: {
      ...state.rolesList,
      loading: false,
      data: action.payload.rolesList,
    },
  }),
  [types.GET_ROLES_ERROR]: (state, action: ActionTypes["getRolesError"]) => ({
    ...state,
    rolesList: {
      ...state.rolesList,
      error: action.payload.error,
    },
  }),
  [types.GET_OBJECT_VIEWS]: (state) => ({
    ...state,
    rolesList: {
      ...state.rolesList,
      loading: true,
      error: null,
    },
  }),
  [types.GET_OBJECT_VIEWS_SUCCESS]: (
    state,
    action: ActionTypes["getObjectViewsSuccess"],
  ) => ({
    ...state,
    viewList: {
      ...state.rolesList,
      loading: false,
      data: action.payload.objectViewList,
    },
  }),
  [types.GET_OBJECT_VIEWS_ERROR]: (
    state,
    action: ActionTypes["getObjectViewsError"],
  ) => ({
    ...state,
    rolesList: {
      ...state.rolesList,
      error: action.payload.error,
    },
  }),
});
