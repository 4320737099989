import React, { ChangeEvent, memo } from "react";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

import { Section, useEditorTranslation } from "core/editor";

import { UntransformedTextFieldConfig } from "../../types";
import { useDefaultTextEditorTranslation } from "../translation";

type Props = {
  rounded: boolean;
  fitContent: boolean;
  onChange: (
    type: keyof UntransformedTextFieldConfig,
  ) => (_: any, checked: boolean | string) => void;
  backgroundExists?: boolean;
  size?: "medium" | "small";
  variant?: "filled" | "outlined";
  fontVariant?: UntransformedTextFieldConfig["fontVariant"];
  wrap?: boolean;
};

enum FontVariants {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  subtitle1 = "subtitle1",
  subtitle2 = "subtitle2",
  body1 = "body1",
  body2 = "body2",
  caption = "caption",
  button = "button",
  overline = "overline",
}

enum Sizes {
  medium = "medium",
  small = "small",
}

enum Variants {
  filled = "filled",
  outlined = "outlined",
}

export const Styles = memo<Props>(
  ({
    rounded,
    fitContent,
    onChange,
    size,
    variant,
    fontVariant,
    backgroundExists,
    wrap = true,
  }) => {
    const {
      fitContentLabel,
      fontVariantLabel,
      variantLabel,
      roundedCornersLabel,
      sizeLabel,
      wrapLabel,
    } = useDefaultTextEditorTranslation();
    const translation = useEditorTranslation();
    const sizes = Object.keys(Sizes).map((currentSize) => (
      <FormControlLabel
        key={currentSize}
        control={<Radio color="primary" />}
        label={translation[`${currentSize}Label`]}
        value={currentSize}
      />
    ));

    const variants = Object.keys(Variants).map((currentVariant) => (
      <FormControlLabel
        key={currentVariant}
        control={<Radio color="primary" />}
        label={translation[`${currentVariant}Label`]}
        value={currentVariant}
      />
    ));

    const fontVariantOptions = Object.keys(FontVariants).map(
      (currentFontVariant) => (
        <MenuItem key={currentFontVariant} value={currentFontVariant}>
          <Typography
            variant={currentFontVariant as TypographyProps["variant"]}
          >
            {currentFontVariant}
          </Typography>
        </MenuItem>
      ),
    );

    const handleFontTypeChange = (ev: ChangeEvent<HTMLInputElement>) =>
      onChange("fontVariant")(ev, ev.target.value);

    return (
      <Section title={translation.stylesTitle} wrapped={true}>
        {backgroundExists && (
          <>
            <FormControl fullWidth={true}>
              <FormLabel component="p">{variantLabel}</FormLabel>
              <RadioGroup
                row={true}
                value={variant ?? Variants.filled}
                onChange={onChange("variant")}
              >
                {variants}
              </RadioGroup>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={fitContent}
                  onChange={onChange("fitContent")}
                />
              }
              label={fitContentLabel}
            />
            {fitContent && (
              <FormControl fullWidth={true}>
                <FormLabel component="p">{sizeLabel}</FormLabel>
                <RadioGroup
                  row={true}
                  value={size ?? Sizes.medium}
                  onChange={onChange("size")}
                >
                  {sizes}
                </RadioGroup>
              </FormControl>
            )}
            <FormControlLabel
              control={
                <Switch checked={rounded} onChange={onChange("rounded")} />
              }
              label={roundedCornersLabel}
            />
          </>
        )}

        <FormControlLabel
          control={<Switch checked={wrap} onChange={onChange("wrap")} />}
          label={wrapLabel}
        />
        <FormControl fullWidth={true}>
          <FormLabel component="p">{fontVariantLabel}</FormLabel>
          <TextField select onChange={handleFontTypeChange} value={fontVariant}>
            {fontVariantOptions}
          </TextField>
        </FormControl>
      </Section>
    );
  },
);
