import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  grip: {
    display: "none",
  },
  toolbar: {
    flexWrap: "nowrap",
    overflow: "auto",
    height: theme.spacing(6),
  },
}));
