import { handleActions } from "core/utils/redux";
import { IState } from "./types";
import { types } from "./actions";

const INITIAL_STATE: IState = {
  error: null,
  loading: false,
  config: null,
};

export const reducer = handleActions<IState>(INITIAL_STATE, {
  [types.LOAD]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [types.LOAD_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    config: action.payload.config,
  }),
  [types.LOAD_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
});
