import { createMuiTheme, fade } from "@material-ui/core";

import { hexToHSLA } from "utils/colors";

const secondaryColor = "#170a4c";
const primaryColor = "#00a1ab";

export const bgColor = hexToHSLA("#E1F1FE", 0.8);

const successColor = "#44ac34";
const errorColor = "#e6312f";
const warningColor = "#ff6f00";

export const textPrimary = "#343958";
export const textSecondary = "#283340";

export const adminTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: successColor,
    },
    warning: { main: warningColor },
    error: { main: errorColor },
    background: {
      default: bgColor,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    action: {
      selected: hexToHSLA(secondaryColor, 0.1),
      active: hexToHSLA(secondaryColor, 0.6),
      hover: hexToHSLA(secondaryColor, 0.15),
      focus: hexToHSLA(secondaryColor, 0.15),
    },
  },
  typography: {
    allVariants: {
      // fontFamily: "good times regular",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: fade(textPrimary, 0.04),
        "&:hover": {
          boxShadow: `1px 1px 6px ${hexToHSLA(textPrimary, 0.15)}`,
          filter: "brightness(0.9)",
        },
      },
      outlinedPrimary: {
        border: `1px solid ${primaryColor}`,
        backgroundColor: fade(primaryColor, 0.04),
        "&:hover": {
          boxShadow: `1px 1px 6px ${hexToHSLA(primaryColor, 0.2)}`,
          filter: "brightness(0.9)",
        },
      },
      outlinedSecondary: {
        border: `1px solid ${secondaryColor}`,
        backgroundColor: fade(secondaryColor, 0.04),
        "&:hover": {
          boxShadow: `1px 1px 6px ${hexToHSLA(secondaryColor, 0.2)}`,
          filter: "brightness(0.9)",
        },
      },
    },
  },
  props: {
    MuiButton: {
      variant: "outlined",
    },
  },
});
