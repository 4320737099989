import { createContext, useContext } from "react";
import { ILocation } from "./reduxModule";

interface IRouterContext {
  location: ILocation | null;
}

export const RouterContext = createContext<IRouterContext>({
  location: null,
});

export function useRouterContext() {
  const context = useContext(RouterContext);
  if (context === undefined) {
    throw new Error(
      "useRouterContext must be used within a RouterContext.Provider",
    );
  }
  return context;
}
