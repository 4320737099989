import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import { actions, types } from "./actions";
import { getLoginConfig } from "services/api";

function* loadSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const data = yield call(getLoginConfig, token);
    yield put(actions.loadSuccess(data));
  } catch (error) {
    yield put(actions.loadError(error.message ?? error.toString()));
  }
}

export function* saga() {
  yield all([yield takeLatest(types.LOAD, loadSaga)]);

  yield put(actions.load());
}
