import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { FileInput } from "../types";
import { Actions, Selectors, Types } from "./types";
import { StorageFileMetadata } from "services/api/types/FileStorage";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: FileInput,
  selectors: Selectors,
) {
  const { accessGroupName, typeGroupName } = element.config;

  function* fetchMetadataSaga() {
    const services: AllServices = yield getContext("services");
    const token = yield select(sessionSelectors.token);

    const currentValue: string | null = yield select(selectors.value);

    if (currentValue !== null) {
      try {
        // fetch metadata about the current file
        const data: StorageFileMetadata = yield call(
          services.api.fileMetadata,
          token,
          currentValue,
        );
        yield put(actions.fetchMetadataSuccess(data));
      } catch (error) {
        yield put(
          actions.fetchMetadataError(error.message ?? error.toString()),
        );
      }
    } else {
      yield put(actions.fetchMetadataSuccess(null));
    }
  }

  function* uploadSaga() {
    const services: AllServices = yield getContext("services");
    const token = yield select(sessionSelectors.token);

    const file = yield select(selectors.file);

    if (file) {
      try {
        const data: StorageFileMetadata = yield call(
          services.api.uploadFile,
          token,
          file,
          accessGroupName,
          typeGroupName,
        );
        yield put(actions.uploadSuccess(data));
        yield put(actions.changeValue(data.fileName));
      } catch (error) {
        yield put(actions.uploadError(error.message ?? error.toString()));
      }
    }
  }

  function* clearSaga() {
    yield put(actions.changeValue(null));
  }

  return function* mainSaga() {
    yield all([
      yield takeLatest(types.UPLOAD, uploadSaga),
      yield takeLatest(types.CLEAR, clearSaga),
      yield takeLatest(types.FETCH_METADATA, fetchMetadataSaga),
    ]);

    yield put(actions.fetchMetadata());
  };
}
