import * as t from "io-ts";
import { IElement } from "core/types";
import { types } from "core/runtime-typing";
import { UntransformedConfig } from "core/types/react";
import {
  EChartsTranslationKeys,
  EchartsContainerMultiConfig,
} from "../common/Echarts";

export const LineChartConfig = t.intersection([
  EchartsContainerMultiConfig,
  t.partial({
    hideLabel: t.boolean,
    hideLegend: t.boolean,
    hideYAxis: t.boolean,
    hideXAxis: t.boolean,
    isArea: t.boolean,
  }),
]);

export type LineChartConfig = t.TypeOf<typeof LineChartConfig>;
export type UntransformedLineChartConfig = UntransformedConfig<LineChartConfig>;

export type LineChart = IElement<LineChartConfig, {}, EChartsTranslationKeys>;

export const selectorTypes = {
  loading: types.boolean(),
  data: types.any(),
  error: types.nullable(types.string()),
};
