import * as t from "io-ts";
import { IElement } from "core/types";

export const HtmlDisplayConfig = t.partial({});

export type HtmlDisplayConfig = t.TypeOf<typeof HtmlDisplayConfig>;

export const HtmlDisplayTranslationKeys = ["text"] as const;

export type HtmlDisplayTranslationKeys = typeof HtmlDisplayTranslationKeys[number];

export type HtmlDisplay = IElement<
  HtmlDisplayConfig,
  {},
  HtmlDisplayTranslationKeys
>;
