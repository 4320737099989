import { Component } from "react";

import { OptionsObject } from "notistack";

import { Notification, NotifierProps } from "./types";

export default class Notifier extends Component<NotifierProps> {
  public displayed: (string | number | undefined)[] = [];

  public storeDisplayed = (id: OptionsObject["key"]) => {
    this.displayed = [...this.displayed, id];
  };

  public shouldComponentUpdate({ notifications: newSnacks }: NotifierProps) {
    if (!newSnacks.length) {
      this.displayed = [];

      return false;
    }

    const { notifications: currentSnacks } = this.props;
    let notExists = false;

    for (const i in newSnacks) {
      const newSnack = newSnacks[i];
      if (newSnack.dismissed) {
        this.props.closeSnackbar(newSnack.key);
        this.props.removeSnackbar(newSnack.key);
      }

      if (notExists) {
        continue;
      }

      notExists =
        notExists ||
        !currentSnacks.filter(({ key }: Notification) => newSnack.key === key)
          .length;
    }

    return notExists;
  }

  public componentDidUpdate() {
    const { notifications } = this.props;

    notifications.forEach(({ key, message, options = {} }: any) => {
      // Do nothing if snackbar is already displayed
      if (!this.displayed.includes(key)) {
        // Display snackbar using notistack
        this.props.enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event: any, reason: any, snackKey: string) => {
            if (options.onClose) {
              options.onClose(event, reason, snackKey);
            }
            // Dispatch action to remove snackbar from redux store
            this.props.removeSnackbar(snackKey);
          },
        });

        // Keep track of snackbars that we've displayed
        this.storeDisplayed(key);
      }
    });
  }

  public render() {
    return null;
  }
}
