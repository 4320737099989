import React, { memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import {
  PageSelector,
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { IPage } from "core/types";
import { ConfigField } from "./ConfigField";
import { InternalLinkFieldConfig } from "../types";
import { useInternalLinkFieldEditorTranslation } from "./translation";

export const Display = memo(() => {
  const { configTitle } = useEditorTranslation();

  const {
    isButtonLabel,
    pageSelectorLabel,
    labelLabel,
  } = useInternalLinkFieldEditorTranslation();
  const {
    elementModel: {
      config,
      config: {
        isButton,
        linkTo,
        linkTo: { params },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<InternalLinkFieldConfig>();

  const toggleIsButton = () => changeConfigValue("isButton", !isButton);

  const handlePageChange = (p: IPage, newParams: any) => {
    changeConfigValue("linkTo", {
      ...linkTo,
      pageId: p.id,
      params: newParams,
    });
  };

  return (
    <>
      <Section title={labelLabel} wrapped={true}>
        <ConfigField name="label" />
      </Section>
      <Section title={configTitle} wrapped={true}>
        <PageSelector
          pageId={(linkTo as any).pageId}
          config={config}
          params={params as any}
          onChange={handlePageChange}
          label={pageSelectorLabel}
        />
      </Section>
      <Section title={"Styling"} wrapped={true}>
        <FormControlLabel
          control={<Switch checked={isButton} onChange={toggleIsButton} />}
          label={isButtonLabel}
        />
      </Section>
    </>
  );
});
