import { IRawElementType } from "core";
import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import { CustomChartConfig, customChartSelectors } from "./types";
import { EChartsTranslationKeys } from "../common/Echarts";
import {
  EchartsCustomChartEditor,
  defaultElement,
  editorMetadata,
} from "./editor";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "echarts_custom",
  component: Component,
  editable: true,
  configType: CustomChartConfig,
  translationKeys: EChartsTranslationKeys,
  defaultElement,
  editorComponent: EchartsCustomChartEditor,
  editorMetadata,
  selectorTypes: customChartSelectors,
};

export default elementType;
