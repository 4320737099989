import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";

import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { UntransformedCustomChartConfig } from "../../types";
import { useCustomChartEditorTranslation } from "../../translation";

export const DataSourceComponent = React.memo(() => {
  const {
    elementModel: {
      config: { dataSource, showBackground = false },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCustomChartConfig>();

  const { viewName } = dataSource;

  const {
    viewLabel,
    valueColumnHelperText,
    showBackgroundLabel,
  } = useCustomChartEditorTranslation();

  const changeDataSource = React.useCallback(
    (newDataSource: UntransformedCustomChartConfig["dataSource"]) =>
      changeConfigValue("dataSource", { ...dataSource, ...newDataSource }),
    [changeConfigValue, dataSource],
  );

  const handleViewNameChange = (newViewName: string) =>
    changeDataSource({
      viewName: newViewName,
    });

  const handleShowBackgroundChange = React.useCallback(
    () => changeConfigValue("showBackground", !showBackground),
    [changeConfigValue, showBackground],
  );

  const { configTitle } = useEditorTranslation();

  return (
    <>
      <Section title={configTitle} wrapped={true}>
        <ViewAutocomplete
          viewValue={viewName}
          viewLabel={viewLabel}
          onViewNameChange={handleViewNameChange}
        />
        <FormHelperText>{valueColumnHelperText}</FormHelperText>
        <FormControlLabel
          control={
            <Switch
              checked={showBackground}
              onChange={handleShowBackgroundChange}
            />
          }
          label={showBackgroundLabel}
        />
      </Section>
    </>
  );
});
