import React, { MouseEvent, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import IconButton, { ButtonProps } from "elementTypes/common/IconButton";
import { useAppBarMenuTranslation } from "./translations";
import {
  Box,
  CardActions,
  CardContent,
  Popover,
  Typography,
} from "@material-ui/core";
import Button from "elementTypes/common/Button";

type Props = {
  color: ButtonProps["color"];
};

export const AppBarMenu = memo<Props>(({ color }) => {
  const {
    settings: settingsTitle,
    logout: logoutTitle,
  } = useAppBarMenuTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const additionalInformation = useSelector(
    sessionSelectors.additionalInformation,
  );
  const isAdmin = useSelector(sessionSelectors.isAdmin);
  const dispatch = useDispatch();
  const settingsHref = "/settings";

  const handleMenu = (e: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleSettingsClick = () => {
    handleClose();
  };
  const handleLogoutClick = () => dispatch(sessionActions.logout());

  if (!additionalInformation) {
    return null;
  }

  let name: string;

  if (additionalInformation.type === "integrated") {
    name = additionalInformation.userName ?? additionalInformation.email;
  } else {
    name = additionalInformation.name;
  }

  return (
    <>
      <IconButton
        color={color}
        onClick={handleMenu}
        icon="account_circle"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        fontSize="large"
        data-testid="user-menu"
      />
      <Popover
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted={true}
      >
        <Box padding={2}>
          <CardContent>
            <Typography variant="subtitle1" align="center" color={color as any}>
              {name}
            </Typography>
            {isAdmin && (
              <Typography variant="subtitle2" align="center" color="error">
                ADMIN
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              iconLeft="settings"
              onClick={handleSettingsClick}
              label={settingsTitle}
              href={settingsHref}
            />
            <Button
              iconRight="exit_to_app"
              onClick={handleLogoutClick}
              label={logoutTitle}
              data-testid="logout-button"
            />
          </CardActions>
        </Box>
      </Popover>
    </>
  );
});
