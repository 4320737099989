import React, { memo, useEffect, useMemo, useState } from "react";
import { DateTimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
  useParentForm,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { TranslationComponent } from "./components";
import { UntransformedDateTimeInputConfig } from "../types";
import { useDateTimeInputEditorTranslation } from "./translation";

export const DateTimeInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const translation = useDateTimeInputEditorTranslation();
  const {
    elementModel: {
      config: {
        dataSource,
        defaultValue,
        showDatePart = true,
        showTimePart = true,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeInputConfig>();
  const {
    fieldPath: [fieldName],
  } = dataSource ?? { fieldPath: [] };
  const { getParentViewField } = useParentForm();

  const isTimeOnly = useMemo(
    () => getParentViewField(fieldName as string)?.generalType.type === "time",
    [fieldName, getParentViewField],
  );

  useEffect(() => {
    if (isTimeOnly && showDatePart) {
      changeConfigValue("showDatePart", false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeOnly]);

  const changeLanguage = (l: Language) => setLang(l);
  const handleDefaultValueInputChange = (date: MaterialUiPickersDate | null) =>
    changeConfigValue("defaultValue", date ? date.toISOString() : null);
  const handleCheckboxInputChange = React.useCallback(
    (input: "showDatePart" | "showTimePart") => (
      _event: React.ChangeEvent<HTMLInputElement>,
      newValue: boolean,
    ) => changeConfigValue(input, newValue),
    [changeConfigValue],
  );

  return (
    <>
      <FormInputDataSourceEditorComponent language={lang} />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        <DateTimePicker
          value={defaultValue ? defaultValue : null}
          label={editorTranslation.defaultValueInputLabel}
          onChange={handleDefaultValueInputChange}
          disabled={Boolean(dataSource)}
          fullWidth={true}
          variant="dialog"
        />
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Switch
                checked={showDatePart}
                onChange={handleCheckboxInputChange("showDatePart")}
                disabled={isTimeOnly}
              />
            }
            label={translation.showDatePartLabel}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showTimePart}
                onChange={handleCheckboxInputChange("showTimePart")}
                disabled={isTimeOnly}
              />
            }
            label={translation.showTimePartLabel}
          />
        </FormGroup>
      </FormInputConfigEditorComponent>
    </>
  );
});
