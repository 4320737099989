import { createUseTranslation } from "core/session/translation";

export const editorTranslation = {
  en: {
    modeTitle: "Mode",
    donutLabel: "Donut",
    labelLabel: "Label",
    labelShowLabel: "Show Label",
    legendShowLabel: "Show Legend",
    pieLabel: "Pie",
    valueLabel: "Value",
    viewLabel: "Source View",
    titleLabel: "Title",
    showBackgroundLabel: "Show Background",
  },
};

export const useChartEditorTranslation = createUseTranslation(
  editorTranslation,
);
