import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

import { BoolInput } from "../../types";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: BoolInput,
) {
  const value = dataSource.createFieldValueSelector(fieldPath, {
    defaultValue: null,
  });

  const disabled = () =>
    dataSource.isReadOnly || Boolean(element.config.disabled);

  const errors = dataSource.createFieldErrorSelector(fieldPath);

  return {
    value,
    disabled,
    errors,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
