import { createSelector } from "reselect";

import { selectorScoper } from "core/utils/redux";
import { IState } from "./types";
import { Table } from "../types";

export function buildSelectors(path: string[], element: Table) {
  const scopeSelector = selectorScoper<IState>(path);

  const loading = (state: any) => scopeSelector(state).loading;
  const data = (state: any) => scopeSelector(state).data;
  const references = (state: any) => scopeSelector(state).references;
  const params = (state: any) => scopeSelector(state).params;
  const loadingParams = (state: any) => scopeSelector(state).loadingParams;
  const error = (state: any) => scopeSelector(state).error;
  const nextFilter = (state: any) => scopeSelector(state).nextFilter;
  const nextPageAvailable = (state: any) =>
    scopeSelector(state).nextPageAvailable;

  const searchInputValue = (state: any) =>
    scopeSelector(state).searchInputValue;

  const metadata = (state: any) => scopeSelector(state).metadata;
  const selected = (state: any) => scopeSelector(state).selected;

  const offset = createSelector([params], (newParams) => newParams.offset);
  const limit = createSelector([params], (newParams) => newParams.limit);
  const order = createSelector([params], (newParams) => newParams.order);

  // Order represented as a dict indexed by field name to help columns know whether to show a sorting icon.
  const orderIndexed = createSelector([order], (newOrder) =>
    newOrder
      ? newOrder.reduce(
          (index, o, i) => ({
            ...index,
            [o.fieldName]: { ...o, pos: i },
          }),
          {},
        )
      : {},
  );

  const hidden = (state: any) =>
    element.config.hidden?.map((h) =>
      h ? (typeof h === "boolean" ? h : h(state)) : null,
    );

  return {
    loading,
    data,
    references,
    params,
    loadingParams,
    offset,
    limit,
    order,
    orderIndexed,
    error,
    nextFilter,
    nextPageAvailable,
    searchInputValue,
    metadata,
    selected,
    hidden,
  };
}
