import React, { memo } from "react";

import { Section, useEditorTranslation } from "core/editor";
import { LinkTo } from "./LinkTo";
import { TypeConfig } from "./TypeConfig";
import { HideSaveAndStayButton } from "./HideAndStayButton";

export const Config = memo(() => {
  const { configTitle } = useEditorTranslation();

  return (
    <Section title={configTitle} wrapped={true}>
      <TypeConfig />
      <LinkTo />
      <HideSaveAndStayButton />
    </Section>
  );
});
