import React, { memo } from "react";

import { ConnectedReduxModuleProps, Element } from "core";
import Button from "../common/Button";
import { ReduxModule } from "./reduxModule";
import { MultipleSubForm } from "./types";

const DefaultMultipleSubForm = memo<
  ConnectedReduxModuleProps<ReduxModule, MultipleSubForm>
>(({ addSubForm, value, element }) => {
  const handleAdd = () => addSubForm();

  const renderSubForms = (value as any[]).map((data, index) => (
    <Element
      key={data.id}
      element={element.children.subForms.element}
      elementProps={{
        index,
        key: data.id,
      }}
    />
  ));

  return (
    <div>
      SubForm
      <Button onClick={handleAdd} label="Add" />
      <div>{renderSubForms}</div>
    </div>
  );
});

export default DefaultMultipleSubForm;
