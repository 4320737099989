import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const DRAWER_HEADER_HEIGHT = "48px";

const getMenuWidth = ({
  menuWidth,
}: {
  menuWidth: string;
  toolbarVariant: string;
}) => menuWidth;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100%",
    },
    appFrame: {
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
      position: "relative",
      width: "100%",
      height: "100%",
      minHeight: "100vh",
      zIndex: 1,
    },
    content: {
      backgroundColor: theme.palette.background.default,
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      willChange: "margin",
    },
    "content-left": {
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
      willChange: "margin",
    },
    "contentShift-left": {
      marginLeft: getMenuWidth,
    },
    drawerPaper: {
      width: getMenuWidth,
      scrollbarWidth: "none",
      "-ms-overflow-style": "-ms-autohiding-scrollbar",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      willChange: "transform, visibility",
    },
    drawerHeader: {
      padding: "0 0px",
      ...theme.mixins.toolbar,
      minHeight: `${DRAWER_HEADER_HEIGHT} !important`,
      position: "sticky",
      top: 0,
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.background.paper,
    },
    drawerLogo: {
      width: "100%",
      height: "100%",
      maxHeight: DRAWER_HEADER_HEIGHT,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      cursor: "pointer",
      overflow: "hidden",
      "& svg, & img": {
        transition: theme.transitions.create("filter", {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
        willChange: "filter",
      },
      "&:hover svg, &:hover img": {
        filter: "brightness(1.15)",
      },
    },
    logoImg: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    cookieConsentContainer: {
      alignItems: "baseline",
      background: "#353535",
      color: "white",
      display: "flex",
      flexWrap: "wrap" as const,
      justifyContent: "center",
      left: "0",
      position: "fixed" as const,
      width: "100%",
      zIndex: 999,
    },
    cookieConsentButton: {
      padding: "5px 10px",
      margin: "15px",
    },
    editMode: {
      backgroundColor: theme.palette.background.default,
      padding: 0,
    },
    rootMenu: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  }),
);

export type DefaultLayoutStyles = ReturnType<typeof useStyles>;

export default useStyles;
