import React, { CSSProperties, memo } from "react";
import clsx from "classnames";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import IconButton from "elementTypes/common/IconButton";
import { useTableEditorTranslation } from "../../../translation";
import { ColumnDetails } from "./Columns";
import { useStyles } from "../styles";
import { getBooleanExpressionValue } from "../utils";

type Props = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  index: number;
  style?: CSSProperties;
  disabled?: boolean;
  onBtnClick: (
    type: "edit" | "delete" | "select",
    params: ColumnDetails | number,
  ) => void;
} & ColumnDetails;

export const ColumnConfig = memo<Props>(
  ({
    name,
    label,
    provided,
    snapshot,
    style,
    onBtnClick,
    isHidden,
    disabled,
    ...rest
  }) => {
    const {
      editColumnTooltip,
      deleteColumnTooltip,
      selectColumnTooltip,
      hiddenColumnTooltip,
      noEditorComponentTooltip,
    } = useTableEditorTranslation();
    const { draggingItem } = useStyles();

    const { isDragging } = snapshot;
    function handleClick(type: "edit" | "delete" | "select") {
      return function () {
        !isDragging &&
          onBtnClick(
            type,
            type === "edit"
              ? ({ name, label, isHidden, ...rest } as ColumnDetails)
              : rest.index,
          );
      };
    }

    const isHiddenExpressionValue =
      getBooleanExpressionValue(isHidden) ?? false;

    return (
      <ListItem
        divider
        className={clsx(
          { [draggingItem]: isDragging },
          "editor-table-section-columns-list-item",
        )}
        selected={isDragging}
        innerRef={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...style,
          ...provided.draggableProps.style,
        }}
      >
        <ListItemText primary={label} />
        <ListItemIcon>
          <IconButton
            icon="delete_outline"
            className="editor-table-column-delete"
            onClick={handleClick("delete")}
            disabled={isDragging}
            tooltip={deleteColumnTooltip}
            edge="end"
          />
          <IconButton
            icon="edit"
            className="editor-table-column-edit"
            onClick={handleClick("edit")}
            disabled={isDragging}
            tooltip={editColumnTooltip}
            edge="end"
          />
          <IconButton
            icon="touch_app"
            className="editor-table-column-select"
            onClick={handleClick("select")}
            disabled={isDragging || isHiddenExpressionValue || disabled}
            tooltip={
              disabled
                ? noEditorComponentTooltip
                : isHiddenExpressionValue
                ? hiddenColumnTooltip
                : selectColumnTooltip
            }
            edge="end"
          />
        </ListItemIcon>
      </ListItem>
    );
  },
);
