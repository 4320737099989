import React, { memo } from "react";

import Typography from "@material-ui/core/Typography";

type Props = {
  error: string;
};

export const RouterError = memo<Props>(({ error }) => (
  <Typography color="error">{error}</Typography>
));
