import { buildSimpleUncontrolledInputModule } from "core/utils/buildSimpleUncontrolledInputModule";
import { IReduxModuleFactoryArgs } from "core";
import { buildUncontrolledActions } from "./actions";
import { uncontrolledSelectors } from "./selectors";
import { AutocompleteInput } from "../../types";
import { buildSaga } from "../controlledReduxModule/saga";
import { buildReducer } from "../controlledReduxModule/reducer";
import { Actions, IState } from "../controlledReduxModule/types";
import { AnyFluxStandardAction } from "core/utils/redux";

const INITIAL_STATE = {
  options: null,
  loadingOptions: false,
  optionsError: null,
  value: null,
  searchInputValue: null,
  errors: null,
  valueObject: [],
  rawOptions: null,
  touched: false,
  disabled: false,
};

export function uncontrolledReduxModuleFactory({
  element,
  path,
}: IReduxModuleFactoryArgs<AutocompleteInput>) {
  const uncontrolledModule = buildSimpleUncontrolledInputModule(
    element,
    path,
    INITIAL_STATE,
  );
  const {
    actions: uncontrolledActions,
    types: uncontrolledTypes,
  } = buildUncontrolledActions(path);

  const actions = {
    ...uncontrolledActions,
    ...uncontrolledModule.actions,
  } as Actions;

  const selectors = {
    ...uncontrolledModule.selectors,
    ...uncontrolledSelectors(path, element, uncontrolledModule.selectors),
  };

  const types = {
    ...uncontrolledModule.types,
    ...uncontrolledTypes,
  };

  const saga = buildSaga(actions, types, element, selectors);

  const ownReducer = buildReducer(types);
  const reducer = <Action extends AnyFluxStandardAction>(
    state: IState,
    action: Action,
  ) => uncontrolledModule.reducer(ownReducer(state, action), action);

  return {
    ...uncontrolledModule,
    actions,
    selectors,
    saga,
    reducer,
  };
}
