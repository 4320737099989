import React, { forwardRef, memo } from "react";
import Badge, { BadgeProps } from "@material-ui/core/Badge";
import Box, { BoxProps } from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab, { FabProps } from "@material-ui/core/Fab";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

import { IMuiIconProps, MuiIcon } from "../MuiIcon";
import { isDefaultSupportedColor } from "./utils";
import Link from "elementTypes/common/Link";

type Placement = TooltipProps["placement"];
type Edge = IconButtonProps["edge"];

export type Props = {
  icon: string;
  tooltip?: string;
  filled?: boolean;
  placement?: Placement;
  edge?: Edge;
  badgeProps?: Partial<BadgeProps>;
  fontSize?: IMuiIconProps["fontSize"];
  processing?: boolean;
  color?: BoxProps["color"];
  href?: string;
} & (IconButtonProps | FabProps);

export type ButtonProps = IconButtonProps | FabProps;

const spinner = <CircularProgress size={24} color="inherit" />;

const Button = memo<Props>(
  ({
    onClick,
    icon,
    tooltip,
    filled,
    placement,
    badgeProps,
    fontSize,
    processing,
    color,
    href,
    ...rest
  }) => {
    const btnIcon = badgeProps ? (
      <Badge {...badgeProps}>
        <MuiIcon icon={icon} fontSize={fontSize} />
      </Badge>
    ) : (
      <MuiIcon icon={icon} fontSize={fontSize} />
    );

    const canHandleColor = isDefaultSupportedColor(color);

    const restWithColor = {
      ...rest,
      color: canHandleColor ? color : undefined,
    };

    const btn = filled ? (
      <Fab onClick={onClick} {...(restWithColor as FabProps)} href={href}>
        {processing ? spinner : btnIcon}
      </Fab>
    ) : (
      <IconButton onClick={onClick} {...(restWithColor as IconButtonProps)}>
        {processing ? spinner : btnIcon}
      </IconButton>
    );

    const colorBtn = canHandleColor ? (
      btn
    ) : (
      <Box color={color} clone>
        {btn}
      </Box>
    );

    const maybeLink =
      href && !restWithColor.disabled ? (
        <Link
          href={!restWithColor.disabled ? href : undefined}
          underline="none"
          color="inherit"
        >
          {colorBtn}
        </Link>
      ) : (
        colorBtn
      );

    return tooltip ? (
      <Tooltip title={tooltip} placement={placement || "left"}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {maybeLink}
        </Box>
      </Tooltip>
    ) : (
      maybeLink
    );
  },
);

export default forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <Button ref={props.innerRef ?? ref} {...props} />
));
