import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import TextField from "@material-ui/core/TextField";

import {
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";

import { usePrevious } from "utils/hooks";
import { UntransformedDataDisplayConfig } from "../types";
import { FormControl, FormLabel, MenuItem } from "@material-ui/core";

import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { MuiIcon } from "../../common/MuiIcon";
import { ToggleButton } from "../../common/ToggleButton";
import { defaultElement } from "./defaultElement";

const colors = ["primary", "secondary", "info", "success", "warning", "error"];

export const DisplayEditor = memo(() => {
  const {
    elementModel: {
      config,
      config: {
        barPosition,
        icon,
        color = "",
        format,
        dataSource: { viewName },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDataDisplayConfig>();
  const { config: { format: defaultFormat } = {} } = defaultElement;

  const handleChange = useCallback(
    (prop, newValue) => changeConfigValue(prop, newValue),
    [changeConfigValue],
  );

  const handleFormatChange = (newFormat: string) =>
    handleChange("format", newFormat);

  const prevViewName = usePrevious(viewName);
  useEffect(() => {
    if (viewName !== prevViewName && format !== defaultFormat) {
      handleChange("format", defaultFormat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewName]);

  const changeIcon = (newIcon: UntransformedDataDisplayConfig["icon"]) =>
    handleChange("icon", newIcon!);

  const handleColorChange = (newColor: string) =>
    handleChange("color", newColor);

  const handleBarPositionChange = (
    _: React.MouseEvent<HTMLElement>,
    value: string | null,
  ) => handleChange("barPosition", value);

  const customFormatInput = ({
    value: formatValue,
    onChange: onFormatChange,
  }: NonExpressionEditorProps) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onFormatChange(e.target.value);

    return (
      <TextField
        label="Format"
        value={formatValue}
        onChange={onCustomChange}
        fullWidth={true}
      />
    );
  };

  const colorDisabled = useMemo(() => !(!!icon?.length || barPosition), [
    icon,
    barPosition,
  ]);

  const customColorInput = ({
    value: colorValue,
    onChange: onColorChange,
  }: NonExpressionEditorProps) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onColorChange(e.target.value);

    return (
      <TextField
        value={colorValue}
        onChange={onCustomChange}
        fullWidth={true}
        select
        disabled={colorDisabled}
      >
        {colors.map((singleColor) => (
          <MenuItem key={singleColor} value={singleColor}>
            {singleColor}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  return (
    <Section title="Display" wrapped={true}>
      <CustomExpressionEditor
        value={format}
        config={config}
        onChange={handleFormatChange}
        label="Format"
        nonExpressionEditor={customFormatInput}
      />
      <IconAutocomplete label="Icon" value={icon} onChange={changeIcon} />
      <CustomExpressionEditor
        value={color}
        config={config}
        onChange={handleColorChange}
        label="Color variant"
        nonExpressionEditor={customColorInput}
        switcherDisabled={colorDisabled}
      />
      <FormControl fullWidth={true}>
        <FormLabel component="p">{"Bar position"}</FormLabel>
        <ToggleButtonGroup
          value={barPosition}
          exclusive
          onChange={handleBarPositionChange}
        >
          <ToggleButton value="left" aria-label="left">
            <MuiIcon icon="border_left" fontSize="large" />
          </ToggleButton>
          <ToggleButton value="top" aria-label="top">
            <MuiIcon icon="border_top" fontSize="large" />
          </ToggleButton>
          <ToggleButton value="right" aria-label="right">
            <MuiIcon icon="border_right" fontSize="large" />
          </ToggleButton>
          <ToggleButton value="bottom" aria-label="bottom">
            <MuiIcon icon="border_bottom" fontSize="large" />
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
    </Section>
  );
});
