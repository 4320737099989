import {
  Theme,
  createStyles,
  lighten,
  makeStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      paddingRight: theme.spacing(1),
    },
    activeButton: {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    nested: ({ depth }: { depth: number }) => ({
      paddingLeft: theme.spacing(depth * 2 + 1),
    }),
    icon: {
      minWidth: 30,
    },
    baseIcon: {
      color: lighten(theme.palette.text.primary, 0.2),

      "$activeButton > &": {
        color: theme.palette.primary.light,
      },
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
  }),
);

export default useStyles;
