import React, { memo } from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Props } from "./container";
import useStyles from "./styles";
import { Box } from "@material-ui/core";

const DefaultExternalLinkField = memo<Props>(
  ({
    element: {
      config: { isButton },
      i18n: { label: i18nLabel },
    },
    link,
  }) => {
    const { text } = useStyles();
    const href = link ? link : i18nLabel;
    const label = i18nLabel ? i18nLabel : link;
    const target = "_blank";

    if (isButton) {
      return (
        <Box clone width="100%" height="100%">
          <Button href={href} target={target} rel="noopener noreferrer">
            {label}
          </Button>
        </Box>
      );
    }

    return (
      <Link href={href} target={target} rel="noopener noreferrer">
        <Typography component="span" className={text}>
          {label}
        </Typography>
      </Link>
    );
  },
);

DefaultExternalLinkField.displayName = "DefaultExternalLinkField";

export default DefaultExternalLinkField;
