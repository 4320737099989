import { createUseTranslation } from "core/session/translation";

export const translation = {
  en: {
    delete: "Delete",
    view: "View",
    upload: "Upload",
    reload: "Reload",
  },
};

export const useFileInputTranslation = createUseTranslation(translation);
