import { ActionCreatorsMapObject } from "redux";
import { Saga } from "redux-saga";

import { IMPLEMENTED_INTERFACES, SET_MODULE_GETTER } from "../constants";
import { ILocation } from "../router/reduxModule";
import { ArgumentTypes } from "../utils/types";
import { IElement } from "./element";
import { NonInitialReducer } from "../utils/redux";

export interface IElementInterface<T> {
  id: string;
  implement: (value: T) => IElementInterfaceImpl<T>;
  get: (module: IReduxModule) => T;
  [SET_MODULE_GETTER]: (getModule: GetModule) => void;
}

export interface IElementInterfaceImpl<T> {
  value: T;
  interface: IElementInterface<T>;
}

export interface IImplementedElementInterfaces {
  [id: string]: IElementInterfaceImpl<any>;
}

export type Implementation<
  I extends IElementInterface<any>
> = I extends IElementInterface<infer T> ? T : never;

export interface IContext<T> {
  id: string;
  provider: (value: T) => IContextProvider<T>;
}

export interface IContextProvider<T> {
  value: T;
  context: IContext<T>;
}

export interface IProvidedContexts {
  [id: string]: IContextProvider<any>;
}

export interface IReduxModule {
  constants?: any;
  actions?: ActionCreatorsMapObject;
  reducer?: NonInitialReducer<any>;
  saga?: Saga;
  selectors?: SelectorsMap;
  context?: IContextProvider<any> | Array<IContextProvider<any>>;
  interfaces?: IElementInterfaceImpl<any> | Array<IElementInterfaceImpl<any>>;
  [IMPLEMENTED_INTERFACES]?: IImplementedElementInterfaces;
}

export type ReduxModuleFactory<
  ReduxModule extends IReduxModule = IReduxModule
  /*Element extends IElement<any, any, any> = IElement<{}>*/ // TODO: uncomment when TODO of IElementType is resolved
> = (args: IReduxModuleFactoryArgs<IElement<any, any, any>>) => ReduxModule;

export interface IGetModuleOptions {
  allowNull?: boolean;
  location?: ILocation;
}

export interface ILifecycleTypes {
  ELEMENT_SHOW: string;
  ELEMENT_HIDE: string;
}

export interface IReduxModuleFactoryArgs<Element = IElement<{}>> {
  path: string[];
  element: Element;
  context: IProvidedContexts;
  lifecycleTypes: ILifecycleTypes;
  getModule: <ReduxModule extends IReduxModule = IReduxModule>(
    id: string | number,
    options?: IGetModuleOptions,
  ) => IGetModuleOptions["allowNull"] extends true
    ? ReduxModule | null
    : ReduxModule;
}

export type GetModule = IReduxModuleFactoryArgs<any>["getModule"];

export interface IReduxModuleContext {
  context: IProvidedContexts;
  scope: string;
  idMaps: Array<Record<string, string>>;
  getModule: GetModule;
}

export type Selector<S> = (state: any) => S;

export type SelectorsMap = Record<string, Selector<any>>;

export type SelectorValues<T extends SelectorsMap> = {
  [P in keyof T]: ReturnType<T[P]>;
};

export type BoundActionCreator<A extends (...args: any[]) => any> = (
  ...args: ArgumentTypes<A>
) => void;

export type BoundActions<T extends ActionCreatorsMapObject> = {
  [P in keyof T]: BoundActionCreator<T[P]>;
};
