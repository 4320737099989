import React from "react";

import {
  Section,
  TranslationEditor,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { Language } from "core/types";
import { useSessionContext } from "core/session/SessionContext";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { CustomChartConfig } from "../../types";
import { useCustomChartEditorTranslation } from "../../translation";

export const TranslationComponent = React.memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = React.useState<Language>(language);
  const {
    elementModel: { i18n },
    changeTranslation,
  } = useElementEditorContext<CustomChartConfig>();
  const editorTranslation = useEditorTranslation();
  const translation = useCustomChartEditorTranslation();
  const changeLanguage = (l: Language) => setLang(l);

  return (
    <Section
      title={editorTranslation.translationTitle}
      wrapped={true}
      cardActions={
        <LanguageSwitch
          language={lang}
          changeLanguage={changeLanguage}
          colorVariant="dark"
          fullWidth={true}
        />
      }
    >
      <TranslationEditor
        translationKey="title"
        i18n={i18n}
        changeTranslation={changeTranslation}
        label={translation.titleLabel}
        language={lang}
      />
    </Section>
  );
});
