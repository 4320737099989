import { createContext, useContext } from "react";
import {
  IElementArrayChild,
  IElementModel,
  IElementSingleChild,
  IElementType,
  TElementModelWithPosition,
  Translation,
} from "../../../types";

export type IElementEditorContext<
  ConfigType = any,
  ChildrenType extends Record<
    string,
    IElementSingleChild | IElementArrayChild
  > = any
> = {
  elementModel:
    | IElementModel<ConfigType, ChildrenType>
    | TElementModelWithPosition<ConfigType, ChildrenType>;
  type: IElementType;
  changeConfigValue: (key: keyof ConfigType, value: any) => void;
  changeTranslation: (value: Translation<string>) => void;
};

const mockPosition = {
  row: 1,
  column: 1,
  width: 1,
  height: 1,
};

const mockElement = {
  id: "0",
  type: { name: "element" },
  i18n: {
    en: {},
  },
  config: {},
  name: "element",
  children: {},
  position: mockPosition,
};

const mockElementType = {
  name: "element",
  component: () => null,
  childrenMetadata: {},
};

export const ElementEditorContext = createContext<
  IElementEditorContext<any, any>
>({
  elementModel: mockElement,
  type: mockElementType,
  changeConfigValue: () => {
    throw new Error("Not implemented");
  },
  changeTranslation: () => {
    throw new Error("Not implemented");
  },
});

export function useElementEditorContext<T = any>() {
  const context = useContext<IElementEditorContext<T>>(ElementEditorContext);
  if (context === undefined) {
    throw new Error(
      "useElementEditorContext must be used within a ElementEditorContext.Provider",
    );
  }
  return context;
}

export const ElementEditorProvider = ElementEditorContext.Provider;
