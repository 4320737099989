import * as t from "io-ts";
import { IElement } from "core/types";
import { CellAlignment } from "../default_table/types";

export const TableHeaderCellConfig = t.partial({
  dataSource: t.type({
    fieldName: t.string,
    sortable: t.boolean,
  }),
  align: CellAlignment,
  width: t.string,
});

export type TableHeaderCellConfig = t.TypeOf<typeof TableHeaderCellConfig>;

export const TableHeaderCellTranslationKeys = ["label"] as const;

export type TableHeaderCellTranslationKeys = typeof TableHeaderCellTranslationKeys[number];

export type TableHeaderCell = IElement<
  TableHeaderCellConfig,
  {},
  TableHeaderCellTranslationKeys
>;

export const I18nContext = t.type({
  textSortTooltip: t.string,
});

export type I18nContext = t.TypeOf<typeof I18nContext>;
