import React, { forwardRef, memo } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

import { useStyles } from "../style";

type Props = {
  name: string;
  onChangeState: (value: any) => () => void;
  i18n: {
    title?: string;
    short_desc?: string;
    long_desc?: string;
  };
};

export const ItemState = memo(
  forwardRef<HTMLLIElement, any>(
    ({ name, onChangeState, i18n }: Props, ref) => {
      const { itemClass } = useStyles();
      const desc = i18n.long_desc;
      const title = i18n.title || i18n.short_desc || name;

      const item = (
        <MenuItem onClick={onChangeState(name)} ref={ref} className={itemClass}>
          {title}
        </MenuItem>
      );

      return desc ? (
        <Tooltip title={desc} placement="right">
          {item}
        </Tooltip>
      ) : (
        item
      );
    },
  ),
);
