import * as t from "io-ts";
import {
  ColorConfig,
  IElement,
  SelectorTypes,
  UntransformedConfig,
} from "core/types";
import { customExpression } from "core/types/customExpression";
import { types } from "core/runtime-typing";

export const CallButtonConfig = t.intersection([
  t.type({
    callName: customExpression(t.string),
    callArgs: customExpression(t.UnknownRecord),
  }),
  t.partial({
    icon: customExpression(t.string),
    color: ColorConfig,
    variant: t.keyof({
      outlined: null,
      contained: null,
    }),
    showNotification: t.boolean,
    disabled: t.boolean,
  }),
]);

export const callButtonSelectors: SelectorTypes<CallButtonConfig> = {
  loading: types.boolean("Indicates if the function is being called right now"),
  error: types.nullable(types.string()),
  result: types.nullable(types.any(), "the function result"),
};

export type CallButtonConfig = t.TypeOf<typeof CallButtonConfig>;

export const CallButtonTranslationKeys = ["label"] as const;

export type CallButtonTranslationKeys = typeof CallButtonTranslationKeys[number];

export type UntransformedCallButtonConfig = UntransformedConfig<
  CallButtonConfig
>;

export type CallButton = IElement<
  CallButtonConfig,
  {},
  CallButtonTranslationKeys
>;
