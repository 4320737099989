import { useRef } from "react";

export function useCache() {
  const cache = useRef(new Map());
  const has = (key: string) => cache.current.has(key);
  const get = (key: string) => cache.current.get(key);
  const set = (key: string, value: any) => cache.current.set(key, value);
  const getOrSet = (key: string, value: any) =>
    has(key) ? get(key) : set(key, value) && get(key);

  return { getOrSet, set, has, get };
}
