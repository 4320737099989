import React, { memo } from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";

type Props = {
  title?: string;
  colSpan?: number;
};

export const EmptyRow = memo<Props>(({ title, colSpan = 10 }) => {
  const { root } = useStyles();
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Typography variant="button" className={root} component="h5">
          {title}
        </Typography>
      </TableCell>
    </TableRow>
  );
});
