import React, {
  ChangeEvent,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedCircularProgressConfig } from "../../types";
import { useCircularProgressEditorTranslation } from "../translation";
import { useStyles } from "./styles";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "../../../default_autocomplete_input/components";
import { SelectOption } from "../../../default_autocomplete_input/types";

const colorOptions = ["primary", "secondary", "inherit"];
const variantOptions = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "subtitle1",
  "subtitle2",
  "body1",
  "caption",
  "button",
  "overline",
];
const colorAutocompleteOptions = colorOptions.map((colorOption) => ({
  value: colorOption,
  label: colorOption,
}));
const variantAutocompleteOptions = variantOptions.map((colorOption) => ({
  value: colorOption,
  label: colorOption,
}));

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: { size, thickness = 0, color, variant },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCircularProgressConfig>();
  const translation = useCircularProgressEditorTranslation();
  const classes = useStyles();
  const [searchColorInputValue, setSearchColorInputValue] = useState<string>(
    "",
  );
  const [searchVariantInputValue, setSearchVariantInputValue] = useState<
    string
  >("");

  const handleChange = useCallback(
    (prop, newValue) => changeConfigValue(prop, newValue),
    [changeConfigValue],
  );

  const selectedColorValue = useMemo(
    () => colorAutocompleteOptions.find((o: SelectOption) => o.value === color),
    [color],
  );

  const selectedVariantValue = useMemo(
    () =>
      variantAutocompleteOptions.find((o: SelectOption) => o.value === variant),
    [variant],
  );

  const handleSizeInputChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => handleChange("size", Number(newValue));

  const handleThicknessInputChange = (
    _event: React.ChangeEvent<{}>,
    value: number | number[],
  ) => {
    if (typeof value === "number") {
      handleChange("thickness", value);
    }
  };

  const handleColorInputChange = (value: IAutocompleteValue) => {
    setSearchColorInputValue("");
    handleChange("color", value);
  };

  const handleVariantInputChange = (value: IAutocompleteValue) => {
    setSearchVariantInputValue("");
    handleChange("variant", value);
  };

  return (
    <Section title={translation.stylingTitle} wrapped={true}>
      <TextField
        value={size}
        name="size"
        type="number"
        label={translation.sizeInputLabel}
        fullWidth={true}
        onChange={handleSizeInputChange}
      />
      <FormControl fullWidth={true} className={classes.topMargin}>
        <FormLabel>{translation.thicknessInputLabel}</FormLabel>
        <Slider
          value={thickness}
          onChange={handleThicknessInputChange}
          min={0}
          max={40}
          valueLabelDisplay="auto"
        />
      </FormControl>
      <BaseAutocomplete
        options={colorAutocompleteOptions}
        onChange={handleColorInputChange}
        valueObject={selectedColorValue ?? ""}
        name="color"
        label={translation.colorInputLabel}
        isLoading={false}
        searchInputValue={searchColorInputValue}
        onInputChange={setSearchColorInputValue}
        virtualizedList={true}
        isClearable={true}
      />
      <BaseAutocomplete
        options={variantAutocompleteOptions}
        onChange={handleVariantInputChange}
        valueObject={selectedVariantValue ?? ""}
        name="variant"
        label={translation.variantInputLabel}
        isLoading={false}
        searchInputValue={searchVariantInputValue}
        onInputChange={setSearchVariantInputValue}
        virtualizedList={true}
        isClearable={true}
      />
    </Section>
  );
});
