import React, { memo } from "react";

import Chip from "@material-ui/core/Chip";

import { IElementComponentProps } from "core";
import { LabelField } from "./types";

type IProps = IElementComponentProps & {
  value: string;
};

const DefaultLabelField = memo<IElementComponentProps<{}, LabelField> & IProps>(
  ({
    element: {
      config: { color, variant, size },
    },
    value,
  }) =>
    value ? (
      <Chip label={value} color={color} variant={variant} size={size} />
    ) : null,
);

export default DefaultLabelField;
