import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

import { ArrayTextInput } from "../../types";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: ArrayTextInput,
) {
  const value = (state: any) =>
    dataSource.createFieldValueSelector(fieldPath, {
      defaultValue: [],
    })(state);

  const disabled = () =>
    dataSource.isReadOnly || Boolean(element.config.disabled);

  return {
    value,
    disabled,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
