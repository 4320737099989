import { FormDataSource } from "elementInterfaces";
import { SubFormTable } from "../../types";
import { buildActions } from "./actions";
import { buildSelectors } from "./selectors";

export function controlledReduxModuleFactory(
  path: string[],
  element: SubFormTable,
) {
  const scope = path.join(".");
  const { elementId, fieldPath } = element.config.dataSource;

  const dataSource = FormDataSource.get(elementId);

  const { actions } = buildActions(scope, fieldPath, dataSource);
  const selectors = buildSelectors(fieldPath, dataSource, element);

  return {
    actions,
    selectors,
  };
}
