import { createContext, useContext } from "react";
import { TableHeaderCell } from "../default_table_header_cell/types";
import { IFilterGroupWithPath } from "./toolsPanel";
import { IOrderIndex, OrderIndexed } from "./reduxModule";
import { TableMetadata } from "./types";

export interface ITableContext {
  orderIndexed: OrderIndexed;
  getColOrder: (
    orderIndexed: OrderIndexed,
    col: TableHeaderCell,
  ) => IOrderIndex | null;
  changeOrder: (name: string, params: { multi: boolean }) => void;
  changeFilter: (filter: IFilterGroupWithPath) => void;
  metadata: TableMetadata;
  error: string | null;
  identifyingColumnName?: string;
  stateColumnName?: string;
  disableSort?: boolean;
}

const TableContext = createContext<ITableContext>({
  orderIndexed: {},
  getColOrder: () => null,
  changeOrder: () => ({}),
  changeFilter: () => {
    throw new Error("Not Implemented");
  },
  metadata: {
    canUpdate: false,
    canDelete: false,
    rows: {},
  },
  error: null,
  disableSort: false,
});

export function useTableContext() {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error(
      "useTableContext must be used within a TableContext.Provider",
    );
  }
  return context;
}

export default TableContext;
