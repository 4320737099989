import React from "react";

import { Section } from "../Section";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { useLanguage } from "utils/hooks";
import { useEditorTranslation } from "../../translation";
import { LanguageSectionProvider } from "./LanguageSectionContext";

export const LanguageSection: React.FC = ({ children }) => {
  const { translationTitle } = useEditorTranslation();
  const { lang, changeLanguage } = useLanguage();

  return (
    <Section
      title={translationTitle}
      wrapped={true}
      cardActions={
        <LanguageSwitch
          language={lang}
          changeLanguage={changeLanguage}
          colorVariant="dark"
          fullWidth={true}
        />
      }
    >
      <LanguageSectionProvider
        value={{
          lang,
          changeLanguage,
        }}
      >
        {children}
      </LanguageSectionProvider>
    </Section>
  );
};
