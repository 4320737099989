import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(),
  },
  marginTop: {
    marginTop: theme.spacing(),
  },
}));

export default useStyles;
