import Component from "./container";
import { InternalLinkFieldConfig, internalLinkFieldSelectors } from "./types";
import { IRawElementType } from "core";
import {
  InternalLinkFieldEditor,
  defaultElement,
  editorMetadata,
} from "./editor";

import { reduxModuleFactory } from "./reduxModule";

export const elementType: IRawElementType = {
  name: "default_internal_link_field",
  reduxModuleFactory,
  component: Component,
  editable: true,
  configType: InternalLinkFieldConfig,
  editorComponent: InternalLinkFieldEditor,
  selectorTypes: internalLinkFieldSelectors,
  defaultElement,
  editorMetadata,
};

export default elementType;
