import { createSelector } from "reselect";

import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";
import { difference } from "utils/object";
import { SubFormTable } from "../../types";
import { HighlightedRows } from "elementTypes/common/TableForm/component";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: SubFormTable,
) {
  const value = (state: any) =>
    dataSource.createFieldValueSelector(fieldPath, {
      defaultValue: [],
    })(state) as Record<string, unknown>[];

  const errors = (state: any) =>
    dataSource.createFieldErrorSelector(fieldPath)(state);

  const touched = (state: any) =>
    dataSource.createFieldTouchedSelector(fieldPath)(state);

  const disabled = () =>
    dataSource.isReadOnly || Boolean(element.config.disabled);

  const originalValue = (state: any) =>
    dataSource.createFieldOriginalValueSelector(fieldPath, {
      defaultValue: [],
    })(state) as Record<string, unknown>[];

  const identifierFieldName =
    dataSource.references[element.config.dataSource.fieldPath[0]]
      .identifierFieldName;

  const highlightedCells = (state: any) =>
    createSelector([value, originalValue], (data, originalData) =>
      data.reduce((acc, curr) => {
        const result = { ...acc };

        const identifierValue = curr[identifierFieldName] as string | number;
        if (identifierValue !== null && identifierValue !== undefined) {
          const originalRow = originalData.find(
            (r) => r[identifierFieldName] === identifierValue,
          );
          if (originalRow) {
            // existing row
            result[identifierValue] = difference(curr, originalRow);
          } else {
            // new row
            result[identifierValue] = Object.keys(curr);
          }
        }

        return result;
      }, {}),
    )(state) as HighlightedRows;

  return {
    value,
    errors,
    touched,
    disabled,
    highlightedCells,
    identifierFieldName: () => identifierFieldName,
  };
}
