import React, { ChangeEvent, memo, useCallback, useState } from "react";

import TextField from "@material-ui/core/TextField";

import { Section, useElementEditorContext } from "core/editor";

import { UntransformedDataDisplayConfig } from "../types";
import { Language } from "core/types";
import { useSessionContext } from "core/session/SessionContext";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { useStyles } from "./styles";

export const LabelEditor = memo(() => {
  const classes = useStyles();
  const {
    elementModel: { i18n },
    changeTranslation,
  } = useElementEditorContext<UntransformedDataDisplayConfig>();
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);

  const changeLanguage = (l: Language) => setLang(l);

  const setLabel = useCallback(
    (value: string) => {
      const newVal = { ...i18n, [lang.code]: { label: value } };
      changeTranslation(newVal);
    },
    [changeTranslation, i18n, lang.code],
  );

  const translation = (i18n[lang.code] || {}) as { label?: string };
  const label = translation.label ?? "";

  const handleLabelChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setLabel(e.target.value),
    [setLabel],
  );

  return (
    <Section
      title="Text"
      wrapped={true}
      classes={{ content: classes.textContent }}
      cardActions={
        <LanguageSwitch
          language={lang}
          changeLanguage={changeLanguage}
          colorVariant="dark"
          fullWidth={true}
        />
      }
    >
      <TextField label="Label" value={label} onChange={handleLabelChange} />
    </Section>
  );
});
