import React, { memo } from "react";
import { SessionContext } from "../SessionContext";
import { mapStateToProps } from "./container";

type Props = ReturnType<typeof mapStateToProps> & {
  children: React.ReactNode;
};

export const SessionProvider = memo<Props>(({ currentLanguage, children }) => (
  <SessionContext.Provider value={{ language: currentLanguage }}>
    {children}
  </SessionContext.Provider>
));

SessionProvider.displayName = "SessionProvider";
