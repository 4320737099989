import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
  }),
);
