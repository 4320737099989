import React, { memo } from "react";

import { Echarts } from ".";

import { IOptionEchartProps } from "./types";

import { IEchartsContainerMultiProps } from "./EchartsContainer";

const EchartsContainerDataset = memo<IEchartsContainerMultiProps>(
  ({
    dataSource: {
      columns: { labelColumnName, valueColumnNames },
    },
    data,
    defaultOptions,
    type,
    title,
    showBackground,
  }) => {
    const defaultSeries = defaultOptions.series ?? [{}];

    const option: IOptionEchartProps = {
      ...defaultOptions,
      yAxis: {
        ...defaultOptions.yAxis,
        type: "value",
      },
      legend: {
        bottom: 0,
        ...defaultOptions.legend,
      },
      xAxis: {
        ...defaultOptions.xAxis,
        type: "category",
      },
      dataset: [
        {
          dimensions: [labelColumnName, ...valueColumnNames],
          source: data,
          sourceHeader: false,
        },
      ],
      series: valueColumnNames.map((o, i) => ({
        name: o,
        ...(defaultSeries[i] ?? defaultSeries[0]),
        type,
      })),
      title: title
        ? {
            text: title,
            x: "center",
            // subtext,
          }
        : null,
    };

    return <Echarts option={option} showBackground={showBackground} />;
  },
);

export default EchartsContainerDataset;
