import { connect } from "react-redux";

import { actions, selectors } from "core/session/reduxModule";
import { actions as editorActions } from "core/editor/reduxModule";
import {
  actions as routerActions,
  selectors as routerSelectors,
} from "core/router/reduxModule";
import { DefaultLayout as DLComponent } from "./component";

export const mapStateToProps = (state: any) => ({
  ui: selectors.ui(state),
  uiOptions: selectors.uiOptions(state),
  location: routerSelectors.location(state),
  isAdminPage: routerSelectors.isAdminPage(state),
  isStaticPage: !!routerSelectors.staticPageId(state),
});

export const mapDispatchToProps = {
  ...actions,
  push: routerActions.push,
  toggleEditMode: editorActions.toggleEditMode,
};

export const DefaultLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DLComponent);
