import React, { memo, useMemo } from "react";
import Cell from "@material-ui/core/TableCell";

import { Element, IElement } from "core";
import { useTableContext } from "../../default_table";
import { DEFAULT_CELL_ALIGN, useBodyStyles } from "../style";
import { CellAlignment } from "../types";
import { TableCellContext } from "./TableCellContext";

type TableCellProps = {
  col: IElement;
  row: any;
  references: Record<string, unknown> | null;
  identifier: string | number;
  alignment?: CellAlignment;
};

export const TableCell = memo<TableCellProps>(
  ({ col, row, identifier, alignment = DEFAULT_CELL_ALIGN, references }) => {
    const { cellClass } = useBodyStyles({});
    const {
      metadata: { rows, canDelete, canUpdate },
      stateColumnName,
    } = useTableContext();

    const tablePermissions = useMemo(
      () => ({
        canUpdate,
        canDelete,
      }),
      [canUpdate, canDelete],
    );

    const rowMetadata = useMemo(
      () =>
        rows && stateColumnName ? rows[row[stateColumnName]] : tablePermissions,
      [tablePermissions, row, rows, stateColumnName],
    );

    return (
      <Cell className={cellClass} align={alignment}>
        <TableCellContext.Provider value={{ alignment }}>
          <Element
            dynamic
            element={col}
            elementProps={{
              data: row,
              key: identifier,
              metadata: rowMetadata,
              references,
            }}
          />
        </TableCellContext.Provider>
      </Cell>
    );
  },
);

TableCell.displayName = "TableCell";
