import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  CallButtonConfig,
  CallButtonTranslationKeys,
  callButtonSelectors,
} from "./types";
import { CallButtonEditor, defaultElement, editorMetadata } from "./editor";
import { IRawElementType } from "core";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_call_button",
  component: Component,
  editable: true,
  configType: CallButtonConfig,
  translationKeys: CallButtonTranslationKeys,
  editorComponent: CallButtonEditor,
  selectorTypes: callButtonSelectors,
  defaultElement,
  editorMetadata,
};

export default elementType;
