import React, { memo } from "react";

import { ConnectedReduxModuleProps } from "core";
import { JsonView } from "../common/JsonView";
import { ReduxModule } from "./reduxModule";
import { JsonInput } from "./types";

const DefaultJsonInput = memo<
  ConnectedReduxModuleProps<ReduxModule, JsonInput>
>(
  ({
    value,
    element: {
      config: { nullable, ...rest },
      i18n: { label },
    },
    changeValue,
    errors,
    disabled,
  }) => (
    <JsonView
      {...rest}
      value={value}
      onValueChange={changeValue}
      label={label}
      nullable={nullable}
      error={errors}
      disabled={disabled}
    />
  ),
);

export default DefaultJsonInput;
