import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IEditUserForm } from "../types";
import { IRole } from "./types";
import { IUser } from "../../../reduxModule/types";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  GET_USER: scopeActionType("GET_USER"),
  GET_USER_SUCCESS: scopeActionType("GET_USER_SUCCESS"),
  GET_USER_ERROR: scopeActionType("GET_USER_ERROR"),
  EDIT_USER: scopeActionType("EDIT_USER"),
  EDIT_USER_ERROR: scopeActionType("EDIT_USER_ERROR"),
  GET_ROLES_SUCCESS: scopeActionType("GET_ROLES_SUCCESS"),
  GET_ROLES_ERROR: scopeActionType("GET_ROLES_ERROR"),
  GET_ROLES: scopeActionType("GET_ROLES"),
};

export const actions = {
  getUser: createAction(types.GET_USER, (userId: string) => ({
    userId,
  })),
  getUserSuccess: createAction(types.GET_USER_SUCCESS, (user: IUser) => ({
    user,
  })),
  getUserError: createAction(types.GET_USER_ERROR, (error: any) => ({
    error,
  })),
  editUser: createAction(
    types.EDIT_USER,
    (userId: string, user: IEditUserForm) => ({
      userId,
      user,
    }),
  ),
  editUserError: createAction(types.EDIT_USER_ERROR, (error: any) => ({
    error,
  })),
  getRolesSuccess: createAction(
    types.GET_ROLES_SUCCESS,
    (rolesList: IRole[]) => ({
      rolesList,
    }),
  ),
  getRolesError: createAction(types.GET_ROLES_ERROR, (error: any) => ({
    error,
  })),
  getRoles: createAction(types.GET_ROLES),
};
