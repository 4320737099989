import React from "react";

import InputAdornment from "@material-ui/core/InputAdornment";

import { IInputAdornment } from ".";
import { MuiIcon } from "../MuiIcon";

const StartInputAdornment: React.FC<IInputAdornment> = ({ icon, text }) => (
  <InputAdornment position="start">
    {icon ? <MuiIcon icon={icon} /> : text}
  </InputAdornment>
);

export default StartInputAdornment;
