import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import noop from "lodash/noop";

import Button from "elementTypes/common/Button";
import { IUIEditForm } from "./types";
import useStyles from "./styles";
import { actions, selectors } from "./reduxModule";
import { TranslationEditor } from "core/editor";
import { useSessionContext } from "core/session";
import { Language, Translation } from "core/types";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import BackButton from "elementTypes/common/BackButton";

export const EditAppsPage = memo(() => {
  const dispatch = useDispatch();
  const ui = useSelector(selectors.ui);
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const { root, footer, dividerFooter } = useStyles();
  const {
    handleSubmit,
    setValue,
    reset,
    control,
    watch,
    formState: { isSubmitting, isSubmitted, isValid },
  } = useForm<IUIEditForm>({
    mode: "onChange",
    defaultValues: {
      i18n: {
        en: {
          label: "",
        },
      },
    },
  });
  const i18n = watch("i18n");

  useEffect(() => {
    if (ui) {
      reset({ i18n: ui.i18nLatest });
    }
  }, [ui, reset]);

  const changeLanguage = (l: Language) => setLang(l);
  const changeTranslation = (value: Translation<string>) => {
    setValue("i18n", value);

    return value;
  };
  const submit = (data: IUIEditForm) => {
    dispatch(actions.editUi(data));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <Grid item={true} xs={12}>
            <TextField
              name="name"
              disabled={true}
              label="Application name"
              fullWidth={true}
              value={ui?.name ?? ""}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid container={true} alignItems="center">
            <Grid item={true} xs={2}>
              <FormControl component="fieldset" fullWidth={true}>
                <FormLabel component="label">
                  <Typography variant="caption">Language</Typography>
                </FormLabel>

                <LanguageSwitch
                  language={lang}
                  changeLanguage={changeLanguage}
                  colorVariant="dark"
                  fullWidth={true}
                />
              </FormControl>
            </Grid>

            <Grid item={true} xs={10}>
              <Controller
                as={
                  <TranslationEditor
                    translationKey="label"
                    i18n={i18n}
                    changeTranslation={noop}
                    label="Label"
                    language={lang}
                  />
                }
                name="i18n"
                control={control}
                rules={{
                  required: true,
                }}
                changeTranslation={changeTranslation}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              disabled={isSubmitting || isSubmitted || !isValid}
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Edit"
            />
          </Grid>
        </Grid>
      </Paper>
      <BackButton />
    </form>
  );
});
