import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

export function convertValue(value: string | null) {
  return value !== null && value !== "" ? Number(value) : null;
}

export function buildActions(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
) {
  const types = {};

  const changeValue = (value: string | null) =>
    dataSource.changeFieldValue(fieldPath, convertValue(value));

  const actions = {
    changeValue,
  };

  return {
    actions,
    types,
  };
}

export type Actions = ReturnType<typeof buildActions>;
