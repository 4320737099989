import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabRoot: {
      display: "grid",
      gridTemplateRows: "50px 1fr",
      gridTemplateColumns: "100%",
    },
    fullSize: {
      height: "100%",
      width: "100%",
    },
    tabsClass: {
      marginTop: theme.spacing(0.25),
      overflow: "auto",
    },
    tabButtonContainer: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    tabContentContainer: {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  }),
);
