import React, { memo, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { MuiIcon } from "../../common/MuiIcon";
import { useStyles } from "../style";

type Props = {
  msg: string;
  error?: boolean;
  icon?: string;
};

export const Info = memo<Props>(({ msg, error, icon, ...rest }) => {
  const { icon: iconStyle, infoWrapper } = useStyles();
  const color = error ? "error" : "inherit";
  const resolvedIcon = useMemo(() => {
    if (icon) {
      if (icon === "loading") {
        return <CircularProgress />;
      } else {
        return <MuiIcon className={iconStyle} icon={icon} color={color} />;
      }
    }

    return null;
  }, [color, icon, iconStyle]);

  return (
    <Typography variant="h5" color={color} className={infoWrapper} {...rest}>
      {resolvedIcon}
      {msg}
    </Typography>
  );
});

Info.displayName = "Info";
