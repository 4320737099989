import Component from "./component";
import { ContainerChildren, ContainerConfig } from "./types";
import { IRawElementType } from "core";
import { ContainerEditor, defaultElement, editorMetadata } from "./editor";

const elementType: IRawElementType = {
  name: "default_container",
  component: Component,
  editable: true,
  configType: ContainerConfig,
  childrenType: ContainerChildren,
  defaultElement,
  editorComponent: ContainerEditor,
  editorMetadata,
};

export default elementType;
