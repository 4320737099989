import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  BoolInputConfig,
  BoolInputTranslationKeys,
  boolInputSelectors,
} from "./types";
import { BoolInputEditor, defaultElement, editorMetadata } from "./editor";
import { ElementGroup, IRawElementType } from "core";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_bool_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: BoolInputConfig,
  selectorTypes: boolInputSelectors,
  translationKeys: BoolInputTranslationKeys,
  editorComponent: BoolInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
