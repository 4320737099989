import React, { memo } from "react";

import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { useBackgroundSelectorTranslation } from "./translation";
import ColorSelect, { IColor, colors } from "../ColorSelect";

export const BG_COLORS = [...colors, "transparent"] as IColor[];

type Props = {
  config: Record<string, any>;
  onChange: (value: string) => void;
};

export const BackgroundSelector = memo<Props>(({ config, onChange }) => {
  const { borderColorLabel, bgColorLabel } = useBackgroundSelectorTranslation();
  const isOutlined = config?.variant === "outlined";

  const customBgColorSelect = ({
    value: textValue,
    onChange: onTextChange,
  }: NonExpressionEditorProps) => (
    <ColorSelect
      value={textValue}
      onChange={onTextChange}
      defaultValue="transparent"
      colorItems={BG_COLORS}
      outlined={isOutlined}
    />
  );

  return (
    <CustomExpressionEditor
      label={isOutlined ? borderColorLabel : bgColorLabel}
      value={config.background}
      config={config}
      onChange={onChange}
      nonExpressionEditor={customBgColorSelect}
    />
  );
});
