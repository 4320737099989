import { FormDataSource } from "elementInterfaces";
import { FormButton } from "../../types";
import { buildActions } from "./actions";
import { buildSelectors } from "./selectors";

export function resetReduxModuleFactory(element: FormButton) {
  const { elementId } = element.config.dataSource;
  const dataSource = FormDataSource.get(elementId);

  const actions = buildActions(dataSource);
  const selectors = buildSelectors(dataSource);

  return {
    actions,
    selectors,
  };
}
