import { IDefaultElement } from "core/types/element";
import { ColorInputConfig } from "../types";

export const defaultElement: IDefaultElement<ColorInputConfig> = {
  i18n: {
    en: {
      label: "",
    },
  },
};
