import React, { memo } from "react";

import Button from "../common/Button";
import { ClipboardCopyButtonProps } from "./container";
import { useStyles } from "./style";

const DefaultClipboardCopyButtonComponent = memo<ClipboardCopyButtonProps>(
  ({
    element: {
      i18n: { label },
      config: { disabled, variant },
    },
    icon,
    color = "info",
    copy,
  }) => {
    const { root } = useStyles();

    return (
      <Button
        onClick={copy}
        label={label}
        className={root}
        iconRight={icon}
        disabled={disabled}
        type="button"
        fullWidth={true}
        color={color}
        variant={variant}
      />
    );
  },
);

export default DefaultClipboardCopyButtonComponent;
