import React, { memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import {
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";
import { UntransformedClipboardCopyButtonConfig } from "../../types";
import { useClipboardCopyButtonEditorTranslation } from "../translation";

export const DisplayComponent = memo(() => {
  const {
    displaySectionTitle,
    disabledLabel,
  } = useClipboardCopyButtonEditorTranslation();
  const {
    elementModel: {
      config: { icon, disabled },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedClipboardCopyButtonConfig>();

  const changeIcon = (
    newIcon: UntransformedClipboardCopyButtonConfig["icon"],
  ) => changeConfigValue("icon", newIcon);

  const handleDisabledInputChange = () =>
    changeConfigValue("disabled", !disabled);

  return (
    <Section title={displaySectionTitle} wrapped={true}>
      <IconAutocomplete label="Icon" value={icon} onChange={changeIcon} />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(disabled)}
            onChange={handleDisabledInputChange}
          />
        }
        label={disabledLabel}
      />
    </Section>
  );
});
