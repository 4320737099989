import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
} from "core/types";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";
import { FormInputConfig } from "../common";
import { SelectorTypes } from "core/types/element";

export const jsonInputSelectors: SelectorTypes<JsonInputConfig> = {
  value: types.nullable(types.anyRecord()),
  errors: types.any(),
  disabled: types.boolean(),
};

export const JsonInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.UnknownRecord])),
  FormInputConfig,
  t.partial({
    collapsed: t.boolean,
    enableClipboard: t.boolean,
  }),
]);

export type JsonInputConfig = t.TypeOf<typeof JsonInputConfig>;

export type UntransformedJsonInputConfigConfig = UntransformedConfig<
  JsonInputConfig
>;

export const JsonInputTranslationKeys = ["label"] as const;

export type JsonInputTranslationKeys = typeof JsonInputTranslationKeys[number];

export type JsonInput = IElement<
  JsonInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  JsonInputTranslationKeys
>;
