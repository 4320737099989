import React, { ChangeEvent, memo } from "react";
import TextField from "@material-ui/core/TextField";

import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { UntransformedClipboardCopyButtonConfig } from "../../types";
import { useClipboardCopyButtonEditorTranslation } from "../translation";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { value },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedClipboardCopyButtonConfig>();
  const { configTitle } = useEditorTranslation();
  const translation = useClipboardCopyButtonEditorTranslation();

  const handleCallArgsChange = (newValue: string) =>
    changeConfigValue("value", newValue);

  const customTextInput = ({
    value: textValue,
    onChange: onTextChange,
  }: NonExpressionEditorProps) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onTextChange(e.target.value);

    return (
      <TextField value={textValue} onChange={onCustomChange} fullWidth={true} />
    );
  };

  return (
    <Section title={configTitle} wrapped={true}>
      <CustomExpressionEditor
        label={translation.valueLabel}
        value={value}
        config={config}
        onChange={handleCallArgsChange}
        nonExpressionEditor={customTextInput}
      />
    </Section>
  );
});
