import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { actions, types } from "./actions";

import AdminService from "services/admin";

const services = AdminService.getInstance();

function* getUserSaga(action: ReturnType<typeof actions["getUser"]>) {
  const token = yield select(sessionSelectors.token);

  try {
    const user = yield call(services.getUser, action.payload.userId, token);

    yield put(actions.getUserSuccess(user));
  } catch (error) {
    yield put(actions.getUserError(error.message ?? error.toString()));
  }
}

export function* saga() {
  const { userId } = yield select(routerSelectors.params);

  yield all([yield takeLatest(types.GET_USER, getUserSaga)]);

  yield put(actions.getUser(userId));
}
