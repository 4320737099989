import React, { memo } from "react";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { LoadingComponent } from "layouts/common/Loading";
import IconButton from "../common/IconButton";
import { AlertBox } from "../common/AlertBox";

import { useStyles } from "./style";
import { MuiIcon } from "../common/MuiIcon";
import { useDataDisplayTranslation } from "./translation";

import { DefaultDataDisplay } from "./component";

const positionToDirection = {
  left: "row",
  top: "column",
  right: "row-reverse",
  bottom: "column-reverse",
} as const;

const DataDisplay = memo<DefaultDataDisplay>(
  ({
    label,
    config: { icon, barPosition = null },
    formattedData,
    error,
    loading,
    load,
    color: colorConfig = "info",
  }) => {
    const onReload = () => load();
    const { root } = useStyles();

    const text = useDataDisplayTranslation();

    const color = `${colorConfig}.main`;

    const hasBar = barPosition !== null;

    const sanitizedFormattedData =
      typeof formattedData === "object" && formattedData !== null
        ? JSON.stringify(formattedData)
        : formattedData;

    let strError;
    if (error) {
      try {
        strError = error.toString();
      } catch (e) {
        strError = "Unexpected Error";
      }
    }

    const inner = (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexGrow="1"
      >
        <IconButton
          icon="refresh"
          tooltip={text.reload}
          onClick={onReload}
          disabled={loading}
          color="inherit"
        />
        <Box flex="1" pb={error ? 1 : 0} pr={error && !icon ? 1 : 0}>
          <Typography variant="subtitle1">{label}</Typography>
          <Typography variant={"h4"}>
            {loading ? <LoadingComponent /> : sanitizedFormattedData}
          </Typography>
          {error && strError && (
            <AlertBox alertTitle="Error" message={strError} />
          )}
        </Box>
        {icon && (
          <Box display="inline-flex" fontSize={80} padding={1} color={color}>
            <MuiIcon icon={icon} />
          </Box>
        )}
      </Box>
    );

    let wrapped: React.ReactNode;
    if (hasBar) {
      wrapped = (
        <Box
          display="flex"
          flexDirection={positionToDirection[barPosition!]}
          flexWrap="nowrap"
          height={1}
        >
          {<Box bgcolor={color} padding={0.5} />}
          {inner}
        </Box>
      );
    } else {
      wrapped = (
        <Box height={1} clone>
          {inner}
        </Box>
      );
    }

    return (
      <Paper variant="outlined" className={root}>
        {wrapped}
      </Paper>
    );
  },
);

export default DataDisplay;
