import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Button from "elementTypes/common/Button";
import useStyles from "../../styles";
import { Table, TableRow } from "../../common";
import {
  actions as settingsActions,
  selectors as settingsSelectors,
} from "./reduxModule";
import { ISettingsConfig } from "./reduxModule/types";

type Row = {
  id: number;
  handleClick?: () => void;
  href?: string;
} & ISettingsConfig;

const Row = memo<Row>(({ handleClick, id, logoPath, href }) => (
  <TableRow rowId={id}>
    <Typography>{logoPath}</Typography>
    <Button
      onClick={handleClick}
      color="secondary"
      label="Edit"
      iconLeft="create"
      href={href}
    />
  </TableRow>
));

export const SettingsPage = memo(() => {
  const dispatch = useDispatch();
  const config = useSelector(settingsSelectors.config);
  const loading = useSelector(settingsSelectors.loading);
  const error = useSelector(settingsSelectors.error);
  const load = () => dispatch(settingsActions.load());

  const titles = ["Logo path", "Actions"];

  const rows = useMemo(
    () =>
      [config].map((setting, key) => (
        <Row
          key={key}
          id={key}
          {...(setting as ISettingsConfig)}
          href="/admin/settings/edit"
        />
      )),
    [config],
  );
  const { horizontallyCenter } = useStyles();

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">Setting</Typography>
      </Grid>
      <Grid item xs={12}>
        <Table
          rows={rows}
          headers={titles.map((title) => ({
            name: title.toLowerCase(),
            title,
          }))}
          onDataReload={load}
          loading={loading}
          error={error}
        />
      </Grid>
    </Grid>
  );
});
