import * as t from "io-ts";
import { IElement } from "core/types";
import { DataSourceConfig } from "elementInterfaces/FormDataSource";
import { SelectorTypes } from "core/types/element";
import { types } from "core/runtime-typing";

/**
 * We use t.keyof(...) instead of t.union(...) to get the benefits stated in the 'Union of string literals'
 * section of https://github.com/gcanti/io-ts
 */
export const FormButtonConfig = t.type({
  type: t.keyof({
    submit: null,
    reset: null,
  }),
  dataSource: DataSourceConfig,
});

export type FormButtonConfig = t.TypeOf<typeof FormButtonConfig>;

export const FormButtonTranslationKeys = ["label"] as const;

export type FormButtonTranslationKeys = typeof FormButtonTranslationKeys[number];

export type FormButton = IElement<
  FormButtonConfig,
  {},
  FormButtonTranslationKeys
>;

export const selectorTypes: SelectorTypes<FormButtonConfig> = {
  disabled: types.boolean(),
};
