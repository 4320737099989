import { handleActions } from "core/utils/redux";
import { IState } from "./types";
import { types } from "./actions";

const INITIAL_STATE: IState = {
  error: null,
  loading: false,
};

export const reducer = handleActions<IState>(INITIAL_STATE, {
  [types.UPDATE_PROFILE]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
});
