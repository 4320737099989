import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  footer: {
    textAlign: "center",
  },
  dividerFooter: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(),
  },
  codeEditor: {
    "& .CodeMirror": {
      height: "150px",
    },
  },
}));

export default useStyles;
