import React, { memo } from "react";

import { Echarts } from ".";

import { IOptionEchartProps } from "./types";

import { IEchartsContainerSingleProps } from "./EchartsContainer";

/*
 * EchartsContainerOneSerie - Echarts Container for echarts that
 * contain only one serie
 *
 */
const EchartsContainerOneSerie = memo<IEchartsContainerSingleProps>(
  ({ dataSource, data, defaultOptions, type, title, showBackground }) => {
    const series = defaultOptions.series ?? [{}];

    const option: IOptionEchartProps = {
      ...defaultOptions,
      series: [
        {
          ...series[0],
          data: data.map((row: any) => ({
            name: row[dataSource.columns.labelColumnName],
            value: row[dataSource.columns.valueColumnName as string],
          })),
          type,
        },
      ],
      legend: {
        bottom: 0,
        ...defaultOptions.legend,
      },
      title: title
        ? {
            text: title,
            x: "center",
            // subtext,
          }
        : null,
    };

    return <Echarts option={option} showBackground={showBackground} />;
  },
);

export default EchartsContainerOneSerie;
