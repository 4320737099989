import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import { actions as sessionActions } from "core/session/reduxModule/actions";
import { actions as routerActions } from "core/router/reduxModule/actions";
import { actions, types } from "./actions";

import AdminService from "services/admin";

const services = AdminService.getInstance();

function* uploadFileSaga(action: ReturnType<typeof actions["uploadFile"]>) {
  const token = yield select(sessionSelectors.token);

  try {
    yield call(services.uploadFile, token, action.payload.file);
    yield put(
      sessionActions.enqueueSnackbar({
        message: "File uploaded successfully",
        options: {
          variant: "success",
        },
      }),
    );
    yield put(routerActions.push("/admin/files"));
  } catch (error) {
    yield put(actions.uploadFileError(error.message ?? error.toString()));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "File was not uploaded",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

function* getFileTypesSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const fileTypes = yield call(services.getFileTypes, token);

    yield put(actions.getFileTypesSuccess(fileTypes));
  } catch (error) {
    yield put(actions.getFileTypesError(error.message ?? error.toString()));
  }
}

function* getFileGroupsSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const fileGroups = yield call(services.getFileGroups, token);

    yield put(actions.getFileGroupsSuccess(fileGroups));
  } catch (error) {
    yield put(actions.getFileTypesError(error.message ?? error.toString()));
  }
}

export function* saga() {
  yield all([
    yield takeLatest(types.UPLOAD_FILE, uploadFileSaga),
    yield takeLatest(types.GET_FILE_TYPES, getFileTypesSaga),
    yield takeLatest(types.GET_FILE_GROUPS, getFileGroupsSaga),
  ]);
}
