import React from "react";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import MuiTabs from "@material-ui/core/Tabs";

import { ConnectedReduxModuleProps, Element } from "core";
import { ReduxModule } from "./reduxModule";
import { useStyles } from "./style";
import { Tabs } from "./types";

type Props = ConnectedReduxModuleProps<ReduxModule, Tabs>;

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  className: string;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper square={true} variant="outlined" className={other.className}>
          {children}
        </Paper>
      )}
    </div>
  );
}

const DefaultTabs = React.memo<Props>(
  ({ select, indexSelected, element: { config, children } }) => {
    const {
      fullSize,
      tabsClass,
      tabRoot,
      tabButtonContainer,
      tabContentContainer,
    } = useStyles();

    const onChange = (
      _event: React.ChangeEvent<Record<string, unknown>>,
      value: number,
    ) => select(value);

    const getTabIndex = (labelName: string) =>
      config.labels.findIndex((label) => label === labelName);

    const tabsProps = {
      ...(config.variant === "standard" && { centered: true }),
      ...(config.variant === "scrollable" && {
        scrollButtons: "auto" as "auto" | "desktop" | "on" | "off",
      }),
    };

    return (
      <div className={clsx(tabRoot, fullSize)}>
        <Paper className={tabButtonContainer} square={true} variant="outlined">
          <MuiTabs
            value={indexSelected}
            onChange={onChange}
            indicatorColor="primary"
            textColor="primary"
            variant={config.variant}
            {...tabsProps}
          >
            {config.labels.map((label, index) => (
              <Tab key={index} label={label} />
            ))}
          </MuiTabs>
        </Paper>
        <div className={clsx(tabsClass, fullSize)}>
          {children.content.elements.map((element, i) => (
            <TabPanel
              value={indexSelected}
              index={getTabIndex(element.name)}
              key={i}
              className={clsx(fullSize, tabContentContainer)}
            >
              <Element key={element.id} element={element} />
            </TabPanel>
          ))}
        </div>
      </div>
    );
  },
);

export default DefaultTabs;
