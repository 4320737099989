import { IReduxModuleFactoryArgs } from "core";
import { AutocompleteInput } from "../types";
import { controlledReduxModuleFactory } from "./controlledReduxModule";
import { uncontrolledReduxModuleFactory } from "./uncontrolledReduxModule";

export function reduxModuleFactory(
  args: IReduxModuleFactoryArgs<AutocompleteInput>,
) {
  const { element, path } = args;

  return element.config.dataSource
    ? controlledReduxModuleFactory(path, element)
    : uncontrolledReduxModuleFactory(args);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
