import { buildActions } from "./actions";
import { buildReducer } from "./reducer";
import { buildSelectors } from "./selectors";

export function uncontrolledReduxModuleFactory(path: string[]) {
  const scope = path.join(".");
  const { actions, types } = buildActions(scope);
  const reducer = buildReducer(types);
  const selectors = buildSelectors(path);

  return {
    actions,
    reducer,
    selectors,
  };
}

export * from "./types";
