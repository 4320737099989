import { createUseTranslation } from "core/session/translation";

export const dateTimeInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    placementInputLabel: "Placement",
    labelLabel: "Label",
    showDatePartLabel: "Show Date",
    showTimePartLabel: "Show Time",
  },
};

export const useDateTimeInputEditorTranslation = createUseTranslation(
  dateTimeInputEditorTranslation,
);
