import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  FormButtonConfig,
  FormButtonTranslationKeys,
  selectorTypes,
} from "./types";
import { IRawElementType } from "core/types/element";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_form_button",
  component: Component,
  editable: true,
  configType: FormButtonConfig,
  translationKeys: FormButtonTranslationKeys,
  selectorTypes,
};

export default elementType;
