import { connect } from "react-redux";

import { withSnackbar } from "notistack";

import { actions, selectors } from "../reduxModule";

import Component from "./component";

const mapStateToProps = (state: any) => ({
  notifications: selectors.notifications(state),
});

export const mapDispatchToProps = {
  removeSnackbar: actions.removeSnackbar,
  closeSnackbar: actions.closeSnackbar,
};

export const Notifier = withSnackbar<any>(
  connect(mapStateToProps, mapDispatchToProps)(Component),
);
