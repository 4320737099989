import {
  FieldPath,
  FormDataSourceImplementation,
} from "../../../../elementInterfaces";
import { GeoJSONInput } from "../../types";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: GeoJSONInput,
) {
  const value = dataSource.createFieldValueSelector(fieldPath, {
    defaultValue: null,
  });

  const errors = dataSource.createFieldErrorSelector(fieldPath);

  const touched = dataSource.createFieldTouchedSelector(fieldPath);

  const disabled = () =>
    dataSource.isReadOnly || Boolean(element.config.disabled);

  return {
    value,
    errors,
    touched,
    disabled,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
