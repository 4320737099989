import React from "react";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Element, IElementComponentProps } from "core";
import { Container } from "./types";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
  }),
);

const DefaultContainer = React.memo<IElementComponentProps<any, Container>>(
  ({
    element: {
      children: { content },
    },
  }) => {
    const classes = useStyles();

    return (
      <Paper variant="outlined" className={classes.root}>
        {content.elements.map((el) => (
          <Element key={el.id} element={el} />
        ))}
      </Paper>
    );
  },
);

export default DefaultContainer;
