import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const LEFT_ASIDE = "250px";
export const RIGHT_ASIDE = "300px";

const ASIDE_TRANSFORM = "margin 0.2s linear";

export const useGridStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      // total height - top bar - footer - 2x padding
      height: `calc(100vh - ${theme.spacing(6)}px - ${theme.spacing(5)}px)`,
      flexDirection: "column",
    },
    topBlock: {
      height: theme.spacing(6),
      width: "100%",
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    asideLeft: {
      gridArea: "left",
      display: "flex",
      flexDirection: "column",
      width: LEFT_ASIDE,
      marginLeft: 0,
      transition: ASIDE_TRANSFORM,
      borderRight: `1px solid ${theme.palette.divider}`,
      willChange: "margin-left",
    },
    asideRight: {
      gridArea: "right",
      display: "flex",
      flexDirection: "column",
      width: RIGHT_ASIDE,
      marginRight: 0,
      transition: ASIDE_TRANSFORM,
      borderLeft: `1px solid ${theme.palette.divider}`,
      willChange: "margin-right",
    },
    asideLeftHidden: {
      marginLeft: `-${LEFT_ASIDE}`,
    },
    asideRightHidden: {
      marginRight: `-${RIGHT_ASIDE}`,
    },
  }),
);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentSection: {
      gridArea: "content",
      overflow: "auto",
      scrollbarWidth: "thin",
      flexGrow: 1,
      "&::-webkit-scrollbar": {
        width: 6,
        background: "#f0f0f0",
        opacity: 0,
      },
      background: theme.palette.background.default,
    },
    overflowContent: {
      height: "100%",
    },
    paddingBox: {
      padding: theme.spacing(),
    },
    tabRoot: {
      // without !important, this class is overwritten by the default MuiTab class
      minWidth: "unset !important",
    },
    panelContent: {
      scrollbarWidth: "thin",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 6,
        background: "#f0f0f0",
        opacity: 0,
      },
      "&:hover::-webkit-scrollbar": {
        opacity: 1,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#cdcdcd",
      },
    },
    topButtons: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    pageField: {
      padding: `0 ${theme.spacing()}px ${theme.spacing(0.5)}px`,
    },
    flexBlock: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    positionsControl: {
      display: "grid",
      gridTemplateColumns: "auto 1fr auto",
      gridGap: theme.spacing(2),
      alignItems: "center",
    },
    marginBlock: {
      margin: `${theme.spacing(0.5)}px 0 ${theme.spacing()}px`,
    },
    inputClass: {
      "& input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input": {
        "-moz-appearance": "textfield",
        appearance: "none",
      },
    },
    infoTitle: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      "& > [class*='MuiIconButton-root']": {
        marginTop: `-${theme.spacing(1.5)}px`,
      },
    },
    infoCard: {
      borderLeft: "unset",
      borderRight: "unset",
    },
    cursor: {
      cursor: "pointer",
    },
    infoTexts: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    flexColumn: {
      minHeight: "100%",
      "& > [class*='MuiCard-root']:last-child": {
        flex: 1,
      },
    },
    rotateIcon: {
      "& [class*='MuiSvgIcon-root']": {
        transform: "rotate(180deg) translateY(-1px)",
      },
    },
  }),
);

export default useStyles;
