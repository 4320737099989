import * as t from "io-ts";
import { IElement } from "core/types";
import { types } from "core/runtime-typing";

export interface IMarker {
  lat: number;
  lng: number;
  name: string;
}

/**
 * TODO:
 * We can make IMarker be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const markerType = types.interface({
  lat: types.number(),
  lng: types.number(),
  name: types.string(),
});

export const AdvancedGoogleMapsConfig = t.type({
  dataSource: t.type({
    viewName: t.string,
    latColumnName: t.string,
    lngColumnName: t.string,
    placeName: t.string,
  }),
  key: t.string,
  defaultZoom: t.number,
  defaultCenter: t.type({
    lat: t.number,
    lng: t.number,
  }),
});

export const advancedGoogleMapsSelectorTypes = {
  loading: types.boolean(),
  data: types.any(),
  error: types.nullable(types.string()),
  selected: types.nullable(markerType),
};

export type AdvancedGoogleMapsConfig = t.TypeOf<
  typeof AdvancedGoogleMapsConfig
>;

export type AdvancedGoogleMaps = IElement<AdvancedGoogleMapsConfig>;
