import React, { memo } from "react";

import Autocomplete, {
  AutocompleteRenderOptionState,
} from "@material-ui/lab/Autocomplete";

import { SelectOption } from "../types";
import { useListStyles, useStyles } from "../style";

import { CommonProps, IAutocompleteValue } from "./BaseAutocomplete";
import matchSorter from "match-sorter";

type SingleAutocompleteProps = {
  value: SelectOption;
  onChange: (value: IAutocompleteValue, reason?: string) => void;
};

type Props = CommonProps & SingleAutocompleteProps;

export const SingleAutocomplete = memo<Props>(
  ({
    options,
    onChange,
    value,
    searchInputValue,
    renderInput,
    disabled,
    isClearable,
    isLoading,
    size,
    autosuggestHighlight,
    virtualizedList,
    Listbox,
    debugMode,
    getOptionLabel,
    highlightedOption,
    customRenderOption,
    groupBy,
    renderGroup,
    onInputChange,
  }) => {
    const { root } = useStyles();
    const handleChange = (
      _: any,
      newValue: SelectOption | null,
      reason: string,
    ) => {
      const val = newValue ? newValue.value : "";
      onChange(val, reason);

      // If the user handle click on the clear icon button
      // It will clear the input value
      if (reason === "clear") {
        onInputChange?.("");
      }
    };

    const renderOption = (
      option: any,
      { inputValue }: AutocompleteRenderOptionState,
    ) => highlightedOption(option, inputValue);

    const inputValue =
      searchInputValue && !!searchInputValue.trim().length
        ? searchInputValue
        : value?.label ?? "";

    const getOptionSelected = (
      option: SelectOption,
      valueOption: SelectOption,
    ) => option.value === valueOption?.value ?? valueOption;

    const filterOptions = (
      selectOptions: SelectOption[],
      { inputValue: inValue }: any,
    ) => {
      const opts = selectOptions;

      return matchSorter(opts, inValue, { keys: ["label", "value"] });
    };

    const classes = useListStyles();

    return (
      <Autocomplete
        classes={classes}
        debug={debugMode}
        className={root}
        options={options}
        getOptionLabel={getOptionLabel}
        disableClearable={!isClearable}
        onChange={handleChange as any}
        disableListWrap={virtualizedList}
        ListboxComponent={virtualizedList ? Listbox : undefined}
        value={value}
        disabled={disabled}
        loading={isLoading}
        renderOption={autosuggestHighlight ? renderOption : customRenderOption}
        renderInput={renderInput}
        filterOptions={filterOptions}
        size={size}
        getOptionSelected={getOptionSelected}
        inputValue={inputValue}
        groupBy={groupBy}
        renderGroup={renderGroup}
        ListboxProps={{
          "data-cypress-selector": "autocomplete-options-popup",
        }}
      />
    );
  },
);

SingleAutocomplete.displayName = "SingleAutocomplete";
