// import { lazy } from "react";
// import { withLazyLoading } from "../../../../elementTypes/helpers/HOC/LazyLoading";

import CustomExpressionEditor from "./component";

export * from "./types";

// TODO find way to preload when in gui editor, so the configuration panel
//  doesn't jump around because of deferred loading

// const CustomExpressionEditor = withLazyLoading(
//   lazy(() => import("./component")),
//   true,
// );

export default CustomExpressionEditor;
