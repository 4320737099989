import { connect } from "react-redux";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { AppBar as ABComponent } from "./component";

export const mapStateToProps = (state: any) => ({
  isLoggedIn: sessionSelectors.isLoggedIn(state),
  isStaticPage: !!routerSelectors.staticPageId(state),
  isAdminPage: routerSelectors.isAdminPage(state),
  isAdmin: sessionSelectors.isAdmin(state),
  location: routerSelectors.location(state),
});

export const AppBar = connect(mapStateToProps)(ABComponent);
