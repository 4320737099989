import React, { ComponentType, memo } from "react";

import Element from "../../Element";
import { IElementModel } from "../../types";
import { IAsyncActionState } from "../../utils/redux";
import { ILocation } from "../reduxModule";
import { RouterContext } from "../RouterContext";
import { TStaticRoutesComponentsMap } from "../types";
import StaticPage from "../../StaticPage";

type ErrorProps = {
  error: string;
};

type Props = {
  loadAppMetadata: IAsyncActionState;
  loadingPage: boolean;
  location: ILocation | null;
  pageElement: IElementModel | null;
  staticPageId: number | null;
  error: string | null;
  notFound: boolean;
  loadingComponent: ComponentType<{}>;
  errorComponent: ComponentType<ErrorProps>;
  notFoundComponent: ComponentType<{}>;
};

export function createRouterComponent(
  staticRoutesComponentsMap: TStaticRoutesComponentsMap,
) {
  const Router = memo<Props>(
    ({
      loadAppMetadata,
      loadingPage,
      pageElement,
      staticPageId,
      notFound,
      location,
      errorComponent: Error,
      notFoundComponent: NotFound,
      loadingComponent: Loading,
      error: loadPageError,
    }) => {
      const error = loadPageError;

      if (error) {
        return <Error error={error} />;
      }

      if (notFound) {
        return <NotFound />;
      }

      if (loadAppMetadata.inProgress || loadingPage) {
        return <Loading />;
      }

      if (staticPageId !== null) {
        const staticPage = staticRoutesComponentsMap[staticPageId];

        return <StaticPage page={staticPage} />;
      }

      if (pageElement) {
        return (
          <RouterContext.Provider value={{ location }}>
            <Element element={pageElement!} />
          </RouterContext.Provider>
        );
      }

      return <Loading />;
    },
  );

  Router.displayName = "Router";

  return Router;
}
