import React, {
  MouseEvent,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

import { SessionContext } from "core/session";
import { getTranslatedTexts } from "core/utils/element";

import { MuiIcon } from "elementTypes/common/MuiIcon";

import { IUi } from "core";
import { useAppSwitchTranslation } from "./translations";
import { PropsFromRedux } from "./container";

export type IProps = PropsFromRedux & IconButtonProps;

const AppSwitch = memo<IProps>(({ changeUi, uiOptions, color, editModeOn }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { language } = useContext(SessionContext);

  const { switchApp } = useAppSwitchTranslation();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
    [],
  );

  const handleClose = useCallback(
    (ui?: IUi) => () => {
      setAnchorEl(null);

      if (ui) {
        changeUi(ui);
      }
    },
    [changeUi],
  );

  const options = useMemo(
    () =>
      uiOptions?.map((uiO) => (
        <MenuItem onClick={handleClose(uiO)} key={uiO.name}>
          {getTranslatedTexts(language, uiO.i18n).label}
        </MenuItem>
      )),
    [uiOptions, language, handleClose],
  );

  if (!(options && options.length)) {
    return null;
  }

  return (
    <>
      <Tooltip title={switchApp}>
        <IconButton color={color} onClick={handleClick} disabled={editModeOn}>
          <MuiIcon icon="apps" fontSize="large" />
        </IconButton>
      </Tooltip>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onBackdropClick={handleClose()}
      >
        {options}
      </Menu>
    </>
  );
});

AppSwitch.displayName = "AppSwitch";

export default AppSwitch;
