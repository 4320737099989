import React from "react";

import { useElementEditorContext } from "core/editor";
import { UntransformedFormConfig } from "../types";
import {
  Config,
  DataSource,
  DefaultData,
  JsonSchema,
  MultiReference,
  Validation,
} from "./components";

export const FormEditorComponent = React.memo(() => {
  const {
    elementModel: {
      config: {
        type,
        dataSource: { identifierName },
      },
    },
  } = useElementEditorContext<UntransformedFormConfig>();

  return (
    <>
      <DataSource />
      {!!identifierName?.length && <MultiReference />}
      <Config />
      <Validation />
      <JsonSchema />
      {type === "create" && <DefaultData />}
    </>
  );
});
