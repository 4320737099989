export const INSTANTIATED = Symbol();

export const IMPLEMENTED_INTERFACES = Symbol();

export const SET_MODULE_GETTER = Symbol();

// Tag to indicate string value is an expression
export const EXPRESSION_TAG = "@@expression:";

// Symbol for adding a prop to all config objects that provides some utils
export const DECODE_UTILS = Symbol();
