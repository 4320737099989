import { Type } from "./Type";
import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";

export class NullableType extends Type {
  public name = `${this.type.name} | null`;

  constructor(public readonly type: Type, description?: string) {
    super(description);
  }

  public validate(value: any): true | TypeError {
    return value === null ? true : this.type.validate(value);
  }

  public getKeyType(key: string): Type | undefined {
    return this.type.getKeyType(key);
  }

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    return this.type.getAutocompleteRecord();
  }
}
