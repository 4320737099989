import { IDefaultElement } from "core/types/element";
import { UntransformedBarChartConfig } from "../types";
import { defaultExpressionValue } from "elementTypes/common/Echarts/editor/Filter";

export const defaultElement: IDefaultElement<UntransformedBarChartConfig> = {
  config: {
    dataSource: {
      viewName: "",
      columns: {
        valueColumnNames: [],
        labelColumnName: "",
      },
    },
    hideLegend: false,
    hideLabel: false,
    isStacked: false,
    filter: defaultExpressionValue,
  },
  i18n: {
    en: {
      title: "",
    },
  },
};
