import React, { lazy, memo, useState } from "react";

import { IElementComponentProps } from "core";
import { ReduxModule } from "./reduxModule";
import { DeleteButton } from "./types";

import Button from "../common/Button";
import IconButton from "../common/IconButton";
import { withLazyLoading } from "../helpers/HOC/LazyLoading";
import { useStyles } from "./style";
import { PropsFromRedux } from "./container";

const ConfirmationModal = withLazyLoading(
  lazy(() => import("./components/ConfirmationModal")),
  true,
);

type Props = IElementComponentProps<ReduxModule, DeleteButton> & PropsFromRedux;

const DefaultDeleteButton = memo<Props>(
  ({
    disabled,
    remove,
    element: {
      config: { icon, showWarning, size, color, placement },
      i18n: {
        label,
        iconLeft,
        iconRight,
        title,
        warning,
        submitTitle,
        cancelTitle,
      },
      ...rest
    },
  }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { root } = useStyles();
    const handleSubmit = () => remove();
    const handleClick = () => {
      if (showWarning) {
        setOpen(true);
      } else {
        handleSubmit();
      }
    };
    const handleClose = () => setOpen(false);
    const commonProps = {
      disabled,
      size,
      color,
      onClick: handleClick,
    };

    const component = icon ? (
      <IconButton
        icon={icon}
        tooltip={label}
        placement={placement}
        {...commonProps}
      />
    ) : (
      <Button
        className={root}
        label={label}
        iconRight={iconRight}
        iconLeft={iconLeft}
        {...commonProps}
        {...rest}
        type="button"
        fullWidth={true}
      />
    );

    return (
      <>
        {component}
        {showWarning && (
          <ConfirmationModal
            open={open}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            title={title}
            content={warning}
            submitTitle={submitTitle}
            cancelTitle={cancelTitle}
          />
        )}
      </>
    );
  },
);

export default DefaultDeleteButton;
