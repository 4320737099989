import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { createWatcherSaga } from "core/utils/saga";
import { PieChart } from "../types";
import { Actions, Types } from "./types";
import { IFilterGroup } from "elementTypes/default_table/toolsPanel/types";
import {
  buildFixedFilterFromConfig,
  mapParamsToApiServiceParams,
} from "elementTypes/default_table/reduxModule/utils";

export function buildSaga(actions: Actions, types: Types, element: PieChart) {
  const { dataSource, filter: configFilter } = element.config;

  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token = yield select(sessionSelectors.token);
    let filter: IFilterGroup | null = null;
    let configFilterValue = null;

    if (configFilter) {
      configFilterValue = (yield select(configFilter)) as ReturnType<
        typeof configFilter
      >;

      filter = buildFixedFilterFromConfig(
        configFilterValue,
      ) as IFilterGroup | null;
    }

    try {
      if (!dataSource.viewName) {
        throw Error("insufficient configuration");
      }

      const data = yield call(
        services.api.loadViewData,
        token,
        dataSource.viewName,
        mapParamsToApiServiceParams({
          limit: 1000,
          filter,
          offset: 0,
          order: null,
        }),
      );

      if (Array.isArray(data)) {
        yield put(actions.loadSuccess(data));
      } else {
        throw Error("not an array");
      }
    } catch (error) {
      yield put(actions.loadError(error.message ?? error.toString()));
    }
  }

  function* callLoad() {
    yield put(actions.load());
  }

  return function* mainSaga() {
    yield all([
      yield takeLatest(types.LOAD, loadSaga),
      yield call(createWatcherSaga, configFilter, {
        onChange: callLoad,
      }),
    ]);

    yield put(actions.load());
  };
}
