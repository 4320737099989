import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IAdminUi } from "core/types/app";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  LOAD: scopeActionType("LOAD"),
  LOAD_SUCCESS: scopeActionType("LOAD_SUCCESS"),
  LOAD_ERROR: scopeActionType("LOAD_ERROR"),
  DELETE_UI: scopeActionType("DELETE_UI"),
  EXPORT_UI: scopeActionType("EXPORT_UI"),
  CHANGE_UI: scopeActionType("CHANGE_UI"),
  EDIT_UI: scopeActionType("EDIT_UI"),
};

export const actions = {
  load: createAction(types.LOAD),
  loadSuccess: createAction(types.LOAD_SUCCESS, (uiList: IAdminUi[]) => ({
    uiList,
  })),
  loadError: createAction(types.LOAD_ERROR, (error: any) => ({ error })),
  deleteUi: createAction(types.DELETE_UI, (uiName: string) => ({ uiName })),
  exportUi: createAction(types.EXPORT_UI, (uiName: string) => ({ uiName })),
  changeUi: createAction(types.CHANGE_UI, (ui: IAdminUi) => ({ ui })),
};
