import { makeStyles } from "@material-ui/core";
import { MarkdownFieldConfig } from "./types";

interface StyleProps {
  visibility: MarkdownFieldConfig["overflow"];
}

export const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "inline-block",
    overflowY: ({ visibility }: StyleProps) => visibility ?? "visible",
  },
});
