import { IStaticRouteConfig } from "core/router/types";

import { AdminPage } from "./pages/home";

import {
  AppsPageRoute,
  AuditsPageRoute,
  FilesPageRoute,
  SettingsPageRoute,
  UsersPageRoute,
} from "./pages";

export const route: IStaticRouteConfig = {
  Component: AdminPage,
  auth: true,
  isAdmin: true,
  routes: {
    "/admin/apps": AppsPageRoute,
    "/admin/audits": AuditsPageRoute,
    "/admin/files": FilesPageRoute,
    "/admin/settings": SettingsPageRoute,
    "/admin/users": UsersPageRoute,
  },
};
