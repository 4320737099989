import * as t from "io-ts";
import { IElement, UntransformedConfig, arrayChild } from "core/types";
import { types } from "core/runtime-typing";

export const TabsConfig = t.type({
  default: t.number,
  labels: t.array(t.string),
  variant: t.keyof({
    standard: null,
    scrollable: null,
    fullWidth: null,
  }),
});

export const TabsChildren = t.type({
  content: arrayChild("*", { positioned: true }),
});

export type TabsConfig = t.TypeOf<typeof TabsConfig>;

export type UntransformedTabsConfig = UntransformedConfig<TabsConfig>;

export type TabsChildren = t.TypeOf<typeof TabsChildren>;

export type Tabs = IElement<TabsConfig, TabsChildren>;

export const selectorTypes = {
  indexSelected: types.number(),
};
