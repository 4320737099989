import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlClass: {
      flexDirection: "row",
      marginBottom: theme.spacing(),

      "& [class*='MuiFormLabel-root']": {
        marginRight: theme.spacing(),
      },
    },
    marginTop: {
      marginTop: theme.spacing(),
    },
  }),
);
