import React, { memo, useCallback } from "react";

import { FormControlLabel, Switch, TextField } from "@material-ui/core";

import { ColorSelect, Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import { UntransformedGeoJSONFieldConfig } from "../../types";
import {
  DEFAULT_CONFIG,
  markerBackgroundColorFunctionDefault,
} from "elementTypes/default_geojson_field/constants";
import { types } from "core/runtime-typing";
import { useDefaultGeoJSONFieldEditorTranslation } from "../translation";

export const ConfigEditor = memo(() => {
  const {
    elementModel: { id, config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedGeoJSONFieldConfig>();

  const translations = useDefaultGeoJSONFieldEditorTranslation();

  const handleValueChange = (value: string) => {
    changeConfigValue("value", value);
  };

  const handleStyleFunctionChange = (value: string) => {
    changeConfigValue("styleFunction", value);
  };

  const handleTooltipFunctionChange = (value: string) => {
    changeConfigValue("tooltipFunction", value);
  };

  const handleMarkerDisplayTextFunctionChange = (value: string) => {
    changeConfigValue("markerDisplayTextFunction", value);
  };

  const handleMarkerBackgroundColorFunctionChange = (value: string) => {
    changeConfigValue("markerBackgroundColorFunction", value);
  };

  const handleSwitchValueChange = (
    key: keyof UntransformedGeoJSONFieldConfig,
  ): React.ComponentProps<typeof Switch>["onChange"] => (_event, checked) => {
    changeConfigValue(key, checked);
  };

  const handleTileLayerUrlChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("tileLayerUrl", e.target.value || null),
    [changeConfigValue],
  );
  return (
    <Section title={"Basic"} wrapped={true}>
      <TextField
        value={config.tileLayerUrl}
        onChange={handleTileLayerUrlChange}
        label={translations.tileLayerUrlLabel}
        helperText={translations.tileLayerUrlDescription}
      />

      <FormControlLabel
        control={
          <Switch
            checked={config.updateMapView ?? DEFAULT_CONFIG.updateMapView}
            onChange={handleSwitchValueChange("updateMapView")}
          />
        }
        label={translations.updateMapViewLabel}
      />
      <TableColumnEditor
        id={id}
        value={config.value}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["json"]}
        onChange={handleValueChange}
      />
      <CustomExpressionEditor
        value={config.value}
        label="Data"
        config={config}
        onChange={handleValueChange}
        disableSwitcher
      />
      <CustomExpressionEditor
        value={config.styleFunction ?? DEFAULT_CONFIG.styleFunction}
        label={translations.stylingFunctionLabel}
        labelTooltip={translations.stylingFunctionDescription}
        config={config}
        onChange={handleStyleFunctionChange}
        disableSwitcher
        additionalScope={styleFunctionTyping}
      />
      <CustomExpressionEditor
        value={config.tooltipFunction ?? DEFAULT_CONFIG.tooltipFunction}
        label={translations.tooltipFunctionLabel}
        labelTooltip={translations.tooltipFunctionDescription}
        config={config}
        onChange={handleTooltipFunctionChange}
        disableSwitcher
        additionalScope={tooltipFunctionTyping}
      />
      <CustomExpressionEditor
        value={
          config.markerDisplayTextFunction ??
          DEFAULT_CONFIG.markerDisplayTextFunction
        }
        label={translations.markerDisplayTextFunctionLabel}
        labelTooltip={translations.markerDisplayTextFunctionDescription}
        config={config}
        onChange={handleMarkerDisplayTextFunctionChange}
        disableSwitcher
        additionalScope={markerDisplayTextFunctionTyping}
      />
      <CustomExpressionEditor
        value={
          config.markerBackgroundColorFunction ??
          DEFAULT_CONFIG.markerBackgroundColorFunction
        }
        label={translations.markerBackgroundColorFunctionLabel}
        labelTooltip={translations.markerBackgroundColorFunctionDescription}
        config={config}
        onChange={handleMarkerBackgroundColorFunctionChange}
        additionalScope={markerBackgroundColorFunctionTyping}
        nonExpressionEditor={({ value, onChange }) => (
          <ColorSelect
            colorItems={[
              "white",
              "black",
              "primary",
              "secondary",
              "success",
              "info",
              "warning",
              "error",
            ]}
            defaultValue={DEFAULT_CONFIG.markerBackgroundColorFunction}
            value={value}
            onChange={onChange}
            background
          />
        )}
        onToggleMode={(isExpression) => {
          if (isExpression) {
            handleMarkerBackgroundColorFunctionChange(
              DEFAULT_CONFIG.markerBackgroundColorFunction,
            );
          } else {
            handleMarkerBackgroundColorFunctionChange(
              markerBackgroundColorFunctionDefault(
                config.markerBackgroundColorFunction ??
                  DEFAULT_CONFIG.markerBackgroundColorFunction,
              ),
            );
          }
        }}
      />
    </Section>
  );
});

const featureTyping = types.interface({
  geometry: types.anyRecord(),
  properties: types.anyRecord(),
});

const styleFunctionTyping = {
  feature: featureTyping,
};

const tooltipFunctionTyping = {
  feature: featureTyping,
};

const markerDisplayTextFunctionTyping = {
  feature: featureTyping,
};

const markerBackgroundColorFunctionTyping = {
  feature: featureTyping,
};
