import { createUseTranslation } from "core/session/translation";

export const colorFieldEditorTranslation = {
  en: {
    valueLabel: "Color",
  },
};

export const useColorFieldEditorTranslation = createUseTranslation(
  colorFieldEditorTranslation,
);
