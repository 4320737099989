import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import {
  actions as routerActions,
  selectors as routerSelectors,
} from "core/router/reduxModule";
import { actions, selectors, types } from ".";

import AdminService from "services/admin";
import { IAdminUi } from "core/types/app";

const services = AdminService.getInstance();

function* loadSaga() {
  try {
    const token = yield select(sessionSelectors.token);
    const { uiName } = yield select(routerSelectors.params);

    if (uiName === "builder") {
      yield put(routerActions.push("/admin/apps"));
    }

    const uiList: IAdminUi[] = yield call(services.getAllUi, token);
    const uiSelected = uiList.find((ui) => ui.name === uiName);

    if (uiSelected) {
      yield put(actions.selectedUi(uiSelected));
    } else {
      yield put(routerActions.push("/admin/apps"));
    }
  } catch (error) {
    yield put(actions.editUiError(error));
  }
}

function* editUISaga(action: ReturnType<typeof actions["editUi"]>) {
  try {
    const token = yield select(sessionSelectors.token);
    const ui = yield select(selectors.ui);

    yield call(services.editUi, ui.name, action.payload.ui, token);
    yield put(
      sessionActions.enqueueSnackbar({
        message: "UI edited successfully",
        options: {
          variant: "success",
        },
      }),
    );

    yield put(routerActions.push("/admin/apps"));
  } catch (error) {
    yield put(actions.editUiError(error));

    yield put(
      sessionActions.enqueueSnackbar({
        message: "UI was not edited",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

export function* saga() {
  yield all([
    yield takeLatest(types.LOAD, loadSaga),
    yield takeLatest(types.EDIT_UI, editUISaga),
  ]);

  yield put(actions.load());
}
