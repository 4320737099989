import { CreateAppsPage } from "./container";

import * as reduxModule from "./reduxModule";

export const route = {
  reduxModule,
  auth: true,
  isAdmin: true,
  Component: CreateAppsPage,
};
