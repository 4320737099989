import { useSelector } from "react-redux";

import { selectors as editorSelectors } from "../reduxModule";
import { IObjectView } from "core/types";

export const useObjectViewList = (): {
  viewList: IObjectView[] | null;
  getViewByName: (name: string) => IObjectView | undefined;
} => {
  const viewList = useSelector(editorSelectors.viewList);
  const getViewByName = (viewName: string) =>
    viewList?.find((view) => view.name === viewName);

  return {
    viewList,
    getViewByName,
  };
};
