import { connect } from "react-redux";

import { EditUsersPage as EUComponent } from "./component";

import { actions, selectors } from "./reduxModule";

export const mapStateToProps = (state: any) => ({
  user: selectors.user(state),
  loading: selectors.loading(state),
  error: selectors.error(state),
  rolesList: selectors.rolesList(state),
});

export const mapDispatchToProps = {
  ...actions,
};

export const CreateUsersPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EUComponent);
