import { stringify } from "query-string";
import request from "superagent";

import { IObjectView, IUi } from "core/types";

import { StorageFileMetadata } from "./types/FileStorage";
import {
  getResponseError,
  onTokenExpired,
  withAuthHeader,
  withCommonHeaders,
} from "utils/api";
import { FILE_STORAGE_PREFIX } from "./constants";

export { onTokenExpired };

export const loadUIList = async ({ token }: { token?: string } = {}): Promise<
  IUi[]
> => {
  try {
    const res = await withCommonHeaders(request.get("/app/ui"), token);
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const loadViewList = async ({
  token,
  role,
}: { token?: string; role?: string } = {}): Promise<IObjectView[]> => {
  try {
    const query = role ? `?${stringify({ role })}` : "";
    const res = await withCommonHeaders(
      request.get("/app/view/" + query),
      token,
    );
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const getViewMetadata = async (
  { token }: { token?: string } = {},
  params: {
    objectViewName: string;
    stateNames?: string[];
  },
) => {
  try {
    const query = `?${stringify(
      { stateNames: params.stateNames },
      { arrayFormat: "bracket" },
    )}`;
    const { body } = await withCommonHeaders(
      request.get(`/app/view/${params.objectViewName}/metadata/many${query}`),
      token,
    );

    return body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const loadAppMetadata = async (
  uiName: string,
  { token }: { token?: string } = {},
  latest?: boolean,
) => {
  try {
    const url = latest ? `/app/ui/${uiName}/latest` : `/app/ui/${uiName}`;
    const { body } = await withCommonHeaders(request.get(url), token);
    return body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const getUIReleases = async (token: string, uiName: string) => {
  try {
    const res = await withCommonHeaders(
      request.get(`/app/ui/${uiName}/release`),
      token,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const getUISavePoints = async (token: string, uiName: string) => {
  try {
    const res = await withCommonHeaders(
      request.get(`/app/ui/${uiName}/save`),
      token,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

interface LoginBody {
  identifier: string;
  password: string;
}

export const login = async (payload: LoginBody): Promise<string> => {
  try {
    const res = await request
      .post("/app/auth/login")
      .send(payload)
      .set("Content-Type", "application/json");
    return res.body.jwt;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const loadViewData = async (
  token: string,
  viewName: string,
  params?: { [k: string]: any },
) => {
  try {
    const queryString = params ? `?${stringify(params)}` : "";
    const res = await withCommonHeaders(
      request.get(`/view/${viewName}${queryString}`),
      token,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const createViewData = async (
  token: string,
  viewName: string,
  data: any,
) => {
  try {
    const res = await withCommonHeaders(
      request
        .post(`/view/${viewName}`)
        .set("Prefer", "return=representation")
        .send(data),
      token,
    );
    return res.body[0];
  } catch (err) {
    throw getResponseError(err);
  }
};

export const updateViewData = async (
  token: string,
  viewName: string,
  data: any,
  identifierName: string,
  identifierValue: any,
) => {
  try {
    const res = await withCommonHeaders(
      request
        .patch(`/view/${viewName}?${identifierName}=eq.${identifierValue}`)
        .set("Prefer", "return=representation")
        .send(data),
      token,
    );
    return res.body[0];
  } catch (err) {
    throw getResponseError(err);
  }
};

export const insertMultipleViewDataRows = async (
  token: string,
  viewName: string,
  data: any[],
) => {
  try {
    const res = await withCommonHeaders(
      request.post(`/view/${viewName}`).send(data),
      token,
    );
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const updateMultipleViewDataRows = async (
  token: string,
  viewName: string,
  data: any[],
) => {
  try {
    const res = await withCommonHeaders(
      request
        .post(`/view/${viewName}`)
        .set("Prefer", "resolution=merge-duplicates")
        .send(data),
      token,
    );
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const deleteMultipleViewDataRows = async (
  token: string,
  viewName: string,
  ids: any[],
  identifierName: string,
) => {
  try {
    const res = await withCommonHeaders(
      request.delete(
        `/view/${viewName}?"${identifierName}"=in.(${ids
          .map((id) => `"${id}"`)
          .join(",")})`,
      ),
      token,
    );
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const deleteViewData = async (
  token: string,
  viewName: string,
  identifierName: string,
  identifierValue: any,
) => {
  try {
    await withCommonHeaders(
      request
        .delete(`/view/${viewName}?${identifierName}=eq.${identifierValue}`)
        .set("Prefer", "return=representation"),
      token,
    );
  } catch (err) {
    throw getResponseError(err);
  }
};

export interface CurrentUserIntegrated {
  type: "integrated";
  email: string;
  userName?: string;
}

export interface CurrentUserOther {
  type: "other";
  name: string;
}

export interface AuthMeResponse {
  id: string;
  role: string;
  language: string;
  additionalInformation: CurrentUserIntegrated | CurrentUserOther;
  isActive: boolean;
  isAdmin: boolean;
}

export const getUser = async (token: string): Promise<AuthMeResponse> => {
  try {
    const res = await withCommonHeaders(request.get("/app/auth/me"), token);

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const updateUser = async (token: string, userData: object) => {
  try {
    await withCommonHeaders(request.put("/app/auth/me").send(userData), token);
  } catch (err) {
    throw getResponseError(err);
  }
};

export * from "./types/stateTransition";

export const runProcedure = async (
  token: string,
  name: string,
  schema: string,
  args: any,
) => {
  try {
    const { body } = await withCommonHeaders(
      request.post(`/${schema}/rpc/${name}`).send(args),
      token,
    );
    return body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const uploadFile = async (
  token: string,
  file: File,
  groupName: string,
  typeGroupName: string,
) => {
  try {
    const req = request.post("/app/storage/file");
    req.attach("file", file, file.name);
    req.field("groupName", groupName);
    req.field("typeGroupName", typeGroupName);
    const { body } = await withAuthHeader(req, token);
    return body as StorageFileMetadata;
  } catch (err) {
    throw err.toString();
  }
};

export const fileMetadata = async (token: string, fileName: string) => {
  try {
    const req = request.get(`${FILE_STORAGE_PREFIX}${fileName}/details`);
    const { body } = await withAuthHeader(req, token);
    return body as StorageFileMetadata;
  } catch (err) {
    throw err.toString();
  }
};

export interface LoginConfig {
  logoPath: string | null;
}

export const getLoginConfig = async (token: string): Promise<LoginConfig> => {
  try {
    const res = await withCommonHeaders(
      request.get("/app/auth/config/login"),
      token,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const getViewCreateStateChanges = async (
  token: string,
  objectViewName: string,
) => {
  try {
    const { body } = await withCommonHeaders(
      request.get(`/app/view/${objectViewName}/state-changes/create`),
      token,
    );

    return body;
  } catch (err) {
    throw getResponseError(err);
  }
};
