import * as t from "io-ts";

import { customExpression } from "core/types/customExpression";
import { UntransformedConfig } from "core/types/react";

import EchartsContainerDataset from "./EchartsContainerDataset";
import EchartsContainerOneSerie from "./EchartsContainerOneSerie";
import { IOptionEchartProps } from "./types";
import { FixedFilterGroup } from "elementTypes/default_table/toolsPanel";

interface IEchartContainerProps {
  defaultOptions: IOptionEchartProps;
}

export interface IConfigEchartsContainerProps {
  config: IOptionEchartProps;
}

export type ConfigEchartsContainerProps = t.InterfaceType<
  IConfigEchartsContainerProps
>;

export const EchartsContainerSingleConfig = t.intersection([
  t.type({
    dataSource: t.type({
      viewName: t.string,
      columns: t.type({
        labelColumnName: t.string,
        valueColumnName: t.string,
      }),
    }),
  }),
  t.partial({
    showBackground: t.boolean,
    filter: customExpression(t.union([t.null, FixedFilterGroup])),
  }),
]);

export const EchartsContainerMultiConfig = t.intersection([
  t.type({
    dataSource: t.type({
      viewName: t.string,
      columns: t.type({
        labelColumnName: t.string,
        valueColumnNames: t.array(t.string),
      }),
    }),
  }),
  t.partial({
    showBackground: t.boolean,
    isStacked: t.boolean,
    filter: customExpression(t.union([t.null, FixedFilterGroup])),
  }),
]);

export type EchartsContainerSingleConfig = t.TypeOf<
  typeof EchartsContainerSingleConfig
>;
export type EchartsContainerMultiConfig = t.TypeOf<
  typeof EchartsContainerMultiConfig
>;

interface CommonProps<Type extends string> {
  type: Type;
  data: any;
  title?: string;
}

export type IEchartsContainerSingleProps = EchartsContainerSingleConfig &
  IEchartContainerProps &
  CommonProps<"pie">;

export type IEchartsContainerMultiProps = EchartsContainerMultiConfig &
  IEchartContainerProps &
  CommonProps<"bar" | "line" | "scatter">;

export type UntransformedEchartConfig = UntransformedConfig<
  EchartsContainerSingleConfig
>;

export { EchartsContainerOneSerie, EchartsContainerDataset };
