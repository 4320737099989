import { selectorScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IState } from "./types";

const scopeSelector = selectorScoper<IState>([
  "staticPages",
  constants.MODULE_NAME,
]);

const config = (state: any) => scopeSelector(state).config;
const loading = (state: any) => scopeSelector(state).loading;
const error = (state: any) => scopeSelector(state).error;

export const selectors = {
  config,
  loading,
  error,
};
