import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  LOAD: scopeActionType("LOAD"),
  LOAD_SUCCESS: scopeActionType("LOAD_SUCCESS"),
  LOAD_ERROR: scopeActionType("LOAD_ERROR"),
};

export const actions = {
  load: createAction(types.LOAD),
  loadSuccess: createAction(types.LOAD_SUCCESS, (config: any) => ({
    config,
  })),
  loadError: createAction(types.LOAD_ERROR, (error: any) => ({ error })),
};
