import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import { actions as sessionActions } from "core/session/reduxModule/actions";
import {
  actions as routerActions,
  selectors as routerSelectors,
} from "core/router/reduxModule";
import { actions, types } from "./actions";

import AdminService from "services/admin";

const services = AdminService.getInstance();

function* getUserSaga(action: ReturnType<typeof actions["getUser"]>) {
  const token = yield select(sessionSelectors.token);

  try {
    const user = yield call(services.getUser, action.payload.userId, token);

    yield put(actions.getUserSuccess(user));
  } catch (error) {
    yield put(actions.getUserError(error.message ?? error.toString()));
  }
}

function* addUiSaga(action: ReturnType<typeof actions["editUser"]>) {
  const token = yield select(sessionSelectors.token);

  try {
    yield call(
      services.editUser,
      action.payload.userId,
      action.payload.user,
      token,
    );
    yield put(
      sessionActions.enqueueSnackbar({
        message: "User edited successfully",
        options: {
          variant: "success",
        },
      }),
    );
    yield put(routerActions.push("/admin/users"));
  } catch (error) {
    yield put(actions.editUserError(error.message ?? error.toString()));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "User was not edited",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

function* getRolesSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const rolesList = yield call(services.getAllRoles, token);

    yield put(actions.getRolesSuccess(rolesList));
  } catch (error) {
    yield put(actions.getRolesError(error.message ?? error.toString()));
  }
}

export function* saga() {
  const { userId } = yield select(routerSelectors.params);

  yield all([
    yield takeLatest(types.GET_USER, getUserSaga),
    yield takeLatest(types.EDIT_USER, addUiSaga),
    yield takeLatest(types.GET_ROLES, getRolesSaga),
  ]);

  yield put(actions.getUser(userId));
  yield put(actions.getRoles());
}
