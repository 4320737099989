import Component from "./container";
import { GeoJSONFieldEditor, defaultElement, editorMetadata } from "./editor";
import { GeoJSONFieldConfig } from "./types";
import { IRawElementType } from "core";

const elementType: IRawElementType = {
  defaultElement,
  name: "default_geojson_field",
  component: Component,
  editable: true,
  configType: GeoJSONFieldConfig,
  editorComponent: GeoJSONFieldEditor,
  editorMetadata,
};

export default elementType;
