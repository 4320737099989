import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Form",
      description: "",
    },
  },
  defaultSize: {
    width: 6,
    height: 5,
  },
  minSize: {
    width: 4,
    height: 3,
  },
};
