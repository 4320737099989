import React, { ChangeEvent, memo, useCallback } from "react";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";

import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { UntransformedDataDisplayConfig } from "../types";
import { useEditorDataDisplayTranslation } from "./translation";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

enum Modes {
  firstRow = "firstRow",
  allData = "allData",
}

export const DataSourceEditor = memo(() => {
  const {
    elementModel: {
      config,
      config: { dataSource, identifier },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDataDisplayConfig>();

  const changeDataSource = useCallback(
    (newDataSource: UntransformedDataDisplayConfig["dataSource"]) =>
      changeConfigValue("dataSource", newDataSource),
    [changeConfigValue],
  );

  const { viewName = "", identifierName = "", setAllData = false } = dataSource;

  const changeIdentifier = useCallback(
    (newIdentifier?: string) =>
      changeConfigValue(
        "identifier",
        newIdentifier?.trim().length ? newIdentifier : undefined,
      ),
    [changeConfigValue],
  );

  const handleViewNameChange = (newViewName: string) => {
    if (identifier?.length) {
      changeIdentifier(undefined);
    }
    changeDataSource({
      viewName: newViewName,
      identifierName: undefined,
    });
  };

  const handleFieldChange = (
    _: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) => {
    identifier?.length && changeIdentifier(undefined);
    changeDataSource({
      ...dataSource,
      identifierName: fieldValue as string,
    });
  };

  const handleSetAllDataChange = (
    _: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => changeDataSource({ ...dataSource, setAllData: value === Modes.allData });

  const {
    dataSourceTitle,
    identifierValueLabel,
    identifierNameLabel,
    viewLabel,
  } = useEditorTranslation();
  const translation = useEditorDataDisplayTranslation();

  const fields = [
    {
      label: identifierNameLabel,
      value: identifierName,
      name: "identifierName",
      isClearable: true,
    },
  ];

  const customIdentifierInput = ({
    value: identifierValue,
    onChange: onIdentifierChange,
  }: {
    value: string;
    onChange: (value: string) => void;
  }) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onIdentifierChange(e.target.value);

    return (
      <TextField
        disabled={!identifierName}
        value={identifierValue}
        onChange={onCustomChange}
        fullWidth={true}
        margin="none"
        size="small"
      />
    );
  };

  const modes = Object.values(Modes).map((mode) => (
    <FormControlLabel
      key={mode}
      control={<Radio color="primary" />}
      label={translation[`${mode}Label`]}
      value={mode}
    />
  ));

  return (
    <Section title={dataSourceTitle} wrapped={true}>
      <ViewAutocomplete
        viewValue={viewName}
        viewLabel={viewLabel}
        onViewNameChange={handleViewNameChange}
        onViewFieldChange={handleFieldChange}
        fields={fields}
      />
      <CustomExpressionEditor
        value={identifier ?? ""}
        config={config}
        onChange={changeIdentifier}
        label={identifierValueLabel}
        nonExpressionEditor={customIdentifierInput}
        switcherDisabled={!identifierName}
      />
      <FormControl fullWidth={true}>
        <FormLabel component="p">{translation.modeTitle}</FormLabel>
        <RadioGroup
          row={true}
          value={setAllData ? Modes.allData : Modes.firstRow}
          onChange={handleSetAllDataChange}
        >
          {modes}
        </RadioGroup>
      </FormControl>
    </Section>
  );
});
