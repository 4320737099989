import React, { memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import {
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";
import { UntransformedCallButtonConfig } from "../../types";
import { useCallButtonEditorTranslation } from "../translation";

export const DisplayComponent = memo(() => {
  const {
    displaySectionTitle,
    showNotificationLabel,
    disabledLabel,
  } = useCallButtonEditorTranslation();
  const {
    elementModel: {
      config: { icon, showNotification, disabled },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCallButtonConfig>();

  const changeIcon = (newIcon: UntransformedCallButtonConfig["icon"]) =>
    changeConfigValue("icon", newIcon);

  const handleShowNotificationInputChange = () =>
    changeConfigValue("showNotification", !showNotification);

  const handleDisabledInputChange = () =>
    changeConfigValue("disabled", !disabled);

  return (
    <Section title={displaySectionTitle} wrapped={true}>
      <IconAutocomplete label="Icon" value={icon} onChange={changeIcon} />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(showNotification)}
            onChange={handleShowNotificationInputChange}
          />
        }
        label={showNotificationLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(disabled)}
            onChange={handleDisabledInputChange}
          />
        }
        label={disabledLabel}
      />
    </Section>
  );
});
