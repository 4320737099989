import { connect } from "react-redux";

import { CreateAppsPage as CAPComponent } from "./component";

import { actions, selectors } from "./reduxModule";

export const mapStateToProps = (state: any) => ({
  loading: selectors.loading(state),
  error: selectors.error(state),
  fileTypes: selectors.fileTypes(state),
  fileGroups: selectors.fileGroups(state),
});

export const mapDispatchToProps = {
  ...actions,
};

export const CreateAppsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CAPComponent);
