import { route as adminRoute } from "./admin";
import { route as loginRoute } from "./login";
import { route as maintenanceRoute } from "./maintenance";
import { route as noAppsAvailableRoute } from "./noAppsAvailable";
import { route as settingsRoute } from "./settings";
import { IStaticRouteConfig } from "core";
import { LOGIN_URL } from "core/router/reduxModule";

const routes: Record<string, IStaticRouteConfig> = {
  "/admin": adminRoute,
  [LOGIN_URL]: loginRoute,
  "/maintenance": maintenanceRoute,
  "/no-apps-available": noAppsAvailableRoute,
  "/settings": settingsRoute,
};

export default routes;
