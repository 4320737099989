import { createUseTranslation } from "../../../session/translation";

export const useExpressionTranslation = createUseTranslation({
  en: {
    expressionModalTitle: "Expression Editor",
    expandTooltip: "Click to Expand",
    toggleLabel: "Toggle Expression mode",
  },
  de: {},
});
