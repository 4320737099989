import React, { MouseEvent, memo } from "react";

import clsx from "classnames";

import { MuiIcon } from "elementTypes/common/MuiIcon";
import {
  IElementModel,
  IElementType,
  TElementModelWithPosition,
} from "../../types";
import { ReduxProps } from "./container";
import { useStyles } from "./style";
import { Box } from "@material-ui/core";
import { getNearestParentElement } from "../utils";

type Props = ReduxProps & {
  elementModel: IElementModel | TElementModelWithPosition;
  elementType: IElementType;
} & {
  children: React.ReactNode;
};

const EditableWrapper = memo<Omit<Props, "editModeOn">>(
  ({
    selectElement,
    setActiveGrid,
    unselectElement,
    selected,
    activeGrid,
    elementModel,
    elementType,
    page,
    updatedElements,
    children,
    shouldHighlightBorders,
  }) => {
    const isGrid = elementType.name === "default_grid";

    const isSelected = Boolean(selected?.element?.id === elementModel.id);
    const isActiveGrid =
      !!activeGrid &&
      (activeGrid?.id === elementModel?.id ||
        elementModel.id.endsWith(`.${activeGrid?.id}`));

    const classes = useStyles();

    const select = () => {
      if (!isSelected) {
        const parent = getNearestParentElement(
          page!.element,
          updatedElements,
          elementModel.id,
          "default_grid",
        );
        if (parent) {
          setActiveGrid(parent, page!);
        }
        selectElement(elementModel, elementType, page!);
      }
    };

    const handleClick = (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.stopPropagation();
      if (isGrid) {
        unselectElement(page!);
        setActiveGrid(elementModel, page!);
      } else {
        select();
      }
    };

    return (
      <div
        className={clsx(
          classes.wrapper,
          {
            [classes.topPadding]: isGrid,
            isSelected,
            isGrid,
            isActiveGrid,
            shouldHighlightBorders,
          },
          "editor-element-wrapper",
        )}
        data-element-id={elementModel.id}
      >
        {!isGrid && (
          <Box className={clsx(classes.label, "editor-label")}>
            <Box display="flex" alignItems="center">
              <div className={"rgl-handle"}>
                <MuiIcon
                  icon={"drag_handle"}
                  fontSize="large"
                  className={clsx(classes.handle)}
                />
              </div>

              <Box
                style={{ cursor: "pointer" }}
                textAlign="left"
                onClick={handleClick}
                textOverflow="ellipsis"
                overflow="hidden"
                maxWidth="20ch"
                whiteSpace="nowrap"
              >
                {elementModel.id}
              </Box>
            </Box>
          </Box>
        )}
        <div
          className={"no-drag"}
          style={{ display: "contents" }}
          onClick={handleClick}
        >
          {children}
        </div>
      </div>
    );
  },
);

const EditableElement: React.FC<Props> = ({
  editModeOn,
  children,
  ...rest
}) => {
  return !editModeOn ? (
    <>{children}</>
  ) : (
    <EditableWrapper {...rest}>{children}</EditableWrapper>
  );
};

EditableElement.displayName = "EditableElementWrapper";

export const EditableElementWrapper = memo(EditableElement);
