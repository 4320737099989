import { IDefaultElement } from "core";
import { SliderInputConfig } from "../types";
import { DEFAULTS } from "../component";

export const defaultElement: IDefaultElement<SliderInputConfig> = {
  config: {
    ...DEFAULTS,
  },
  i18n: {
    en: {
      label: "",
    },
  },
};
