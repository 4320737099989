import React, { memo } from "react";

import { ConfigComponent } from "./components/Config";
import { DisplayComponent } from "./components/Display";
import { LanguageComponent } from "./components/Language";
import { StylingComponent } from "./components/Styling";

export const ClipboardCopyButtonEditor = memo(() => (
  <>
    <ConfigComponent />
    <LanguageComponent />
    <DisplayComponent />
    <StylingComponent />
  </>
));
