import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FixedSizeList } from "react-window";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";

import { TElementModelWithPosition } from "core";
import { useElementEditorContext } from "core/editor";
import { actions as editorActions } from "core/editor/reduxModule";
import { useElementTypesContext } from "core/ElementTypesContext";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { Language } from "core/types";
import { getTranslatedTexts } from "core/utils/element";
import { TableHeaderCell } from "elementTypes/default_table_header_cell/types";
import { CellAlignment, UntransformedTableConfig } from "../../../types";
import { ColumnConfig } from "./ColumnConfig";
import { useColumnsContext } from "./ColumnsContext";
import { ColumnRow } from "./ColumnRow";

export type IColumn = TableHeaderCell & {
  position: {
    column: number;
  };
};

export type ColumnDetails =
  | (Omit<IColumn, "i18n"> & {
      label: string;
      index: number;
      isNew?: boolean;
      isHidden?: string | null;
    })
  | ReturnType<typeof newColumnParams>;
export type ExtendedColumnConfig = ReturnType<typeof getHeaderConfig>;

type IItem = IColumn | string | null;

const columnItemSize = 48;

export const Columns = memo(() => {
  const {
    elementModel,
    elementModel: {
      children: {
        header: { elements: columns },
        body: { elements: bodyColumns },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTableConfig>();
  const page = useSelector(routerSelectors.page);
  const {
    language,
    hidden,
    updateChildren,
    setColumnDetails,
    deleteColumn,
  } = useColumnsContext();
  const { elementTypes: allElementTypes } = useElementTypesContext();
  const dispatch = useDispatch();

  function onDragEnd({ destination, source }: DropResult) {
    if (!destination || source.index === destination.index) {
      return;
    }

    updateChildren(
      elementModel,
      reorderList(columns, source.index, destination.index) as IColumn[],
      page!,
      "header",
    );
    updateChildren(
      elementModel,
      reorderList(bodyColumns, source.index, destination.index) as IColumn[],
      page!,
      "body",
    );
    changeConfigValue(
      "hidden",
      reorderList(hidden, source.index, destination.index),
    );
  }

  const selectElement = useCallback(
    (...params: Parameters<typeof editorActions.selectElement>) => {
      dispatch(editorActions.selectElement(...params));
    },
    [dispatch],
  );

  function selectBodyColumn(index: number) {
    const columnElement: TElementModelWithPosition | undefined =
      bodyColumns[index];

    if (columnElement) {
      const elementType = allElementTypes[columnElement.type.name];

      // check if the element type actually exists
      // this is just an additional check for the case that an unknown element
      // type is set (e.g. when the app definition was manually changed)
      if (elementType) {
        selectElement(columnElement, elementType, page!);
      }
    }
  }

  function handleBtnClick(
    type: "edit" | "delete" | "select",
    params: ColumnDetails | number,
  ) {
    switch (type) {
      case "edit":
        setColumnDetails(params as ColumnDetails);
        break;
      case "delete":
        deleteColumn(params as number);
        break;
      case "select":
        selectBodyColumn(params as number);
        break;
      default:
        break;
    }
  }

  function isDisabledColumn(index: number) {
    const columnElement: TElementModelWithPosition | undefined =
      bodyColumns[index];

    if (columnElement) {
      // if the editorComponent is defined return disabled as false,
      // if not return disabled as true.
      return !allElementTypes[columnElement.type.name].editorComponent;
    }

    return true;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, snapshot, { source: { index } }) => {
          const { i18n, ...rest } = columns[index];
          const label = getTranslatedTexts(language, i18n).label;
          const disabled = isDisabledColumn(index);

          return (
            <ColumnConfig
              {...rest}
              label={label}
              provided={provided}
              snapshot={snapshot}
              onBtnClick={handleBtnClick}
              index={index}
              disabled={disabled}
            />
          );
        }}
      >
        {(provided) => (
          <FixedSizeList
            height={columnItemSize * Math.min(columns.length, 5)}
            itemCount={columns.length}
            itemSize={columnItemSize}
            width="100%"
            outerRef={provided.innerRef}
            itemData={{ columns, handleBtnClick, isDisabledColumn }}
          >
            {ColumnRow}
          </FixedSizeList>
        )}
      </Droppable>
    </DragDropContext>
  );
});

function newColumnParams(order: number) {
  return {
    name: "",
    label: "",
    config: {
      dataSource: {
        fieldName: "",
        sortable: false,
      },
      align: "center" as CellAlignment,
    },
    position: {
      column: order,
      row: 1,
      width: 1,
      height: 1,
    },
    isNew: true,
    index: order - 1,
    isHidden: null as null | string,
  };
}

function getHeaderConfig(
  data: Record<string, any>,
  lang: Language,
  order: number,
) {
  return {
    name: data.name,
    i18n: { [lang.code]: { label: data.label } },
    type: "default_table_header_cell",
    config: {
      dataSource: {
        fieldName: data.fieldName || "",
        sortable: data.sortable,
      },
      align: data.align,
      ...(!(data.width === "auto") && { width: "1px" }),
    },
    position: {
      column: order,
      height: 1,
      width: 1,
      row: 1,
    },
  };
}

const reorderList = (list: IItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return typeof removed === "string" || !removed
    ? result
    : (result as IColumn[]).map((item: IColumn, index: number) => ({
        ...item,
        position: {
          ...item.position,
          column: index + 1,
        },
      }));
};
