import React, { memo } from "react";
import { PropsFromRedux } from "./container";
import IconButton, { ButtonProps } from "elementTypes/common/IconButton";
import { useAdminButtonTranslation } from "./translations";

type Props = PropsFromRedux & ButtonProps;

export const AdminButton = memo<Props>(({ color, editModeOn }) => {
  const { label } = useAdminButtonTranslation();

  const buttonProps = {
    icon: "developer_board",
    tooltip: label,
    color,
    placement: "bottom" as const,
    fontSize: "large" as const,
    disabled: editModeOn,
    href: "/admin",
  };

  return <IconButton {...buttonProps} />;
});
