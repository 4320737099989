import React, { memo, useCallback, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { UntransformedSliderInputConfig } from "../../types";
import { useSliderInputEditorTranslation } from "../translation";
import IconButton from "../../../common/IconButton";
import Button from "../../../common/Button";
import DialogWrapper from "../../../helpers/HOC/DialogWrapper";
import { DialogContent } from "./DialogContent";

const newMarkParams = {
  value: undefined,
  isNew: true,
};

export type MarkDetails = {
  value?: number;
  isNew?: boolean;
  index?: number;
};

export const Marks = memo(() => {
  const {
    elementModel: {
      config: { marks = [] },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedSliderInputConfig>();
  const editorTranslation = useEditorTranslation();
  const translation = useSliderInputEditorTranslation();
  const [markDetails, setMarkDetails] = useState<MarkDetails | null>(null);

  const handleChangeMarks = useCallback(
    (newValue: { value: number }[]) => changeConfigValue("marks", newValue),
    [changeConfigValue],
  );

  const setNewMarkParams = () => setMarkDetails(newMarkParams);
  const handleClose = () => setMarkDetails(null);
  const handleSubmit = (data: { [k: string]: any }) => {
    const marksArray = typeof marks === "boolean" ? [] : marks;
    if (markDetails?.isNew) {
      handleChangeMarks([...marksArray, { value: Number(data.value) }]);
    } else {
      if (!(markDetails && markDetails.index !== undefined)) {
        // this must not happen
        return;
      }
      marksArray[markDetails.index].value = Number(data.value);
      handleChangeMarks([...marksArray]);
    }
    handleClose();
  };
  const handleDeleteClick = () => {
    Array.isArray(marks) &&
      handleChangeMarks(
        marks.filter((mark) => mark === markDetails && markDetails?.value),
      );
    handleClose();
  };
  const handleEditClick = (mark: MarkDetails, index: number) => () =>
    setMarkDetails({ ...mark, isNew: false, index });

  return (
    <Section
      title={translation.marksTitle}
      wrapped={true}
      headerAction={
        <IconButton
          icon="add_to_photos"
          onClick={setNewMarkParams}
          color="primary"
          tooltip={translation.addMarkTooltip}
        />
      }
    >
      {Array.isArray(marks) &&
        marks.map((mark, index) => (
          <ListItem divider={true} key={index}>
            <ListItemText primary={mark.value} />
            <ListItemIcon>
              <IconButton icon="edit" onClick={handleEditClick(mark, index)} />
            </ListItemIcon>
          </ListItem>
        ))}
      <DialogWrapper
        isForm={true}
        keepMounted={false}
        open={Boolean(markDetails)}
        title={
          markDetails?.isNew
            ? translation.createMarkTitle
            : translation.editMarkTitle
        }
        submitTitle={
          markDetails?.isNew
            ? editorTranslation.createButton
            : editorTranslation.updateButton
        }
        cancelTitle={editorTranslation.cancelButton}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitDisabled={true}
        subActions={
          !markDetails?.isNew && (
            <Button
              label={editorTranslation.deleteButton}
              onClick={handleDeleteClick}
              color="error.main"
            />
          )
        }
      >
        {markDetails && <DialogContent {...markDetails} />}
      </DialogWrapper>
    </Section>
  );
});
