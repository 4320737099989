import * as t from "io-ts";
import { Implementation } from "core";
import { FormDataSource } from "./FormDataSource";

export type FieldPath = Array<string | number>;

export type FormDataSourceImplementation = Implementation<
  typeof FormDataSource
>;

export const DataSourceConfig = t.type({
  elementId: t.union([t.number, t.string]),
});

export type DataSourceConfig = t.TypeOf<typeof DataSourceConfig>;

export const WithDataSourceConfig = t.type({
  dataSource: DataSourceConfig,
});

export type WithDataSourceConfig = t.TypeOf<typeof WithDataSourceConfig>;

export const WithOptionalDataSourceConfig = t.partial(
  WithDataSourceConfig.props,
);

export type WithOptionalDataSourceConfig = t.TypeOf<
  typeof WithOptionalDataSourceConfig
>;

export const WithFieldDataSourceConfig = t.type({
  dataSource: t.intersection([
    DataSourceConfig,
    t.type({
      fieldPath: t.array(t.union([t.string, t.number])),
    }),
  ]),
});

export type WithFieldDataSourceConfig = t.TypeOf<
  typeof WithFieldDataSourceConfig
>;

export const WithOptionalFieldDataSourceConfig = (defaultValueType?: t.Mixed) =>
  t.partial({
    ...WithFieldDataSourceConfig.props,
    defaultValue: defaultValueType ?? t.undefined,
  });

export type WithOptionalFieldDataSourceConfig = t.TypeOf<
  ReturnType<typeof WithOptionalFieldDataSourceConfig>
>;
