import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
) {
  const value = dataSource.createFieldValueSelector(fieldPath, {
    defaultValue: [],
  });

  return {
    value,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
