import React, { ChangeEvent, lazy, memo, useCallback, useState } from "react";
import deepEqual from "fast-deep-equal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import { Section, useEditorTranslation } from "core/editor";
import { buildCustomExpressionValue, isCustomExpression } from "core";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { UntransformedTableConfig } from "../../types";
import { useTableEditorTranslation } from "../../translation";
import { TableFilter } from "../../toolsPanel/DefaultTableFilter";
import { removePath } from "../../toolsPanel/utils";
import {
  FilterGroupCombinator,
  FixedFilterGroup,
  IFilterGroup,
  IFilterGroupWithPath,
} from "../../toolsPanel";
import {
  buildConfigFromFixedFilter,
  buildFixedFilterFromConfig,
} from "../../reduxModule/utils";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

const defaultFilterValue: IFilterGroup = {
  combinator: FilterGroupCombinator.AND,
  filters: [],
};

const defaultExpressionValue = buildCustomExpressionValue(
  JSON.stringify(defaultFilterValue, null, 2),
);

type Props = {
  config: UntransformedTableConfig;
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const FixedFilter = memo<Props>(
  ({ config, config: { fixedFilter, filter }, changeConfigValue }) => {
    const translation = useTableEditorTranslation();
    const { applyButton, cancelButton } = useEditorTranslation();

    const [filterIsExpression, setIsExpression] = useState(
      isCustomExpression(fixedFilter),
    );
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

    const fixedFilterExpression = (fixedFilter ??
      defaultExpressionValue) as string;

    const handleClose = useCallback(() => setAnchorEl(null), []);

    const handleChange = useCallback(
      (prop, newValue) => changeConfigValue(prop, newValue),
      [changeConfigValue],
    );

    const changeFixedFilter = useCallback(
      (newFixedFilter?: string | IFilterGroup) =>
        handleChange(
          "fixedFilter",
          typeof newFixedFilter === "string"
            ? newFixedFilter
            : buildConfigFromFixedFilter(newFixedFilter ?? null),
        ),
      [handleChange],
    );

    const onChangeFilter = useCallback(
      (nextFilter: IFilterGroupWithPath) =>
        changeFixedFilter(removePath(nextFilter) as IFilterGroup),
      [changeFixedFilter],
    );

    const [openDialogFilter, setOpenDialogFilter] = useState<boolean>(false);
    const handleToggleDialogFilter = useCallback(
      () => setOpenDialogFilter((prevOpen) => !prevOpen),
      [],
    );

    const createFilter = useCallback(() => {
      changeFixedFilter(defaultFilterValue as IFilterGroup);
      handleToggleDialogFilter();
    }, [changeFixedFilter, handleToggleDialogFilter]);

    const deleteFilter = useCallback(() => {
      filterIsExpression && setIsExpression(false);
      changeFixedFilter(undefined);
    }, [changeFixedFilter, filterIsExpression]);

    const updateFixedFilter = useCallback(
      (isExpression: boolean) => {
        changeFixedFilter(
          isExpression
            ? (defaultExpressionValue as string)
            : (defaultFilterValue as IFilterGroup),
        );
        setIsExpression(isExpression);
      },
      [changeFixedFilter],
    );

    const handleChangeMode = useCallback(
      (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (
          (checked && !deepEqual(fixedFilter, defaultFilterValue)) ||
          (!checked && String(fixedFilter) !== defaultExpressionValue)
        ) {
          setAnchorEl(event.currentTarget);
        } else {
          updateFixedFilter(checked);
        }
      },
      [fixedFilter, updateFixedFilter],
    );

    const handleUpdateFilter = useCallback(() => {
      updateFixedFilter(!filterIsExpression);
      handleClose();
    }, [filterIsExpression, handleClose, updateFixedFilter]);

    return (
      <>
        <Section
          title={translation.fixedFilterTitle}
          wrapped={Boolean(fixedFilter)}
          headerAction={
            <IconButton
              icon={fixedFilter ? "delete_outline" : "add"}
              onClick={fixedFilter ? deleteFilter : createFilter}
              tooltip={
                fixedFilter
                  ? translation.removeFilterTooltip
                  : translation.addFilterTooltip
              }
            />
          }
        >
          {fixedFilter && (
            <>
              {!filterIsExpression && (
                <Button
                  label={translation.defaultFilterButton}
                  onClick={handleToggleDialogFilter}
                  color="primary"
                  iconLeft="filter_list"
                  fullWidth
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={filterIsExpression}
                    onChange={handleChangeMode}
                  />
                }
                label={translation.advancedLabel}
              />
              <Popover
                onClose={handleClose}
                actionsAlign="center"
                actions={
                  <>
                    <Button
                      label={applyButton}
                      color="secondary"
                      onClick={handleUpdateFilter}
                    />
                    <Button label={cancelButton} onClick={handleClose} />
                  </>
                }
                anchorEl={anchorEl}
              >
                <Typography align="center">
                  {translation.applyFilterConfirmation}
                </Typography>
              </Popover>
              {filterIsExpression && (
                <CustomExpressionEditor
                  onChange={changeFixedFilter}
                  value={fixedFilterExpression}
                  config={config}
                  disableSwitcher={true}
                />
              )}
            </>
          )}
        </Section>
        {!filterIsExpression && filter && (
          <TableFilter
            fields={filter.fields}
            filter={
              (buildFixedFilterFromConfig(
                fixedFilter as FixedFilterGroup,
              ) as IFilterGroup | null) ?? defaultFilterValue
            }
            changeFilter={onChangeFilter}
            openDialogFilter={openDialogFilter}
            handleToggleDialogFilter={handleToggleDialogFilter}
          />
        )}
      </>
    );
  },
);
