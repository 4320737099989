import React from "react";

import { Filter } from "elementTypes/common/Echarts/editor";
import { DataSourceComponent } from "./components/DataSource";
import { TranslationComponent } from "./components/Translation";
import { OptionComponent } from "./components/Option";

export const EchartsCustomChartEditor = React.memo(() => (
  <>
    <DataSourceComponent />
    <TranslationComponent />
    <Filter />
    <OptionComponent />
  </>
));
