import { FormDataSource } from "../../../../elementInterfaces";
import { MarkdownInput } from "../../types";
import { buildActions } from "./actions";
import { buildSelectors } from "./selectors";

export function controlledReduxModuleFactory(element: MarkdownInput) {
  const { elementId, fieldPath } = element.config.dataSource!;

  const dataSource = FormDataSource.get(elementId);

  const { actions } = buildActions(fieldPath, dataSource);
  const selectors = buildSelectors(fieldPath, dataSource, element);

  return {
    actions,
    selectors,
  };
}
