import React, { memo } from "react";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import { Form } from "./components/Form";
import { selectors as editSettingsSelectors } from "./reduxModule";
import BackButton from "elementTypes/common/BackButton";

export const EditSettingsPage = memo(() => {
  const { headerPaper } = useStyles();
  const config = useSelector(editSettingsSelectors.config);

  return (
    <>
      <Paper variant="outlined" className={headerPaper}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Typography variant="h5">Editing</Typography>
          </Grid>
        </Grid>
      </Paper>
      {config && <Form />}
      <BackButton />
    </>
  );
});
