import React, {
  lazy,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  JSONSchema6,
  JSONSchema6Definition,
  JSONSchema6TypeName,
} from "json-schema";

import deepEqual from "fast-deep-equal";
import cloneDeep from "lodash/cloneDeep";
import { FixedSizeList } from "react-window";

import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import { GeneralTypes } from "core/types/app";

import { usePrevious } from "utils/hooks";
import IconButton from "../../../../common/IconButton";
import DialogWrapper from "../../../../helpers/HOC/DialogWrapper";
import { withLazyLoading } from "../../../../helpers/HOC/LazyLoading";
import { FormConfig } from "../../../types";
import { useEditorFormTranslation } from "../../translation";
import { Field, FieldRow } from "./FieldRow";

const DialogContent = withLazyLoading(
  lazy(() => import("./SchemaDialogContent")),
  true,
);

const getType = (type: GeneralTypes): JSONSchema6TypeName => {
  switch (type) {
    case "text":
    case "dateTime":
    case "date":
    case "time":
      return "string";
    case "json":
      return "object";
    default:
      return type as JSONSchema6TypeName;
  }
};

const getDateFormat = (
  type: string,
): Record<"format", string> | Record<string, unknown> => {
  switch (type) {
    case "dateTime":
    case "date":
    case "time":
      return { format: "date-time" };
    // case "time":
    //   return { format: "time"}; // use when time input will be fixed
    default:
      return {};
  }
};

const emptyToUndefined = (value: unknown) =>
  typeof value === "string" && !(value as string).trim().length
    ? undefined
    : value;

const transformProps = (properties: {
  [k: string]: JSONSchema6Definition;
}): { [k: string]: JSONSchema6Definition } =>
  Object.entries(properties).reduce(
    (res, [key, value]) => ({
      ...res,
      ...(key === "items" && typeof value === "object"
        ? {
            [key]: transformProps(
              value as { [k: string]: JSONSchema6Definition },
            ),
          }
        : { [key]: emptyToUndefined(value) }),
    }),
    {},
  );

const typeExists = (type?: JSONSchema6TypeName | JSONSchema6TypeName[]) =>
  type && !!(type as string | string[]).length;

const getSchemaProperties = (
  currentProperties: JSONSchema6,
  defaultProperties?: JSONSchema6,
): JSONSchema6["properties"] =>
  Object.entries(currentProperties as Record<string, JSONSchema6>).reduce(
    (res, [key, value]) => {
      const defaultPropsKey = defaultProperties?.[key] as JSONSchema6;
      return {
        ...res,
        [key]: {
          ...(value as JSONSchema6),
          ...(!typeExists(value.type) && {
            ...defaultPropsKey,
          }),
        },
        ...(value.items &&
          typeof value.items === "object" && {
            items: {
              ...value.items,
              ...(!typeExists((value.items as JSONSchema6).type) && {
                ...(defaultPropsKey?.items as JSONSchema6),
              }),
            },
          }),
        ...(value.properties &&
          !!Object.keys(value.properties).length && {
            properties: getSchemaProperties(
              value.properties,
              defaultPropsKey.properties,
            ),
          }),
      };
    },
    {},
  );

const getMissingTypes = (
  currentSchema: JSONSchema6,
  defaultSchema: JSONSchema6,
) => {
  let nextSchema = {
    ...currentSchema,
  };

  if (currentSchema.properties) {
    nextSchema = {
      ...nextSchema,
      properties: getSchemaProperties(
        currentSchema.properties,
        defaultSchema.properties,
      ),
    };
  }
  return nextSchema;
};

function buildNullable(
  type: JSONSchema6TypeName,
  nullable: boolean,
): JSONSchema6TypeName | JSONSchema6TypeName[] {
  return nullable
    ? (type as JSONSchema6TypeName)
    : ([type, "null"] as JSONSchema6TypeName[]);
}

export const JsonSchema = memo(() => {
  const {
    elementModel: {
      config: {
        dataSource: { viewName },
        jsonSchema,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<FormConfig>();
  const {
    addSchemaTooltip,
    deleteSchemaTooltip,
    editSchemaTitle,
    validationTitle,
  } = useEditorFormTranslation();
  const { cancelButton, editButton, updateButton } = useEditorTranslation();
  const prevViewName = usePrevious(viewName);
  const { getViewByName } = useObjectViewList();

  const [fieldSchema, setFieldSchema] = useState<Field | null>(null);

  const handleClose = () => setFieldSchema(null);

  const changeValue = useCallback(
    (newJsonSchema: FormConfig["jsonSchema"]) =>
      changeConfigValue("jsonSchema", newJsonSchema),
    [changeConfigValue],
  );

  const currentView = useMemo(() => getViewByName(viewName), [
    getViewByName,
    viewName,
  ]);

  const { fields: currentViewFields, identifyingField: viewIdentifyingField } =
    currentView ?? {};

  const defaultSchema: JSONSchema6 = useMemo(() => {
    let required: string[] = [];
    const properties = currentViewFields
      ? currentViewFields.reduce((result, field) => {
          const nullable = field.nullable !== false;
          if (!nullable && field.name !== viewIdentifyingField?.name) {
            required = [...required, field.name];
          }
          return {
            ...result,
            [field.name]: {
              type: field.generalType.isArray
                ? buildNullable("array", nullable)
                : buildNullable(getType(field.generalType.type), nullable),
              ...(field.generalType.isArray && {
                items: {
                  type: getType(field.generalType.type) as JSONSchema6TypeName,
                },
              }),
              ...getDateFormat(field.generalType.type),
            },
          };
        }, {})
      : {};
    return {
      type: "object",
      required,
      properties,
    };
  }, [currentViewFields, viewIdentifyingField]);

  const currentSchema = useMemo(() => {
    if (!currentView?.jsonSchema) {
      return null;
    }

    return getMissingTypes(currentView?.jsonSchema, defaultSchema);
  }, [currentView, defaultSchema]);

  const isChanged = useMemo(() => !deepEqual(currentSchema, jsonSchema), [
    currentSchema,
    jsonSchema,
  ]);

  useEffect(
    () => {
      if (isChanged && jsonSchema && prevViewName !== viewName) {
        changeValue(currentSchema ?? defaultSchema);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewName],
  );

  const onActionClick = () => {
    if (jsonSchema) {
      changeValue(undefined);
    } else {
      changeValue(currentSchema ?? defaultSchema);
    }
  };

  const fields = (jsonSchema as JSONSchema6)?.properties;
  const cachedRequiredFields = (jsonSchema as JSONSchema6)?.required ?? [];
  const schemaRequiredFields = (currentSchema as JSONSchema6)?.required ?? [];

  const items = useMemo(
    () =>
      fields &&
      Object.keys(fields).map((item) => ({
        name: item,
        isRequired: cachedRequiredFields.includes(item),
        canBeChanged: !schemaRequiredFields.includes(item),
        ...((fields[item] as JSONSchema6) ??
          defaultSchema?.properties?.[item] ??
          {}),
      })),
    [fields, defaultSchema, cachedRequiredFields, schemaRequiredFields],
  );

  const formDataToSchema = ({
    data,
    schema,
  }: {
    data: { [k: string]: any };
    schema?: JSONSchema6;
  }) => {
    let nextSchema = schema
      ? cloneDeep(schema)
      : { type: "object", required: [] };

    for (const fieldName in data) {
      const { isRequired, ...restProperties } = data[fieldName];

      if (isRequired !== undefined) {
        let nextRequired = (nextSchema?.required ?? []).filter(
          (field: string) => field !== fieldName,
        );
        nextRequired = isRequired ? [...nextRequired, fieldName] : nextRequired;

        nextSchema = {
          ...nextSchema,
          required: nextRequired,
        } as JSONSchema6;
      }

      const nextProps = (nextSchema as JSONSchema6).properties ?? {};

      const transformedProps = transformProps(restProperties);

      nextSchema = {
        ...nextSchema,
        properties: {
          ...nextProps,
          [fieldName]: {
            ...(transformedProps as JSONSchema6["properties"]),
            ...(restProperties.properties && {
              ...formDataToSchema({
                data: restProperties.properties,
                schema: nextProps[fieldName] as JSONSchema6,
              }),
            }),
          },
        } as { [k: string]: JSONSchema6Definition },
      };
    }

    return nextSchema;
  };

  const onSubmit = (data: { [k: string]: any }) => {
    const nextValue = formDataToSchema({ data, schema: jsonSchema });
    !deepEqual(nextValue, jsonSchema) && changeValue(nextValue);
    handleClose();
  };

  const itemSize = 48;

  return (
    <>
      {currentView && (
        <>
          <Section
            title={validationTitle}
            headerAction={
              <IconButton
                icon={jsonSchema ? "delete_outline" : "add"}
                tooltip={jsonSchema ? deleteSchemaTooltip : addSchemaTooltip}
                onClick={onActionClick}
              />
            }
          >
            {!!items?.length && (
              <FixedSizeList
                height={itemSize * Math.min(items.length, 7)}
                itemCount={items.length}
                itemSize={itemSize}
                width="100%"
                itemData={items}
              >
                {(props) => (
                  <FieldRow
                    {...props}
                    onEditClick={setFieldSchema}
                    editTooltip={editButton}
                  />
                )}
              </FixedSizeList>
            )}
          </Section>
          <DialogWrapper
            isForm={true}
            keepMounted={false}
            open={Boolean(fieldSchema)}
            title={editSchemaTitle}
            submitTitle={updateButton}
            cancelTitle={cancelButton}
            handleClose={handleClose}
            handleSubmit={onSubmit}
            submitDisabled={true}
            fullWidth={true}
            maxWidth={
              fieldSchema?.type === "object" ||
              fieldSchema?.type?.includes("object")
                ? "md"
                : "sm"
            }
          >
            {fieldSchema && <DialogContent {...fieldSchema} />}
          </DialogWrapper>
        </>
      )}
    </>
  );
});
