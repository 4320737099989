import { createUseTranslation } from "core/session/translation";

export const editorTranslation = {
  en: {
    alignmentTitle: "Alignment",
    horizontalLabel: "Horizontal",
    verticalLabel: "Vertical",
    colorsTitle: "Colors",
    textColorLabel: "Text Color",
    bgColorLabel: "Background Color",
    borderColorLabel: "Border Color",
    variantLabel: "Color Variant",
    fontVariantLabel: "Font Variant",
    roundedCornersLabel: "Rounded Corners",
    fitContentLabel: "Fit Content",
    sizeLabel: "Size",
    wrapLabel: "Wrap lines",
  },
};

export const colorsTranslation = {
  en: {
    primary: "Primary",
    secondary: "Secondary",
    success: "Success",
    error: "Error",
    warning: "Warning",
    info: "Info",
    default: "Default",
    lighter: "Lighter",
    disabled: "Disabled",
    transparent: "No Color",
  },
};

export const useDefaultTextEditorTranslation = createUseTranslation(
  editorTranslation,
);

export const useColorsTranslation = createUseTranslation(colorsTranslation);
