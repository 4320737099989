/**
 * Find difference between two objects
 * @param  {object} sourceObject - Source object to compare targetObject against
 * @param  {object} targetObject  - New object with potential changes
 * @return {object} keys with differences
 */
export function difference(
  sourceObject: Record<string, unknown>,
  targetObject: Record<string, unknown>,
) {
  return Object.keys(sourceObject).filter(
    (k) => sourceObject[k] !== targetObject[k],
  );
}
