import React, { ChangeEvent, memo, useCallback, useMemo } from "react";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { buildCustomExpressionValue } from "core";
import { useElementEditorContext } from "core/editor";
import { FormConfig } from "../../types";
import { useEditorFormTranslation } from "../translation";

enum Types {
  create = "create",
  edit = "edit",
  detail = "detail",
}
const types = [Types.create, Types.edit, Types.detail];

export const TypeConfig = memo(() => {
  const {
    elementModel: {
      config: { type, dataSource },
    },
    changeConfigValue,
  } = useElementEditorContext<FormConfig>();

  const { typeLabel, ...labels } = useEditorFormTranslation();

  const handleValueChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      changeConfigValue("type", value);

      if (value === "create") {
        // Set identifier value as undefined
        changeConfigValue("dataSource", {
          ...dataSource,
          identifierValue: undefined,
        });
      } else {
        !dataSource.identifierValue &&
          dataSource.identifierName &&
          changeConfigValue("dataSource", {
            ...dataSource,
            identifierValue: buildCustomExpressionValue(null),
          });
      }
    },
    [changeConfigValue, dataSource],
  );

  const items = useMemo(
    () =>
      types.map((formType) => (
        <MenuItem key={formType} value={formType}>
          {labels[`${formType}Label`]}
        </MenuItem>
      )),
    [labels],
  );

  return (
    <TextField
      value={type}
      label={typeLabel}
      onChange={handleValueChange}
      select={true}
      fullWidth={true}
    >
      {items}
    </TextField>
  );
});
