import * as t from "io-ts";
import { ComponentType } from "react";

import { INSTANTIATED } from "../constants";
import { Type } from "../runtime-typing";
import {
  IElementArrayChild,
  IElementModelArrayChild,
  IElementModelSingleChild,
  IElementSingleChild,
} from "./elementChildren";
import { Translated, Translation } from "./i18n";
import { IElementComponent } from "./react";
import { ReduxModuleFactory } from "./redux";

// /**
//  * contains all information about a column
//  */
// export interface IObjectColumn {
//   /**
//    * metadata
//    */
//   defaultVisible: boolean;
//   /**
//    * metadata
//    */
//   followIfKey: boolean;
//   /**
//    * metadata
//    */
//   followTargetField?: string;
//   /**
//    * the name of this column - used for data fetching & retrieval
//    */
//   name: string;
//   /**
//    * the datatype of the column
//    */
//   type: string;
//   /**
//    * the order in the view this column comes from
//    */
//   number: number;
//   moduleName: string;
//   objectName: string;
//   viewName: string;
// }
//
// /**
//  * contains all information about a view
//  */
// export interface IObjectView {
//   /**
//    * objectView id
//    */
//   id: number;
//   /**
//    * metadata
//    * whether this is the default view for this object
//    */
//   default: boolean;
//   /**
//    * the view name - used for data fetching
//    */
//   name: string;
//   moduleName: string;
//   objectName: string;
//   /**
//    * Identifier column
//    */
//   identifier?: IObjectColumn;
//   jsonSchema: object;
// }

export type IElementProps = Record<string, any>;

export const TCssGridConfig = t.type({
  row: t.number,
  column: t.number,
  width: t.number,
  height: t.number,
});

export type TCssGridConfig = t.TypeOf<typeof TCssGridConfig>;

interface IWithPosition {
  /**
   * Configuration for CSS grid
   */
  position: TCssGridConfig;
}

export type TElementChildren = Record<
  string,
  IElementModelSingleChild | IElementModelArrayChild
>;

/**
 * the configuration for an element, including i18n, but excluding actual data
 */
export interface IElementModel<
  ConfigType extends Record<string, any> = {},
  ChildrenType extends TElementChildren = {},
  TranslationKeys extends keyof any = keyof {}
> {
  id: string;
  /**
   * a custom configuration object
   * used for default values, colors, filters, ...
   */
  config: ConfigType;
  /**
   * the name of the element - for debugging purposes
   */
  name: string;
  /**
   * an element can have access to multiple grids
   * e.g. a form renders the grid containing the input elements
   */
  children: ChildrenType;
  /**
   * here is metadata about the type of element (coming from the backend)
   */
  type: {
    name: string;
  };
  i18n: Translation<TranslationKeys>;
}

export type TElementModelWithPosition<
  ConfigType = {},
  ChildrenType extends Record<
    string,
    IElementModelSingleChild | IElementModelArrayChild
  > = {},
  TranslationKeys extends keyof any = keyof {}
> = IElementModel<ConfigType, ChildrenType, TranslationKeys> & IWithPosition;

export interface IElement<
  ConfigType = {},
  ChildrenType extends Record<
    string,
    IElementSingleChild | IElementArrayChild
  > = {},
  TranslationKeys extends keyof any = keyof {}
> extends IElementModel<ConfigType, ChildrenType, TranslationKeys> {
  [INSTANTIATED]: true;
  /**
   * additional values that can be used in config values templates, that will be provided at runtime.
   */
  props: IElementProps;
  originalId: string;
  scope: string;
  originalConfig: ConfigType;
}

export type TElementWithPosition<
  ConfigType = {},
  ChildrenType extends Record<
    string,
    IElementSingleChild | IElementArrayChild
  > = {},
  TranslationKeys extends keyof any = keyof {}
> = IElement<ConfigType, ChildrenType, TranslationKeys> & IWithPosition;

export type TTranslatedElement<Element extends IElement> = Element & {
  i18n: Element extends IElement<any, any, infer TranslationKeys>
    ? Translated<TranslationKeys>
    : never;
};

export type AnyElement<
  ConfigType = {},
  ChildrenType extends Record<
    string,
    IElementSingleChild | IElementArrayChild
  > = {},
  TranslationKeys extends keyof any = keyof {}
> =
  | IElement<ConfigType, ChildrenType, TranslationKeys>
  | IElementModel<ConfigType, ChildrenType, TranslationKeys>;

export type GetElementType = (element: AnyElement) => IElementType;

export enum ElementGroup {
  Basic = "basic",
  Inputs = "inputs",
  Various = "various",
}

export type TypeFactory<Config = any> = (params: {
  config: Config;
  state: any;
}) => Type;

export type SelectorTypes<Config = any> = Record<
  string,
  Type | TypeFactory<Config>
>;

/**
 * TODO:
 * Check the status of this issue: https://github.com/Microsoft/TypeScript/issues/16597
 * If and when it is instantiated, we can improve the typing by passing Element as a generic type to the
 * ReduxModuleFactory type. The problem is that right now typescript only allows passing all arguments
 * or no arguments to a generic type, which means if we provide a value for ReduxModuleFactory's `Element`
 * we must also provide a value for ReduxModule, and that would break the inferring of the return type of
 * `reduxModuleFactory`. The same can be applied to make the relation between `component` and `configType`
 * consistent.
 */
export interface IRawElementType<ExtraProps = any> {
  name: string;
  component: IElementComponent<ExtraProps>;
  group?: ElementGroup;
  reduxModuleFactory?: ReduxModuleFactory; // <Element>;
  configType?: t.Mixed; // elementType: Element;
  selectorTypes?: SelectorTypes;
  /**
   * TODO:
   * This type should be better enforced. We have to find some way of forcing `childrenType` to by an io-ts
   * type that can have required or optional string keys, which values are IElementSingleChild or
   * IElementArrayChild. Right now this is being enforced at runtime, in `buildElementTypeGetter` in
   * `src/core/getElementType.ts`.
   */
  childrenType?: t.Mixed;
  translationKeys?: readonly string[];
  editorComponent?: ComponentType;
  defaultElement?: IDefaultElement;
  editorMetadata?: ElementEditorMetadata;
  editable?: boolean;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface ElementEditorMetadata {
  // texts to be displayed in the gui editor
  i18n?: Translation<"title" | "description">;
  minSize?: Dimensions;
  maxSize?: Dimensions;
  defaultSize?: Dimensions;
}

export interface IDefaultElement<ConfigType = any> {
  name?: string;
  config?: ConfigType;
  i18n?: any;
  children?: Record<
    string,
    Record<string, TDefaultElementChild | TDefaultElementChild[]>
  >;
  position?: TCssGridConfig;
}

export type TDefaultElementChild =
  | (IDefaultElement & { type: { name: string } })
  | string;

export interface IChildMetadata {
  isArray: boolean;
  required: boolean;
  getValidElementTypes: (
    elementTypes: Record<string, IElementType>,
  ) => IElementType[];
}

export interface IElementType<ExtraProps = any>
  extends IRawElementType<ExtraProps> {
  childrenMetadata: Record<string, IChildMetadata>;
  translationType?: t.Mixed;
  editable?: boolean;
}

export type TypeOfSelectors<Selectors extends Record<string, t.Mixed>> = {
  [K in keyof Selectors]: t.TypeOf<Selectors[K]>;
};
