import React, { memo, useMemo, useRef, useState } from "react";
import merge from "lodash/merge";
import pick from "lodash/pick";
import { Editor, EditorConfiguration } from "codemirror";
import { Controlled as CodeMirror, ICodeMirror } from "react-codemirror2";
import { Box, Paper, Popper, Typography } from "@material-ui/core";

import clsx from "clsx";

import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/display/fullscreen.css";
import "codemirror/addon/display/fullscreen";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/neat.css";

import "codemirror/mode/sql/sql";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/xml/xml";
import "codemirror/mode/python/python";
import "codemirror/mode/yaml/yaml";
import "codemirror/mode/markdown/markdown";

import "./javascript-hint";

import { IAugmentedHint } from "./javascript-hint";
import { useStyles } from "./style";

import "./codemirror-mode-regex.ts";
import "./regex-styles.css";

type CodeEditor = {
  value: string;
  language: string;
  onChange: (editor: any, data: any, value: string) => void;
  disableAutocomplete?: boolean;
  onAutoComplete?: string | false | ((cm: any) => void);
  theme?: string;
  className?: string;
  autofocus?: boolean;
  lineNumbers?: boolean;
  options?: Partial<EditorConfiguration>;
  label?: string;
  stylesAsMuiInput?: boolean;
} & ICodeMirror;

export const CodeEditor = memo<CodeEditor>(
  ({
    value,
    disableAutocomplete,
    onAutoComplete,
    onChange,
    language,
    theme = "neat",
    className,
    autofocus,
    lineNumbers,
    options = {},
    label,
    stylesAsMuiInput,
    ...rest
  }) => {
    const classes = useStyles();
    const hintRef = useRef<HTMLDivElement>(null);
    const [selectedHint, setSelectedHint] = useState<null | IAugmentedHint>();

    const [focused, setFocus] = useState(false);

    const extraKeys: any = useMemo(() => {
      let result: CodeMirror.ShowHintOptions["extraKeys"] = {
        F11: (cm: CodeMirror.Editor) => {
          cm.setOption("fullScreen", !cm.getOption("fullScreen"));
        },
        Esc: (cm: CodeMirror.Editor) => {
          if (cm.getOption("fullScreen")) {
            cm.setOption("fullScreen", false);
          }
        },
      };

      if (!disableAutocomplete && onAutoComplete) {
        result = {
          ...result,
          "Ctrl-Space": onAutoComplete,
          // Mac users can't use Ctrl-Space
          // thus we allow Tab for autocompletion as well
          Tab: onAutoComplete,
        };
      }

      return result;
    }, [disableAutocomplete, onAutoComplete]);

    /**
     * We avoid a real deep merge because there might be special proxy objects that simulate Arrays, and that breaks
     * lodash's merge
     */
    const mergeOptions = {
      mode: language,
      theme,
      lineNumbers,
      extraKeys,
      autofocus,
    };
    const fullOptions: EditorConfiguration = {
      ...options,
      ...merge(mergeOptions, pick(options, Object.keys(mergeOptions))),
      hintOptions: {
        ...options.hintOptions,
        completeSingle: false,
        select: (hint: any) => setSelectedHint(hint),
        close: () => setSelectedHint(null),
        container: hintRef.current,
        showHint: true,
      } as any,
    };

    const editorDidMount = (editor: Editor) => {
      setTimeout(() => editor.refresh(), 0);
    };

    const onKeyEvent = (editor: Editor, event: any) => {
      if (!disableAutocomplete && onAutoComplete) {
        setTimeout(() => editor.showHint(event), 0);
      }
    };

    return (
      <>
        <div className="CodeEditor-hints-container" ref={hintRef} />
        <div
          className={clsx(classes.root, {
            [classes.adaptToMuiStyles]: stylesAsMuiInput,
          })}
        >
          <fieldset
            className={clsx(classes.fieldset, {
              [classes.focused]: focused,
            })}
          >
            {label && (
              <legend>
                <Typography
                  variant="caption"
                  component="label"
                  color={focused ? "primary" : undefined}
                >
                  {label}
                </Typography>
              </legend>
            )}
          </fieldset>
          <CodeMirror
            {...rest}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            editorDidMount={editorDidMount}
            value={value}
            options={fullOptions}
            onBeforeChange={onChange}
            onChange={() => ({})}
            className={clsx(className, "code-editor", classes.codeMirror)}
            onKeyPress={onKeyEvent}
          />
          <Popper
            open={!!selectedHint}
            anchorEl={hintRef.current && (hintRef.current.firstChild as any)}
            placement="left-start"
            className="CodeEditor-info-box"
          >
            <Paper variant="outlined" square>
              {selectedHint && (
                <Box p={1}>
                  <Typography variant="h3" gutterBottom>
                    {selectedHint.displayText}
                  </Typography>

                  <Typography variant="h6">{selectedHint.type.name}</Typography>
                  {selectedHint.type.description && (
                    <Typography variant="body1" component="pre">
                      {selectedHint.type.description}
                    </Typography>
                  )}
                </Box>
              )}
            </Paper>
          </Popper>
        </div>
      </>
    );
  },
);
