import React, { memo, useCallback, useMemo, useState } from "react";

import { Section, useObjectViewList } from "core/editor";
import { useSessionContext } from "core/session";
import { getTranslatedText } from "core/utils/element";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { UntransformedTableConfig } from "../../types";
import { useTableEditorTranslation } from "../../translation";

type Props = {
  viewName: UntransformedTableConfig["dataSource"]["viewName"];
  simpleFilter: UntransformedTableConfig["simpleFilter"];
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const SimpleFilter = memo<Props>(
  ({ viewName, simpleFilter = [], changeConfigValue }) => {
    const translation = useTableEditorTranslation();
    const { language } = useSessionContext();
    const { getViewByName } = useObjectViewList();

    const [
      searchSimpleFilterInputValue,
      setSearchSimpleFilterInputValue,
    ] = useState<string>("");

    const currentView = useMemo(() => getViewByName(viewName), [
      getViewByName,
      viewName,
    ]);
    const viewFields = currentView?.fields;

    const handleChange = useCallback(
      (prop, newValue) => changeConfigValue(prop, newValue),
      [changeConfigValue],
    );

    const handleSimpleFilterInputChange = useCallback(
      (value: IAutocompleteValue) => {
        setSearchSimpleFilterInputValue("");
        handleChange("simpleFilter", value as string[]);
      },
      [handleChange],
    );

    const options = useMemo(
      () =>
        viewFields
          ?.filter(
            (viewField) =>
              viewField.generalType.type === "text" &&
              !viewField.generalType.isArray,
          )
          .map((viewField) => ({
            value: viewField.name,
            label: `${getTranslatedText(language, viewField.i18n, "title")} (${
              viewField.name
            })`,
          })) ?? [],
      [viewFields, language],
    );

    const selectedSimpleFilterValue = useMemo(
      () =>
        simpleFilter.flatMap((sFilter) =>
          options.filter((o: SelectOption) => o.value === sFilter),
        ),
      [options, simpleFilter],
    );

    return (
      <Section
        title={translation.simpleFilterTitle}
        wrapped={true}
        defaultOpened={false}
      >
        <BaseAutocomplete
          options={options}
          onChange={handleSimpleFilterInputChange}
          valueObject={selectedSimpleFilterValue}
          name="simpleFilter"
          label={translation.simpleFilterLabel}
          isLoading={false}
          searchInputValue={searchSimpleFilterInputValue}
          onInputChange={setSearchSimpleFilterInputValue}
          virtualizedList={true}
          isMulti={true}
          size="small"
          variant="outlined"
        />
      </Section>
    );
  },
);
