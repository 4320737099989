import { TableHeaderCell } from "../../default_table_header_cell/types";
import {
  FixedFilterGroup,
  FixedFilterRule,
  IFilterField,
  IFilterGroup,
  IFilterRule,
  isFilterGroup,
  isFilterRule,
} from "../toolsPanel";
import { IFetchParams, IOrderIndex, ITableParams, OrderIndexed } from "./types";

type MapGroup = {
  group: IFilterGroup;
  isRoot?: boolean;
  fullTextSearch?: boolean;
};

type MapRule = {
  rule: IFilterRule;
  fullTextSearch?: boolean;
};

export function buildUtils() {
  return {
    getColOrder,
  };
}

export function getColOrder(
  orderIndexed: OrderIndexed,
  { config }: TableHeaderCell,
): IOrderIndex | null {
  return config.dataSource
    ? orderIndexed[config.dataSource.fieldName] || null
    : null;
}

export function mapParamsToApiServiceParams(
  params: ITableParams | IFetchParams,
  fullTextSearch?: boolean,
) {
  const apiParams: any = {
    offset: params.offset,
    limit: params.limit,
    ...mapFilterToApiFilter(params.filter, fullTextSearch),
  };

  if (params.order) {
    const order = params.order
      .map((o) => `${o.fieldName}.${o.asc ? "asc" : "desc"}`)
      .join(",");
    if (order) {
      apiParams.order = order;
    }
  }

  return apiParams;
}

export function mapFilterToApiFilter(
  filter: IFilterGroup | null,
  fullTextSearch?: boolean,
) {
  if (!(filter && filter.filters && filter.filters.length)) {
    return {};
  }
  const mapped = mapGroup({ group: filter, isRoot: true, fullTextSearch });
  return mapped
    ? {
        [filter.combinator.toLowerCase()]: mapGroup({
          group: filter,
          isRoot: true,
          fullTextSearch,
        }),
      }
    : {};
}

function mapGroup({ group, isRoot = false, fullTextSearch }: MapGroup) {
  const comb = isRoot ? "" : group.combinator.toLowerCase();
  const filters: string = group.filters
    .map((f) =>
      f
        ? isFilterGroup(f)
          ? mapGroup({ group: f, fullTextSearch })
          : mapRule({ rule: f, fullTextSearch })
        : f,
    )
    .filter(Boolean)
    .join(",");
  return filters ? `${comb}(${filters})` : "";
}

function trimValue(value: any) {
  try {
    return value.trim();
  } catch {
    return value;
  }
}

function mapRule({ rule, fullTextSearch }: MapRule) {
  let ruleStr = `"${rule.field.name}".`;
  switch (rule.operator) {
    case "is_true":
      ruleStr = `${ruleStr}is."true"`;
      break;
    case "is_false":
      ruleStr = `${ruleStr}is."false"`;
      break;
    case "is_null":
      ruleStr = `${ruleStr}is."null"`;
      break;
    case "is_not_null":
      ruleStr = `${ruleStr}not.is."null"`;
      break;
    case "in":
      ruleStr = `${ruleStr}in.(${rule.value.toString()})`;
      break;
    case "ilike":
      ruleStr = `${ruleStr}${rule.operator}."${
        fullTextSearch ? "*" : ""
      }${trimValue(rule.value)}*"`;
      break;
    default:
      ruleStr = `${ruleStr}${rule.operator}."${trimValue(rule.value)}"`;
  }
  return ruleStr;
}

export function buildFixedFilterFromConfig(
  filter: FixedFilterRule | FixedFilterGroup | null,
): IFilterRule | IFilterGroup | null {
  let builtFilter: IFilterRule | IFilterGroup | null = null;
  if (filter) {
    if (isFilterRule(filter)) {
      builtFilter = {
        field: {
          name: filter.field,
        } as IFilterField,
        operator: filter.operator,
        value: filter.value,
      };
    } else {
      builtFilter = {
        combinator: filter.combinator,
        filters: filter.filters.map(buildFixedFilterFromConfig),
      };
    }
  }
  return builtFilter;
}

export function buildConfigFromFixedFilter(
  filter: IFilterRule | IFilterGroup | null,
): FixedFilterRule | FixedFilterGroup | null {
  let builtFilter: FixedFilterRule | FixedFilterGroup | null = null;
  if (filter) {
    if (isFilterRule(filter)) {
      builtFilter = {
        field: filter.field.name,
        operator: filter.operator,
        value: filter.value,
      };
    } else {
      builtFilter = {
        combinator: filter.combinator,
        filters: filter.filters.map(buildConfigFromFixedFilter),
      };
    }
  }
  return builtFilter;
}

export const buildILikeFilter = (
  fieldName: string,
  value: string,
  hidden?: boolean,
): IFilterRule[] => [
  {
    field: { name: fieldName } as IFilterField,
    value,
    operator: "ilike",
    hidden,
  },
];

export const buildEqFilter = (
  fieldName: string,
  value: string,
): IFilterRule[] => [
  { field: { name: fieldName } as IFilterField, value, operator: "eq" },
];
