import { connect } from "react-redux";

import { UsersPage as UPComponent } from "./component";

import { actions, selectors } from "./reduxModule";

import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";

export const mapStateToProps = (state: any) => {
  const additionalInformation = sessionSelectors.additionalInformation(state);

  return {
    userList: selectors.userList(state),
    loading: selectors.loading(state),
    error: selectors.error(state),
    currentEmail:
      additionalInformation?.type === "integrated"
        ? additionalInformation.email
        : null,
  };
};

export const mapDispatchToProps = {
  ...actions,
  ...sessionActions,
};

export const UsersPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UPComponent as any);
