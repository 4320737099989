import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import { BarChartConfig } from "./types";
import { EChartsTranslationKeys } from "../common/Echarts";
import {
  EchartsBarChartEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { IRawElementType } from "core";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "echarts_bar_chart",
  component: Component,
  editable: true,
  configType: BarChartConfig,
  translationKeys: EChartsTranslationKeys,
  defaultElement,
  editorComponent: EchartsBarChartEditor,
  editorMetadata,
};

export default elementType;
