import React from "react";

import { ConfigComponent, StylingComponent } from "./components";

export const StorageImageEditor = React.memo(() => {
  return (
    <>
      <ConfigComponent />
      <StylingComponent />
    </>
  );
});
