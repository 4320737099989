import { selectorScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IState } from "./types";

/**
 * TODO:
 * "staticPages" should be removed from here. Static redux modules should be factory functions that take the path
 */
const scopeSelector = selectorScoper<IState>([
  "staticPages",
  constants.MODULE_NAME,
]);

const loading = (state: any) => scopeSelector(state).loading;
const error = (state: any) => scopeSelector(state).error;
const userList = (state: any) => scopeSelector(state).userList;

export const selectors = {
  loading,
  error,
  userList,
};
