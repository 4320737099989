import React, { memo } from "react";

import Typography from "@material-ui/core/Typography";

import { IElementComponentProps } from "core";
import { CustomTextField } from "./types";

const DefaultCustomTextField = memo<
  IElementComponentProps<{}, CustomTextField>
>(({ element: { config: { text, style } } }) => (
  <Typography {...style}>{text}</Typography>
));

export default DefaultCustomTextField;
