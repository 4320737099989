import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { actions, types } from "./actions";
import AdminService from "services/admin";

const services = AdminService.getInstance();

function* loadSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const data = yield call(services.getAllFiles, { token });
    yield put(actions.loadSuccess(data));
  } catch (error) {
    yield put(actions.loadError(error.message ?? error.toString()));
  }
}

function* deleteSaga({
  payload: { fileName },
}: ReturnType<typeof actions["delete"]>) {
  const token = yield select(sessionSelectors.token);

  try {
    yield call(services.deleteFile, token, fileName);
    yield put(
      sessionActions.enqueueSnackbar({
        message: "File deleted successfully",
        options: {
          variant: "success",
        },
      }),
    );
    yield put(actions.load());
  } catch (error) {
    yield put(
      sessionActions.enqueueSnackbar({
        message: error.message.toString(),
        options: {
          variant: "error",
        },
      }),
    );
  }
}

export function* saga() {
  yield all([yield takeLatest(types.LOAD, loadSaga)]);
  yield all([yield takeLatest(types.DELETE, deleteSaga)]);

  yield put(actions.load());
}
