import React, { memo, useMemo } from "react";
import classNames from "classnames";

import Dropzone, {
  IDropzoneProps,
  IFileWithMeta,
  ILayoutProps,
} from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import MuiButton from "@material-ui/core/Button";

import { MuiIcon } from "elementTypes/common/MuiIcon";

import useStyles from "../styles";

type Props = {
  onChange: (fileWithMeta: IFileWithMeta | null) => void;
};

const Layout = memo<ILayoutProps>(
  ({
    input,
    previews,
    dropzoneProps,
    files,
    extra: { maxFiles, active: isDragging },
  }) => {
    const { dropButton, activeBorder, previewCard } = useStyles();
    const isFileSelected = !!files.length;
    const uploadedFiles = useMemo(
      () =>
        previews?.map(
          (preview, i: number) =>
            preview && (
              <Card
                key={`Preview-${i}`}
                className={previewCard}
                variant="outlined"
              >
                {preview}
              </Card>
            ),
        ),
      [previews, previewCard],
    );

    return (
      <Grid item={true} xs={12} container justify="center">
        {uploadedFiles}
        <MuiButton
          {...dropzoneProps}
          className={classNames(dropzoneProps.className, dropButton, {
            [activeBorder]: isDragging,
          })}
          component="div"
          variant="outlined"
          color="primary"
          startIcon={
            <MuiIcon icon={isFileSelected ? "check" : "cloud_upload"} />
          }
          disabled={isFileSelected}
        >
          {isFileSelected ? "File selected" : "Select or Drop file"}
          {files.length < maxFiles && input}
        </MuiButton>
      </Grid>
    );
  },
);

export const UploadZone = memo<Props>(({ onChange }) => {
  const onChangeStatus: IDropzoneProps["onChangeStatus"] = (file, status) => {
    switch (status) {
      case "done":
        onChange(file);
        break;
      case "removed":
        onChange(null);
        break;
      default:
        break;
    }
  };

  return (
    <Dropzone
      onChangeStatus={onChangeStatus}
      LayoutComponent={Layout}
      inputContent={null}
      maxFiles={1}
    />
  );
});
