import { IPage } from "../types";
import { prefixPageUrl, stringifyQueries } from "./reduxModule/utils";

/**
 * TODO:
 * Move all url params io functions to this service to keep consistency between parsing and stringifying.
 */

export function getPushArguments(
  page: IPage,
  params: Record<string, any>,
): [string] {
  return [prefixPageUrl(`${page.url}?${stringifyQueries(params)}`)];
}
