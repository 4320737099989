import React, { ReactNode, memo } from "react";

import Box, { BoxProps } from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { default as MuiPopover, PopoverProps } from "@material-ui/core/Popover";

import { useStyles } from "./styles";

export type Align = "center" | "flex-start" | "flex-end";

type Props = {
  children: ReactNode;
  anchorEl: HTMLButtonElement | HTMLElement | null;
  onClose: PopoverProps["onClose"];
  actions?: ReactNode;
  actionsAlign?: Align;
  cardProps?: BoxProps;
} & Partial<PopoverProps>;

export default memo<Props>(
  ({
    actions,
    children,
    anchorEl,
    onClose,
    actionsAlign,
    cardProps,
    ...rest
  }) => {
    const { actionsRoot } = useStyles({ align: actionsAlign || "flex-start" });

    return (
      <MuiPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        {...rest}
      >
        <Box clone {...cardProps}>
          <CardContent>{children}</CardContent>
        </Box>
        {actions && (
          <CardActions className={actionsRoot}>{actions}</CardActions>
        )}
      </MuiPopover>
    );
  },
);
