import { createUseTranslation } from "core/session/translation";

export const editorFormTranslation = {
  en: {
    linkToLabel: "Link To",
    typeLabel: "Type",
    createLabel: "Create Form",
    editLabel: "Edit Form",
    detailLabel: "Detail Form",
    validationTitle: "Fields Validation",
    deleteSchemaTooltip: "Delete Schema",
    addSchemaTooltip: "Add Schema",
    requiredTooltip: "Required",
    defaultRequiredTooltip: "Field is not nullable, required by default",
    fieldTypeTooltip: "Field Type",
    nullTypeTooltip: "Field Value can be Null",
    createValidation: "Create Validation",
    removeValidation: "Remove Validation",
    customValidationLabel: "Custom Validation",
    defaultDataTitle: "Default Data",
    createDefaultData: "Create Default Data",
    removeDefaultData: "Remove Default Data",
    defaultDataLabel: "Default Data",
    stateFieldNameLabel: "State Field Name",
    exclusiveLabel: "Exclusive",
    itemsTypeLabel: "Items Type",
    itemsLabel: "Items",
    minItemsLabel: "Minimum Items",
    minLabel: "Minimum",
    maxLabel: "Maximum",
    patternLabel: "Pattern",
    uniqueItemsLabel: "Unique Items",
    editSchemaTitle: "Edit Field Validation",
    propertiesTitle: "Properties",
    newPropertyTooltip: "Add New Property",
    hideSaveAndStayLabel: "Hide Save and Stay button",
  },
};

export const useEditorFormTranslation = createUseTranslation(
  editorFormTranslation,
);
