import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    codeClass: {
      marginTop: theme.spacing(),

      "& .CodeMirror": {
        height: "50px",
      },
    },
    suggestionsDropdown: {
      "& > li[aria-selected=true], & > li:hover": {
        color: `${theme.palette.text.primary} !important`,
        backgroundColor: `${theme.palette.action.selected} !important`,
      },
    },
  }),
);
