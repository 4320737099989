import { ConnectedProps, connect } from "react-redux";

import { selectors as editorSelectors } from "core/editor/reduxModule";
import { buildMapStateToProps } from "core/utils/react-redux";
import { AdminButton as ABComponent } from "./component";

const connector = connect(
  buildMapStateToProps({
    editModeOn: editorSelectors.editModeOn,
  }),
);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export const AdminButton = connector(ABComponent);
