import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import { TextInputEditor, defaultElement, editorMetadata } from "./editor";
import {
  TextInputConfig,
  TextInputTranslationKeys,
  textInputSelectors,
} from "./types";
import { ElementGroup, IRawElementType } from "core";

const elementType: IRawElementType = {
  defaultElement,
  reduxModuleFactory,
  name: "default_text_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: TextInputConfig,
  selectorTypes: textInputSelectors,
  translationKeys: TextInputTranslationKeys,
  editorComponent: TextInputEditor,
  editorMetadata,
};

export default elementType;
