import React, { MouseEvent, memo, useState } from "react";

import { ColorResult, SketchPicker } from "react-color";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel, {
  FormControlLabelProps,
} from "@material-ui/core/FormControlLabel";
import Popover from "@material-ui/core/Popover";

import { isStringExists } from "../../../utils/string";
import { useStyles } from "./style";

export type Placement = FormControlLabelProps["labelPlacement"];

type Props = {
  value: string;
  changeValue: (value: string | null) => void;
  label?: string;
  disabled?: boolean;
  placement?: Placement;
  nullable?: boolean;
};

export const ColorInput = memo<Props>(
  ({ value, changeValue, label, disabled, placement, nullable }) => {
    const color = value || "transparent";
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
    const { colorControl, labelControl } = useStyles({
      isLabel: isStringExists(label),
      bgColor: color,
      placement,
    });

    const handleClick = (event: MouseEvent<any>) =>
      setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleChange = ({ hex }: ColorResult) => {
      const nextVal = hex === "transparent" && nullable ? null : hex;
      if (nextVal !== value) {
        changeValue(nextVal);
      }
    };

    const component = <button onClick={handleClick} className={colorControl} />;
    const open = Boolean(anchorEl);
    return (
      <>
        <FormControl fullWidth={true} data-disabled={disabled}>
          <FormControlLabel
            className={labelControl}
            control={component}
            disabled={disabled}
            label={label}
            labelPlacement={placement}
          />
        </FormControl>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className="color-input-popover"
        >
          <SketchPicker color={color} onChangeComplete={handleChange} />
        </Popover>
      </>
    );
  },
);
