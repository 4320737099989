import React from "react";
import { useCreateInitialGridElement } from "utils/hooks/useCreateInitialElement";

import { ContainerConfig } from "../types";

export const ContainerEditor = () => {
  useCreateInitialGridElement<ContainerConfig>("container_grid");

  return <div />;
};
