import { Selector } from "../types";
import { select, takeLatest } from "redux-saga/effects";

import deepEqual from "fast-deep-equal";

interface Options<T> {
  firstValue?: T;
  onChange?: (value: T) => Generator;
  onError?: (e: any) => Generator;
  listenTo?: string | string[];
}

export function createWatcherSaga<T>(
  selector: Selector<T> | null | undefined,
  options: Options<T> = {},
) {
  let lastValue = options.firstValue;
  function* checkSelector() {
    if (selector) {
      try {
        const currentValue = yield select(selector);

        if (!deepEqual(currentValue, lastValue)) {
          lastValue = currentValue;
          if (options.onChange) {
            yield options.onChange(currentValue);
          }
        }
      } catch (e) {
        // the selector could fail, call the onError generator
        if (options.onError) {
          yield options.onError(e);
        }
      }
    }
  }

  return takeLatest(options.listenTo ?? "*", checkSelector);
}
