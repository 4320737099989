import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { getTranslatedTextSaga } from "core/session/translation/createUseTranslation";
import { sessionTranslation } from "core/session/translations";
import { CallButton } from "../types";
import { Actions, Types } from "./types";

export function buildSaga(actions: Actions, types: Types, element: CallButton) {
  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token = yield select(sessionSelectors.token);
    const { showNotification } = element.config;

    const callArgs = (yield select(element.config.callArgs)) as ReturnType<
      typeof element.config.callArgs
    >;
    const callName = (yield select(element.config.callName)) as ReturnType<
      typeof element.config.callName
    >;

    try {
      const result = yield call(
        services.api.runProcedure,
        token,
        callName,
        "view",
        callArgs,
      );
      yield put(actions.loadSuccess(result));
      if (showNotification) {
        yield put(
          sessionActions.enqueueSnackbar({
            message: yield call(
              getTranslatedTextSaga,
              sessionTranslation,
              "success",
            ),
            options: {
              variant: "success",
            },
          }),
        );
      }
    } catch (error) {
      yield put(actions.loadError(error.message ?? error.toString()));
      yield put(
        sessionActions.enqueueSnackbar({
          message: yield call(
            getTranslatedTextSaga,
            sessionTranslation,
            "error",
          ),
          options: {
            variant: "error",
          },
        }),
      );
    }
  }

  return function* mainSaga() {
    yield all([yield takeLatest(types.LOAD, loadSaga)]);
  };
}
