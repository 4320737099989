import React, { memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { useElementEditorContext } from "core/editor";
import { FormConfig } from "../../types";
import { useEditorFormTranslation } from "../translation";

export const HideSaveAndStayButton = memo(() => {
  const {
    elementModel: {
      config: { hideSaveAndStayButton = false },
    },
    changeConfigValue,
  } = useElementEditorContext<FormConfig>();
  const { hideSaveAndStayLabel } = useEditorFormTranslation();

  const handleHideAndStayInputChange = () =>
    changeConfigValue("hideSaveAndStayButton", !hideSaveAndStayButton);

  return (
    <FormControlLabel
      control={
        <Switch
          data-testid="hide-save-and-stay-button"
          checked={hideSaveAndStayButton}
          onChange={handleHideAndStayInputChange}
        />
      }
      label={hideSaveAndStayLabel}
    />
  );
});
