import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
  footer: {
    textAlign: "center",
  },
  dividerFooter: {
    marginBottom: 16,
    marginTop: 8,
  },
}));

export default useStyles;
