import React, { memo } from "react";

import { isCustomExpression } from "core";
import { Section } from "core/editor";
import { UntransformedTextFieldConfig } from "../../types";
import { useDefaultTextEditorTranslation } from "../translation";
import {
  BG_COLORS,
  BackgroundSelector,
  ColorSelector,
} from "core/editor/common";

type Props = {
  config: UntransformedTextFieldConfig;
  onChange: (
    type: keyof UntransformedTextFieldConfig,
  ) => (value: string) => void;
};

export const FieldColors = memo<Props>(({ config, onChange }) => {
  const { colorsTitle } = useDefaultTextEditorTranslation();

  const handleColorChange = (type: "color" | "background") => (
    nextValue: string,
  ) => {
    let newValue = "";

    if (isCustomExpression(nextValue) || BG_COLORS.includes(nextValue)) {
      newValue = nextValue;
    } else if (type === "color") {
      newValue = "default";
    } else {
      newValue = "transparent";
    }

    onChange(type)(newValue);
  };

  return (
    <Section title={colorsTitle} wrapped={true}>
      <ColorSelector config={config} onChange={handleColorChange("color")} />
      <BackgroundSelector
        config={config}
        onChange={handleColorChange("background")}
      />
    </Section>
  );
});
