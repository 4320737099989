import React, { memo } from "react";

import Typography, { TypographyProps } from "@material-ui/core/Typography";

import { MarkdownFieldConfig } from "../types";

interface IOwnProps {
  level: number;
}

export type IProps = IOwnProps & TypographyProps;

export const Heading = (align: MarkdownFieldConfig["align"] = "left") =>
  memo<IProps>(({ level, children }) => (
    <Typography
      variant={`h${level}` as TypographyProps["variant"]}
      align={align}
    >
      {children}
    </Typography>
  ));
