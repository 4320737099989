import React, { memo, useEffect } from "react";
import { Controller, EventFunction } from "react-hook-form";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import { useHookFormContext } from "../../../common/HookForm";
import { useStyles } from "../style";
import { useOperatorsTranslation } from "../../translation";

type Props = {
  operator: string;
  path: number[];
  operators: string[];
  onChange: EventFunction;
};

export const Operator = memo<Props>(
  ({ onChange, path, operator, operators }) => {
    const { marginRight } = useStyles();
    const { control, setValue } = useHookFormContext();
    const translation = useOperatorsTranslation();

    /*
     * Since `react-hook-form` minimizes the number of re-renders
     * there is a need to trigger `setValue` when new `Rule` has been added or deleted
     */
    useEffect(() => {
      setValue(`[${path}].operator`, operator);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operator, path]);

    return (
      <Controller
        as={
          <TextField select={true} className={marginRight} margin="dense">
            {operators.map((fieldOperator: string) => (
              <MenuItem key={fieldOperator} value={fieldOperator}>
                <Tooltip
                  title={
                    translation[`${fieldOperator}Description`] || fieldOperator
                  }
                >
                  <div style={{ flexGrow: 1 }}>
                    {translation[`${fieldOperator}Label`] || fieldOperator}
                  </div>
                </Tooltip>
              </MenuItem>
            ))}
          </TextField>
        }
        name={`[${path}].operator`}
        control={control}
        defaultValue={operator}
        onChange={onChange}
      />
    );
  },
);
