import React, { memo, useCallback, useMemo, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { Section, useElementEditorContext } from "core/editor";
import { BoolInputConfig } from "../../types";
import { useBoolInputEditorTranslation } from "../translation";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "../../../default_autocomplete_input/components";
import { SelectOption } from "../../../default_autocomplete_input/types";

const placementOptions = ["bottom", "top", "end", "start"];
const placementAutocompleteOptions = placementOptions.map((colorOption) => ({
  value: colorOption,
  label: colorOption,
}));

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: { placement, isSwitch },
    },
    changeConfigValue,
  } = useElementEditorContext<BoolInputConfig>();
  const translation = useBoolInputEditorTranslation();
  const [searchPlacementInputValue, setSearchPlacementInputValue] = useState<
    string
  >("");

  const handlePlacementChange = useCallback(
    (newValue: BoolInputConfig["placement"]) =>
      changeConfigValue("placement", newValue),
    [changeConfigValue],
  );
  const handleIsSwitchChange = useCallback(
    (newValue: BoolInputConfig["isSwitch"]) =>
      changeConfigValue("isSwitch", newValue),
    [changeConfigValue],
  );

  const selectedPlacementValue = useMemo(
    () =>
      placementAutocompleteOptions.find(
        (o: SelectOption) => o.value === placement,
      ),
    [placement],
  );

  const handlePlacementInputChange = (value: IAutocompleteValue) => {
    setSearchPlacementInputValue("");
    // Fix empty value ""
    handlePlacementChange(value ? (value as any) : undefined);
  };
  const handleIsSwitchInputChange = () => handleIsSwitchChange(!isSwitch);

  return (
    <Section title={translation.stylingTitle} wrapped={true}>
      <BaseAutocomplete
        options={placementAutocompleteOptions}
        onChange={handlePlacementInputChange}
        valueObject={selectedPlacementValue ?? ""}
        name="placement"
        label={translation.placementInputLabel}
        isLoading={false}
        searchInputValue={searchPlacementInputValue}
        onInputChange={setSearchPlacementInputValue}
        virtualizedList={true}
        isClearable={true}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(isSwitch)}
            onChange={handleIsSwitchInputChange}
          />
        }
        label={translation.isSwitchLabel}
      />
    </Section>
  );
});
