import React, { ReactNode, memo, useMemo } from "react";

import {
  default as MuiTableRow,
  TableRowProps,
} from "@material-ui/core/TableRow";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";

import { useTableContext } from "./context";

type Props = {
  children: ReactNode[];
  rowId: string | number;
  rowProps?: TableRowProps;
  cellProps?: TableCellProps;
};

export const TableRow = memo<Props>(
  ({ children, rowId, rowProps, cellProps }) => {
    const { alignment } = useTableContext();
    const cells = useMemo(
      () =>
        children.map((child, i) => (
          <TableCell
            key={`${rowId}-TableCell-${i}`}
            align={alignment[i]}
            {...cellProps}
          >
            {child}
          </TableCell>
        )),
      [children, cellProps, rowId, alignment],
    );

    return <MuiTableRow {...rowProps}>{cells}</MuiTableRow>;
  },
);
