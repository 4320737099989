import { UntransformedGeoJSONFieldConfig } from "./types";
import {
  RequiredConfigProperties,
  buildCustomExpressionValue,
} from "core/types";

export const markerBackgroundColorFunctionDefault = (currentValue: string) =>
  buildCustomExpressionValue(`(feature) => {return "${currentValue}"}`);

export const DEFAULT_CONFIG: RequiredConfigProperties<UntransformedGeoJSONFieldConfig> = {
  tileLayerUrl: null,
  updateMapView: true,
  styleFunction: buildCustomExpressionValue("(feature) => {return {}}"),
  tooltipFunction: buildCustomExpressionValue('(feature) => {return ""}'),
  markerBackgroundColorFunction: "white",
  markerDisplayTextFunction: buildCustomExpressionValue(
    '(feature) => {return ""}',
  ),
};
