import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 16,
  },
  footer: {
    textAlign: "center",
  },
  dividerFooter: {
    marginBottom: 16,
    marginTop: 8,
  },
  dropButton: {
    overflow: "unset",
    "&.dzu-dropzone": {
      minHeight: "90px",
    },
  },
  activeBorder: {
    "&.dzu-dropzoneActive": {
      border: "2px dashed currentColor",
    },
  },
  previewCard: {
    width: "100%",
    marginBottom: theme.spacing(),
    "& .dzu-previewContainer": {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
      minHeight: "45px",
      borderBottom: "none",
    },
    "& .dzu-previewFileName": {
      color: "currentColor",
    },
  },
}));

export default useStyles;
