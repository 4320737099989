import React, { memo, useContext, useMemo, useState } from "react";

import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { mapStateToProps } from "./container";

import {
  ConnectedStaticReduxModuleActionsProps,
  IMenuItem,
  ITranslatedMenuItem,
  RouterReduxModule,
} from "core";
import { SessionContext } from "core/session";
import { prefixPageUrl } from "core/router/reduxModule/utils";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import Link from "elementTypes/common/Link";
import useStyles from "./styles";
import { GenerateMenuList, IGenerateMenuItemsProps } from "../MenuList";

type MenuItemProps = {
  item: ITranslatedMenuItem;
  menu: IMenuItem[];
} & ConnectedStaticReduxModuleActionsProps<RouterReduxModule> &
  IGenerateMenuItemsProps &
  ReturnType<typeof mapStateToProps>;

export const MenuItem = memo<MenuItemProps>(
  ({ depth = 0, item, menu, uiName, menuItemSelected, getPageById }) => {
    const { language } = useContext(SessionContext);
    const classes = useStyles({ depth });
    const isNested = !!menu.length;
    const isError = !isNested && !item.externalLink && !item.pageId;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const handleClickMenu = () => setMenuOpen((prevOpen) => !prevOpen);
    // TODO handle external links
    const page = useMemo(
      () => (item.pageId ? getPageById(item.pageId) : null),
      [item.pageId, getPageById],
    );

    const prefixedUrl = page ? prefixPageUrl(page.url) : null;

    const itemIcon =
      item.i18n.icon || isError ? (
        <ListItemIcon
          className={`${classes.icon} ${
            isError ? classes.errorIcon : classes.baseIcon
          }`}
        >
          <MuiIcon
            icon={isError ? "error" : item.i18n.icon!}
            fontSize="large"
          />
        </ListItemIcon>
      ) : (
        <div className={`${classes.icon} ${classes.baseIcon}`} />
      );

    const SubMenu = useMemo(
      () =>
        GenerateMenuList(menu, uiName, menuItemSelected, language, depth + 1),
      [depth, menu, uiName, menuItemSelected, language],
    );

    const getMenuHref = () => {
      let href;

      if (!isNested) {
        if (item.externalLink) {
          href = item.externalLink;
        }

        if (item.pageId && prefixedUrl) {
          href = prefixedUrl;
        }
      }

      return href;
    };

    return (
      <Link href={getMenuHref()} underline="none" color="inherit">
        <ListItem
          className={`${classes.button} ${classes.nested} menu-item`}
          button={true}
          onClick={isNested ? handleClickMenu : undefined}
          selected={prefixedUrl === menuItemSelected}
        >
          {itemIcon}
          <ListItemText primary={item.i18n.label} />
          {isNested && (menuOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {isNested && (
          <Collapse
            in={menuOpen}
            unmountOnExit={false}
            timeout={0}
            mountOnEnter={true}
          >
            {SubMenu}
          </Collapse>
        )}
      </Link>
    );
  },
);
