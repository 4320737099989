import { IMPLEMENTED_INTERFACES, SET_MODULE_GETTER } from "./constants";
import {
  GetModule,
  IElementInterface,
  IElementInterfaceImpl,
  IReduxModule,
} from "./types";

// Used to give each interface instance a unique id.
let nextId = 0;

/*
 * This class provides a functionality similar to React's Context but for Redux, and tied to a specific element,
 * it doesn't "bubble down" to descendants.
 */
class ElementInterface<T> implements IElementInterface<T> {
  public readonly id: string;

  /**
   * This attribute is needed to get an element's module in the `get` function, if an element id is passed. It
   * will be set by the core after calling a reduxModuleFactory.
   */
  private getModule: GetModule | null = null;

  constructor() {
    this.id = (++nextId).toString();
  }

  public implement(value: T): IElementInterfaceImpl<T> {
    return {
      value,
      interface: this,
    };
  }

  /**
   * Get the implemented interface, passing either an element's redux module, or an element id.
   */
  public get(moduleOrId: IReduxModule | string | number) {
    if (!this.getModule) {
      throw new Error(
        "Do not call get() before returning the implementation from a reduxModuleFactory.",
      );
    }

    let module: IReduxModule;
    const t = typeof moduleOrId;
    if (t === "string" || t === "number") {
      module = this.getModule(moduleOrId.toString(), { allowNull: false });
    } else {
      module = moduleOrId as IReduxModule;
    }

    const implementation: IElementInterfaceImpl<T> | undefined = (module[
      IMPLEMENTED_INTERFACES
    ] || {})[this.id];
    if (!implementation) {
      /**
       * TODO:
       * The 'module' should contain a reference to the element. To do that, we can split the IReduxModule
       * interface in two: one for the factory output, and another one for the "built" module. The built
       * module can have selectors, actions, context, interfaces, etc, as required props that will be filled
       * with empty values by the reducerManager upon creation.
       * With that reference this message can provide information about the pointed element with
       * `getElementDisplayName()`.
       */
      throw new Error(
        `Module does not implement the ${this.constructor.name} interface`,
      );
    }
    return implementation.value;
  }

  public [SET_MODULE_GETTER](getModule: GetModule) {
    this.getModule = getModule;
  }
}

export function createElementInterface<T>(): ElementInterface<T> {
  return new ElementInterface<T>();
}
