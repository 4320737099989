import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { Props } from "./component";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      willChange: "margin, width",
    },
    appBarShift: {
      transition: theme.transitions.create(["margin", "width"], {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
      width: ({ menuWidth }: Props) => `calc(100% - ${menuWidth})`,
      marginLeft: ({ menuWidth }: Props) => menuWidth,
    },
    appBarText: {
      marginLeft: ({ hidden }: Props) =>
        hidden ? theme.spacing(1) : undefined,
      flexGrow: 1,
    },
  }),
);

export default useStyles;
