import React, { memo, useCallback, useMemo, useState } from "react";

import { AutocompleteRenderGroupParams } from "@material-ui/lab/Autocomplete";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import { BoundActions, Translation } from "../../../types";
import { useSessionContext } from "../../../session";
import { getTranslatedTexts } from "../../../utils/element";
import { actions } from "../../reduxModule";

import {
  BaseAutocomplete,
  IAutocompleteValue,
  getValueObject,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";

import { mapStateToProps } from "./container";
import { FieldsAutocomplete, IField } from "./components";

type OwnProps = {
  viewLabel: string;
  onViewNameChange: (viewName: string) => void;
  viewValue?: string;
  fields?: IField[];
  onViewFieldChange?: (
    fieldName: string,
    fieldValue: IAutocompleteValue,
  ) => void;
  isClearable?: boolean;
};

type Props = ReturnType<typeof mapStateToProps> &
  BoundActions<typeof actions> &
  OwnProps;

export const customRenderOption = (option: SelectOption) => (
  <ListItemText
    primary={option.label}
    secondary={option.value}
    secondaryTypographyProps={{ variant: "overline", display: "block" }}
  />
);

export const defaultItemSize = 52;

export default memo<Props>(
  ({
    viewList,
    viewValue = "",
    viewLabel,
    onViewNameChange,
    fields,
    onViewFieldChange,
    error,
    isClearable,
  }) => {
    const [searchInputValue, changeInputValue] = useState<string>("");
    const { language } = useSessionContext();
    const getTranslatedLabel = useCallback(
      (i18n: Translation<keyof any>) =>
        getTranslatedTexts(language, i18n).title,
      [language],
    );

    const selectOptions = viewList?.length
      ? viewList
          .sort((a, b) => -b.object.name.localeCompare(a.object.name))
          .map((view) => ({
            value: view.name,
            label: getTranslatedLabel(view.i18n),
          }))
      : [];

    const valueObject = getValueObject(selectOptions, viewValue);
    const handleViewChange = (
      value: string[] | string | boolean | number | number[] | null,
    ) => {
      onViewNameChange(value as string);
      searchInputValue.trim().length && onInputChange("");
    };

    const onInputChange = (searchValue: string) =>
      changeInputValue(searchValue);

    const renderGroup = (params: AutocompleteRenderGroupParams) => [
      <ListSubheader key={params.key} component="div" color="primary">
        {params.group}
      </ListSubheader>,
      params.children,
    ];

    const groupBy = (option: SelectOption) => {
      const foundView = viewList?.find((view) => view.name === option.value);

      const title = foundView ? getTranslatedLabel(foundView.object.i18n) : "";

      return title;
    };

    const viewFields = useMemo(
      () =>
        fields?.map((field) => {
          const onFieldChange = (newFieldValue: IAutocompleteValue) => {
            onViewFieldChange?.(field.name, newFieldValue);
          };
          const parentView = viewList?.find((view) => view.name === viewValue);

          return (
            <FieldsAutocomplete
              key={field.name}
              view={parentView}
              onChange={onFieldChange}
              disabled={Boolean(error)}
              getTranslatedLabel={getTranslatedLabel}
              {...field}
            />
          );
        }),
      [
        fields,
        viewList,
        viewValue,
        onViewFieldChange,
        error,
        getTranslatedLabel,
      ],
    );

    const isLoading = !error && !viewList;

    return (
      <div>
        <BaseAutocomplete
          options={selectOptions}
          valueObject={valueObject}
          label={viewLabel}
          name="view"
          onChange={handleViewChange}
          isLoading={isLoading}
          virtualizedList={true}
          groupBy={groupBy}
          renderGroup={renderGroup}
          optionsError={error}
          defaultItemSize={defaultItemSize}
          customRenderOption={customRenderOption}
          onInputChange={onInputChange}
          searchInputValue={searchInputValue}
          isClearable={isClearable}
        />
        {viewFields}
      </div>
    );
  },
);
