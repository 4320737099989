import React, { ChangeEvent, memo } from "react";

import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";

import { isStringExists } from "utils/string";

export interface IProps {
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  labelLeft?: string;
  labelRight?: string;
  disabled?: boolean;
}

export const Switcher = memo<IProps>(
  ({ value, labelLeft, labelRight, name, onChange, ...rest }) => {
    const isSwitchLabels =
      isStringExists(labelLeft) && isStringExists(labelRight);

    return isSwitchLabels ? (
      <Grid container={true} alignItems="center">
        <Grid item={true}>
          <Chip
            label={labelLeft}
            disabled={value}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Switch
          checked={value}
          value={name}
          name={name}
          datatype={"bool"}
          onChange={onChange}
          {...rest}
        />
        <Grid item={true}>
          <Chip
            label={labelRight}
            disabled={!value}
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
    ) : (
      <Switch
        checked={value}
        value={name}
        name={name}
        datatype={"bool"}
        onChange={onChange}
        {...rest}
      />
    );
  },
);
