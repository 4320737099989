import { v4 as uuid } from "uuid";
import * as R from "ramda";
import cloneDeep from "lodash/cloneDeep";

import {
  DEFAULT_LANGUAGE_CODE,
  Language,
  Translation,
} from "../../../../types";
import { ItemDetails } from "./components";

export type IData = { [k: string]: any };

export const reorderList = (
  list: ItemDetails[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((item: ItemDetails, index: number) => ({
    ...item,
    order: index + 1,
  }));
};

export const createNewEntry = (
  order: number,
  language: Language,
  label?: string,
): ItemDetails => ({
  id: uuid().split("-")[0],
  i18n: {
    [DEFAULT_LANGUAGE_CODE]: {
      label: label ?? "",
      icon: "",
    },
    [language.code]: {
      label: label ?? "",
      icon: "",
    },
  } as Translation<"label" | "icon">,
  order,
  pageId: "",
  menu: [],
  externalLink: null,
  isNew: true,
});

export const getMenuPath = (p: number[]) =>
  p.reduce((f, i) => [...f, "menu", i], [] as Array<string | number>);

export const convertValuesToData = (values: IData) => {
  let data = {};
  for (const key in values) {
    const [path, fieldName] = key.split(".");
    const value = values[key];
    // eslint-disable-next-line
    const newKey = path.replace(/[\[\]\'\']/g, "");
    data = R.assocPath([newKey, fieldName], value, data);
  }
  return data as IData;
};

export const exposeMenuValues = (
  menu: ItemDetails[],
  data: IData,
  language: Language,
  path: number[] = [],
) => {
  const nextMenu = cloneDeep(menu);
  return data && !!Object.keys(data).length
    ? nextMenu.map((item, index: number) => {
        const itemPath = [...path, index];
        const nextItem = item;
        const getTranslation = (param: string) =>
          data[itemPath.toString()]?.[param] ??
          nextItem.i18n[language.code]?.[param];

        for (const key in item) {
          switch (key) {
            case "i18n":
              nextItem.i18n = {
                ...nextItem.i18n,
                [language.code]: {
                  label: getTranslation("label"),
                  icon: getTranslation("icon"),
                },
              } as Translation<"label" | "icon">;
              break;
            case "menu":
              nextItem.menu = nextItem.menu.length
                ? exposeMenuValues(nextItem.menu, data, language, itemPath)
                : [];
              break;
            default:
              nextItem[key] = data[itemPath.toString()]?.[key] ?? nextItem[key];
              break;
          }
        }

        return nextItem;
      })
    : nextMenu;
};

export const assembleItemData = ({
  data,
  item,
  language,
}: {
  data: IData;
  item: ItemDetails;
  language: Language;
}) => {
  const { menu, i18n, ...params } = cloneDeep(item);
  const menuWithValues = exposeMenuValues(menu, data, language);

  const parentValue = data[""];
  const nextParams = params;
  for (const key in params) {
    if (key !== "menu" && key !== "i18n") {
      nextParams[key] = parentValue?.[key] ?? params[key];
    }
  }

  return {
    ...nextParams,
    menu: menuWithValues,
    i18n: {
      ...i18n,
      [language.code]: {
        label: parentValue.label,
        icon: parentValue.icon,
      },
    },
  };
};

export const findObjectByKey = (
  arr: { [k: string]: any }[],
  key: string,
  value: string,
  nestingKey: string,
): { [k: string]: any } | undefined => {
  if (!arr.length) {
    return;
  }

  return (
    arr.find((d) => d[key] === value) ??
    findObjectByKey(
      arr.flatMap((d: { [k: string]: any }) => d[nestingKey] || []),
      key,
      value,
      nestingKey,
    )
  );
};

export const omitPropInObjectsArray = (
  arr: { [k: string]: any }[],
  prop: string,
  nestingKey: string,
): { [k: string]: any }[] => {
  if (!arr.length) {
    return [];
  }

  return arr.map((d) => ({
    ...R.omit([prop], d),
    [nestingKey]: omitPropInObjectsArray(d[nestingKey], prop, nestingKey),
  }));
};
