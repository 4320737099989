import React, { memo } from "react";
import { useSelector } from "react-redux";

import { IElementComponentProps } from "core";
import { Grid } from "./types";

import { selectors as editorSelectors } from "core/editor/reduxModule";
import { EditorGrid, StaticGrid } from "./components";

import { AnyElementWithPosition } from "core/types";

type Props = IElementComponentProps<Record<string, unknown>, Grid>;

export type ChildElement = AnyElementWithPosition;

const DefaultGrid = memo<Props>(({ element: initialElement, elementModel }) => {
  const editModeOn = useSelector(editorSelectors.editModeOn);
  /**
   * At the moment, we need to access `updatedElements` inside `DefaultGrid`
   * because this is the only way to manage grid elements while editing.
   * The parent grid has no information about changes
   * taking place inside the child <Element /> while it renders.
   */
  const updatedElements = useSelector(editorSelectors.updatedElements);

  const element = (updatedElements[initialElement.originalId] ||
    initialElement) as any;
  const { children } = element;

  const elements = children.content.elements.map(
    (child: ChildElement) => updatedElements[child.id] || child,
  );

  const isFormGrid = initialElement.name === "form_grid";

  const component = editModeOn ? (
    <EditorGrid
      elementModel={elementModel}
      element={{
        ...element,
        children: {
          ...element.children,
          content: {
            ...element.children.content,
            elements,
          },
        },
      }}
    />
  ) : (
    <StaticGrid
      elementProps={element.props}
      elements={elements}
      isFormGrid={isFormGrid}
    />
  );

  return component;
});

DefaultGrid.displayName = "DefaultGrid";

export default DefaultGrid;
