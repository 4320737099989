import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { CellAlignment } from "../default_table/types";

interface IDefaultTableHeaderCell {
  width?: string;
  align?: CellAlignment;
}

export const useStyles = makeStyles<Theme, IDefaultTableHeaderCell>(
  (theme: Theme) => {
    const borderColor = theme.palette.divider;
    return createStyles({
      cell: ({ width }) => ({
        padding: theme.spacing(),
        borderBottom: `1px solid ${borderColor}`,
        borderTop: `1px solid ${borderColor}`,
        "&:not(:last-child)": {
          borderRight: `1px solid ${borderColor}`,
        },
        ...(width && { width }),
      }),
      title: ({ align }) => ({
        // the sort icon on the right is 24px wide, we need to compensate that
        // if the alignment type is center - else it's not important
        paddingLeft: align === "center" ? 24 : 0,
      }),
    });
  },
);
