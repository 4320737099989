import { selectorScoper } from "../../utils/redux";
import { MODULE_NAME } from "./constants";
import { IState } from "./types";
import { createSelector } from "reselect";
import { getUpdatedAppMetadata } from "./utils";

const scopeSelector = selectorScoper<IState>([MODULE_NAME]);

const uiSelector = (state: any) => scopeSelector(state).ui;
const uiListSelector = (state: any) => scopeSelector(state).uiList;

export const selectors = {
  appMetadata: (state: any) => getUpdatedAppMetadata(state, scopeSelector),
  initialAppMetadata: (state: any) => scopeSelector(state).appMetadata,
  ui: uiSelector,
  uiList: uiListSelector,
  uiOptions: createSelector(
    uiSelector,
    uiListSelector,
    (currentUi, uiList) =>
      uiList?.filter((ui) => !currentUi || ui.name !== currentUi.name) ?? [],
  ),
  loadAppMetadata: (state: any) => scopeSelector(state).loadAppMetadata,
  token: (state: any) => scopeSelector(state).token,
  error: (state: any) => scopeSelector(state).error,
  loggingIn: (state: any) => scopeSelector(state).loggingIn,
  isLoggedIn: (state: any) => Boolean(scopeSelector(state).token),
  notifications: (state: any) => scopeSelector(state).notifications,
  currentLanguage: (state: any) => scopeSelector(state).currentLanguage,
  isAdmin: (state: any) => scopeSelector(state).isAdmin,
  additionalInformation: (state: any) =>
    scopeSelector(state).additionalInformation,
};
