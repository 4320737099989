import { Actions, Selectors, Types } from "./types";
import { AllServices } from "core/buildStore";
import { AutocompleteInput } from "../../types";
import { buildEqFilter } from "../../../default_table/reduxModule/utils";
import { getValueObject } from "../../components/utils";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { getTranslatedTextSaga } from "core/session/translation/createUseTranslation";
import { editorTranslation } from "core/editor";

import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import {
  FilterGroupCombinator,
  IFilterGroup,
} from "../../../default_table/toolsPanel";
/**
 * TODO:
 * Maybe move this util to a `common` folder, it's being used by more than one element.
 */
import {
  buildFixedFilterFromConfig,
  buildILikeFilter,
  mapParamsToApiServiceParams,
} from "../../../default_table/reduxModule/utils";

const buildSearchFilter = (fieldName: string, value: string) =>
  ({
    combinator: "AND",
    filters: buildILikeFilter(fieldName, value),
  } as IFilterGroup);

const buildSearchNumberFilter = (fieldName: string, value: string) =>
  ({
    combinator: "AND",
    filters: buildEqFilter(fieldName, value),
  } as IFilterGroup);

export function buildSaga(
  actions: Actions,
  types: Types,
  element: AutocompleteInput,
  selectors: Selectors,
) {
  const { reference, virtualizedList, isMulti } = element.config;

  function* loadOptionsSaga(action: ReturnType<Actions["loadOptions"]>) {
    if (!reference) {
      yield put(actions.loadOptionsSuccess(null, null));
      return;
    }
    // It means that the form was reset.
    if (action.payload.setInputValue) {
      yield put(actions.changeSearchInputValue(""));
    }

    const services: AllServices = yield getContext("services");
    const token = yield select(sessionSelectors.token);
    const searchInputValue = yield select(selectors.searchInputValue);
    const value = yield select(selectors.value);
    const searchFilterValue = `${
      element.config.fullTextSearch ? "*" : ""
    }${searchInputValue}*`;
    const referencedColumnName = reference.columnName;
    const referencedColumnLabel = reference.columnLabel;

    let filter: IFilterGroup | null = null;
    let configFilterValue = null;
    const configFilter = element.config.filter;
    const fieldName = referencedColumnLabel || referencedColumnName;
    if (searchInputValue && !configFilter) {
      filter = !Number(searchFilterValue.replace("*", ""))
        ? buildSearchFilter(fieldName, searchFilterValue)
        : buildSearchNumberFilter(
            fieldName,
            searchFilterValue.replace("*", ""),
          );
    }
    if (configFilter) {
      configFilterValue = (yield select(configFilter)) as ReturnType<
        typeof configFilter
      >;
      filter = buildFixedFilterFromConfig(
        configFilterValue,
      ) as IFilterGroup | null;

      if (searchInputValue) {
        filter = filter
          ? {
              combinator: FilterGroupCombinator.AND,
              filters: [
                filter,
                ...buildILikeFilter(fieldName, searchFilterValue),
              ],
            }
          : buildSearchFilter(fieldName, searchFilterValue);
      }
    }

    const viewName = reference.viewName;
    if (!viewName.length) {
      // Inside editor mode if element has just been created viewName can be empty
      // set error to warn user of viewName necessity
      const viewNameError = yield call(
        getTranslatedTextSaga,
        editorTranslation,
        "viewNameError",
      );
      yield put(actions.loadOptionsError(viewNameError));
      return;
    }

    try {
      const data = yield call(
        services.api.loadViewData,
        token,
        viewName,
        mapParamsToApiServiceParams({
          filter,
          offset: 0,
          limit: virtualizedList ? 1000 : 10,
          order: null,
        }),
      );

      const arrayifiedValue = Array.isArray(value) ? value : [value];

      const isAlreadyInData = arrayifiedValue.every((currentValue) =>
        data?.some(
          (object: any) => object[referencedColumnName] === currentValue,
        ),
      );
      // retrieve data for the current value(s), so we can display the label(s)
      let valueData = [];
      if (!isAlreadyInData && value && value !== "") {
        valueData = (yield all(
          arrayifiedValue.map((v) =>
            call(
              services.api.loadViewData,
              token,
              reference.viewName,
              mapParamsToApiServiceParams({
                filter: buildSearchNumberFilter(
                  referencedColumnName,
                  String(v),
                ),
                offset: 0,
                limit: 1,
                order: null,
              }),
            ),
          ),
        )).map((v: any[]) => v?.[0]);
      }

      const combinedData = [...new Set([...valueData, ...data])].filter(
        Boolean,
      );
      const generatedOptions = combinedData.map((row: any) => {
        const dataValue = row[referencedColumnName];
        const label = String(
          referencedColumnLabel ? row[referencedColumnLabel] : dataValue,
        );
        return {
          value: dataValue,
          label,
        };
      });
      const valueObject = getValueObject(generatedOptions, value, isMulti);

      yield put(actions.loadOptionsSuccess(generatedOptions, combinedData));
      yield put(actions.setValueObject(valueObject));

      if (action.payload.setInputValue && !isMulti) {
        // We need to set the search input value only when isn't multi
        // Because when isMulti the search input value doesn't indicate the actual value.
        yield put(actions.changeSearchInputValue(valueObject?.["label"]));
      }
    } catch (error) {
      yield put(actions.loadOptionsError(error.message ?? error.toString()));
    }
  }

  return function* saga() {
    yield all([yield takeLatest(types.LOAD_OPTIONS, loadOptionsSaga)]);
    yield put(actions.loadOptions());
  };
}
