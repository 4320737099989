import { createUseTranslation } from "core/session/translation";

export const translation = {
  en: {
    configSectionTitle: "Value",
    stylingSectionTitle: "Formatting",
    isWrappedLabel: "Has background and border",
    fullSizeLabel: "Show full size image on click",
    isUsingFileStorageLabel: "Path is file name from CYPEX File Storage",
  },
};

export const useEditorStorageImageTranslation = createUseTranslation(
  translation,
);
