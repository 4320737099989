import React, { memo } from "react";
import { areEqual } from "react-window";
import { JSONSchema6, JSONSchema6TypeName } from "json-schema";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";

import { IFixedRow } from "core";
import { useEditorTranslation } from "core/editor";
import IconButton from "../../../../common/IconButton";

export type SchemaProps = {
  items?: {
    type: JSONSchema6TypeName | JSONSchema6TypeName[];
  } & SchemaProps;
} & JSONSchema6;

export type Field = SchemaProps & {
  name: string;
  type: JSONSchema6TypeName | JSONSchema6TypeName[];
  isRequired: boolean;
  canBeChanged: boolean;
};

type IRow = IFixedRow<Field> & {
  editTooltip: string;
  onEditClick: (params: Field) => void;
};

export const FieldRow = memo<IRow>(
  ({ data, index, style, editTooltip, onEditClick }) => {
    const { requiredTooltip } = useEditorTranslation();
    const field = data[index];

    const handleEditClick = () => onEditClick(field);
    const label = (
      <ListItemText
        primary={field.name}
        {...(field.isRequired && {
          primaryTypographyProps: {
            color: "primary",
          },
        })}
      />
    );

    return (
      <ListItem key={field.name} divider={true} style={style}>
        {field.isRequired ? (
          <Tooltip title={requiredTooltip} placement="right">
            {label}
          </Tooltip>
        ) : (
          label
        )}
        <ListItemIcon>
          <IconButton
            icon="edit"
            onClick={handleEditClick}
            tooltip={editTooltip}
            edge="end"
            disabled={!field.type}
          />
        </ListItemIcon>
      </ListItem>
    );
  },
  areEqual,
);
