import React, {
  MutableRefObject,
  ReactNode,
  memo,
  useEffect,
  useState,
} from "react";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader, { CardHeaderProps } from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";

import classNames from "classnames";
import IconButton from "elementTypes/common/IconButton";

import { useStyles } from "./styles";

type Props = {
  title: string;
  children: ReactNode | ReactNode[];
  wrapped?: boolean;
  classes?: {
    root?: string;
    content?: string;
  };
  headerAction?: CardHeaderProps["action"];
  cardActions?: ReactNode;
  innerRef?: MutableRefObject<HTMLElement | null>;
  defaultOpened?: boolean;
};

export const Section = memo<Props>(
  ({
    title,
    children,
    wrapped,
    classes,
    headerAction,
    cardActions,
    innerRef,
    defaultOpened = true,
  }) => {
    const ownClasses = useStyles();
    const [open, setOpen] = useState<boolean>(defaultOpened);
    const toggleContent = () => setOpen((prevOpen) => !prevOpen);
    const contentExists = Boolean(cardActions || children);

    useEffect(() => {
      if (!open && defaultOpened) {
        setOpen(defaultOpened);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultOpened]);

    useEffect(() => {
      if (!contentExists && open) {
        setOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentExists]);

    return (
      <Box
        marginBottom={0.5}
        clone
        className={classNames(ownClasses.specificGap, classes?.root)}
      >
        <Card
          square
          variant="outlined"
          ref={innerRef}
          className={ownClasses.card}
        >
          <CardHeader
            title={title}
            titleTypographyProps={{ variant: "subtitle1" }}
            action={
              <Box display="flex" alignItems="center">
                {headerAction}
                <IconButton
                  icon={open ? "expand_less" : "expand_more"}
                  onClick={toggleContent}
                  disabled={!contentExists}
                  data-test-title={title}
                />
              </Box>
            }
            className={ownClasses.header}
            classes={{ action: ownClasses.action }}
          />
          <Collapse in={open} timeout="auto" unmountOnExit>
            {cardActions && <CardActions>{cardActions}</CardActions>}
            {wrapped ? (
              <CardContent
                className={classNames(ownClasses.content, classes?.content)}
              >
                {children}
              </CardContent>
            ) : (
              <>{children}</>
            )}
          </Collapse>
        </Card>
      </Box>
    );
  },
);

Section.displayName = "Section";
