import React, { memo, useCallback } from "react";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedCircularProgressConfig } from "../types";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { StylingComponent, TranslationComponent } from "./components";
import { useCircularProgressEditorTranslation } from "./translation";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";

export const CircularProgressEditor = memo(() => {
  const {
    elementModel: { id, config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCircularProgressConfig>();
  const translation = useCircularProgressEditorTranslation();

  const { value } = config;

  const handleValueChange = useCallback(
    (newValue: string) => changeConfigValue("value", newValue),
    [changeConfigValue],
  );

  const handleExpressionValueChange = (newValue: string) => {
    handleValueChange(newValue);
  };

  return (
    <>
      <Section title={translation.valueLabel} wrapped={true}>
        <TableColumnEditor
          id={id}
          value={value}
          onChange={handleExpressionValueChange}
          allowedDataTypeIsArray={false}
          allowedDataTypes={["number"]}
        />
        <CustomExpressionEditor
          value={value}
          config={config}
          onChange={handleExpressionValueChange}
          disableSwitcher
        />
      </Section>
      <TranslationComponent />
      <StylingComponent />
    </>
  );
});
