import React, { memo } from "react";

import { DataSourceEditor } from "./DataSource";
import { Display } from "./Display";

export const InternalLinkFieldEditor = memo(() => (
  <>
    <DataSourceEditor />
    <Display />
  </>
));
