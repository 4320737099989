import React, { memo, useState } from "react";

import { IObjectView, IObjectViewField, Translation } from "../../../../types";

import {
  BaseAutocomplete,
  IAutocompleteValue,
  getValueObject,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";

import { customRenderOption, defaultItemSize } from "../component";

export type IField = {
  label: string;
  value: IAutocompleteValue;
  name: string;
  isClearable?: boolean;
};

type Props = {
  onChange: (fieldValue: IAutocompleteValue) => void;
  getTranslatedLabel: (i18n: Translation<keyof any>) => string;
  view?: IObjectView;
  disabled?: boolean;
} & IField;

export const FieldsAutocomplete = memo<Props>(
  ({
    view,
    value,
    name,
    label,
    onChange,
    disabled,
    getTranslatedLabel,
    isClearable,
  }) => {
    const isMulti = Array.isArray(value);
    const [searchInputValue, changeInputValue] = useState<string>("");
    const onInputChange = (searchValue: string) =>
      changeInputValue(searchValue);

    const handleChange = (newValue: IAutocompleteValue) => {
      onChange(newValue);
      searchInputValue.trim().length && onInputChange("");
    };

    const fieldOptions = view?.fields.map((field: IObjectViewField) => ({
      value: field.name,
      label: getTranslatedLabel(field.i18n),
    }));

    const fieldValueObject = fieldOptions
      ? isMulti
        ? ((value as any[]) || []).map((val: string | number) =>
            getValueObject(fieldOptions, val),
          )
        : getValueObject(fieldOptions, value)
      : "";

    return (
      <BaseAutocomplete
        options={fieldOptions || []}
        valueObject={fieldValueObject as SelectOption | SelectOption[]}
        label={label}
        name={name}
        onChange={handleChange}
        isLoading={false}
        virtualizedList={true}
        isMulti={isMulti}
        disabled={disabled}
        customRenderOption={customRenderOption}
        defaultItemSize={defaultItemSize}
        onInputChange={onInputChange}
        searchInputValue={searchInputValue}
        isClearable={isClearable}
      />
    );
  },
);
