import React, { ReactNode, memo, useContext } from "react";
import { Helmet } from "react-helmet";

import { ElementTypesConsumer } from "../../ElementTypesContext";
import { IAppMetadata } from "../../types";
import { getTranslatedTexts } from "../../utils/element";
import { SessionContext } from "../SessionContext";
import { MapStateToProps } from "./container";
import { AdminLayout } from "staticPages/admin/components/AdminLayout";
import { StaticPagesLayout } from "staticPages/common/Layout";
import { LoadingComponent } from "layouts/common/Loading";

type Props = ReturnType<MapStateToProps>;

type ComponentProps = {
  appMetadata: IAppMetadata | null;
  children: ReactNode;
};

const Component = memo<ComponentProps>(({ appMetadata, children }) => (
  <ElementTypesConsumer>
    {({ getLayout }) => {
      const layoutName = appMetadata?.release.definition.layout.name;
      const RealLayout = getLayout(layoutName);
      if (!RealLayout) {
        return `Layout "${layoutName}" not supported`;
      }
      return <RealLayout appMetadata={appMetadata!}>{children}</RealLayout>;
    }}
  </ElementTypesConsumer>
));

Component.displayName = "LayoutChildrenComponent";

export const Layout = memo<Props>(
  ({
    appMetadata,
    loadAppMetadata: { inProgress },
    children,
    ui,
    page,
    isStaticPage,
    isAdminPage,
    isLoggedIn,
  }) => {
    const { language } = useContext(SessionContext);
    const uiTitle = ui
      ? getTranslatedTexts(language, ui.i18n).label || ui.name
      : "";
    const pageTitle = page
      ? getTranslatedTexts(language, page.i18n).label || page.url
      : "";

    let content;
    if (isStaticPage) {
      if (isAdminPage) {
        content = <AdminLayout>{children}</AdminLayout>;
      } else if (isLoggedIn) {
        // If is logged and isn't a static admin page so is a setting page

        if (appMetadata) {
          content = <Component appMetadata={appMetadata}>{children}</Component>;
        } else {
          content = <StaticPagesLayout>{children}</StaticPagesLayout>;
        }
      } else {
        content = children;
      }
    } else if (appMetadata) {
      content = <Component appMetadata={appMetadata}>{children}</Component>;
    }
    return (
      <>
        <Helmet>
          {isStaticPage ? (
            isAdminPage ? (
              <title>Administration</title>
            ) : (
              <title>Settings</title>
            )
          ) : ui ? (
            page ? (
              <title>{`${pageTitle} - ${uiTitle}`}</title>
            ) : (
              <title>{uiTitle}</title>
            )
          ) : (
            <title>Loading</title>
          )}
        </Helmet>
        {inProgress ? <LoadingComponent /> : content}
      </>
    );
  },
);

Layout.displayName = "Layout";
