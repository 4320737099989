import React, { memo, useEffect } from "react";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";
import { FileInput } from "./types";
import {
  ButtonGroup,
  CircularProgress,
  Button as MuiButton,
  Tooltip,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { MuiIcon } from "../common/MuiIcon";
import { useFileInputTranslation } from "./translation";
import Button from "elementTypes/common/Button";
import { FILE_STORAGE_PREFIX } from "services/api/constants";

const StorageFileInput = memo<
  ConnectedReduxModuleProps<ReduxModule, FileInput>
>(
  ({
    value,
    element: {
      id,
      i18n,
      config: { disabled, accept, nullable },
    },
    upload,
    clear,
    fetchMetadata,
    metadata,
    loading,
    metadataError,
  }) => {
    const classes = useStyles();
    const t = useFileInputTranslation();

    const metadataName = metadata?.fileName;

    useEffect(() => {
      // if the value changed from outside, retrieve metadata
      if (!loading && metadataName !== value && !!value && !metadataError) {
        fetchMetadata();
      }
    }, [fetchMetadata, loading, metadataName, value, metadataError]);

    const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.item(0);
      if (file) {
        upload(file);
      }
    };

    const deleteButton =
      nullable &&
      (value ? (
        <Button tooltip={t.delete} onClick={clear} color="error.main">
          <MuiIcon icon="delete_outline" />
        </Button>
      ) : (
        <Button onClick={clear} disabled={true} color="error.main">
          <MuiIcon icon="delete_outline" />
        </Button>
      ));

    const linkOrError =
      (loading && (
        <Button disabled={true}>
          <CircularProgress size="1em" />
        </Button>
      )) ||
      (metadata && (
        <Tooltip title={t.view}>
          <MuiButton
            href={`${FILE_STORAGE_PREFIX}${metadata.fileName}`}
            target="_blank"
          >
            {metadata.realName}
          </MuiButton>
        </Tooltip>
      )) ||
      (metadataError && (
        <Button
          tooltip={t.reload}
          color="error.main"
          label={metadataError}
          onClick={fetchMetadata}
        ></Button>
      ));

    return (
      <div>
        <ButtonGroup disabled={disabled}>
          <Tooltip title={t.upload}>
            <MuiButton
              component="label"
              htmlFor={id}
              startIcon={<MuiIcon icon={value ? "check" : "cloud_upload"} />}
            >
              {i18n.label}
            </MuiButton>
          </Tooltip>
          {linkOrError}
          {deleteButton}
        </ButtonGroup>
        <input
          type="file"
          accept={accept}
          className={classes.input}
          id={id}
          onChange={onFileUpload}
        />
      </div>
    );
  },
);

StorageFileInput.displayName = "StorageFileInput";

export default StorageFileInput;
