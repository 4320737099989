import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { IUIGenerateForm } from "./types";
import useStyles from "./styles";
import { actions, selectors } from "./reduxModule";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  Select,
  Typography,
} from "@material-ui/core";
import { useTranslator } from "core/session/translation/createUseTranslation";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";
import BackButton from "elementTypes/common/BackButton";

export const GenerateAppsPage = memo(() => {
  const dispatch = useDispatch();
  const rolesList = useSelector(selectors.rolesList);
  const viewList = useSelector(selectors.viewList);
  const { root, footer, dividerFooter } = useStyles();
  const {
    handleSubmit,
    register,
    control,
    errors,
    watch,
    formState: { isSubmitting, isValid },
    setValue,
  } = useForm<IUIGenerateForm>({
    mode: "onChange",
    defaultValues: { role: "", name: "", description: "", objectViews: [] },
  });

  const role = watch("role");
  const objectViews = watch("objectViews");

  useEffect(() => {
    if (role) {
      dispatch(actions.getObjectViews(role as string));
      setValue("objectViews", []);
    }
  }, [dispatch, role, setValue]);

  const submit = (data: IUIGenerateForm) => {
    dispatch(actions.generateUI(data));
  };

  const handleRefreshClick = () => dispatch(actions.getRoles());

  const handleRefreshObjectViewsClick = () =>
    dispatch(actions.getObjectViews(role));

  const allSelected = viewList.data?.every((v) => objectViews.includes(v.id));

  const handleSelectAllObjectViewsClick = () =>
    setValue(
      "objectViews",
      allSelected ? [] : viewList.data?.map((v) => v.id) ?? [],
    );

  const { translate } = useTranslator();

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Generate New Application</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Name"
              error={Boolean(errors?.name)}
              fullWidth={true}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              fullWidth={true}
              error={Boolean(errors?.description)}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  error={Boolean(errors?.role)}
                  disabled={!rolesList.data}
                  select={!!rolesList.data}
                  label="Owner"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        size="small"
                        icon="refresh"
                        tooltip="Refresh"
                        onClick={handleRefreshClick}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {rolesList.data?.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="role"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              disabled={!viewList.data}
              error={Boolean(errors?.objectViews)}
            >
              <InputLabel>Object Views</InputLabel>
              <Controller
                as={
                  <Select
                    margin="dense"
                    multiple
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },

                      getContentAnchorEl: null,
                    }}
                    renderValue={(selected) => (
                      <Box display="flex" flexWrap="wrap">
                        {(selected as string[]).map((value) => {
                          const view = viewList.data?.find(
                            (v) => v.id === value,
                          );
                          return (
                            view && (
                              <Box m={0.25} key={value}>
                                <Chip
                                  label={view.name}
                                  variant="outlined"
                                  size="small"
                                />
                              </Box>
                            )
                          );
                        })}
                      </Box>
                    )}
                    input={
                      <Input
                        fullWidth
                        startAdornment={
                          <>
                            <IconButton
                              size="small"
                              icon="refresh"
                              tooltip="Refresh"
                              onClick={handleRefreshObjectViewsClick}
                              disabled={!viewList.data}
                            />
                            <IconButton
                              size="small"
                              icon={allSelected ? "clear" : "check"}
                              tooltip="Select / Unselect all"
                              onClick={handleSelectAllObjectViewsClick}
                              disabled={!viewList.data}
                            />
                          </>
                        }
                      />
                    }
                  >
                    {viewList.data?.map(({ id, name, i18n }) => (
                      <MenuItem key={id} value={id}>
                        <Checkbox checked={objectViews.includes(id)} />
                        <Typography color="primary">
                          {translate(i18n).title}
                        </Typography>
                        {NON_BREAKING_SPACE}({name})
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="objectViews"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} className={footer}>
            {/*
              <Divider className={dividerFooter} />
              <Typography>
                Newly generated UIs are not visible for their owner. They have
                to be activated to be usable.
              </Typography> */}
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              disabled={isSubmitting || !isValid}
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Generate"
            />
          </Grid>
        </Grid>
      </Paper>
      <BackButton />
    </form>
  );
});
