import React, { memo } from "react";
import { Controller } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import { useHookFormContext } from "../../../common/HookForm";
import { useSliderInputEditorTranslation } from "../translation";
import { MarkDetails } from "./Marks";

type Props = MarkDetails;

export const DialogContent = memo<Props>(({ value }) => {
  const translation = useSliderInputEditorTranslation();
  const { control, errors } = useHookFormContext();

  return (
    <FormControl fullWidth={true}>
      <Controller
        as={<TextField margin="dense" label={translation.valueLabel} />}
        error={Boolean(errors.value)}
        name="value"
        type="number"
        control={control}
        defaultValue={value}
        rules={{
          required: true,
        }}
      />
    </FormControl>
  );
});
