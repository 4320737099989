import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import { useChartEditorTranslation } from "../../translation";

import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { defaultElement } from "../defaultElement";
import { LineChartConfig } from "../../types";

export const SetupComponent = React.memo(() => {
  const {
    elementModel: {
      config: {
        dataSource,
        hideLegend,
        hideLabel,
        hideXAxis,
        hideYAxis,
        isArea,
        showBackground = false,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<LineChartConfig>();

  const { viewName, columns } = dataSource;

  const {
    labelLabel,
    labelShowLabel,
    legendShowLabel,
    xAxisLabel,
    yAxisLabel,
    valueLabel,
    viewLabel,
    valueColumnHelperText,
    isAreaLabel,
    showBackgroundLabel,
  } = useChartEditorTranslation();

  const changeDataSource = React.useCallback(
    (newDataSource: Partial<LineChartConfig["dataSource"]>) =>
      changeConfigValue("dataSource", { ...dataSource, ...newDataSource }),
    [changeConfigValue, dataSource],
  );

  const handleViewNameChange = (newViewName: string) =>
    changeDataSource({
      viewName: newViewName,
      columns: defaultElement.config?.dataSource.columns,
    });

  const handleFieldChange = (
    fieldName: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) =>
    changeDataSource({
      columns: { ...columns, [fieldName]: fieldValue },
    });

  const toggleConfigs = (
    config:
      | "hideLegend"
      | "hideLabel"
      | "hideXAxis"
      | "hideYAxis"
      | "isArea"
      | "showBackground",
  ) => () => {
    let newConfigValue: boolean;

    switch (config) {
      case "hideLegend":
        newConfigValue = !hideLegend;
        break;
      case "hideLabel":
        newConfigValue = !hideLabel;
        break;
      case "hideXAxis":
        newConfigValue = !hideXAxis;
        break;
      case "hideYAxis":
        newConfigValue = !hideYAxis;
        break;
      case "isArea":
        newConfigValue = !isArea;
        break;
      case "showBackground":
        newConfigValue = !showBackground;
        break;
    }

    changeConfigValue(config, newConfigValue);
  };

  const fields = [
    {
      label: labelLabel,
      value: columns.labelColumnName,
      name: "labelColumnName",
    },
    {
      label: valueLabel,
      value: columns.valueColumnNames,
      name: "valueColumnNames",
    },
  ];

  const { configTitle } = useEditorTranslation();

  return (
    <Section title={configTitle} wrapped={true}>
      <ViewAutocomplete
        viewValue={viewName}
        viewLabel={viewLabel}
        onViewNameChange={handleViewNameChange}
        onViewFieldChange={handleFieldChange}
        fields={fields}
      />
      <FormHelperText>{valueColumnHelperText}</FormHelperText>
      <FormControlLabel
        control={
          <Switch
            checked={!hideLegend}
            onChange={toggleConfigs("hideLegend")}
          />
        }
        label={legendShowLabel}
      />
      <FormControlLabel
        control={
          <Switch checked={!hideLabel} onChange={toggleConfigs("hideLabel")} />
        }
        label={labelShowLabel}
      />
      <FormControlLabel
        control={
          <Switch checked={!hideXAxis} onChange={toggleConfigs("hideXAxis")} />
        }
        label={xAxisLabel}
      />
      <FormControlLabel
        control={
          <Switch checked={!hideYAxis} onChange={toggleConfigs("hideYAxis")} />
        }
        label={yAxisLabel}
      />
      <FormControlLabel
        control={<Switch checked={isArea} onChange={toggleConfigs("isArea")} />}
        label={isAreaLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={showBackground}
            onChange={toggleConfigs("showBackground")}
          />
        }
        label={showBackgroundLabel}
      />
    </Section>
  );
});
