import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dividerClass: {
      margin: `${theme.spacing()}px 0`,
    },
    root: {
      overflowY: "auto",
      height: "100%",
      scrollbarWidth: "none",
      "-ms-overflow-style": "-ms-autohiding-scrollbar",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }),
);
