import { IStaticRouteConfig } from "core/router/types";

import { AppsPage } from "./component";

import * as reduxModule from "./reduxModule";

import {
  CreateAppsPageRoute,
  EditAppsPageRoute,
  GenerateAppsPageRoute,
} from "./pages";

export const route: IStaticRouteConfig = {
  reduxModule,
  Component: AppsPage,
  auth: true,
  isAdmin: true,
  routes: {
    "/admin/apps/create": CreateAppsPageRoute,
    "/admin/apps/generate": GenerateAppsPageRoute,
    "/admin/apps/edit/:uiName": EditAppsPageRoute,
  },
};
