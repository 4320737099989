import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  icon: {
    position: "relative",

    display: "grid",
    justifyItems: "center",
    alignItems: "center",

    backgroundColor: "var(--bg-color)",
    color: "var(--color)",
    borderRadius: "50%",
    width: "40px",
    height: "40px",

    "&::before": {
      position: "absolute",
      content: "''",
      width: "0px",
      height: "0px",
      bottom: "-34px",
      left: "2px",
      border: "18px solid transparent",
      borderTop: "28px solid",
      borderTopColor: "var(--bg-color)",
    },
    "& div": {
      position: "absolute",
      fontSize: "20px",
      lineHeight: 1,
      background: "var(--bg-color)",
    },
    "&$empty div": {
      borderRadius: "50%",
      width: 16,
      height: 16,
      background: "var(--color)",
    },
  },
  empty: {},
}));
