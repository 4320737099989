import * as t from "io-ts";
import {
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
  customExpression,
} from "core/types";

export const GeoJSONFieldConfig = t.intersection([
  t.partial({
    tileLayerUrl: t.union([t.null, t.string]),
    updateMapView: t.boolean,
    styleFunction: customExpression(t.Function),
    tooltipFunction: customExpression(t.Function),
    markerDisplayTextFunction: customExpression(t.Function),
    markerBackgroundColorFunction: t.union([
      t.string,
      customExpression(t.Function),
    ]),
  }),
  t.type({
    value: customExpression(t.union([t.null, t.UnknownRecord, t.UnknownArray])),
  }),
]);

export type GeoJSONFieldConfig = t.TypeOf<typeof GeoJSONFieldConfig>;

export type UntransformedGeoJSONFieldConfig = UntransformedConfig<
  GeoJSONFieldConfig
>;

export const GeoJSONFieldTranslationKeys = [] as const;

export type GeoJSONFieldTranslationKeys = typeof GeoJSONFieldTranslationKeys[number];

export type GeoJSONField = IElement<
  GeoJSONFieldConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  GeoJSONFieldTranslationKeys
>;
