import React, { memo, useCallback, useMemo, useState } from "react";
import { FixedSizeList, areEqual } from "react-window";

import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import { DEFAULT_LANGUAGE_CODE, IFixedRow, Language, Translation } from "core";
import { Section, useEditorTranslation } from "core/editor";
import { useSessionContext } from "core/session";
import { getTranslatedTexts } from "core/utils/element";
import { useAutocompleteEditorTranslation } from "../translation";
import IconButton from "../../../common/IconButton";
import Button from "../../../common/Button";
import DialogWrapper from "../../../helpers/HOC/DialogWrapper";
import { DialogContent } from "./DialogContent";

const newOptionParams = {
  value: "",
  i18n: {
    [DEFAULT_LANGUAGE_CODE]: {
      label: "",
    },
  } as Translation<"label">,
  isNew: true,
};

export type OptionDetails = {
  value: string | number | boolean;
  i18n: Translation<"label">;
  isNew?: boolean;
  index?: number;
};

type Props = {
  options: OptionDetails[];
  language: Language;
  onChange: (options: OptionDetails[]) => void;
  handleChangeLanguage: (lang: Language) => void;
};

type IRow = IFixedRow<OptionDetails>;

export const Options = memo<Props>(
  ({ options, onChange, language, handleChangeLanguage }) => {
    const editorTranslation = useEditorTranslation();
    const translation = useAutocompleteEditorTranslation();
    const [optionDetails, setOptionDetails] = useState<OptionDetails | null>(
      null,
    );

    const setNewOptionParams = () => setOptionDetails(newOptionParams);
    const handleClose = () => setOptionDetails(null);
    const handleSubmit = (data: { [k: string]: any }) => {
      const newOption = {
        value: data.value,
        i18n: {
          ...optionDetails!.i18n,
          [language.code]: {
            label: data.label,
          },
        },
      } as OptionDetails;
      if (optionDetails?.isNew) {
        onChange([...options, newOption]);
      } else {
        onChange([
          ...options.map((option, index) =>
            index === optionDetails?.index
              ? { ...option, ...newOption }
              : option,
          ),
        ]);
      }
      handleClose();
    };

    const handleDeleteClick = () => {
      onChange(
        options.filter(
          (_option: OptionDetails | null, index: number) =>
            index !== optionDetails?.index,
        ),
      );
      handleClose();
    };

    const handleEditClick = useCallback(
      (option: OptionDetails, index: number) => () =>
        setOptionDetails({ ...option, isNew: false, index }),
      [setOptionDetails],
    );

    const isValueUnique = (value: any) => {
      const isExists = Boolean(
        options.find((option) => option.value === value),
      );
      if (optionDetails?.isNew) {
        return !isExists;
      } else {
        return value === optionDetails?.value || !isExists;
      }
    };

    const lastIndex = useMemo(() => options.length - 1, [options]);
    const { language: appLang } = useSessionContext();
    const getLabel = useCallback(
      (i18n: Translation<"label">) => getTranslatedTexts(appLang, i18n)?.label,
      [appLang],
    );

    const Row = memo<IRow>(({ data: items, index, style }) => {
      const option = items[index];

      return (
        <ListItem divider={index !== lastIndex} key={index} style={style}>
          <ListItemText
            primary={getLabel(option.i18n)}
            secondary={option?.value}
            secondaryTypographyProps={{ variant: "overline" }}
          />
          <ListItemIcon>
            <IconButton icon="edit" onClick={handleEditClick(option!, index)} />
          </ListItemIcon>
        </ListItem>
      );
    }, areEqual);

    const itemSize = 60;

    return (
      <Section
        title={translation.optionsTitle}
        headerAction={
          <IconButton
            icon="add_to_photos"
            onClick={setNewOptionParams}
            color="primary"
            tooltip={translation.addOptionTooltip}
          />
        }
      >
        {!!options.length && <Divider />}
        <FixedSizeList
          height={itemSize * Math.min(options.length, 7)}
          itemCount={options!.length}
          itemSize={itemSize}
          width="100%"
          itemData={options}
        >
          {Row}
        </FixedSizeList>
        <DialogWrapper
          isForm={true}
          keepMounted={false}
          open={Boolean(optionDetails)}
          title={
            optionDetails?.isNew
              ? translation.createOptionTitle
              : translation.editOptionTitle
          }
          submitTitle={
            optionDetails?.isNew
              ? editorTranslation.createButton
              : editorTranslation.updateButton
          }
          cancelTitle={editorTranslation.cancelButton}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          submitDisabled={true}
          subActions={
            !optionDetails?.isNew && (
              <Button
                label={editorTranslation.deleteButton}
                color="error.main"
                onClick={handleDeleteClick}
              />
            )
          }
        >
          {optionDetails && (
            <DialogContent
              {...optionDetails}
              language={language}
              handleChangeLanguage={handleChangeLanguage}
              setOptionDetails={setOptionDetails}
              isValueUnique={isValueUnique}
            />
          )}
        </DialogWrapper>
      </Section>
    );
  },
);
