import { getOrElse } from "fp-ts/lib/Either";
import * as t from "io-ts";

import { EXPRESSION_TAG } from "../constants";

export function getOrThrow(
  validation: t.Validation<any>,
  getError: (errors: t.Errors) => Error,
) {
  const result = getOrElse(getError)(validation);
  if (result instanceof Error) {
    throw result;
  }
  return result;
}

/**
 * Hack the io-ts string type to treat values that start with the expression tag as invalid.
 */
export function hackStringType() {
  (t.string as any).validate = (i: any, context: t.Context) =>
    typeof i === "string" && !i.startsWith(EXPRESSION_TAG)
      ? t.success(i)
      : t.failure(i, context);
}
