import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";

import { Section, useElementEditorContext } from "core/editor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { useDateTimeFieldEditorTranslation } from "../translation";
import { UntransformedDateTimeFieldConfig } from "../../types";

export const SetupComponent = React.memo(() => {
  const {
    elementModel: {
      id,
      config,
      config: { value, showDatePart = true, showTimePart = true },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeFieldConfig>();
  const translation = useDateTimeFieldEditorTranslation();

  const handleExpressionValueChange = React.useCallback(
    (newValue: string) => changeConfigValue("value", newValue),
    [changeConfigValue],
  );
  const handleCheckboxInputChange = React.useCallback(
    (input: "showDatePart" | "showTimePart") => (
      _event: React.ChangeEvent<HTMLInputElement>,
      newValue: boolean,
    ) => changeConfigValue(input, newValue),
    [changeConfigValue],
  );
  const customInputValue = ({
    value: inputValue,
    onChange: onIdentifierChange,
  }: {
    value: string;
    onChange: (value: string) => void;
  }) => {
    const onCustomChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      onIdentifierChange(e.target.value);

    return (
      <TextField
        disabled={!value}
        label={translation.valueLabel}
        value={inputValue}
        onChange={onCustomChange}
        fullWidth={true}
      />
    );
  };

  return (
    <Section title={translation.valueLabel} wrapped={true}>
      <TableColumnEditor
        id={id}
        value={value}
        onChange={handleExpressionValueChange}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["date", "dateTime", "time"]}
      />
      <CustomExpressionEditor
        value={value as any}
        config={config}
        onChange={handleExpressionValueChange}
        label={translation.valueLabel}
        nonExpressionEditor={customInputValue}
        disableSwitcher={false}
        switcherDisabled={!value}
      />
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={showDatePart}
              onChange={handleCheckboxInputChange("showDatePart")}
            />
          }
          label={translation.showDatePartLabel}
        />
      </FormGroup>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={showTimePart}
              onChange={handleCheckboxInputChange("showTimePart")}
            />
          }
          label={translation.showTimePartLabel}
        />
      </FormGroup>
    </Section>
  );
});
