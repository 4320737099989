import React, { memo } from "react";

import { Display, LinkConfig, Translation } from "./components";

export const ExternalLinkFieldEditor = memo(() => (
  <>
    <LinkConfig />
    <Translation />
    <Display />
  </>
));
