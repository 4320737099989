import React, { MouseEvent, forwardRef, memo } from "react";

import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";

import { useStyles } from "./style";
import classNames from "classnames";
import { CellAlignment } from "../default_table/types";

type ISortLabelProps = {
  active: boolean;
  label: string;
  ableToSort: boolean;
  tooltip: string;
  direction?: "desc" | "asc";
  onSortClick?: (e: MouseEvent) => void;
  className?: string;
  align?: CellAlignment;
};

export const SortLabel = memo(
  forwardRef<HTMLButtonElement, ISortLabelProps>(
    (
      {
        active,
        className,
        label,
        ableToSort,
        onSortClick,
        direction,
        tooltip,
        align,
      },
      ref,
    ) => {
      const { title } = useStyles({ align });

      return (
        <Tooltip title={tooltip} placement={"bottom-start"} enterDelay={300}>
          <TableSortLabel
            ref={ref}
            disabled={!ableToSort}
            active={active}
            direction={direction || "asc"}
            onClick={onSortClick}
            className={classNames(title, className)}
          >
            {label}
          </TableSortLabel>
        </Tooltip>
      );
    },
  ),
);

SortLabel.displayName = "SortLabel";
