import React, { memo } from "react";

import { TableFooter as MuiTableFooter } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import IconButton from "elementTypes/common/IconButton";

type Props = {
  colSpan: number;
  onClick: () => void;
  loading?: boolean;
};

export const TableFooter = memo<Props>(({ colSpan, onClick, loading }) => (
  <MuiTableFooter>
    <TableRow>
      <TableCell align="right" scope="row" colSpan={colSpan}>
        <IconButton
          onClick={onClick}
          color="secondary"
          icon="Cached"
          size="small"
          disabled={loading}
          processing={loading}
        />
      </TableCell>
    </TableRow>
  </MuiTableFooter>
));
