import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { Actions, actions, types } from "./actions";

import AdminService from "services/admin";

const services = AdminService.getInstance();

function* loadSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const data = yield call(services.getAllUsers, token);
    yield put(actions.loadSuccess(data));
  } catch (error) {
    yield put(actions.loadError(error.message ?? error.toString()));
  }
}

function* deleteUserSaga(action: ReturnType<Actions["deleteUser"]>) {
  const token = yield select(sessionSelectors.token);

  try {
    yield call(services.deleteUser, action.payload.id, token);
    yield put(actions.deleteUserSuccess(action.payload.id));
    // reload the table
    yield put(actions.load());
    yield put(
      sessionActions.enqueueSnackbar({
        message: "User deleted successfully",
        options: {
          variant: "success",
        },
      }),
    );
  } catch (error) {
    const errorMessage = error.message ?? error.toString();
    yield put(actions.deleteUserError(action.id, errorMessage));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "User could not be deleted: " + errorMessage,
        options: {
          variant: "error",
        },
      }),
    );
  }
}

export function* saga() {
  yield all([
    yield takeLatest(types.LOAD, loadSaga),
    yield takeLatest(types.DELETE_USER, deleteUserSaga),
  ]);

  yield put(actions.load());
}
