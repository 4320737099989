import { handleActions } from "core/utils/redux";
import { IState } from "./types";
import { types } from "./actions";

const INITIAL_STATE: IState = {
  user: null,
  error: null,
  loading: false,
};

export const reducer = handleActions<IState>(INITIAL_STATE, {
  [types.GET_USER]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [types.GET_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    user: action.payload.user,
  }),
  [types.GET_USER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
});
