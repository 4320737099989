import { TUpdatedElements } from "./reduxModule/types";
import {
  IElementModel,
  IElementModelArrayChild,
  IElementModelSingleChild,
  IObjectViewField,
  TCssGridConfig,
  TElementModelWithPosition,
  Translation,
} from "../types";

import { humanize } from "../../utils/string";

type Element = TUpdatedElements["string"];

/**
 * recursively find nearest element of type `searchType` to `childId`,
 * starting at `rootElement`
 * returns:
 * true if found childId and parent is not of searched type
 * null if not found
 * Element if found childId
 */
const innerFindElement = (
  rootElement: Element,
  updatedElements: TUpdatedElements,
  childId: string,
  searchType: string,
): Element | true | null => {
  const parentElement = updatedElements[rootElement.id] ?? rootElement;

  if (parentElement.id === childId) {
    return true;
  }

  for (const childKey in parentElement.children) {
    const childObject = parentElement.children[childKey] as
      | IElementModelSingleChild
      | IElementModelArrayChild;
    const children: Element[] =
      "elements" in childObject ? childObject.elements : [childObject.element];

    for (const baseChildElement of children) {
      const child = updatedElements[baseChildElement.id] ?? baseChildElement;

      const found = innerFindElement(
        child,
        updatedElements,
        childId,
        searchType,
      );
      if (found === true) {
        if (parentElement.type.name === searchType) {
          return parentElement;
        }
      }
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const innerFindElements = (
  rootElement: Element,
  updatedElements: TUpdatedElements,
  searchType: string,
  elements: Element[] = [],
): Element[] => {
  const parentElement = updatedElements[rootElement.id] ?? rootElement;

  for (const child in parentElement.children) {
    const childName: IElementModelSingleChild | IElementModelArrayChild =
      parentElement.children[child];
    const children: Element[] =
      "elements" in childName ? childName.elements : [childName.element];

    for (const baseChildElement of children) {
      const updatedChild =
        updatedElements[baseChildElement.id] ?? baseChildElement;
      if (updatedChild.type.name === searchType) {
        elements = [...elements, updatedChild];
      }

      innerFindElements(updatedChild, updatedElements, searchType, elements);
    }
  }

  return elements;
};

/**
 * finds the nearest parent of a specific element type
 * @param rootElement where to start the search
 * @param updatedElements the list of updated elements
 * @param childId where to end
 * @param elementType which type of element we search
 */
export const getNearestParentElement = (
  rootElement: Element,
  updatedElements: TUpdatedElements,
  childId: string,
  elementType: string,
): Element | null => {
  const found = innerFindElement(
    rootElement,
    updatedElements,
    childId,
    elementType,
  );
  if (found === true) {
    return null;
  }
  return found;
};

export const getParentElement = (
  rootElement: Element,
  updatedElements: TUpdatedElements,
  childId: string,
): IElementModel | TElementModelWithPosition | null => {
  const parentElement = updatedElements[rootElement.id] ?? rootElement;
  for (const child in parentElement.children) {
    const childName: IElementModelSingleChild | IElementModelArrayChild =
      parentElement.children[child];
    if ("element" in childName) {
      return getParentElement(childName.element, updatedElements, childId);
    } else {
      const currentChild = childName.elements.find(
        (el: Element) => el.id === childId,
      );

      if (!currentChild) {
        return childName.elements
          .map((childEl) => getParentElement(childEl, updatedElements, childId))
          .filter(Boolean)[0];
      } else {
        return parentElement;
      }
    }
  }
  // this can only happen if searching for the parent of the root grid
  return null;
};

export const getElementById = (
  rootElement: Element,
  updatedElements: TUpdatedElements,
  elementId: string,
): Element | null => {
  const parentElement = updatedElements[rootElement.id] ?? rootElement;
  if (parentElement.id === elementId) {
    return parentElement;
  } else {
    for (const child in parentElement.children) {
      const childName: IElementModelSingleChild | IElementModelArrayChild =
        parentElement.children[child];
      if ("element" in childName) {
        return getElementById(
          updatedElements[childName.element.id] ?? childName.element,
          updatedElements,
          elementId,
        );
      } else {
        const currentChild = childName.elements.find(
          (el: Element) => el.id === elementId,
        );

        if (currentChild) {
          return updatedElements[currentChild.id] ?? currentChild;
        } else {
          return childName.elements
            .map((childEl) =>
              getElementById(
                updatedElements[childEl.id] ?? childEl,
                updatedElements,
                elementId,
              ),
            )
            .filter(Boolean)[0];
        }
      }
    }
  }

  return null;
};

export const getPageElementsByType = (
  rootElement: Element,
  updatedElements: TUpdatedElements,
  elementType: string,
): (IElementModel | TElementModelWithPosition)[] =>
  innerFindElements(rootElement, updatedElements, elementType);

export type UnifiedDataType =
  | "text"
  | "number"
  | "dateTime"
  | "date"
  | "time"
  | "json"
  | "boolean"
  | "fallback";

export type ViewFieldToElement = Pick<Element, "config" | "name"> & {
  type: string;
  position?: TCssGridConfig;
  i18n?: Translation<string>;
};

type viewFieldToElementTypeFunction = (props: {
  field: IObjectViewField;
  parentElement: Element;
  position: TCssGridConfig;
  parentType: "default_form" | "default_table";
}) => ViewFieldToElement;

export const viewFieldToElementType: Record<
  UnifiedDataType,
  Record<"field" | "input", viewFieldToElementTypeFunction>
> = {
  boolean: {
    field: ({ field, parentElement, parentType, position }) => ({
      type: "default_bool_field",
      name: field.name,
      config: {
        value:
          parentType === "default_form"
            ? `@@expression:Boolean(elements["${parentElement.id}"].data["${field.name}"])`
            : `@@expression:Boolean(props.data["${field.name}"])`,
      },
      position,
    }),
    input: ({ field, parentElement, position }) => ({
      type: "default_bool_input",
      name: field.name,
      config: {
        dataSource: {
          elementId: parentElement.id,
          fieldPath: [field.name],
        },
      },
      i18n: {
        en: {
          label: humanize(field.name),
          labelLeft: "",
          labelRight: "",
        },
      },
      position,
    }),
  },
  date: {
    field: ({ field, parentElement, parentType, position }) => ({
      type: "default_date_time_field",
      name: field.name,
      config: {
        value:
          parentType === "default_form"
            ? `@@expression:elements["${parentElement.id}"].data["${field.name}"]`
            : `@@expression:props.data["${field.name}"]`,
        showTimePart: false,
        showDatePart: true,
      },
      position,
    }),
    input: ({ field, parentElement, position }) => ({
      type: "default_date_input",
      name: field.name,
      config: {
        dataSource: {
          elementId: parentElement.id,
          fieldPath: [field.name],
        },
      },
      i18n: {
        en: {
          label: humanize(field.name),
        },
      },
      position,
    }),
  },
  dateTime: {
    field: ({ field, parentElement, parentType, position }) => ({
      type: "default_date_time_field",
      name: field.name,
      config: {
        value:
          parentType === "default_form"
            ? `@@expression:elements["${parentElement.id}"].data["${field.name}"]`
            : `@@expression:props.data["${field.name}"]`,
        showTimePart: true,
        showDatePart: true,
      },
      position,
    }),
    input: ({ field, parentElement, position }) => ({
      type: "default_date_time_input",
      name: field.name,
      config: {
        dataSource: {
          elementId: parentElement.id,
          fieldPath: [field.name],
        },
      },
      i18n: {
        en: {
          label: humanize(field.name),
        },
      },
      position,
    }),
  },
  time: {
    field: ({ field, parentElement, parentType, position }) => ({
      type: "default_date_time_field",
      name: field.name,
      config: {
        value:
          parentType === "default_form"
            ? `@@expression:elements["${parentElement.id}"].data["${field.name}"]`
            : `@@expression:props.data["${field.name}"]`,
        showDatePart: false,
        showTimePart: true,
      },
      position,
    }),
    input: ({ field, parentElement, position }) => ({
      type: "default_time_input",
      name: field.name,
      config: {
        dataSource: {
          elementId: parentElement.id,
          fieldPath: [field.name],
        },
      },
      i18n: {
        en: {
          label: humanize(field.name),
        },
      },
      position,
    }),
  },
  json: {
    field: ({ field, parentElement, parentType, position }) => ({
      type: "default_json_field",
      name: field.name,
      config: {
        value:
          parentType === "default_form"
            ? `@@expression:elements["${parentElement.id}"].data["${field.name}"]`
            : `@@expression:props.data["${field.name}"]`,
      },
      position,
    }),
    input: ({ field, parentElement, position }) => ({
      type: "default_json_input",
      name: field.name,
      config: {
        dataSource: {
          elementId: parentElement.id,
          fieldPath: [field.name],
        },
      },
      i18n: {
        en: {
          label: humanize(field.name),
        },
      },
      position,
    }),
  },
  number: {
    field: ({ field, parentElement, parentType, position }) => ({
      type: "default_number_field",
      name: field.name,
      config: {
        value:
          parentType === "default_form"
            ? `@@expression:elements["${parentElement.id}"].data["${field.name}"]`
            : `@@expression:props.data["${field.name}"]`,
        precision: null,
        isPercentage: false,
        prefix: "",
        suffix: "",
        highlight: "@@expression:false",
      },
      position,
    }),
    input: ({ field, parentElement, position }) => ({
      type: "default_number_input",
      name: field.name,
      config: {
        dataSource: {
          elementId: parentElement.id,
          fieldPath: [field.name],
        },
      },
      i18n: {
        en: {
          label: humanize(field.name),
        },
      },
      position,
    }),
  },
  text: {
    field: ({ field, parentElement, parentType, position }) =>
      field.generalType.isArray
        ? {
            type: "default_array_text_field",
            name: field.name,
            config: {
              values:
                parentType === "default_form"
                  ? `@@expression:elements["${parentElement.id}"].data["${field.name}"]`
                  : `@@expression:props.data["${field.name}"]`,
            },
            position,
          }
        : {
            type: "default_text_field",
            name: field.name,
            config: {
              text:
                parentType === "default_form"
                  ? `@@expression:elements["${parentElement.id}"].data["${field.name}"]`
                  : `@@expression:props.data["${field.name}"]`,
            },
            position,
          },
    input: ({ field, parentElement, position }) => ({
      type: field.generalType.isArray
        ? "default_array_text_input"
        : "default_text_input",
      name: field.name,
      config: {
        dataSource: {
          elementId: parentElement.id,
          fieldPath: [field.name],
        },
      },
      i18n: {
        en: {
          label: humanize(field.name),
        },
      },
      position,
    }),
  },
  fallback: {
    field: ({ field, parentElement, parentType, position }) => ({
      type: "default_text_field",
      name: field.name,
      config: {
        text:
          parentType === "default_form"
            ? `@@expression:String(elements["${parentElement.id}"].data["${field.name}"])`
            : `@@expression:String(props.data["${field.name}"])`,
      },
      position,
    }),
    input: ({ field, parentElement, position }) => ({
      type: "default_text_input",
      name: field.name,
      config: {
        dataSource: {
          elementId: parentElement.id,
          fieldPath: [field.name],
        },
      },
      i18n: {
        en: {
          label: humanize(field.name),
        },
      },
      position,
    }),
  },
};
