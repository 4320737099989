import React, { memo, useCallback } from "react";

import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { UntransformedAdvancedConditionalConfig } from "../types";

export const AdvancedConditionalEditor = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedAdvancedConditionalConfig>();

  const handleChange = useCallback(
    (nextValue: string) => changeConfigValue("value", nextValue),
    [changeConfigValue],
  );

  return (
    <Section title="Show content" wrapped>
      <CustomExpressionEditor
        value={config.value}
        config={config}
        onChange={handleChange}
        disableSwitcher
      />
    </Section>
  );
});
