import { createContext, useContext } from "react";

import { IPage, Translation } from "../types";
import { ISelectedElement } from "./reduxModule";

type DraggableParams = {
  i: string;
  w: number;
  h: number;
} | null;

interface IEditorContext {
  editModeOn: boolean;
  changeConfigValue: (
    selected: ISelectedElement,
    key: string,
    value: any,
  ) => void;
  changePositionValue: (
    selected: ISelectedElement,
    key: string,
    value: any,
  ) => void;
  changeTranslationValue: (
    selected: ISelectedElement,
    value: Translation<string>,
  ) => void;
  setDraggableElement: (params: DraggableParams) => void;
  unselectElement: (page: IPage) => void;
}

export const EditorContext = createContext<IEditorContext>({
  editModeOn: false,
  changeConfigValue: () => {
    throw new Error("Not implemented");
  },
  changePositionValue: () => {
    throw new Error("Not implemented");
  },
  changeTranslationValue: () => {
    throw new Error("Not implemented");
  },
  setDraggableElement: () => {
    throw new Error("Not implemented");
  },
  unselectElement: () => {
    throw new Error("Not implemented");
  },
});

export function useEditorContext() {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error(
      "useEditorContext must be used within a EditorContext.Provider",
    );
  }
  return context;
}

export const EditorProvider = EditorContext.Provider;
export const EditorConsumer = EditorContext.Consumer;
