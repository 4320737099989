import { assocPath } from "ramda";

import { selectors as editorSelectors } from "../../editor/reduxModule/selectors";
import { DEFAULT_LANGUAGE, LANGUAGES } from "../../types";
import { LANGUAGE_STORAGE_KEY } from "./constants";
import { IAppMetadata } from "../../types";
import { IState } from "./types";

export function getInitialLanguage() {
  const storedLanguageCode = localStorage.getItem(LANGUAGE_STORAGE_KEY);
  return storedLanguageCode
    ? LANGUAGES.find((l) => l.code === storedLanguageCode) || DEFAULT_LANGUAGE
    : DEFAULT_LANGUAGE;
}

export const getUpdatedAppMetadata = (
  state: any,
  scopeSelector: (state: any) => IState,
) => {
  const appMetadata: IAppMetadata | null = scopeSelector(state).appMetadata;
  const updatedLayoutDefinition = editorSelectors.updatedLayoutDefinition(
    state,
  );
  const updatedMenu = editorSelectors.updatedMenu(state);
  let nextAppMetadata = appMetadata;

  if (updatedLayoutDefinition) {
    nextAppMetadata = assocPath(
      ["release", "definition", "layout", "definition"],
      updatedLayoutDefinition,
      nextAppMetadata,
    );
  }

  if (updatedMenu) {
    nextAppMetadata = assocPath(
      ["release", "definition", "menu"],
      updatedMenu,
      nextAppMetadata,
    );
  }
  return nextAppMetadata;
};
