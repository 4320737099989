import React, { memo, useCallback } from "react";
import { Controller } from "react-hook-form";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import { useEditorTranslation } from "core/editor";
import { Language, Translation } from "core/types";
import { getTranslatedTexts } from "core/utils/element";
import { useHookFormContext } from "../../../common/HookForm";
import { allowedCharactersPattern } from "utils/string";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { useAutocompleteEditorTranslation } from "../translation";
import { OptionDetails } from "./Options";

type Props = OptionDetails & {
  i18n: Translation<"label">;
  language: Language;
  handleChangeLanguage: (lang: Language) => void;
  isValueUnique: (value: any) => boolean;
  setOptionDetails: React.Dispatch<React.SetStateAction<OptionDetails | null>>;
};

export const DialogContent = memo<Props>(
  ({
    value,
    i18n,
    language,
    handleChangeLanguage,
    setOptionDetails,
    isValueUnique,
  }) => {
    const translation = useAutocompleteEditorTranslation();
    const editorTranslation = useEditorTranslation();
    const { control, errors, getValues, setValue } = useHookFormContext();

    const getLabel = (lang: Language) => getTranslatedTexts(lang, i18n)?.label;

    const label = getLabel(language);

    const onChange = (nextLang: Language) => {
      const values = getValues();

      setOptionDetails((prevOption) => ({
        ...prevOption!,
        value: values.value,
        i18n: {
          ...prevOption!.i18n,
          [language.code]: {
            label: values.label,
          },
        },
      }));
      handleChangeLanguage(nextLang);
      setValue("label", getLabel(nextLang));
    };

    const onValidation = (nextVal: any, field: "value" | "label") => {
      if (field === "value") {
        return !!nextVal.toString().trim().length && isValueUnique(nextVal);
      }

      return !!nextVal.toString().trim().length;
    };

    const error = useCallback(
      (errorName?: { type: string }, fieldName?: string) => {
        switch (errorName?.type) {
          case "validate":
            return fieldName === "value"
              ? editorTranslation.notUniqueError
              : editorTranslation.invalidError;
          case "pattern":
            return editorTranslation.notMatchError;
          case "required":
            return editorTranslation.requiredError;
          default:
            return undefined;
        }
      },
      [editorTranslation],
    );

    return (
      <>
        <FormControl fullWidth={true}>
          <LanguageSwitch
            language={language}
            changeLanguage={onChange}
            colorVariant="dark"
          />
          <Controller
            as={
              <TextField
                margin="dense"
                label={translation.labelLabel}
                helperText={error(errors.label)}
              />
            }
            error={Boolean(errors.label)}
            name="label"
            control={control}
            defaultValue={label}
            rules={{
              required: true,
              validate: (val: any) => onValidation(val, "label"),
            }}
          />
        </FormControl>
        <FormControl fullWidth={true}>
          <Controller
            as={
              <TextField
                margin="dense"
                label={translation.valueLabel}
                helperText={error(errors.value, "value")}
              />
            }
            error={Boolean(errors.value)}
            name="value"
            control={control}
            defaultValue={value}
            rules={{
              required: true,
              validate: (val: any) => onValidation(val, "value"),
              pattern: allowedCharactersPattern,
            }}
          />
        </FormControl>
      </>
    );
  },
);
