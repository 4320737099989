import React, { lazy, memo } from "react";
import { useSelector } from "react-redux";
import { dissoc } from "ramda";

import { IElement, getExpression } from "core";
import { useEditorTranslation, useElementEditorContext } from "core/editor";
import { selectors as routerSelectors } from "core/router/reduxModule";
import Button from "elementTypes/common/Button";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { TableHeaderCell } from "elementTypes/default_table_header_cell/types";
import { useTableEditorTranslation } from "../../../translation";
import { UntransformedTableConfig } from "../../../types";
import { useColumnsContext } from "./ColumnsContext";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { IGenerateColumn, generateColumnName } from "./ColumnEditor";
import { IColumn } from "./Columns";

const ColumnDialogContent = withLazyLoading(
  lazy(() => import("./ColumnDialogContent")),
  true,
);
const HiddenFieldComponent = withLazyLoading(
  lazy(() => import("./HiddenFieldComponent")),
  true,
);

export const ColumnDialog = memo(() => {
  const { createColumnTitle, editColumnTitle } = useTableEditorTranslation();
  const {
    cancelButton,
    createButton,
    deleteButton,
    updateButton,
  } = useEditorTranslation();
  const {
    elementModel,
    elementModel: {
      children: {
        header: { elements: columns },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTableConfig>();
  const {
    elementTypes,
    columnDetails,
    currentView,
    hidden,
    language,
    getElementTypeFromField,
    updateChildren,
    setColumnDetails,
    deleteColumn,
    generateColumn,
  } = useColumnsContext();
  const page = useSelector(routerSelectors.page);

  const elementTypesDialogConfig = Object.keys(elementTypes).map((name) => {
    const et = elementTypes[name];
    return { name, i18n: et.editorMetadata?.i18n };
  });

  function handleClose() {
    setColumnDetails(null);
  }

  function validateColumnName(columnName: string) {
    return !columns.some(
      (col: IElement) => col.name === `table_header_column-${columnName}`,
    );
  }

  function onCustomExpressionChange(nextExpression: string) {
    return (
      columnDetails &&
      setColumnDetails({
        ...columnDetails,
        isHidden: nextExpression as string,
      })
    );
  }

  function handleDeleteClick() {
    if (columnDetails) {
      deleteColumn(columnDetails?.index);
      handleClose();
    }
  }

  function onSubmit(data: { [k: string]: any }) {
    const isExpression = !!getExpression(columnDetails?.isHidden ?? "").trim()
      .length;
    const isHidden = isExpression ? columnDetails?.isHidden : null;

    if (columnDetails?.isNew) {
      const field = currentView?.fields.find((f) => f.name === data.fieldName);

      const elementType = field ? getElementTypeFromField(field) : null;
      const commonProps = {
        ...data,
        position: {
          column: columnDetails.position.column,
          row: 1,
          width: 1,
          height: 1,
        },
      };

      const element =
        elementType?.type === data.elementType
          ? ({ ...elementType, ...commonProps } as IGenerateColumn)
          : {
              name: data.name,
              type: data.elementType,
              config: {},
              ...commonProps,
            };

      generateColumn(element);
      changeConfigValue("hidden", [...hidden, isHidden]);
      handleClose();
    } else {
      const isChanged = Object.keys(data).some(
        (key) => data[key] !== columnDetails?.[key],
      );
      if (isChanged) {
        const headerElements = columns.map((col: IColumn) => {
          const colConfig = !(data.width === "auto")
            ? { ...col.config, width: "1px" }
            : (dissoc("width", col.config) as TableHeaderCell["config"]);

          return col.name !== columnDetails?.name
            ? col
            : {
                ...col,
                name: generateColumnName(data.name),
                i18n: { ...col.i18n, [language.code]: { label: data.label } },
                config: {
                  ...colConfig,
                  dataSource: {
                    ...colConfig.dataSource,
                    fieldName: data.fieldName ?? "",
                    sortable: data.sortable,
                  },
                  align: data.align,
                },
              };
        });
        updateChildren(elementModel, headerElements, page!, "header");
        changeConfigValue("hidden", [
          ...hidden.map((hiddenField, hiddenIndex) =>
            hiddenIndex === columnDetails?.index ? isHidden : hiddenField,
          ),
        ]);
      }

      handleClose();
    }
  }

  return (
    <DialogWrapper
      isForm={true}
      keepMounted={false}
      open={Boolean(columnDetails)}
      title={columnDetails?.isNew ? createColumnTitle : editColumnTitle}
      submitTitle={columnDetails?.isNew ? createButton : updateButton}
      cancelTitle={cancelButton}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      submitDisabled={true}
      subActions={
        <>
          {!columnDetails?.isNew && (
            <Button
              label={deleteButton}
              color="error.main"
              onClick={handleDeleteClick}
            />
          )}
        </>
      }
    >
      {columnDetails && (
        <ColumnDialogContent
          {...columnDetails}
          elementTypes={elementTypesDialogConfig}
          isNameUnique={validateColumnName}
          fieldNames={currentView?.fields}
          getElementTypeFromField={getElementTypeFromField}
        >
          <HiddenFieldComponent
            value={columnDetails.isHidden}
            onChange={onCustomExpressionChange}
            config={columnDetails.config}
          />
        </ColumnDialogContent>
      )}
    </DialogWrapper>
  );
});
