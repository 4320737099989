import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    popper: {
      zIndex: 1002,
    },
    paperFilterDialog: { width: "600px", height: "600px" },
    marginRight: {
      marginRight: theme.spacing(),
    },
    flexWrapper: {
      display: "flex",
    },
    deleteBtn: {
      alignSelf: "flex-end",
    },
    ruleGap: {
      paddingLeft: theme.spacing(),
    },
    groupCombinator: {
      marginTop: 0,
      marginBottom: 0,
    },
    groupWrapper: {
      alignItems: "center",
      margin: theme.spacing(),
    },
    groupButtons: {
      alignItems: "stretch",
    },
    menuIcon: {
      marginRight: theme.spacing(0.5),
    },
    inputSearch: {
      marginRight: theme.spacing(0.5),
      "& [class*='MuiInputBase-input']": {
        padding: `${theme.spacing()}px 0 ${theme.spacing()}px ${theme.spacing(
          2,
        )}px`,
      },
    },
  }),
);
