import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { ISettingsConfig } from "../../../reduxModule/types";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  LOAD: scopeActionType("LOAD"),
  LOAD_SUCCESS: scopeActionType("LOAD_SUCCESS"),
  LOAD_ERROR: scopeActionType("LOAD_ERROR"),
  EDIT_CONFIG: scopeActionType("EDIT_CONFIG"),
  EDIT_CONFIG_ERROR: scopeActionType("EDIT_CONFIG_ERROR"),
};

export const actions = {
  load: createAction(types.LOAD),
  loadSuccess: createAction(types.LOAD_SUCCESS, (config: any) => ({
    config,
  })),
  loadError: createAction(types.LOAD_ERROR, (error: any) => ({ error })),
  editConfig: createAction(types.EDIT_CONFIG, (config: ISettingsConfig) => ({
    config,
  })),
  editConfigError: createAction(types.EDIT_CONFIG_ERROR, (error: any) => ({
    error,
  })),
};
