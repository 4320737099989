import React, { memo, useCallback } from "react";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedColorFieldConfig } from "../types";
import { useColorFieldEditorTranslation } from "./translation";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

export const ColorFieldEditor = memo(() => {
  const {
    elementModel: { id, config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedColorFieldConfig>();
  const translation = useColorFieldEditorTranslation();
  const { value } = config;

  const handleValueChange = useCallback(
    (newValue: string) => changeConfigValue("value", newValue),
    [changeConfigValue],
  );

  const handleExpressionValueChange = (newValue: string) => {
    handleValueChange(newValue);
  };

  return (
    <Section title={translation.valueLabel} wrapped={true}>
      <TableColumnEditor
        id={id}
        value={value}
        onChange={handleExpressionValueChange}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text"]}
      />
      <CustomExpressionEditor
        value={value}
        config={config}
        onChange={handleExpressionValueChange}
        disableSwitcher
      />
    </Section>
  );
});
