import React, { memo, useEffect, useMemo, useState } from "react";
import ListItemText from "@material-ui/core/ListItemText";

import {
  BaseAutocomplete,
  BaseAutocompleteProps,
  IAutocompleteValue,
} from "../../default_autocomplete_input/components";
import { SelectOption } from "../../default_autocomplete_input/types";

export type AutocompleteOption = SelectOption;

type Props = Partial<BaseAutocompleteProps> & {
  value?: string | null;
  onChange?: (value: string) => void;
  label?: string;
  name?: string;
  options?: SelectOption[];
  error?: string;
};

export const customRenderOption = (option: AutocompleteOption) => (
  <ListItemText
    primary={option.label}
    secondary={option.value}
    secondaryTypographyProps={{ variant: "overline", display: "block" }}
  />
);

export const Autocomplete = memo<Props>(
  ({
    onChange,
    value,
    label = "",
    name = "",
    options = [],
    error,
    ...rest
  }) => {
    const valueObject = useMemo(() => options.find((o) => o.value === value), [
      value,
      options,
    ]);

    const [searchInputValue, setInputValue] = useState<string>("");

    useEffect(() => {
      !!searchInputValue.length && setInputValue("");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueObject]);

    const handleChange = (newValue: IAutocompleteValue) =>
      onChange?.(newValue as string);

    const handleInputChange = (newValue: string) => setInputValue(newValue);

    return (
      <BaseAutocomplete
        options={options}
        onChange={handleChange}
        valueObject={valueObject ?? ""}
        name={name}
        label={label}
        isLoading={false}
        searchInputValue={searchInputValue}
        onInputChange={handleInputChange}
        defaultItemSize={48}
        virtualizedList={true}
        isClearable={true}
        errors={error}
        {...rest}
      />
    );
  },
);
