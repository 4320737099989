import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  filters: {
    display: "inline-flex",
    justifyContent: "flex-end",
  },
  filter: {
    flex: 1,
    marginLeft: `${theme.spacing()}px`,
    marginRight: `${theme.spacing()}px`,
  },
  cellCentered: {
    textAlign: "center",
  },
  dialog: {
    width: "800px",
  },
}));

export default useStyles;
