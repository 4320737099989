import React, { CSSProperties, memo, useMemo } from "react";
import { Controller } from "react-hook-form";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";

import IconButton from "../IconButton";
import { HookForm, useHookFormContext } from "../HookForm";
import { Column } from "./TableHeader";
import { CustomInput } from "./CustomInput";
import { gridTemplateColumns } from "./component";
import { useStyles } from "./styles";

type Props = {
  data: Record<string, unknown>;
  style: CSSProperties;
  onDelete: (indexToDelete: number) => void;
  selectRow: (indexToSelect: number | null) => void;
  onRowSubmit: (newRow: Record<string, unknown>) => void;
  rowMetaData: {
    id: number;
    columns: Column[];
    active?: boolean;
    isNew?: boolean;
    disableBtn?: boolean;
    /**
     * which cells should be highlighted
     */
    highlight?: string[];
    /**
     * should the current row be highlighted
     */
    highlightRow?: boolean;
  };
  actionsDisabled?: boolean;
};

const getDefaultValue = (type: string) => {
  switch (type) {
    case "boolean":
      return false;
    default:
      return "";
  }
};

const FieldController = memo<{
  field: Column;
  error: Record<string, unknown>;
  value: any;
  name: string;
  autoFocus?: boolean;
  active?: boolean;
  highlight?: boolean;
}>(({ field, error, value, name, autoFocus, active, highlight }) => {
  const { control } = useHookFormContext();
  const defaultValue = value ?? getDefaultValue(field.generalType.type);
  const { highlightTextField } = useStyles();

  return (
    <FormControl
      fullWidth={field.generalType.type !== "boolean"}
      data-field-name={name}
      data-cell-highlight={highlight}
    >
      <Controller
        as={(otherProps: any) => (
          <CustomInput
            generalType={field.generalType}
            {...otherProps}
            clearable={Boolean(!field.required) && active}
          />
        )}
        error={error?.type}
        name={name}
        control={control}
        defaultValue={defaultValue}
        disabled={!active}
        autoFocus={autoFocus}
        className={highlight ? highlightTextField : undefined}
        rules={{
          required: field.generalType.type !== "boolean" && field.required,
          validate: (val: any) => field.validate(val),
        }}
      />
    </FormControl>
  );
});

const FormRow = memo<Omit<Props, "style" | "onRowSubmit">>(
  ({
    data,
    rowMetaData: {
      active,
      id,
      columns,
      isNew,
      disableBtn,
      highlight,
      highlightRow,
    },
    onDelete,
    selectRow,
    actionsDisabled,
  }) => {
    const { errors, reset } = useHookFormContext();
    const handleSelectRow = () => selectRow(id);

    const handleDeleteClick = () => {
      if (active && !isNew) {
        reset();
        selectRow(null);
      } else {
        onDelete(id);
      }
    };

    const items = columns.map((field, index) => {
      const key = field.field;
      const value = data[key];
      const error = errors?.[key];
      const highlighted = highlight?.includes(key) || highlightRow;

      return (
        <FieldController
          key={`${id}-${key}-${index}`}
          field={field}
          error={error}
          value={value}
          name={key}
          autoFocus={index === 0}
          active={active}
          highlight={highlighted}
        />
      );
    });

    return (
      <>
        {!actionsDisabled && (
          <Box display="flex" alignItems="center" justifyContent="start" mb={1}>
            <IconButton
              icon={active ? "check" : "edit"}
              type={active ? "submit" : "button"}
              {...(!active && {
                onClick: handleSelectRow,
              })}
              tooltip={active ? "Save Row" : "Edit Row"}
              disabled={disableBtn}
            />
            <IconButton
              icon={active && !isNew ? "clear" : "delete_outline"}
              onClick={handleDeleteClick}
              tooltip={active && !isNew ? "Cancel Editing" : "Delete Row"}
              disabled={disableBtn}
            />
          </Box>
        )}
        {items}
      </>
    );
  },
);

export const TableRow = memo<Props>(({ style, onRowSubmit, ...rest }) => {
  const onSubmit = (data: { [k: string]: any }) => {
    const newRow = Object.entries(data).reduce(
      (res, [key, value]: [string, unknown]) => ({
        ...res,
        [key]: value !== null && !String(value).length ? null : value,
      }),
      {},
    );

    onRowSubmit({ ...rest.data, ...newRow });
  };

  const gridLength = useMemo(
    () =>
      Object.keys(rest.data).filter((key) =>
        rest.rowMetaData.columns.find((col) => col.field === key),
      )?.length,
    [rest.data, rest.rowMetaData.columns],
  );

  return (
    <HookForm
      onSubmit={onSubmit}
      boxProps={{
        display: "grid",
        gridGap: 8,
        gridTemplateColumns: gridTemplateColumns(
          gridLength,
          rest.actionsDisabled,
        ),
        style,
        bgcolor: rest.rowMetaData.active
          ? "action.selected"
          : Math.abs(rest.rowMetaData.id % 2) === 1
          ? "grey.100"
          : "none",
        px: 1.5,
      }}
    >
      <FormRow {...rest} />
    </HookForm>
  );
});
