import { createUseTranslation } from "core/session/translation";

export const useExternalLinkFieldEditorTranslation = createUseTranslation({
  en: {
    linkConfigTitle: "Configuration",
    linkLabel: "Link",
    linkPlaceholder: "https://www.cybertec-postgresql.com",
    displayConfigTitle: "Display",
    isButtonLabel: "Display as button",
    linkHelperText:
      "You have to prefix the URL with a protocol - e.g. http:// or https://",
  },
});
