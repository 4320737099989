import {
  Theme,
  createStyles,
  fade,
  makeStyles,
} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlightTextField: {
      backgroundColor: fade(theme.palette.info.main, 0.2),
      "&:hover": {
        backgroundColor: fade(theme.palette.info.main, 0.15),
      },
    },
  }),
);
