import React from "react";
import * as t from "io-ts";

import Alert from "@material-ui/lab/Alert";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { INSTANTIATED } from "../constants";
import { Type, types } from "../runtime-typing";
import {
  AnyElement,
  DEFAULT_LANGUAGE_CODE,
  IElementModel,
  Language,
  Translated,
  Translation,
  TypeFactory,
  getPropTypes,
} from "../types";

export function getElementDisplay(element: AnyElement) {
  return `Element of type "${element.type.name}" with id "${element.id}"`;
}

export function isElementInstance(element: AnyElement) {
  return Boolean((element as any)[INSTANTIATED]);
}

export function getTranslatedTexts<Keys extends keyof any>(
  { code: languageCode }: Language,
  translation: Translation<Keys>,
): Translated<Keys> {
  if (languageCode === DEFAULT_LANGUAGE_CODE) {
    return translation[languageCode];
  }
  const defaults = translation[DEFAULT_LANGUAGE_CODE];
  const values = translation[languageCode];
  if (!values || !Object.keys(values).length) {
    return defaults;
  }

  return Object.keys(defaults).reduce((acc, k) => {
    let val = values[k];
    val = val === undefined ? defaults[k] : val;
    return { ...acc, [k]: val };
  }, {}) as Translated<Keys>;
}

export function getTranslatedText<Key extends keyof any>(
  { code: languageCode }: Language,
  translation: Translation<Key>,
  key: Key,
) {
  const defaultValue = translation[DEFAULT_LANGUAGE_CODE][key];
  if (languageCode === DEFAULT_LANGUAGE_CODE) {
    return defaultValue;
  }
  const values = translation[languageCode];
  return values ? values[key] || defaultValue : defaultValue;
}

const useErrorStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
      "& > .MuiAlert-message": {
        whiteSpace: "nowrap",
        overflowX: "scroll",
        scrollbarWidth: "none",
        "-ms-overflow-style": "-ms-autohiding-scrollbar",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
    tooltip: {
      maxWidth: "100%",
      overflow: "auto",
    },
  }),
);

export function ElementWithError({ error }: { error: any }) {
  const { root, tooltip } = useErrorStyles();
  return (
    <Tooltip
      interactive
      classes={{ tooltip }}
      title={
        <Typography component="pre">
          {error.message ?? error.toString()}
        </Typography>
      }
    >
      <Alert
        severity="error"
        className={root}
        data-cypress-selector="element-with-error"
      >
        This Element contains an error
      </Alert>
    </Tooltip>
  );
}

export function getElementChildrenTypePropTypes(
  childrenType: t.Mixed,
  config: Record<string, any>,
  state: any,
): Record<string, Type> {
  let childrenPropTypes: Record<string, Type>;
  if (childrenType instanceof t.IntersectionType) {
    childrenPropTypes = childrenType.types.reduce(
      (acc: Record<string, Type>, type: t.Mixed) => ({
        ...acc,
        ...getElementChildrenTypePropTypes(type, config, state),
      }),
      {},
    );
  } else if (
    childrenType instanceof t.InterfaceType ||
    childrenType instanceof t.PartialType
  ) {
    childrenPropTypes = {};
    for (const k of Object.keys(childrenType.props)) {
      const propTypes = getPropTypes(childrenType.props[k]) || {};
      childrenPropTypes[k] = types.interface(
        Object.keys(propTypes).reduce((acc, pk) => {
          const typeOrFactory: Type | TypeFactory = propTypes[pk];
          return {
            ...acc,
            [pk]:
              typeof typeOrFactory === "function"
                ? typeOrFactory({ config, state })
                : typeOrFactory,
          };
        }, {}),
      );
    }
  } else {
    throw new Error(`Unsupported io-ts type for Children "${childrenType}"`);
  }
  return childrenPropTypes;
}

export function findParentAndChildKey(
  childModel: IElementModel<any, any, any>,
  models: IElementModel[],
): [IElementModel, string] | null {
  for (const model of models) {
    for (const key of Object.keys(model.children)) {
      const child = model.children[key];
      if (
        child.element
          ? child.element.id === childModel.id
          : child.elements.find((e: IElementModel) => e.id === childModel.id)
      ) {
        return [model, key];
      }
    }
  }
  return null;
}
