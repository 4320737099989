import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import { actions, types } from "./actions";
import { selectors } from "./selectors";

import AdminService from "services/admin";
import { parseQueries, stringifyQueries } from "core/router/reduxModule/utils";
import history from "core/router/reduxModule/history";
import { IAuditTable } from "./types";

const services = AdminService.getInstance();

function* loadSaga() {
  const token = yield select(sessionSelectors.token);
  const schemas = yield select(selectors.schemas);
  const tables = yield select(selectors.tables);

  try {
    const search = parseQueries(history.location.search);

    // Delete ui on query if exist
    if (search["ui"]) {
      delete search["ui"];
    }

    const data: IAuditTable[] = yield call(
      services.getTableAudit,
      token,
      search,
    );
    yield put(actions.loadSuccess(data));

    // Just populate schemas and tables on the first time
    if (!schemas.length && !tables.length) {
      yield put(
        actions.setSchemas([...new Set(data.map((audit) => audit.schemaName))]),
      );
      yield put(
        actions.setTables([...new Set(data.map((audit) => audit.tableName))]),
      );
    }
  } catch (error) {
    yield put(actions.loadError(error.message ?? error.toString()));
  }
}

function* changeFilterSaga(action: ReturnType<typeof actions["changeFilter"]>) {
  try {
    const queries = parseQueries(history.location.search);

    const search = stringifyQueries({
      ...queries,
      ...action.payload.filter,
    });

    const replaceLocation = {
      ...history.location,
      search,
    };

    yield call([history, "replace"], replaceLocation);

    yield put(actions.load());
  } catch (error) {
    yield put(actions.loadError(error.message ?? error.toString()));
  }
}

export function* saga() {
  yield all([
    yield takeLatest(types.LOAD, loadSaga),
    yield takeLatest(types.CHANGE_FILTER, changeFilterSaga),
  ]);

  yield put(actions.load());
}
