import React, { memo, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { IFileWithMeta } from "react-dropzone-uploader";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";

import Button from "elementTypes/common/Button";
import BackButton from "elementTypes/common/BackButton";
import { AdminUploadFilePage, IFileForm } from "./types";
import useStyles from "./styles";
import { IFileGroup, IFileType } from "./reduxModule/types";
import { UploadZone } from "./components";

export const CreateAppsPage = memo<AdminUploadFilePage>(
  ({ uploadFile, getFileTypes, getFileGroups, fileGroups, fileTypes }) => {
    const { root, footer, dividerFooter } = useStyles();
    const {
      handleSubmit,
      register,
      formState: { isSubmitting },
      errors,
    } = useForm<IFileForm>();

    const [fileWithMeta, setFileWithMeta] = useState<IFileWithMeta | null>(
      null,
    );

    useEffect(() => {
      getFileTypes();
      getFileGroups();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = (data: IFileForm) => {
      if (fileWithMeta) {
        data = {
          ...data,
          file: fileWithMeta.file,
        };
        uploadFile(data);
        fileWithMeta.remove();
      }
    };

    return (
      <form onSubmit={handleSubmit(submit)}>
        <Paper variant="outlined" className={root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                options={fileGroups.map((group: IFileGroup) => ({
                  name: group.name,
                }))}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Group"
                    name="groupName"
                    error={Boolean(errors?.groupName)}
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={fileTypes.map((type: IFileType) => ({
                  name: type.typeName,
                }))}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type"
                    name="typeGroupName"
                    error={Boolean(errors?.typeGroupName)}
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <UploadZone onChange={setFileWithMeta} />
            <Grid item xs={12} className={footer}>
              <Divider className={dividerFooter} />
              <Button
                color="secondary"
                iconRight="forward"
                type="submit"
                label="Upload"
                disabled={!fileWithMeta || isSubmitting}
              />
            </Grid>
          </Grid>
        </Paper>
        <BackButton />
      </form>
    );
  },
);
