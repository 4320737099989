import React, { memo, useMemo } from "react";

import Fade from "@material-ui/core/Fade";

import { IApiError } from "core/types";
import { AlertBox } from "../../common/AlertBox";
import { useErrorsTranslation } from "../translation";

type Props = {
  errors: {
    load: IApiError | string | null;
    save: IApiError | string | null;
  };
};

export const FormErrors = memo<Props>(({ errors }) => {
  const { alertTitle } = useErrorsTranslation();

  const renderErrors = useMemo(
    () =>
      Object.keys(errors).map(
        (key) =>
          errors[key] && (
            <AlertBox
              key={key}
              alertTitle={alertTitle}
              message={errors[key].message ?? errors[key]}
            />
          ),
      ),
    [errors, alertTitle],
  );

  return (
    <Fade in={!!renderErrors.length}>
      <div>{renderErrors}</div>
    </Fade>
  );
});
