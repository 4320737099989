import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";

import { InternalLinkField } from "../types";
import { Actions, Selectors, Types } from "./types";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: InternalLinkField,
  selectors: Selectors,
) {
  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token = yield select(sessionSelectors.token);

    const identifierValue = yield select(selectors.identifier);

    const { dataSource } = element.config;
    if (!dataSource || identifierValue === null) {
      yield put(actions.loadSuccess(null));
      return;
    }

    const metadata = {
      order: dataSource.identifierName
        ? `${dataSource.identifierName}.desc`
        : null,
      offset: 0,
      limit: 1,
    };
    if (dataSource.identifierName && identifierValue !== undefined) {
      metadata[dataSource.identifierName] = `eq.${identifierValue}`;
    }

    try {
      const data = yield call(
        services.api.loadViewData,
        token,
        dataSource.viewName,
        metadata,
      );
      yield put(actions.loadSuccess(data[0]));
    } catch (error) {
      yield put(actions.loadError(error.message ?? error.toString()));
    }
  }

  return function* mainSaga() {
    yield all([yield takeLatest(types.LOAD, loadSaga)]);

    yield put(actions.load());
  };
}
