import React, { memo } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";

import { Section } from "core/editor";
import { UntransformedTableConfig } from "../../types";
import { useTableEditorTranslation } from "../../translation";
import { intervalSliderMarks } from "./utils";

type Props = {
  interval: UntransformedTableConfig["interval"];
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const Interval = memo<Props>(({ interval = 0, changeConfigValue }) => {
  const translation = useTableEditorTranslation();

  const handleIntervalChange = (
    _event: React.ChangeEvent<unknown>,
    value: number | number[],
  ) => {
    if (typeof value === "number") {
      changeConfigValue("interval", value);
    }
  };

  return (
    <Section
      title={translation.advancedTitle}
      wrapped={true}
      defaultOpened={false}
    >
      <FormControl>
        <Tooltip title={translation.intervalTooltipLabel}>
          <FormLabel>{translation.intervalLabel}</FormLabel>
        </Tooltip>
        <Slider
          value={interval}
          onChange={handleIntervalChange}
          step={null}
          min={intervalSliderMarks[0].value}
          max={intervalSliderMarks[intervalSliderMarks.length - 1].value}
          valueLabelDisplay="auto"
          marks={intervalSliderMarks}
        />
      </FormControl>
    </Section>
  );
});
