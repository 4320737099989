import { actions } from "core/router/reduxModule";
import {
  IElementComponentProps,
  IReduxModule,
  PropsFromConnector,
} from "core/types";
import Component from "./component";
import { ArrayTextField } from "./types";
import { connectElement } from "core/utils/react-redux";

const mapStateToProps = (
  state: any,
  { element }: IElementComponentProps<IReduxModule, ArrayTextField>,
) => ({
  values: element.config.values ? element.config.values(state) : null,
});

const connector = connectElement<
  IReduxModule,
  ArrayTextField,
  typeof mapStateToProps,
  typeof actions
>(mapStateToProps, actions);

export type PropsFromRedux = PropsFromConnector<typeof connector>;

export default connector(Component);
