import * as apiService from "./api";
import loader from "./loader";
import adminService from "./admin";

export function buildServices() {
  return {
    loader,
    api: apiService,
    admin: adminService,
  };
}

export type Services = ReturnType<typeof buildServices>;
