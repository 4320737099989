import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IUIEditForm } from "../types";
import { IAdminUi } from "core/types/app";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  LOAD: scopeActionType("LOAD"),
  SELECTED_UI: scopeActionType("SELECTED_UI"),
  EDIT_UI: scopeActionType("EDIT_UI"),
  EDIT_UI_ERROR: scopeActionType("EDIT_UI_ERROR"),
};

export const actions = {
  load: createAction(types.LOAD),
  selectedUi: createAction(types.SELECTED_UI, (selectedUi: IAdminUi) => ({
    selectedUi,
  })),
  editUi: createAction(types.EDIT_UI, (ui: IUIEditForm) => ({
    ui,
  })),
  editUiError: createAction(
    types.EDIT_UI_ERROR,
    (error: Record<string, unknown>) => ({
      error,
    }),
  ),
};
