import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IUICreateForm } from "../types";
import { IRole } from "./types";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  ADD_UI_ERROR: scopeActionType("ADD_UI_ERROR"),
  ADD_UI: scopeActionType("ADD_UI"),
  GET_ROLES_SUCCESS: scopeActionType("GET_ROLES_SUCCESS"),
  GET_ROLES_ERROR: scopeActionType("GET_ROLES_ERROR"),
  GET_ROLES: scopeActionType("GET_ROLES"),
};

export const actions = {
  addUIError: createAction(types.ADD_UI_ERROR, (error: any) => ({ error })),
  addUI: createAction(types.ADD_UI, (ui: IUICreateForm) => ({
    ui,
  })),
  getRolesSuccess: createAction(
    types.GET_ROLES_SUCCESS,
    (rolesList: IRole[]) => ({
      rolesList,
    }),
  ),
  getRolesError: createAction(types.GET_ROLES_ERROR, (error: any) => ({
    error,
  })),
  getRoles: createAction(types.GET_ROLES, () => ({})),
};
