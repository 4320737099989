import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  FileInputConfig,
  FileInputTranslationKeys,
  fileInputSelectors,
} from "./types";
import { FileInputEditor, defaultElement, editorMetadata } from "./editor";
import { ElementGroup, IRawElementType } from "core";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "storage_file_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: FileInputConfig,
  selectorTypes: fileInputSelectors,
  translationKeys: FileInputTranslationKeys,
  editorComponent: FileInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
