import { createSelector } from "reselect";
import { AdvancedConditional } from "../types";

export function buildSelectors(_: string[], element: AdvancedConditional) {
  const visible = createSelector(
    [element.config.value],
    (isVisible) => isVisible,
  );

  return {
    visible,
  };
}
