import { createUseTranslation } from "core/session/translation";

export const tableTranslation = {
  en: {
    actionsTitleButton: "Actions",
    buttonRefresh: "Refresh",
    buttonNextPage: "",
    buttonPreviousPage: "",
    description: "",
    filterCancelButton: "Cancel",
    filterSubmitButton: "Apply Filter",
    filterTitle: "Filter",
    exportTitle: "Export",
    textError: "",
    textErrorColumns: "",
    textErrorConnection: "",
    textErrorFilter: "",
    textNoData: "NO DATA FOUND",
    searchPlaceholder: "Search",
    simpleFilterDisabled: "The advanced filter is set",
    requiredError: "Value is Required",
    notANumberError: "Value should be a number",
    invalidError: "Invalid Value",
    notMatchError:
      "Name can only contain numbers, letters, hyphens and underscores",
    notUniqueError: "Name Should be Unique",
    messageDeleted: "Successfully deleted",
    messageUpdated: "Successfully updated",
    groupButtonLabel: "Group",
    groupButtonDescription: "Add Group",
    ruleButtonLabel: "Rule",
    ruleButtonDescription: "Add Rule",
    deleteGroupButtonDescription: "Delete Group",
    deleteRuleButtonDescription: "Delete Rule",
  },
  es: {
    buttonRefresh: "Refrescar",
    filterTitle: "Filtro",
    exportTitle: "Exportar",
    simpleFilterDisabled: "El filtro avanzado esta configurado",
  },
  de: {
    groupButtonLabel: "Gruppe",
    groupButtonDescription: "Gruppe hinzufügen",
    ruleButtonLabel: "Regel",
    ruleButtonDescription: "Regel hinzufügen",
    deleteGroupButtonDescription: "Gruppe löschen",
    deleteRuleButtonDescription: "Regel löschen",
  },
};

export const editorTranslation = {
  en: {
    alignTitle: "Align",
    elementTypeTitle: "Element Type of the Table Body Column",
    columnsTitle: "Columns",
    advancedTitle: "Advanced",
    widthTitle: "Width",
    allowedFilterTitle: "Allowed filter",
    fixedFilterTitle: "Fixed Filter",
    defaultSortTitle: "Default sort",
    autoLabel: "Auto",
    centerLabel: "Center",
    elementTypeLabel: "Element Type",
    fitContentLabel: "Fit Content",
    labelLabel: "Label",
    nameLabel: "Name",
    leftLabel: "Left",
    rightLabel: "Right",
    sortableLabel: "Sortable",
    addColumnTooltip: "Add Column",
    generateColumnsTooltip: "Generate Columns",
    generateConfirmation:
      "This action will lead to generating default columns and deleting the existing ones. Are you sure?",
    changeViewButton: "Change Source View",
    changeViewConfirmation:
      "Current table data and configuration will be deleted. Are you sure?",
    createColumnTitle: "Create Column",
    editReferenceTitle: "Edit Reference",
    createReferenceTitle: "Create Reference",
    editColumnTitle: "Edit Column",
    intervalLabel: "Automatic refresh interval (seconds)",
    intervalTooltipLabel:
      "Interval seconds in which the data should automatically be reloaded. 0 = disabled",
    advancedLabel: "Advanced Mode",
    selectionTitle: "Selection",
    firstRowSelectedLabel: "First row selected",
    canSelectRowLabel: "Can select row",
    referencesTitle: "References",
    simpleFilterTitle: "Simple Filter",
    fieldNameLabel: "Field Name",
    viewFieldLabel: "View Field",
    simpleFilterLabel: "Fields",
    ascTooltip: "Sort Ascending",
    descTooltip: "Sort Descending",
    defaultFilterButton: "Filter",
    addFilterTooltip: "Add Filter",
    removeFilterTooltip: "Remove Filter",
    editColumnTooltip: "Edit Column",
    selectColumnTooltip: "Select Column Element",
    hiddenColumnTooltip: "Hidden column can't be selected",
    deleteColumnTooltip: "Delete Column",
    editReferenceTooltip: "Edit Reference",
    deleteReferenceTooltip: "Delete Reference",
    operatorsTooltip: "Available Operators",
    applyFilterConfirmation:
      "Warning! When switching between base and advance modes current fixed filter can't be implemented and will be removed",
    noEditorComponentTooltip: "This column element can not be selected",
  },
};

export const operatorsTranslation = {
  en: {
    eqLabel: "=",
    eqDescription: "left side is equal to right side",
    ltLabel: "<",
    ltDescription: "left side is less than right side",
    gtLabel: ">",
    gtDescription: "left side is greater than right side",
    ilikeLabel: "starts with",
    ilikeDescription: "the value starts with the input",
    is_nullLabel: "is null",
    is_nullDescription: "the value is null",
    is_not_nullLabel: "is not null",
    is_not_nullDescription: "the value is not null",
    is_trueLabel: "is true",
    is_trueDescription: "the value is true",
    is_falseLabel: "is false",
    is_falseDescription: "the value is false",
  },
  de: {
    eqLabel: "=",
    eqDescription: "Wert und Eingabe sind gleich",
    ltLabel: "<",
    ltDescription: "Wert ist kleiner als Eingabe",
    gtLabel: ">",
    gtDescription: "Wert ist größer als Eingabe",
    ilikeLabel: "beginnt mit",
    ilikeDescription: "Wert beginnt mit Eingabe",
    is_nullLabel: "ist null",
    is_nullDescription: "Wert ist null",
    is_not_nullLabel: "ist nicht null",
    is_not_nullDescription: "Wert ist nicht null",
    is_trueLabel: "ist wahr",
    is_trueDescription: "Wert ist wahr",
    is_falseLabel: "ist falsch",
    is_falseDescription: "Wert ist falsch",
  },
};

export const useTableTranslation = createUseTranslation(tableTranslation);

export const useTableEditorTranslation = createUseTranslation(
  editorTranslation,
);

export const useOperatorsTranslation = createUseTranslation(
  operatorsTranslation,
);
