import React, { memo } from "react";

import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

type Props = {
  value: string;
  config: Record<string, any>;
  onChange: (value: string) => void;
};

export const AdvancedMode = memo<Props>(({ value = "", config, onChange }) => (
  <CustomExpressionEditor
    value={value}
    config={config}
    onChange={onChange}
    disableSwitcher={true}
  />
));
