import React, { memo, useMemo } from "react";

import { Theme, createStyles, makeStyles } from "@material-ui/core";

import { Element } from "core";
import Cell from "./Cell";

import { ChildElement } from "../component";
import { sortElements } from "../utils";

type Props = {
  elements: ChildElement[];
  elementProps: Record<string, any>;
  isFormGrid: boolean;
};

export const DEFAULT_GRID_COLUMN_COUNT = 12;

const ROW_SPACING_MULTIPLICATOR = 6;
// additional space to display form field helper text
const FORM_ROW_SPACING_MULTIPLICATOR = 6.5;

export const SPACING_MULTIPLICATOR = (isForm: boolean): number =>
  isForm ? FORM_ROW_SPACING_MULTIPLICATOR : ROW_SPACING_MULTIPLICATOR;

export const GRID_SPACING_FACTOR = 1;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      // https://css-tricks.com/preventing-a-grid-blowout/
      gridTemplateColumns: `repeat(${DEFAULT_GRID_COLUMN_COUNT}, minmax(0, 1fr))`,
      gridAutoRows: ({ isFormGrid }: { isFormGrid: boolean }) =>
        theme.spacing(SPACING_MULTIPLICATOR(isFormGrid)),
      gridGap: theme.spacing(GRID_SPACING_FACTOR),
    },
  }),
);

export const StaticGrid = memo<Props>(
  ({ elements, elementProps, isFormGrid }) => {
    const { root } = useStyles({ isFormGrid });
    const items = useMemo(
      () =>
        sortElements(elements).map((child: any) => (
          <Cell {...child.position} key={child.id}>
            <Element element={child} elementProps={elementProps} />
          </Cell>
        )),
      [elements, elementProps],
    );

    return <div className={root}>{items}</div>;
  },
);
