import React, { memo } from "react";
import Markdown from "react-markdown";
import Typography from "@material-ui/core/Typography";
import GitInfo from "react-git-info/macro";
import { format } from "date-fns";

import useStyles from "../styles";

export const AdminFooter = memo(() => {
  const { footer, gitInfo: gitInfoClassname } = useStyles();
  const gitInfo = GitInfo();
  const gitInfoDate = format(new Date(gitInfo.commit.date), "yyyy-MM-dd");

  return (
    <footer className={footer}>
      <div />
      <Markdown source="CYPEX is a product developed by [CYBERTEC](https://www.cybertec-postgresql.com/)." />
      <Typography
        className={gitInfoClassname}
      >{`${gitInfo.commit.shortHash} | ${gitInfoDate}`}</Typography>
    </footer>
  );
});
