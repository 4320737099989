import React, { memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedExternalLinkFieldConfig } from "../../types";
import { useExternalLinkFieldEditorTranslation } from "../translation";

export const Display = memo(() => {
  const {
    displayConfigTitle,
    isButtonLabel,
  } = useExternalLinkFieldEditorTranslation();

  const {
    elementModel: {
      config: { isButton },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedExternalLinkFieldConfig>();

  const toggleIsButton = () => changeConfigValue("isButton", !isButton);

  return (
    <Section title={displayConfigTitle} wrapped={true}>
      <FormControlLabel
        control={<Switch checked={isButton} onChange={toggleIsButton} />}
        label={isButtonLabel}
      />
    </Section>
  );
});
