import React, { memo } from "react";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";

import Button from "../common/Button";
import { FormButton } from "./types";
import useStyles from "./styles";

const DefaultFormButton = memo<
  ConnectedReduxModuleProps<ReduxModule, FormButton>
>(({ click, disabled, element }) => {
  const { root } = useStyles();

  return (
    <Button
      onClick={click}
      disabled={disabled}
      label={element.i18n.label}
      className={root}
      {...element.config}
    />
  );
});

DefaultFormButton.displayName = "DefaultFormButton";

export default DefaultFormButton;
