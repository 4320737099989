import { v4 as uuidv4 } from "uuid";

import {
  Definition,
  IDefaultElement,
  IElementModel,
  IElementModelArrayChild,
  IElementModelSingleChild,
  IElementType,
  IMenuItem,
  IPage,
  LayoutDefinition,
  TCssGridConfig,
  TDefaultElementChild,
  TElementChildren,
  TElementModelWithPosition,
} from "../../types";
import { TUpdatedElements } from "./types";

export function createElement(
  getElementType: (t: string) => IElementType,
  type: IElementType,
  nextElementId: number,
  defaultElement?: IDefaultElement,
): [IElementModel, number];
export function createElement(
  getElementType: (t: string) => IElementType,
  type: IElementType,
  nextElementId: number,
  defaultElement?: IDefaultElement | undefined,
  position?: TCssGridConfig,
  name?: string,
): [TElementModelWithPosition, number];
export function createElement(
  getElementType: (t: string) => IElementType,
  type: IElementType,
  nextElementId: number,
  defaultElement: IDefaultElement | undefined = type.defaultElement,
  position?: TCssGridConfig,
  name?: string,
): [IElementModel, number] | [TElementModelWithPosition, number] {
  if (!defaultElement) {
    throw new Error(
      `Cannot create element of type "${type.name}". No "defaultElement" key in type.`,
    );
  }

  /**
   * TODO:
   * For now we're generating element ids like this.
   * In the future, name and id will be the same thing, so the generated id should look something like
   * "button3" or "table1". For doing that, we first need an map of element types to names
   * ( internal_link_button => "button" ), and something similar to the current (currently ignored)
   * `nextElementId` var, but indexed by name: { nextElementIds: { button: 1, table: 2 } }.
   * When we load an UI generated server-side, we need to traverse the whole UI and deduce the next id
   * for each name. We also should check for uniqueness after creating the id.
   */
  // const id = `new-element-${nextElementId++}`;
  const id = uuidv4().split("-")[0];
  const elementName = name ?? type.name;
  const elementId = `${elementName}_${id}`;

  const { config, i18n, children = {} } = defaultElement;

  const builtChildren: Record<
    string,
    IElementModelSingleChild | IElementModelArrayChild
  > = {};
  let child: TDefaultElementChild | TDefaultElementChild[];
  let builtChild;
  for (const childName of Object.keys(children)) {
    child = children[childName].element ?? children[childName].elements;
    if (Array.isArray(child)) {
      const builtChildArray = [];
      for (const item of child) {
        [builtChild, nextElementId] = createChildElement(
          getElementType,
          nextElementId,
          item,
        );
        builtChildArray.push(builtChild);
      }
      builtChildren[childName] = { elements: builtChildArray };
    } else {
      [builtChild, nextElementId] = createChildElement(
        getElementType,
        nextElementId,
        child,
      );
      builtChildren[childName] = { element: builtChild };
    }
  }

  return [
    {
      id: elementId,
      position,
      name: elementName,
      type: {
        name: type.name,
      },
      config: config || {},
      children: builtChildren,
      i18n: i18n || {},
    } as IElementModel | TElementModelWithPosition,
    nextElementId,
  ];
}

function createChildElement(
  getElementType: (t: string) => IElementType,
  nextElementId: number,
  child: TDefaultElementChild,
): [IElementModel, number] {
  if (typeof child === "string") {
    return createElement(getElementType, getElementType(child), nextElementId);
  }

  const { type, ...childElement } = child;
  const elementType = getElementType(type.name);

  return createElement(
    getElementType,
    elementType,
    nextElementId,
    childElement,
    childElement?.position,
    childElement?.name ?? elementType.name,
  );
}

/**
 * TODO:
 * This function can be optimized if we either index the updatedElements by page, or we build (and mantain) an index
 * of elementId-pageId
 */
export function getUpdatedUiDefinition(
  original: Definition,
  pages: Record<string, IPage>,
  updatedElements: TUpdatedElements,
  updatedLayoutDefinition: LayoutDefinition | null,
  updatedMenu: IMenuItem[] | null,
): Definition {
  return {
    ...original,
    menu: updatedMenu ?? original.menu,
    layout: {
      ...original.layout,
      definition: updatedLayoutDefinition ?? original.layout.definition,
    },
    pages: Object.keys(pages).reduce((p, id) => {
      const page = pages[id];
      return {
        ...p,
        [id]: {
          ...page,
          element: getUpdatedElement(page.element, updatedElements),
        },
      };
    }, pages),
  };
}

function getUpdatedElement(
  element: IElementModel,
  updatedElements: TUpdatedElements,
): IElementModel | TElementModelWithPosition {
  const updated = updatedElements[element.id] || element;
  const updatedChildren: TElementChildren = {};
  for (const childName of Object.keys(updated.children)) {
    const child = updated.children[childName];
    if ("element" in child) {
      updatedChildren[childName] = {
        element: getUpdatedElement(
          (child as IElementModelSingleChild).element,
          updatedElements,
        ),
      };
    } else {
      updatedChildren[childName] = {
        elements: (child as IElementModelArrayChild).elements.map((e) =>
          getUpdatedElement(e, updatedElements),
        ),
      };
    }
  }
  return {
    ...updated,
    children: updatedChildren,
  };
}
