import { selectorScoper } from "core/utils/redux";
import { IState } from "./types";

export function buildSelectors(path: string[]) {
  const scopeSelector = selectorScoper<IState>(path);

  const value = (state: any) => scopeSelector(state).value;

  return {
    value,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
