import React, { memo } from "react";

import { CodeBlock } from "../../common/CodeBlock";

interface IOwnProps {
  value: string;
}

type IProps = IOwnProps;

const customStyle = {
  display: "inline-flex",
  padding: "0 .2em",
  margin: 0,
  fontSize: "90%",
  backgroundColor: "hsla(214, 100%, 97%, .9)",
  borderRadius: "3px",
};

export const InlineCode = memo<IProps>(({ value }) => (
  <CodeBlock value={value} customStyle={customStyle} />
));
