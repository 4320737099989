import React, { memo, useEffect, useMemo, useState } from "react";
import { FixedSizeList, areEqual } from "react-window";
import isNumber from "lodash/isNumber";
import isDate from "lodash/isDate";
import isArray from "lodash/isArray";
import deepEqual from "fast-deep-equal";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { IFixedRow, IObjectViewField } from "core";
import { getTranslatedText } from "core/utils/element";
import { useSessionContext } from "core/session";

import { AlertBox } from "../AlertBox";
import IconButton from "../IconButton";

import { Column, TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import { stringToDateObject } from "utils/string";

export type HighlightedRow = string[];

export type HighlightedRows = Record<string, HighlightedRow>;

type ITableFormProps = {
  data: Record<string, unknown>[];
  fields: IObjectViewField[];
  errors: string | Record<string, string>;
  title?: string;
  actionsDisabled?: boolean;
  onRowAdd: (newRow: Record<string, unknown>) => void;
  onRowDelete: (oldIndex: number) => void;
  onRowUpdate: (rowIndex: number, newRow: Record<string, unknown>) => void;
  identifierFieldName: string;
  highlightedCells?: HighlightedRows;
};
type IRow = IFixedRow<any>;

const itemSize = 64;

export const gridTemplateColumns = (
  quantity: number,
  actionsDisabled?: boolean,
) =>
  actionsDisabled
    ? `repeat(${quantity}, minmax(100px, 1fr))`
    : `110px repeat(${quantity}, minmax(100px, 1fr))`;

const getEmptyRowData = (fields: IObjectViewField[]) =>
  fields.reduce((result, field) => ({ ...result, [field.name]: null }), {});

const validateValueByType = (type: string, required: boolean, value: any) => {
  switch (type) {
    case "boolean":
      return required ? value !== undefined && value !== null : true;
    case "number":
      return required ? isNumber(value) : true;
    case "json":
      return required ? value && Object.keys(value).length : true;
    case "date":
    case "dateTime":
    case "time":
      return required ? isDate(stringToDateObject(value)) : true;
    default:
      return required ? !!value?.trim().length : true;
  }
};

export const TableForm = memo<ITableFormProps>(
  ({
    title,
    actionsDisabled,
    data,
    fields,
    onRowDelete,
    onRowAdd,
    onRowUpdate,
    errors,
    identifierFieldName,
    highlightedCells,
  }) => {
    const { language } = useSessionContext();
    const [editableRowId, setEditableRowId] = useState<number | null>(null);
    const [rows, setRows] = useState<Record<string, unknown>[]>(data);

    useEffect(() => {
      !deepEqual(data, rows) && setRows(data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleDeleteRow = (deleteId: number) => {
      data.length === rows.length
        ? onRowDelete(deleteId)
        : setRows(rows.filter((_, index) => index !== deleteId));
      setEditableRowId(null);
    };

    const handleRowSelect = (rowIndex: number | null) =>
      setEditableRowId(rowIndex);

    const validateField = (field: IObjectViewField) => (nextValue: any) => {
      const {
        generalType: { type, isArray: valueIsArray },
        nullable,
      } = field;
      const required = nullable === false;

      if (valueIsArray) {
        return required
          ? nextValue && isArray(nextValue) && !!nextValue.length
          : true;
      } else {
        return validateValueByType(type, required, nextValue);
      }
    };

    const columns = useMemo(
      () =>
        fields.map((field) => ({
          field: field.name,
          title: getTranslatedText(language, field.i18n, "title") ?? field.name,
          generalType: field.generalType,
          validate: validateField(field),
          required: field.nullable === false,
        })) as Column[],
      [fields, language],
    );

    const handleSubmitRow = (newRow: Record<string, unknown>) => {
      data.length === rows.length && editableRowId !== null
        ? onRowUpdate(editableRowId, newRow)
        : onRowAdd(newRow);
      setEditableRowId(null);
    };

    const Row = memo<IRow>(({ data: rowsData, index, style }) => {
      const item = rowsData[index];
      const identifierValue = item[identifierFieldName];

      return (
        <TableRow
          data={item}
          style={style}
          onDelete={handleDeleteRow}
          onRowSubmit={handleSubmitRow}
          selectRow={handleRowSelect}
          actionsDisabled={actionsDisabled}
          rowMetaData={{
            id: index,
            active: Boolean(editableRowId === index),
            columns,
            isNew: data.length < rows.length,
            disableBtn: editableRowId !== index && editableRowId !== null,
            highlight:
              identifierValue !== null && identifierValue !== undefined
                ? highlightedCells?.[identifierValue]
                : undefined,
            highlightRow:
              identifierValue === null || identifierValue === undefined,
          }}
        />
      );
    }, areEqual);

    const handleAddRow = () => {
      const newRow = getEmptyRowData(fields);

      setEditableRowId(rows.length);
      setRows((prevRows) => [...prevRows, newRow]);
    };

    return (
      <Box
        width="100%"
        height="100%"
        overflow="auto"
        position="relative"
        border={1}
        borderColor="action.disabled"
        borderRadius="borderRadius"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          py={1}
          zIndex="1"
          top={0}
          position="sticky"
          bgcolor="background.paper"
        >
          <Typography variant="h6">{title}</Typography>
          {!actionsDisabled && (
            <Box>
              <IconButton
                icon="add"
                tooltip={"Add Row"}
                onClick={handleAddRow}
                disabled={!(editableRowId === null)}
              />
            </Box>
          )}
        </Box>
        <Box>
          <TableHeader columns={columns} actionsDisabled={actionsDisabled} />
          {errors && typeof errors === "string" && (
            <AlertBox message={errors} />
          )}
          {!!rows.length && (
            <FixedSizeList
              height={itemSize * Math.min(rows.length, 10)}
              itemCount={rows.length}
              itemSize={itemSize}
              width="100%"
              itemData={rows}
            >
              {Row}
            </FixedSizeList>
          )}
        </Box>
      </Box>
    );
  },
);
