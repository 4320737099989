import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

import { stringToDateObject } from "utils/string";

import { DateTimeInput } from "../../types";

export function buildValue(value: string | Date | null) {
  if (typeof value === "string") {
    return stringToDateObject(value);
  }
  return value;
}

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: DateTimeInput,
) {
  const selectValue = dataSource.createFieldValueSelector(fieldPath, {
    defaultValue: null,
  });
  const value = (state: any): Date | null => {
    const date = selectValue(state);

    return typeof date === "string" ? stringToDateObject(date) : date;
  };

  const disabled = () =>
    dataSource.isReadOnly || Boolean(element.config.disabled);

  const errors = dataSource.createFieldErrorSelector(fieldPath);

  return {
    value,
    disabled,
    errors,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
