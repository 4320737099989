import { createContext, useContext } from "react";
import { Align } from ".";

export interface ITableContext {
  alignment: Align[];
}

const TableContext = createContext<ITableContext>({
  alignment: [],
});

export function useTableContext() {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error(
      "useTableContext must be used within a TableContext.Provider",
    );
  }
  return context;
}

export const TableContextProvider = TableContext.Provider;
export default TableContext;
