import { connect } from "react-redux";

import { IElementComponentProps, createColorSelector } from "core";
import { connectErrorHandlerUtils } from "core/utils/react-redux";
import Component from "./component";
import { TextField } from "./types";

const mapStateToProps = (
  state: any,
  {
    element: { config },
  }: IElementComponentProps<Record<string, unknown>, TextField>,
) => ({
  text: config.text(state),
  color: createColorSelector(config.color)(state),
  background: createColorSelector(config.background)(state),
});

export default connect(
  connectErrorHandlerUtils.enhanceMapStateToProps(mapStateToProps),
)(connectErrorHandlerUtils.enhanceComponent(Component));
