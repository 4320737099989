import { connect } from "react-redux";

import { AuditsPage as APComponent } from "./component";

import { actions, selectors } from "./reduxModule";

import { actions as sessionActions } from "core/session/reduxModule";

export const mapStateToProps = (state: any) => ({
  auditsList: selectors.auditsList(state),
  loading: selectors.loading(state),
  error: selectors.error(state),
  schemas: selectors.schemas(state),
  tables: selectors.tables(state),
});

export const mapDispatchToProps = {
  ...actions,
  ...sessionActions,
};

export const TablesAuditPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(APComponent);
