import deepEqual from "fast-deep-equal";
import { createSelector } from "reselect";

import { selectorScoper } from "../../utils/redux";
import * as routerSelectors from "../../router/reduxModule/baseSelectors";
import { selectors as sessionSelectors } from "../../session/reduxModule/selectors";
import { MODULE_NAME } from "./constants";
import { IState } from "./types";

const scopeSelector = selectorScoper<IState>([MODULE_NAME]);

const reusedSelectors = {
  initialAppMetadata: (state: any) =>
    sessionSelectors.initialAppMetadata(state),
  newPages: (state: any) => scopeSelector(state).newPages,

  updatedElements: (state: any) => scopeSelector(state).updatedElements,

  updatedLayoutDefinition: (state: any) =>
    scopeSelector(state).updatedLayoutDefinition,
  updatedMenu: (state: any) => scopeSelector(state).updatedMenu,
};

const isLayoutChanged = createSelector(
  [
    reusedSelectors.initialAppMetadata,
    reusedSelectors.updatedElements,
    reusedSelectors.updatedLayoutDefinition,
    reusedSelectors.updatedMenu,
    reusedSelectors.newPages,
  ],
  (
    appMetadata,
    updatedElements,
    updatedLayoutDefinition,
    updatedMenu,
    newPages,
  ) => {
    const result =
      !!Object.keys({
        ...updatedElements,
      }).length ||
      (!!Object.keys({
        ...newPages,
      }).length &&
        !deepEqual(newPages, appMetadata?.release.definition.pages)) ||
      (updatedLayoutDefinition &&
        !deepEqual(
          updatedLayoutDefinition,
          appMetadata?.release.definition.layout.definition,
        )) ||
      (updatedMenu &&
        !deepEqual(updatedMenu, appMetadata?.release.definition.menu));
    return result;
  },
);

const activeSavePointId = (state: any) => {
  const { uiSavePoints, uiReleases } = scopeSelector(state);
  const releaseId = sessionSelectors.appMetadata(state)?.release.id;
  const savePointId = uiReleases?.find((release) => release.id === releaseId)
    ?.savePoint.id;
  const activePoint = uiSavePoints?.find((point) => point.id === savePointId);

  return activePoint?.id;
};

export const selectors = {
  ...reusedSelectors,
  editModeOn: (state: any) => scopeSelector(state).editModeOn,
  selectedByPageId: (state: any) => scopeSelector(state).selectedByPageId,
  draggableElementParams: (state: any) =>
    scopeSelector(state).draggableElementParams,
  isResizing: (state: any) => scopeSelector(state).isResizing,
  isDragging: (state: any) => scopeSelector(state).isDragging,
  selected: (state: any) => {
    const page = routerSelectors.page(state);
    return page ? scopeSelector(state).selectedByPageId[page.id] || null : null;
  },
  activeGrid: (state: any) => {
    const page = routerSelectors.page(state);
    return page ? scopeSelector(state).activeGrid[page.id] || null : null;
  },
  nextElementId: (state: any) => scopeSelector(state).nextElementId,
  uiReleases: (state: any) => scopeSelector(state).uiReleases,
  uiSavePoints: (state: any) => scopeSelector(state).uiSavePoints,
  viewList: (state: any) => scopeSelector(state).viewList,
  errors: (state: any) => scopeSelector(state).errors,
  saving: (state: any) => scopeSelector(state).saving,
  saveError: (state: any) => scopeSelector(state).saveError,
  releasing: (state: any) => scopeSelector(state).releasing,
  isLayoutChanged: (state: any) => isLayoutChanged(state),
  creatingPage: (state: any) => scopeSelector(state).creatingPage,
  newRoutes: (state: any) => scopeSelector(state).newRoutes,
  usedUrls: (state: any) => scopeSelector(state).usedUrls,
  activeSavePointId: (state: any) => activeSavePointId(state),
};

export type Selectors = typeof selectors;
