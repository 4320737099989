import { lazy } from "react";

import { IRawElementType } from "core";
import { withLazyLoading } from "../helpers/HOC/LazyLoading";
import { reduxModuleFactory } from "./reduxModule";
import {
  AdvancedGoogleMapsConfig,
  advancedGoogleMapsSelectorTypes,
} from "./types";
import { GoogleMapsEditor, defaultElement, editorMetadata } from "./editor";

export const Component = withLazyLoading(
  lazy(() => import("./container")),
  true,
);

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "advanced_google_maps",
  component: Component,
  editable: true,
  configType: AdvancedGoogleMapsConfig,
  selectorTypes: advancedGoogleMapsSelectorTypes,
  editorMetadata,
  defaultElement,
  editorComponent: GoogleMapsEditor,
};

export default elementType;
