import React, { memo } from "react";

import { IElementComponentProps } from "core";
import { ColorField } from "./types";
import { useStyles } from "./style";
import { NON_BREAKING_SPACE } from "../common/utils";

interface IProps extends IElementComponentProps<{}, ColorField> {
  value: string | null;
}

const DefaultColorField = memo<IProps>(({ value }) => {
  const classes = useStyles({ value: value! });

  if (value) {
    const checkColor = () => {
      if (value.match(/#/) || value.match(/rgb/) || value.match(/rgba/)) {
        return <div className={classes.colorField} />;
      } else {
        return null;
      }
    };

    return (
      <div className={classes.rowField}>
        {checkColor()}
        {value}
      </div>
    );
  }

  return <span>{NON_BREAKING_SPACE}</span>;
});

export default DefaultColorField;
