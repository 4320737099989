import { IDefaultElement } from "core/types/element";
import { SubFormTableConfig } from "../types";

export const defaultElement: IDefaultElement<SubFormTableConfig> = {
  config: {
    dataSource: {
      elementId: "",
      fieldPath: [],
    },
    fields: [],
  },
  i18n: {
    en: {
      label: "",
    },
  },
};
