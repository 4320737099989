import { TableDataSourceImplementation } from "elementInterfaces";
import { StateChangeDropDown } from "../types";

export function buildActions(
  element: StateChangeDropDown,
  tableDataSource: TableDataSourceImplementation,
) {
  const { dataSource } = element.config;

  const actions = {
    changeValue: (value: any) =>
      tableDataSource.updateRow(element.props.data, {
        [dataSource.fieldName]: value,
      }),
  };

  return {
    actions,
  };
}

type R = ReturnType<typeof buildActions>;
export type Actions = R["actions"];
