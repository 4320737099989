import React, { memo, useState } from "react";

import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
} from "core/editor";
import {
  AdvancedComponent,
  DataSourceComponent,
  MultiSelectionComponent,
  TranslationComponent,
} from "./components";

export const AutocompleteInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);

  const changeLanguage = (l: Language) => setLang(l);

  return (
    <>
      <FormInputDataSourceEditorComponent language={lang} />
      <DataSourceComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent />
      <AdvancedComponent />
      <MultiSelectionComponent />
    </>
  );
});
