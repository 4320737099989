import { buildSimpleUncontrolledInputModule } from "core/utils/buildSimpleUncontrolledInputModule";
import { uncontrolledValueSelector } from "./selectors";
import { IReduxModuleFactoryArgs } from "core";
import { DateTimeInput } from "../../types";

const INITIAL_STATE = {
  value: null,
  errors: null,
};

export function uncontrolledReduxModuleFactory({
  element,
  path,
}: IReduxModuleFactoryArgs<DateTimeInput>) {
  const uncontrolledModule = buildSimpleUncontrolledInputModule(
    element,
    path,
    INITIAL_STATE,
  );

  return {
    ...uncontrolledModule,
    selectors: {
      ...uncontrolledModule.selectors,
      value: uncontrolledValueSelector(uncontrolledModule.selectors.value),
    },
  };
}
