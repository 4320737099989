import { IDefaultElement } from "core/types/element";
import { GeoJSONInputConfig } from "../types";

export const defaultElement: IDefaultElement<GeoJSONInputConfig> = {
  i18n: {
    en: {
      label: "",
    },
  },
};
