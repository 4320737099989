import React, { memo, useCallback, useMemo, useState } from "react";
import { Controller } from "react-hook-form";

import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useFilterContext } from "../FilterContext";
import { useTableTranslation } from "../../translation";
import IconButton from "../../../common/IconButton";
import { useHookFormContext } from "../../../common/HookForm";
import { useStyles } from "../style";
import { IFilterRuleWithPath } from "../types";
import { Field } from "./Field";
import { Operator } from "./Operator";
import { Value } from "./Value";

type IProps = IFilterRuleWithPath;

const FilterRule = memo<IProps>(({ path, field, operator, value }) => {
  const {
    control,
    getValues,
    errors,
    clearError,
    setValue,
  } = useHookFormContext();

  const { removeFilter, fields } = useFilterContext();
  const { invalidError, deleteRuleButtonDescription } = useTableTranslation();
  const { flexWrapper, marginRight, deleteBtn } = useStyles();

  const error = errors[path.toString()] as any;

  const [ruleHasValue, setHasValue] = useState<boolean>(
    Boolean(!operator?.includes("is")),
  );

  const [fieldName, setFieldName] = useState<string>(field.name);

  const getField = useCallback(
    (fName: string) => fields.find((f) => f.name === fName),
    [fields],
  );
  const currentField = useMemo(() => getField(fieldName) || field, [
    fieldName,
    field,
    getField,
  ]);

  const {
    name,
    operators,
    input: { type },
  } = currentField;

  const onOperatorChange = ([ev]: any) => {
    const {
      target: { value: newValue },
    } = ev;
    const withoutValue = newValue.includes("is");
    if (ruleHasValue !== !withoutValue) {
      setHasValue(!withoutValue);
    }
    if (withoutValue) {
      setValue(`[${path}].value`, "");
    }
    return newValue;
  };

  const onFieldChange = ([ev]: any) => {
    const {
      target: { value: newValue },
    } = ev;
    const newField = getField(newValue);
    const values = getValues();
    setFieldName(newValue);
    if (newField) {
      if (!newField.operators.includes(values[`[${path}].operator`])) {
        setValue(`[${path}].operator`, newField.operators[0]);
      }
      if (type !== newField.input.type) {
        setValue(`[${path}].value`, "");
      }
    }

    return newValue;
  };

  const renderDefaultValue = (
    <Controller
      as={
        <TextField
          className={marginRight}
          margin="dense"
          type={type}
          disabled={true}
        />
      }
      name={`[${path}].defaultValue`}
      control={control}
      defaultValue=""
    />
  );

  const handleRemove = () => {
    if (error) {
      clearError([path.toString()]);
    }
    removeFilter({ path, values: getValues() });
  };

  return (
    <>
      <div className={flexWrapper}>
        <Field name={name} onChange={onFieldChange} path={path} />
        <Operator
          operator={operator}
          path={path}
          operators={operators}
          onChange={onOperatorChange}
        />
        {ruleHasValue ? (
          <Value
            path={path}
            value={value}
            error={Boolean(error?.value)}
            type={type}
          />
        ) : (
          renderDefaultValue
        )}
        <IconButton
          onClick={handleRemove}
          icon="delete_outline"
          color="error.main"
          className={deleteBtn}
          tooltip={deleteRuleButtonDescription}
          disableRipple={true}
        />
      </div>
      {error && ruleHasValue && (
        <>
          {Object.values(error).map((er: any) => (
            <FormHelperText
              error={true}
              component="span"
              key={`${er.type}-${path.toString()}`}
            >
              {er.message.length ? er.message : invalidError}
            </FormHelperText>
          ))}
        </>
      )}
    </>
  );
});

FilterRule.displayName = "FilterRule";

export default FilterRule;
