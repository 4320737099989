import React, { memo, useCallback } from "react";
import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";
import { FieldConfig, SubFormTable } from "./types";

import TableForm from "../common/TableForm";

type Props = ConnectedReduxModuleProps<ReduxModule, SubFormTable>;
/**
 * replace all `NaN` values with `null` in an object
 * @param data
 */
function replaceNaN(data: Record<string, unknown>) {
  const newData = { ...data };
  for (const key in newData) {
    const value = newData[key];
    if (typeof value === "number" && isNaN(value)) {
      newData[key] = null;
    }
  }
  return newData;
}

function fixRowData(data: Record<string, unknown>, fieldConfig: FieldConfig[]) {
  // set null for all columns
  const defaultData = fieldConfig.reduce(
    (acc, next) => ({ ...acc, [next.name]: null }),
    {},
  );
  return { ...defaultData, ...replaceNaN(data) };
}

const DefaultSubFormTable = memo<Props>((props) => {
  const {
    changeValue,
    value: data,
    element: {
      config: {
        fields,
        dataSource: { fieldPath },
      },
      i18n: { label },
    },
    errors,
    disabled,
    highlightedCells,
    identifierFieldName,
  } = props;

  const handleDataChange = useCallback(
    (newData: Record<string, unknown>[]) => {
      changeValue(newData);
    },
    [changeValue],
  );

  if (!fields.length) {
    return null;
  }

  const handleRowAdd = (newRow: Record<string, unknown>) => {
    const nextRow = fixRowData(newRow, fields);
    const newData = [...data, nextRow];

    handleDataChange(newData);
  };

  const handleRowUpdate = (rowId: number, newRow: Record<string, unknown>) => {
    const nextRow = fixRowData(newRow, fields);
    const newData = [...data];

    newData[rowId] = nextRow;

    handleDataChange(newData);
  };

  const handleRowDelete = (oldIndex: number) => {
    const newData = [...data];

    newData.splice(oldIndex, 1);

    handleDataChange(newData);
  };

  return (
    <TableForm
      title={label || (fieldPath[0] as string)}
      data={data}
      fields={fields}
      onRowAdd={handleRowAdd}
      onRowDelete={handleRowDelete}
      onRowUpdate={handleRowUpdate}
      errors={errors}
      actionsDisabled={disabled}
      identifierFieldName={identifierFieldName}
      highlightedCells={highlightedCells}
    />
  );
});

export default DefaultSubFormTable;
