import React from "react";

import { connect } from "react-redux";

import {
  OptionsObject,
  SnackbarProviderProps,
  WithSnackbarProps,
  withSnackbar,
} from "notistack";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";

import { actions } from "core/session/reduxModule/actions";

export const SNACKBAR_ANCHOR_ORIGIN: SnackbarProviderProps["anchorOrigin"] = {
  horizontal: "center",
  vertical: "top",
};

export const SnackbarDefaultAction = (key: OptionsObject["key"]) => {
  const mapDispatchToProps = {
    removeSnackbar: actions.removeSnackbar,
  };

  const Component = withSnackbar<WithSnackbarProps>(
    connect(
      null,
      mapDispatchToProps,
    )(({ closeSnackbar, removeSnackbar }: any) => {
      const onClick = () => {
        closeSnackbar(key);
        removeSnackbar(key);
      };

      return (
        <Tooltip title="Dismiss">
          <IconButton
            color="inherit"
            size="small"
            centerRipple={true}
            onClick={onClick}
          >
            <ClearIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      );
    }),
  );

  return <Component />;
};
