import React, { memo } from "react";

import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { Language } from "core/types";
import {
  Section,
  TranslationEditor,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { UntransformedArrayTextInputConfig } from "../../types";
import { useArrayTextInputEditorTranslation } from "../translation";

type TranslationComponentProps = {
  language: Language;
  handleChangeLanguage: (l: Language) => void;
};

export const TranslationComponent = memo<TranslationComponentProps>(
  ({ language, handleChangeLanguage }) => {
    const {
      elementModel: { i18n },
      changeTranslation,
    } = useElementEditorContext<UntransformedArrayTextInputConfig>();
    const editorTranslation = useEditorTranslation();
    const translation = useArrayTextInputEditorTranslation();

    return (
      <Section
        title={editorTranslation.translationTitle}
        wrapped={true}
        cardActions={
          <LanguageSwitch
            language={language}
            changeLanguage={handleChangeLanguage}
            colorVariant="dark"
            fullWidth={true}
          />
        }
      >
        <TranslationEditor
          translationKey="label"
          i18n={i18n}
          changeTranslation={changeTranslation}
          label={translation.labelLabel}
          language={language}
        />
      </Section>
    );
  },
);
