import { OwnConfig } from "./types";
import { RequiredConfigProperties } from "core/types";

export const DEFAULT_CONFIG: RequiredConfigProperties<OwnConfig> = {
  allowDelete: true,
  allowDrag: true,
  allowLine: true,
  allowPolygon: true,
  allowRectangle: true,
  tileLayerUrl: null,
  maximumFeatures: null,
};
