import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  MarkdownInputConfig,
  MarkdownInputTranslationKeys,
  markdownInputSelectors,
} from "./types";
import { MarkdownInputEditor, defaultElement, editorMetadata } from "./editor";
import { ElementGroup, IRawElementType } from "core";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_markdown_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: MarkdownInputConfig,
  selectorTypes: markdownInputSelectors,
  translationKeys: MarkdownInputTranslationKeys,
  editorComponent: MarkdownInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
