import React, { memo } from "react";

import Markdown from "react-markdown";

import classNames from "classnames";

import useStyles from "./styles";

import { LayoutProps } from "core";
import { useFooterTranslation } from "./translations";

const DEFAULT_MENU_WIDTH = "240px";
const DEFAULT_TOOLBAR_VARIANT = "dense";

interface IProps {
  openState: boolean;
  layout?: LayoutProps["appMetadata"]["release"]["definition"]["layout"];
}

export const Footer = memo<IProps>(({ openState, layout }) => {
  const menuWidth = layout?.definition.menu?.width ?? DEFAULT_MENU_WIDTH;
  const toolbarVariant =
    layout?.definition.toolbar?.variant ?? DEFAULT_TOOLBAR_VARIANT;
  const classes = useStyles({ menuWidth, toolbarVariant });

  const footerClassNames = classNames(classes.footer, classes["content-left"], {
    [classes.contentShift]: openState,
    [classes["contentShift-left"]]: openState,
  });

  const { defaultFooterText } = useFooterTranslation();

  const textSource = layout?.definition.footer?.text ?? defaultFooterText;

  return (
    <footer className={footerClassNames}>
      <Markdown source={textSource} />
    </footer>
  );
});

Footer.displayName = "Footer";
