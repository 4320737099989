import request from "superagent";
import { Definition } from "core/types";

import { getResponseError, withAuthHeader, withCommonHeaders } from "utils/api";
import { IUICreateForm } from "staticPages/admin/pages/apps/pages/create/types";
import { IUIGenerateForm } from "staticPages/admin/pages/apps/pages/generate/types";
import { IFileForm } from "staticPages/admin/pages/files/pages/upload/types";
import { IUserForm } from "staticPages/admin/pages/users/pages/create/types";
import { IEditUserForm } from "staticPages/admin/pages/users/pages/edit/types";
import { ISettingsConfig } from "staticPages/admin/pages/settings/reduxModule/types";
import { IUIEditForm } from "staticPages/admin/pages/apps/pages/edit/types";
import { FILE_STORAGE_PREFIX } from "services/api/constants";

export default class AdminService {
  private static _instance: AdminService;

  public static getInstance(): AdminService {
    if (!AdminService._instance) {
      AdminService._instance = new AdminService();
    }

    return AdminService._instance;
  }

  public async getAllUi(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/ui/admin/list"),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async generateUi(token: string, uiData: IUIGenerateForm) {
    try {
      const data = {
        ...uiData,
        objectViews: uiData.objectViews.map((v) => ({ id: v })),
      };

      const { body } = await withCommonHeaders(
        request.post("/app/ui/generate").send(data),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createUi(token: string, uiData: IUICreateForm) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/ui").send(uiData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async restoreUi(
    uiName: string,
    savePointId: string,
    { token }: { token?: string } = {},
  ) {
    try {
      const res = await withCommonHeaders(
        request
          .post(`/app/ui/${uiName}/save/${savePointId}/restore`)
          .send({ uiName, savePointId }),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async saveUi(
    name: string,
    definition: Definition,
    description: string,
    { token }: { token?: string } = {},
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/ui/${name}/save`).send({ definition, description }),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editUi(name: string, editData: IUIEditForm, token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.put(`/app/ui/${name}`).send(editData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async releaseUi(
    uiName: string,
    data: { name: string; description: string },
    { token }: { token?: string } = {},
  ) {
    try {
      const res = await withCommonHeaders(
        request.post(`/app/ui/${uiName}/release`).send(data),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteUI(uiName: string, { token }: { token?: string } = {}) {
    try {
      const res = await withCommonHeaders(
        request.delete(`/app/ui/${uiName}`),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async publishReleaseUi(
    uiName: string,
    releaseName: string,
    { token }: { token?: string } = {},
  ) {
    try {
      const res = await withCommonHeaders(
        request.post(`/app/ui/${uiName}/release/${releaseName}/publish`),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllFiles({ token }: { token?: string } = {}) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/storage/file"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async uploadFile(token: string, fileData: IFileForm) {
    try {
      let req = request.post("/app/storage/file");

      req = withAuthHeader(req, token);

      const { body } = await req
        .field("groupName", fileData.groupName)
        .field("typeGroupName", fileData.typeGroupName)
        .attach("file", fileData.file);

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteFile(token: string, fileName: string) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`${FILE_STORAGE_PREFIX}${fileName}`),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFileGroups(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/storage/groups"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFileTypes(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/storage/types"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllRoles(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/roles"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getTableAudit(token: string, query?: Record<string, string>) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/audit/table").query({ ...query }),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getUserAudit(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/audit/user-events"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getUser(userId: string, token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/users/integrated/${userId}`),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllUsers(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/users/integrated"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async saveUser(token: string, user: IUserForm) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/users/integrated").send(user),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editUser(userId: string, user: IEditUserForm, token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.put(`/app/users/integrated/${userId}`).send(user),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteUser(userId: string, token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/users/integrated/${userId}`).send(),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editLoginConfig(config: ISettingsConfig, token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.patch("/app/auth/config/login").send(config),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }
}
