import React, { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { formatDistanceToNow, formatRelative } from "date-fns";

import { actions, selectors } from "./reduxModule";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { IAdminUi } from "core";
import useStyles from "../../styles";
import { Table, TableRow } from "../../common";
import { useTranslation } from "core/session";
import { Align } from "../../common/components/Table";

const tableConfig: Array<{ align: Align; title: string; name: string }> = ([
  { title: "Name", align: "left" },
  { title: "Title", align: "left" },
  { title: "Description", align: "left" },
  { title: "Modified", align: "left" },
  { title: "Owner", align: "left" },
  { title: "Published release", align: "center" },
  { title: "Actions", align: "center" },
] as const).map((config) => ({ ...config, name: config.title.toLowerCase() }));

type Row = {
  ui: IAdminUi;
  handleClick: (props: IAdminUi) => void;
  handleDelete: (uiName: string) => void;
  handleExport: (uiName: string) => void;
};
const Row = memo<Row>(({ ui, handleClick, handleDelete, handleExport }) => {
  const {
    id,
    name,
    description,
    lastUpdated,
    role,
    i18nPublished,
    publishedRelease,
    i18nLatest,
  } = ui;

  const onClick = () => handleClick(ui);
  const onDelete = () => handleDelete(name);
  const onExport = () => handleExport(name);

  const publishedTranslated = useTranslation(i18nPublished);
  const latestTranslated = useTranslation(i18nLatest);

  const publishedLabel = publishedTranslated.label;
  const latestLabel = latestTranslated.label;

  // this is needed for the basic model builder, which is implemented
  // as an app
  const isModelBuilder = name === "builder";

  return (
    <TableRow rowId={id}>
      <Typography component="span">{name}</Typography>

      <Typography component="span">
        {publishedLabel}
        {publishedLabel !== latestLabel ? (
          <Tooltip title={"Unpublished"} arrow>
            <Box
              component="span"
              color="text.disabled"
              fontWeight="fontWeightBold"
              fontSize={12}
              marginLeft={1}
            >
              {latestLabel}{" "}
            </Box>
          </Tooltip>
        ) : null}
      </Typography>

      <Typography>{description}</Typography>

      <Tooltip title={formatRelative(new Date(lastUpdated), new Date())}>
        <Typography component="span">
          {formatDistanceToNow(new Date(lastUpdated), {
            addSuffix: true,
          })}
        </Typography>
      </Tooltip>

      <Typography>{role}</Typography>

      <Typography>{publishedRelease.versionName}</Typography>

      <Box display="flex" alignItems="center" justifyContent="center">
        <IconButton
          tooltip={isModelBuilder ? "Can't edit model builder" : "Edit"}
          icon="create"
          color="primary"
          data-testid="admin-apps-action-edit"
          href={`/admin/apps/edit/${name}`}
          disabled={isModelBuilder}
        />
        <IconButton
          tooltip="Start"
          icon="slideshow"
          color="primary"
          data-testid="admin-apps-action-start"
          onClick={onClick}
        />
        <IconButton
          tooltip={isModelBuilder ? "Can't export model builder" : "Export"}
          icon="cloud_download"
          onClick={onExport}
          color="primary"
          data-testid="admin-apps-action-export"
          disabled={isModelBuilder}
        />
        <IconButton
          tooltip={isModelBuilder ? "Can't delete model builder" : "Delete"}
          icon="delete_outline"
          onClick={onDelete}
          color="error.main"
          data-testid="admin-apps-action-delete"
          disabled={isModelBuilder}
        />
      </Box>
    </TableRow>
  );
});

export const AppsPage = memo(() => {
  const dispatch = useDispatch();
  const uiList = useSelector(selectors.uiList);
  const loading = useSelector(selectors.loading);
  const error = useSelector(selectors.error);
  const {
    horizontallyCenter,
    inlineAndEndFlex,
    applicationsHeaderButton,
  } = useStyles();

  const handleAppClick = useCallback(
    (app: IAdminUi) => dispatch(actions.changeUi(app)),
    [dispatch],
  );
  const handleDeleteApp = useCallback(
    (uiName: string) => dispatch(actions.deleteUi(uiName)),
    [dispatch],
  );
  const handleDataReload = () => dispatch(actions.load());
  const handleExportJsonApp = useCallback(
    (uiName: string) => dispatch(actions.exportUi(uiName)),
    [dispatch],
  );

  const rows = useMemo(
    () =>
      uiList?.map((ui) => (
        <Row
          key={ui.id}
          ui={ui}
          handleClick={handleAppClick}
          handleDelete={handleDeleteApp}
          handleExport={handleExportJsonApp}
        />
      )),
    [uiList, handleAppClick, handleDeleteApp, handleExportJsonApp],
  );

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">Applications</Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={inlineAndEndFlex}>
        <Button
          color="primary"
          href="/admin/apps/generate"
          iconLeft="add"
          label="GENERATE"
          className={applicationsHeaderButton}
        />
        <Button
          color="primary"
          href="/admin/apps/create"
          iconLeft="cloud_upload"
          label="IMPORT"
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          alignment={tableConfig}
          rows={rows}
          headers={tableConfig}
          onDataReload={handleDataReload}
          loading={loading}
          error={error}
        />
      </Grid>
    </Grid>
  );
});
