import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import { actions as sessionActions } from "core/session/reduxModule/actions";
import { actions as routerActions } from "core/router/reduxModule/actions";
import { actions, types } from "./actions";
import { AllServices } from "core/buildStore";
import { IObjectView, IUi } from "core";
import AdminService from "services/admin";
import { ActionTypes } from "./types";

const services = AdminService.getInstance();

function* addUiSaga(action: ActionTypes["generateUI"]) {
  const token = yield select(sessionSelectors.token);

  try {
    yield call(services.generateUi, token, action.payload.ui);
    yield put(
      sessionActions.enqueueSnackbar({
        message: "UI generated successfully",
        options: {
          variant: "success",
        },
      }),
    );
    const allServices: AllServices = yield getContext("services");
    const uiList = (yield call(allServices.api.loadUIList, {
      token,
    })) as IUi[];
    yield put(sessionActions.setUiList(uiList));

    yield put(routerActions.push("/admin/apps"));
  } catch (error) {
    yield put(actions.generateUIError(error.message ?? error.toString()));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "UI was not generated",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

function* getRolesSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const rolesList = yield call(services.getAllRoles, token);

    yield put(actions.getRolesSuccess(rolesList));
  } catch (error) {
    yield put(actions.getRolesError(error.message ?? error.toString()));
  }
}

function* getObjectViewsSaga(action: ActionTypes["getObjectViews"]) {
  const token = yield select(sessionSelectors.token);

  try {
    const allServices: AllServices = yield getContext("services");
    const objectViewList: IObjectView[] = yield call(
      allServices.api.loadViewList,
      { token, role: action.payload.role },
    );

    yield put(actions.getObjectViewsSuccess(objectViewList));
  } catch (error) {
    yield put(actions.getObjectViewsError(error.message ?? error.toString()));
  }
}

export function* saga() {
  yield all([
    yield takeLatest(types.GENERATE_UI, addUiSaga),
    yield takeLatest(types.GET_ROLES, getRolesSaga),
    yield takeLatest(types.GET_OBJECT_VIEWS, getObjectViewsSaga),
  ]);
  yield put(actions.getRoles());
}
