import React, { ChangeEvent, memo, useMemo } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import { ConnectedReduxModuleProps } from "core";
import { getColumnName } from "../common/utils";
import { ReduxModule } from "./reduxModule";
import { SelectOption, StaticSelect } from "./types";

const DefaultStaticSelect = memo<
  ConnectedReduxModuleProps<ReduxModule, StaticSelect>
>(
  ({
    value,
    element: {
      i18n: { label, emptyValueLabel },
      config: { dataSource, disabled, options, nullable },
    },
    changeValue,
  }) => {
    const onChange = ({
      target: { value: nextVal },
    }: ChangeEvent<HTMLInputElement>) => {
      if (nullable && nextVal === undefined) {
        changeValue(null);
      } else {
        changeValue(nextVal);
      }
    };
    const name = getColumnName(dataSource);
    const isOptions = options && !!options.length;
    const selectOptions = useMemo(() => {
      if (isOptions) {
        return (options as SelectOption[]).map(
          ({ label: labelOption, value: valueOption }) => (
            <MenuItem key={valueOption} value={valueOption}>
              {labelOption}
            </MenuItem>
          ),
        );
      } else {
        return null;
      }
    }, [options, isOptions]);

    return (
      <TextField
        label={label}
        fullWidth={true}
        value={value || ""}
        onChange={onChange}
        name={name}
        disabled={disabled || !isOptions}
        select={true}
        InputLabelProps={{ shrink: true }}
      >
        {selectOptions}
        {nullable && (
          <MenuItem key={emptyValueLabel} value="">
            {emptyValueLabel}
          </MenuItem>
        )}
      </TextField>
    );
  },
);

export default DefaultStaticSelect;
