import React, { ChangeEvent, memo, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { formatDistanceToNow, formatRelative } from "date-fns";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

import { ConnectedStaticReduxModuleActionsProps } from "core/types/react";
import { RouterReduxModule } from "core/router/types";
import Button from "elementTypes/common/Button";
import Link from "elementTypes/common/Link";
import { TablesAuditPage } from "./types";
import useStyles from "./styles";
import { IAuditTable } from "./reduxModule/types";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";

import { default as commonStyles } from "staticPages/admin/styles";
import { Table, TableRow } from "staticPages/admin/common";

type Props = ConnectedStaticReduxModuleActionsProps<RouterReduxModule> &
  TablesAuditPage;

type Row = {
  handleClick: (params: IAuditTable) => void;
} & IAuditTable;

const Row = memo<Row>(({ handleClick, ...props }) => {
  const {
    id,
    transactionID,
    userName,
    userID,
    schemaName,
    tableName,
    operation,
    timestamp,
  } = props;

  const onClick = () => handleClick(props);

  return (
    <TableRow rowId={id}>
      <Typography>{transactionID}</Typography>
      <Link
        variant="body2"
        title="View user info"
        href={`/admin/users/view/${userID}`}
      >
        {userID}
      </Link>
      <Typography>{userName}</Typography>
      <Typography>{schemaName}</Typography>
      <Typography>{tableName}</Typography>
      <Typography>{operation}</Typography>
      <Tooltip title={formatRelative(new Date(timestamp), new Date())}>
        <Typography>
          {formatDistanceToNow(new Date(timestamp), {
            addSuffix: true,
          })}
        </Typography>
      </Tooltip>
      <Button color="primary" onClick={onClick} label="Details" />
    </TableRow>
  );
});

export const AuditsPage = memo<Props>(
  ({ auditsList, load, changeFilter, schemas, tables, loading, error }) => {
    const classes = useStyles();
    const [auditSelected, setAuditSelected] = useState<IAuditTable | null>(
      null,
    );

    const handleFilterChange = (filter: string) => (
      _e: ChangeEvent<unknown>,
      value: { name: string } | null,
    ) => changeFilter({ [filter]: value?.name });

    const handleDetailsButtonClick = (audit: IAuditTable) =>
      setAuditSelected(audit);
    const handleDetailsDialogClose = () => setAuditSelected(null);

    const { horizontallyCenter } = commonStyles();

    const titles = [
      "Transaction ID",
      "User ID",
      "Username",
      "Schema Name",
      "Table Name",
      "Operation",
      "Date",
      "Actions",
    ];

    const rows = useMemo(
      () =>
        auditsList.map((audit) => (
          <Row
            key={audit.id}
            {...audit}
            handleClick={handleDetailsButtonClick}
          />
        )),
      [auditsList],
    );

    return (
      <Grid container spacing={2} className={horizontallyCenter}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h5">Audit Tables</Typography>
        </Grid>
        <Grid item={true} xs={12} sm={4} className={classes.filters}>
          <Autocomplete
            className={classes.filter}
            options={schemas.map((schema) => ({ name: schema }))}
            getOptionLabel={(option) => option.name}
            onChange={handleFilterChange("schemaName")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Schema"
                name="schema"
                InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
              />
            )}
          />

          <Autocomplete
            className={classes.filter}
            options={tables.map((table) => ({ name: table }))}
            getOptionLabel={(option) => option.name}
            onChange={handleFilterChange("tableName")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Table"
                name="table"
                InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            rows={rows}
            headers={titles.map((title) => ({
              name: title.toLowerCase(),
              title,
            }))}
            onDataReload={load}
            loading={loading}
            error={error}
          />
        </Grid>
        {auditSelected && (
          <DialogWrapper
            open={true}
            title={"Details"}
            cancelTitle="Close"
            handleClose={handleDetailsDialogClose}
            className={classes.dialog}
            maxWidth={false}
          >
            <ReactDiffViewer
              leftTitle="Before"
              rightTitle="After"
              oldValue={`${JSON.stringify(auditSelected.before, null, 2)}`}
              newValue={`${JSON.stringify(auditSelected.after, null, 2)}`}
              splitView={true}
              compareMethod={DiffMethod.WORDS}
              styles={{
                diffRemoved: {
                  overflowX: "auto",
                  whiteSpace: "pre",
                },
                diffAdded: {
                  overflowX: "auto",
                  whiteSpace: "pre",
                },
              }}
            />
          </DialogWrapper>
        )}
      </Grid>
    );
  },
);
