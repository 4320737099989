import React, { memo } from "react";

import { PageSelector, useElementEditorContext } from "core/editor";
import { IPage } from "core/types";
import { FormConfig } from "../../types";
import { useEditorFormTranslation } from "../translation";

export const LinkTo = memo(() => {
  const { elementModel, changeConfigValue } = useElementEditorContext<
    FormConfig
  >();
  const { linkToLabel } = useEditorFormTranslation();

  let pageParams;

  const handlePageChange = (p: IPage, params: any) => {
    // TODO: allow changeConfigValue("linkTo.pageId")
    changeConfigValue("linkTo", {
      ...elementModel.config.linkTo,
      pageId: p.id,
      params,
    });
  };

  return (
    <>
      <PageSelector
        pageId={elementModel.config.linkTo?.pageId as any}
        params={elementModel.config.linkTo?.params as any}
        config={elementModel.config}
        onChange={handlePageChange}
        label={linkToLabel}
      />
      {pageParams}
    </>
  );
});
