import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IAuditTable } from "./types";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  LOAD: scopeActionType("LOAD"),
  LOAD_SUCCESS: scopeActionType("LOAD_SUCCESS"),
  LOAD_ERROR: scopeActionType("LOAD_ERROR"),
  CHANGE_FILTER: scopeActionType("CHANGE_FILTER"),
  SET_SCHEMAS: scopeActionType("SET_SCHEMAS"),
  SET_TABLES: scopeActionType("SET_TABLES"),
};

export const actions = {
  load: createAction(types.LOAD),
  loadSuccess: createAction(
    types.LOAD_SUCCESS,
    (auditsList: IAuditTable[]) => ({
      auditsList,
    }),
  ),
  loadError: createAction(types.LOAD_ERROR, (error: any) => ({ error })),
  changeFilter: createAction(
    types.CHANGE_FILTER,
    (filter: Record<string, string | undefined | null>) => ({ filter }),
  ),
  setSchemas: createAction(types.SET_SCHEMAS, (schemas: string[]) => ({
    schemas,
  })),
  setTables: createAction(types.SET_TABLES, (tables: string[]) => ({
    tables,
  })),
};
