import React, { ChangeEvent, memo, useCallback, useState } from "react";

import TextField from "@material-ui/core/TextField";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { Section, useElementEditorContext } from "core/editor";
import { TextInputConfig } from "../../types";
import { useStyles } from "../styles";
import { useTextInputEditorTranslation } from "../translation";

enum Inputs {
  startAdornment = "startAdornment",
  endAdornment = "endAdornment",
}
const styles = [Inputs.startAdornment, Inputs.endAdornment];

export const StyleInput = memo<{ name: string }>(({ name }) => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<TextInputConfig>();

  const translation = useTextInputEditorTranslation();

  const [isIcon, setIsIcon] = useState<boolean>(
    !!config[`${name}Icon`]?.length,
  );
  const inputName = (isIcon ? `${name}Icon` : name) as keyof TextInputConfig;

  const changeConfig = useCallback(
    (newValue: string) => changeConfigValue(inputName, newValue),
    [changeConfigValue, inputName],
  );

  const value = isIcon ? config[`${name}Icon`] : config[name] ?? "";

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    changeConfig(e.target.value);

  const handleModeChange = (_: any, newValue: string) => {
    const nextIsIcon = newValue === "icon";
    if (config[name] !== config[`${name}Icon`]) {
      changeConfigValue(inputName, "");
      changeConfigValue(
        (nextIsIcon ? `${name}Icon` : name) as keyof TextInputConfig,
        value,
      );
    }
    setIsIcon(nextIsIcon);
  };

  const { controlClass } = useStyles();

  return (
    <>
      <TextField
        value={value}
        name={inputName}
        label={translation[name]}
        fullWidth={true}
        onChange={onInputChange}
      />
      <FormControl fullWidth={true} className={controlClass}>
        <FormLabel component="p">{translation.appplyTitle}</FormLabel>
        <RadioGroup
          row={true}
          value={isIcon ? "icon" : "text"}
          onChange={handleModeChange}
        >
          <FormControlLabel
            control={<Radio color="primary" />}
            label={translation.textLabel}
            value="text"
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label={translation.iconLabel}
            value="icon"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
});

export const Styling = memo(() => {
  const content = styles.map((inputName) => (
    <StyleInput name={inputName} key={inputName} />
  ));

  const { styleTitle } = useTextInputEditorTranslation();

  return (
    <Section title={styleTitle} wrapped={true}>
      {content}
    </Section>
  );
});
