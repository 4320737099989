import { FilesPage } from "./container";

import * as reduxModule from "./reduxModule";

import { UploadFilesPageRoute } from "./pages";

export const route = {
  reduxModule,
  Component: FilesPage,
  auth: true,
  isAdmin: true,
  routes: {
    "/admin/files/upload": UploadFilesPageRoute,
  },
};
