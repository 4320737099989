import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { formatDistanceToNow, formatRelative } from "date-fns";

import { default as commonStyles } from "staticPages/admin/styles";
import { Table, TableRow } from "staticPages/admin/common";
import { IUserAudit } from "./reduxModule/types";
import { actions, selectors } from "./reduxModule";
import Link from "elementTypes/common/Link";

const Row = React.memo<IUserAudit>(
  ({ id, event, message, userID, timestamp }) => {
    return (
      <TableRow rowId={id}>
        <Link
          variant="body2"
          title="View user info"
          href={`/admin/users/view/${userID}`}
        >
          {userID}
        </Link>
        <Typography>{event}</Typography>
        <Typography>{message}</Typography>
        <Tooltip title={formatRelative(new Date(timestamp), new Date())}>
          <Typography>
            {formatDistanceToNow(new Date(timestamp), {
              addSuffix: true,
            })}
          </Typography>
        </Tooltip>
      </TableRow>
    );
  },
);

export const UsersAuditPage = React.memo(() => {
  const { horizontallyCenter } = commonStyles();
  const auditsList = useSelector(selectors.auditsList);
  const loading = useSelector(selectors.loading);
  const error = useSelector(selectors.error);
  const dispatch = useDispatch();

  const titles = ["User ID", "Event", "Message", "Date"];

  const rows = auditsList.map((audit) => <Row key={audit.id} {...audit} />);

  const handleDataReload = () => dispatch(actions.load());

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12}>
        <Typography variant="h5">Audit Users</Typography>
      </Grid>
      <Grid item xs={12}>
        <Table
          rows={rows}
          headers={titles.map((title) => ({
            name: title.toLowerCase(),
            title,
          }))}
          onDataReload={handleDataReload}
          loading={loading}
          error={error}
        />
      </Grid>
    </Grid>
  );
});
