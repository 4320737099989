import { IDefaultElement } from "core/types/element";
import { FileInputConfig } from "../types";

export const defaultElement: IDefaultElement<FileInputConfig> = {
  config: {
    dataSource: {
      elementId: "",
      fieldPath: [],
    },
    accessGroupName: "",
    typeGroupName: "",
  },
  i18n: {
    en: {
      label: "",
    },
  },
};
