import React, { memo } from "react";

import clsx from "clsx";

import { ReduxProps } from "./container";
import { Switcher } from "./Switcher";
import { ColorVariant } from "./styles";

interface OwnProps {
  color: ColorVariant;
  className?: string;
}

type Props = ReduxProps & OwnProps;

export const LanguageSwitch = memo<Props>(
  ({ language, changeLanguage, color, className }) => (
    <Switcher
      changeLanguage={changeLanguage}
      language={language}
      colorVariant={color}
      className={clsx(className, "language-switch")}
    />
  ),
);
