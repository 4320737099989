import React, { ReactElement, forwardRef, memo } from "react";
import { useDispatch } from "react-redux";
import Box, { BoxProps } from "@material-ui/core/Box";
import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { actions as routerActions } from "core/router/reduxModule";
import { MuiIcon } from "../MuiIcon";
import { isDefaultSupportedColor } from "../IconButton/utils";

interface IHrefProps {
  href: string;
}

interface IOwnProps {
  label?: string;
  tooltip?: string;
  iconRight?: string;
  iconLeft?: string;
  disabled?: boolean;
  component?: any;
  className?: string;
  processing?: boolean;
  color?: BoxProps["color"];
}

export type IButtonProps = (
  | (IHrefProps & Partial<React.ComponentPropsWithoutRef<"a">>)
  | Record<string, unknown>
) &
  IOwnProps &
  ButtonProps;

const Button = memo<IButtonProps>(
  ({
    onClick,
    tooltip,
    iconRight,
    iconLeft,
    innerRef,
    color,
    variant,
    disabled,
    size,
    fullWidth,
    className,
    type,
    disableRipple,
    href,
    processing,
    ...props
  }) => {
    const dispatch = useDispatch();
    const leftIconCode = iconLeft && <MuiIcon icon={iconLeft} />;
    const rightIconCode = iconRight && <MuiIcon icon={iconRight} />;

    const withTooltip = (children: ReactElement<IButtonProps>) =>
      tooltip && !disabled ? (
        <Tooltip title={tooltip}>{children}</Tooltip>
      ) : (
        children
      );

    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      if (onClick) {
        onClick(event);
      }

      if (href) {
        event.preventDefault();

        dispatch(routerActions.push(href));
      }
    };

    const canHandleColor = !color || isDefaultSupportedColor(color);

    const buttonChildren =
      "label" in props
        ? !!props.label?.length && (
            <Box clone overflow="hidden" textOverflow="ellipsis">
              <Typography color="inherit" component="div">
                {props.label}
              </Typography>
            </Box>
          )
        : props.children;

    const button = (
      <MuiButton
        {...props}
        onClick={handleClick}
        disabled={disabled}
        size={size}
        variant={variant}
        color={canHandleColor ? color : undefined}
        fullWidth={fullWidth}
        ref={innerRef}
        className={className}
        type={type}
        disableRipple={disableRipple}
        startIcon={leftIconCode}
        endIcon={
          processing ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            rightIconCode
          )
        }
        href={href}
      >
        {buttonChildren}
      </MuiButton>
    );

    const buttonWithColor = canHandleColor ? (
      button
    ) : (
      <Box
        clone
        color={color}
        borderColor={
          variant === "outlined" ||
          variant === undefined /* default variant is "outlined", see theme */
            ? color
            : undefined
        }
        /* TODO handle contained variant */
      >
        {button}
      </Box>
    );

    return withTooltip(buttonWithColor);
  },
);

Button.displayName = "CommonButton";

export default forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => (
  <Button ref={ref} {...props} />
));
