import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export type ColorVariant = "light" | "dark" | "primaryText" | "secondaryText";

type Props = { color: ColorVariant };

const colorMapping = (theme: Theme): Record<ColorVariant, string> => ({
  light:
    theme.palette.type === "light"
      ? theme.palette.common.white
      : theme.palette.text.primary,
  dark:
    theme.palette.type === "light"
      ? theme.palette.text.primary
      : theme.palette.common.white,
  primaryText: theme.palette.primary.contrastText,
  secondaryText: theme.palette.secondary.contrastText,
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: ({ color }: Props) => colorMapping(theme)[color],
      borderColor: "currentColor",
    },
    popper: {
      zIndex: theme.zIndex.modal,
    },
  }),
);
