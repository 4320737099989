import React, { memo } from "react";

import { ConnectedReduxModuleProps } from "core";

import { ReduxModule } from "./reduxModule";
import { DataDisplay, DataDisplayConfig } from "./types";
import DefaultDataDisplay from "./DataDisplay";

type Props = ConnectedReduxModuleProps<ReduxModule, DataDisplay>;

export type DefaultDataDisplay = {
  label: Props["element"]["i18n"]["label"];
  config: Omit<DataDisplayConfig, "dataSource" | "format">;
} & Pick<Props, "formattedData" | "error" | "load" | "loading" | "color">;

const DefaultDataDisplayComponent = memo<Props>(
  ({
    element: {
      i18n: { label },
      config,
    },
    formattedData,
    error,
    loading,
    load,
    color = "info",
  }) => (
    <DefaultDataDisplay
      label={label}
      config={config}
      formattedData={formattedData}
      error={error}
      loading={loading}
      load={load}
      color={color}
    />
  ),
);

export default DefaultDataDisplayComponent;
