import React, { memo } from "react";

import { FormattedDate, FormattedTime } from "react-intl";

import { IElementComponentProps } from "core";
import { Tooltip } from "@material-ui/core";
import { formatDistanceToNow } from "date-fns";
import { DateTimeField } from "./types";
import { stringToDateObject } from "utils/string";

interface IProps
  extends IElementComponentProps<Record<string, unknown>, DateTimeField> {
  value: string | null;
}

const DefaultDateTimeField = memo<IProps>(
  ({
    value,
    element: {
      config: { isRelative, showDatePart, showTimePart },
    },
  }) => {
    const dateValue = stringToDateObject(value);
    if (!dateValue) {
      return null;
    }

    const formattedValue = (
      <>
        {showDatePart && <FormattedDate value={dateValue} />}{" "}
        {showTimePart && <FormattedTime value={dateValue} />}
      </>
    );

    if (isRelative) {
      const relativeValue = formatDistanceToNow(dateValue, {
        addSuffix: true,
      });

      return (
        <Tooltip title={formattedValue}>
          <span>{relativeValue}</span>
        </Tooltip>
      );
    }

    return formattedValue;
  },
);

DefaultDateTimeField.displayName = "DateTimeField";

export default DefaultDateTimeField;
