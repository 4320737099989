import React, { CSSProperties, memo } from "react";

import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import IconButton from "../../../common/IconButton";
import { useEditorTranslation } from "core/editor";
import { Reference } from "./MultiReference";

type Props = {
  onClick: (params: Reference) => void;
  onDelete: (name: string) => void;
  style?: CSSProperties;
} & Reference;

export const ReferenceItem = memo<Props>(
  ({ style, onClick, onDelete, ...props }) => {
    const { editButton, deleteButton } = useEditorTranslation();
    const { targetView, referencingFieldName } = props;
    const handleEditClick = () => onClick(props as Reference);
    const handleDelete = () => onDelete(targetView);

    return (
      <ListItem divider style={style}>
        <Box overflow="auto" clone>
          <ListItemText
            primary={<Typography variant="subtitle2">{targetView}</Typography>}
            primaryTypographyProps={{ color: "primary" }}
            secondary={referencingFieldName}
          />
        </Box>
        <ListItemIcon>
          <IconButton
            icon="delete_outline"
            onClick={handleDelete}
            color="error.main"
            tooltip={deleteButton}
          />
          <IconButton
            icon="edit"
            onClick={handleEditClick}
            tooltip={editButton}
            edge="end"
          />
        </ListItemIcon>
      </ListItem>
    );
  },
);
