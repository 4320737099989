import React, { memo } from "react";
import classNames from "classnames";
import MUIAppBar from "@material-ui/core/AppBar";
import IconButton from "elementTypes/common/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { LanguageSwitch } from "../LanguageSwitch";
import Typography from "@material-ui/core/Typography";
import { PropTypes } from "@material-ui/core";

import { AppBarMenu } from "../AppBarMenu";
import { mapStateToProps } from "./container";
import useStyles from "./styles";
import AppSwitch from "../AppSwitch";
import AdminButton from "../AdminButton";
import { EditorSwitch } from "../EditorSwitch";
import { LOGIN_URL } from "../../../core/router/reduxModule/constants";

export type Props = {
  label: string;
  open: boolean;
  hidden?: boolean;
  handleDrawerChange: () => void;
  menuWidth: string;
  headerButtonsColor: PropTypes.Color;
  toolbarVariant?: "dense" | "regular";
  handleLoginClick?: () => void;
} & ReturnType<typeof mapStateToProps>;

export const AppBar = memo<Props>((props) => {
  const {
    label,
    open,
    hidden,
    handleDrawerChange,
    toolbarVariant,
    headerButtonsColor,
    isLoggedIn,
    isAdmin,
    isStaticPage,
    handleLoginClick,
    location,
  } = props;

  const classes = useStyles(props);
  const { pathname } = location;

  return (
    <MUIAppBar
      className={classNames(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      position="relative"
      elevation={0}
    >
      <Toolbar variant={toolbarVariant} disableGutters={true}>
        {!hidden ? (
          <IconButton
            color={headerButtonsColor}
            onClick={handleDrawerChange}
            icon="menu"
          />
        ) : null}
        <Typography
          variant="h5"
          color="inherit"
          noWrap={true}
          className={classes.appBarText}
        >
          {label}
        </Typography>
        <LanguageSwitch color="primaryText" />
        {isAdmin && (
          <>
            <AdminButton color={headerButtonsColor} />
            {!isStaticPage && (
              <EditorSwitch
                color={headerButtonsColor}
                toggleDrawer={handleDrawerChange}
                drawerOpen={open}
              />
            )}
          </>
        )}
        <AppSwitch color={headerButtonsColor} />
        {isLoggedIn && <AppBarMenu color={headerButtonsColor} />}
        {!isLoggedIn && pathname !== LOGIN_URL ? (
          <IconButton
            color={headerButtonsColor}
            onClick={handleLoginClick}
            tooltip="Go to login page"
            icon="lockOpen"
            data-testid="go-to-login-button"
          />
        ) : null}
      </Toolbar>
    </MUIAppBar>
  );
});

AppBar.displayName = "AppBar";
