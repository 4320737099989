import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "classnames";

import { MuiIcon } from "elementTypes/common/MuiIcon";
import useStyles from "../styles";

type UserInfo = {
  label: string;
  icon: string;
  value: string;
};

export const UserInfo = React.memo<UserInfo>(({ label, icon, value }) => {
  const { marginTop, marginBottom } = useStyles();

  return (
    <FormControl
      fullWidth
      className={clsx(marginTop, marginBottom)}
      variant="outlined"
    >
      <InputLabel>{label}</InputLabel>

      <OutlinedInput
        value={value}
        labelWidth={label.length * 10}
        disabled
        startAdornment={
          <InputAdornment position="start">
            <MuiIcon icon={icon} fontSize="default" />
          </InputAdornment>
        }
      />
    </FormControl>
  );
});
