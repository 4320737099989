import React, { CSSProperties, memo } from "react";
import clsx from "classnames";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import IconButton from "elementTypes/common/IconButton";
import { useDefaultTabsEditorTranslation } from "../translation";
import { useStyles } from "./styles";

export type TabConfigProps = {
  name: string;
  defaultTab: boolean;
  isNew: boolean;
  index: number;
  config: any;
};

type Props = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  index: number;
  onButtonClick: (
    type: "edit" | "delete",
    params: TabConfigProps | number,
  ) => void;
  style?: CSSProperties;
} & TabConfigProps;

export const TabConfig = memo<Props>(
  ({ name, defaultTab, provided, snapshot, style, onButtonClick, ...rest }) => {
    const {
      editTabTooltip,
      deleteTabTooltip,
    } = useDefaultTabsEditorTranslation();
    const { draggingItem } = useStyles();
    const { isDragging } = snapshot;
    const handleClick = (type: "edit" | "delete") => () =>
      !isDragging &&
      onButtonClick(
        type,
        type === "edit"
          ? ({ name, defaultTab, ...rest } as TabConfigProps)
          : rest.index,
      );

    return (
      <ListItem
        divider
        className={clsx(
          { [draggingItem]: isDragging },
          "editor-tabs-section-tabs-list-item",
        )}
        selected={isDragging}
        innerRef={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...style,
          ...provided.draggableProps.style,
        }}
      >
        <ListItemText primary={name} />
        <ListItemIcon>
          <IconButton
            icon="delete_outline"
            className="editor-tabs-tab-delete"
            onClick={handleClick("delete")}
            disabled={isDragging}
            tooltip={deleteTabTooltip}
            edge="end"
          />
          <IconButton
            icon="edit"
            className="editor-tabs-tab-edit"
            onClick={handleClick("edit")}
            disabled={isDragging}
            tooltip={editTabTooltip}
            edge="end"
          />
        </ListItemIcon>
      </ListItem>
    );
  },
);
