import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    inputEndAdornment: {
      top: "calc(50% - 12px)",

      "& [class*='MuiButtonBase-root']": {
        color: "inherit",
      },
    },
  }),
);
