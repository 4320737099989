import React, { memo, useCallback } from "react";

import { Section, useElementEditorContext } from "core/editor";

import { UntransformedFormConfig } from "../../types";
import { useEditorFormTranslation } from "../translation";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { buildCustomExpressionValue } from "core";

import IconButton from "../../../common/IconButton";

const INITIAL_VALUE = buildCustomExpressionValue("true");

export const Validation = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedFormConfig>();
  const {
    customValidationLabel,
    createValidation,
    removeValidation,
  } = useEditorFormTranslation();

  const { validation } = config;

  const changeValue = useCallback(
    (newValidationExpression: UntransformedFormConfig["validation"]) =>
      changeConfigValue("validation", newValidationExpression),
    [changeConfigValue],
  );

  const deleteValidation = () => changeValue(undefined);
  const addValidation = () => changeValue(INITIAL_VALUE);

  return (
    <Section
      wrapped={Boolean(validation)}
      title={customValidationLabel}
      headerAction={
        <IconButton
          icon={validation ? "delete_outline" : "add"}
          onClick={validation ? deleteValidation : addValidation}
          tooltip={validation ? removeValidation : createValidation}
        />
      }
    >
      {validation && (
        <CustomExpressionEditor
          value={validation}
          config={config}
          onChange={changeValue}
          disableSwitcher
        />
      )}
    </Section>
  );
});
