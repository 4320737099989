import React, { memo } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { DEFAULT_APP_URL } from "core/router/reduxModule";
import { AppBarMenu } from "layouts/common/AppBarMenu";
import IconButton from "elementTypes/common/IconButton";
import useStyles from "../styles";

export const AdminHeader = memo(() => {
  const { appBar, title } = useStyles();

  return (
    <AppBar
      color="secondary"
      position="static"
      variant="outlined"
      className={appBar}
    >
      <Toolbar variant="dense">
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap={true}
          className={title}
        >
          Administration
        </Typography>
        <IconButton
          icon="screen_share"
          tooltip="Back To App"
          href={DEFAULT_APP_URL}
          color="inherit"
          fontSize="large"
        />
        <AppBarMenu color={"inherit"} />
      </Toolbar>
    </AppBar>
  );
});
