import React, { memo, useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import * as R from "ramda";

import Typography from "@material-ui/core/Typography";

import DialogWrapper from "../../helpers/HOC/DialogWrapper";
import { useTableContext } from "../../default_table";
import { useTableTranslation } from "../translation";
import { useTableToolsContext } from "../toolsPanel";
import FilterGroup from "./FilterGroup";
import { FilterProvider } from "./FilterContext";
import { useStyles } from "./style";
import { IFilterGroupWithPath } from "./types";

import {
  FilterGroupCombinator,
  IData,
  IFilterField,
  IFilterGroup,
  UpdateParams,
  addFilter,
  addPathToGroup,
  getFilterPath,
} from "../toolsPanel";

type Props = {
  fields: IFilterField[];
  filter: IFilterGroup | null;
  openDialogFilter: boolean;
  handleToggleDialogFilter: () => void;
  changeFilter: (filter: IFilterGroupWithPath) => void;
  error?: string | null;
};

const getNextFilterFromData = (
  data: IData,
  currentFilter: IFilterGroupWithPath,
  fields: IFilterField[],
): IFilterGroupWithPath => {
  let nextFilter = cloneDeep(currentFilter) as IFilterGroupWithPath;

  for (const value in data) {
    if (value === "initial") {
      nextFilter = { ...nextFilter, ...data[value] };
    } else {
      const path = value.split(",").map((n) => Number.parseInt(n, 10));
      const keys = data[value];

      for (const key in keys) {
        let valuePath = [...getFilterPath(path), key as string];
        let nextValue = keys[key];
        if (key === "fieldName") {
          const pred = R.whereEq({ name: keys[key] });

          nextValue = fields.find((f: IFilterField) => pred(f)) || {
            name: keys[key],
          };
          valuePath = [...getFilterPath(path), "field"];
        }
        if (key === "defaultValue") {
          valuePath = [...getFilterPath(path), "value"];
        }
        nextFilter = R.assocPath(valuePath, nextValue, nextFilter);
      }
    }
  }

  return nextFilter;
};

const convertValuesToData = (values: IData) => {
  let data = {};
  for (const key in values) {
    const [path, fieldName] = key.split(".");
    const value = values[key];
    // eslint-disable-next-line
    const newKey = path.replace(/[\[\]\'\']/g, "");
    data = R.assocPath([newKey, fieldName], value, data);
  }
  return data as IData;
};

const DefaultTableFilter = memo(() => {
  const { changeFilter, error } = useTableContext();

  const {
    fields,
    filter,
    openDialogFilter,
    handleToggleDialogFilter,
  } = useTableToolsContext();

  return (
    <TableFilter
      changeFilter={changeFilter}
      error={error}
      fields={fields}
      filter={filter}
      openDialogFilter={openDialogFilter}
      handleToggleDialogFilter={handleToggleDialogFilter}
    />
  );
});

export const TableFilter = memo<Props>(
  ({
    changeFilter,
    error,
    fields,
    filter,
    openDialogFilter,
    handleToggleDialogFilter,
  }) => {
    const {
      filterTitle,
      filterCancelButton,
      filterSubmitButton,
    } = useTableTranslation();

    const { paperFilterDialog } = useStyles();

    const nextFilterWithPath = useMemo(
      () => addPathToGroup(filter as IFilterGroup, []),
      [filter],
    );

    const [currentFilter, setFilter] = useState<IFilterGroupWithPath>(
      nextFilterWithPath,
    );

    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
      if (openDialogFilter) {
        setFilter(nextFilterWithPath);
      }
    }, [nextFilterWithPath, openDialogFilter]);

    useEffect(() => {
      if (isSubmitting) {
        setSubmitting(false);
        !error && handleToggleDialogFilter();
      }
    }, [error, isSubmitting, handleToggleDialogFilter]);

    const onSubmit = (data: IData) => {
      const nextFilter = getNextFilterFromData(data, currentFilter, fields);
      changeFilter(nextFilter);
      setSubmitting(true);
    };

    const getNextFilter = (values: IData) => {
      const data = convertValuesToData(values) as IData;
      return getNextFilterFromData(data, currentFilter, fields);
    };

    const addFilterGroup = ({ path, values }: UpdateParams) =>
      setFilter(
        addFilter(getNextFilter(values), path, {
          combinator: FilterGroupCombinator.AND,
          filters: [],
        }),
      );

    const setDefaultValue = (type: string) => (type === "number" ? 0 : "");

    const addFilterRule = ({
      path,
      field,
      values,
    }: UpdateParams & { field: IFilterField }) =>
      setFilter(
        addFilter(getNextFilter(values), path, {
          field,
          operator: field?.operators[0],
          value: field ? setDefaultValue(field.input?.type) : "",
        }),
      );

    const removeFilter = ({ path, values }: UpdateParams) =>
      setFilter(
        addPathToGroup(
          R.dissocPath([...getFilterPath(path)], getNextFilter(values)),
        ),
      );

    const formOptions = { mode: "onChange" as const };

    return (
      <DialogWrapper
        title={filterTitle}
        open={openDialogFilter}
        submitTitle={filterSubmitButton}
        cancelTitle={filterCancelButton}
        handleClose={handleToggleDialogFilter}
        handleSubmit={onSubmit}
        paperClass={paperFilterDialog}
        isForm={true}
        formOptions={formOptions}
        submitDisabled={Boolean(error) || isSubmitting}
      >
        <FilterProvider
          value={{
            addFilterGroup,
            addFilterRule,
            removeFilter,
            fields,
          }}
        >
          <FilterGroup group={currentFilter as IFilterGroupWithPath} />
          {error && openDialogFilter && (
            <Typography color="error">{error}</Typography>
          )}
        </FilterProvider>
      </DialogWrapper>
    );
  },
);

DefaultTableFilter.displayName = "DefaultTableFilter";

export default DefaultTableFilter;
