import { QueryResult, useQuery } from "react-query";
import { useSelector } from "react-redux";

import AdminService from "services/admin";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { QueryKeys } from "./queryKeys";

type IQuery = {
  queryKey: string;
  queryFn: (...args: any[]) => void;
};

export const resultToRespond = <DataType>(
  result: QueryResult<DataType, Error>,
) => ({
  data: result.data,
  loading: result.isFetching,
  error: result.error,
});

const services = AdminService.getInstance();
export const useToken = () => useSelector(sessionSelectors.token);

export const useQueryFn = <
  Fn extends (...args: any[]) => any,
  Rt = ReturnType<Fn>
>(
  fn: Fn,
) => (_key: string, ...args: Parameters<Fn>): Rt => fn(...args);

export const useData = (
  { queryKey, queryFn }: IQuery,
  params?: Record<string, any>,
) => {
  const token = useToken();
  const result = useQuery([queryKey, token, { params }], useQueryFn(queryFn));
  return resultToRespond(result);
};

export const fetchUsers = {
  queryKey: QueryKeys.fetchUsers,
  queryFn: services.getAllUsers,
};

export const fetchApps = {
  queryKey: QueryKeys.fetchApps,
  queryFn: services.getAllUi,
};
