import { handleActions } from "core/utils/redux";
import { IState } from "./types";
import { types } from "./actions";

const INITIAL_STATE: IState = {
  ui: null,
  error: null,
  loading: false,
};

export const reducer = handleActions<IState>(INITIAL_STATE, {
  [types.LOAD]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [types.SELECTED_UI]: (state, action) => ({
    ...state,
    ui: action.payload.selectedUi,
    loading: false,
  }),
  [types.EDIT_UI_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error,
    loading: false,
  }),
});
