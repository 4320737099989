import { connect } from "react-redux";

import { selectors as sessionSelectors } from "core/session/reduxModule";

import { CreateUsersPage as CUComponent } from "./component";

import { actions, selectors } from "./reduxModule";

export const mapStateToProps = (state: any) => ({
  loading: selectors.loading(state),
  error: selectors.error(state),
  rolesList: selectors.rolesList(state),
  language: sessionSelectors.currentLanguage(state),
});

export const mapDispatchToProps = {
  ...actions,
};

export const CreateUsersPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CUComponent);
