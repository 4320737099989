import { IElementComponentProps, PropsFromConnector } from "core";
import {
  actions as routerActions,
  selectors as routerSelectors,
} from "core/router/reduxModule";
import { connectElement } from "core/utils/react-redux";
import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { actions } from "core/session/reduxModule";
import { Table } from "./types";

const mapStateToProps = (
  state: any,
  { module }: IElementComponentProps<ReduxModule, Table>,
) => ({
  location: routerSelectors.location(state),
  getColOrder: module.utils.getColOrder,
});

const mapDispatchToProps = {
  updateQueryStringValues: routerActions.updateQueryStringValues,
  enqueueSnackbar: actions.enqueueSnackbar,
};

const connector = connectElement<
  ReduxModule,
  Table,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>(mapStateToProps, mapDispatchToProps);

export type Props = PropsFromConnector<typeof connector>;

export default connector(Component);
