import React, { memo } from "react";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { Section } from "core/editor";
import { UntransformedTableConfig } from "../../types";
import { useTableEditorTranslation } from "../../translation";

type Props = {
  canSelectRow: UntransformedTableConfig["canSelectRow"];
  firstRowSelected: UntransformedTableConfig["firstRowSelected"];
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const SelectionEditor = memo<Props>(
  ({ canSelectRow, firstRowSelected, changeConfigValue }) => {
    const {
      canSelectRowLabel,
      firstRowSelectedLabel,
      selectionTitle,
    } = useTableEditorTranslation();

    const toggleCanSelectRow = () =>
      changeConfigValue("canSelectRow", !canSelectRow);
    const toggleFirstRowSelected = () =>
      changeConfigValue("firstRowSelected", !firstRowSelected);

    return (
      <Section
        title={selectionTitle}
        wrapped={true}
        defaultOpened={false}
        data-testid="teststststs"
      >
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(canSelectRow)}
                onChange={toggleCanSelectRow}
                data-testid="can-select-row-switch"
              />
            }
            label={canSelectRowLabel}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(firstRowSelected)}
                onChange={toggleFirstRowSelected}
                data-testid="first-row-selected-switch"
              />
            }
            disabled={!canSelectRow}
            label={firstRowSelectedLabel}
          />
        </FormControl>
      </Section>
    );
  },
);
