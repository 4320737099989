import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";

import { differenceWith, dissocPath } from "ramda";
import deepEqual from "fast-deep-equal";

import { AllServices } from "core/buildStore";
import {
  actions as routerActions,
  selectors as routerSelectors,
} from "core/router/reduxModule";
import { getPushArguments } from "core/router";
import { editorTranslation } from "core/editor";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { Form } from "../types";
import { Actions, Selectors, Types } from "./types";
import { getTranslatedTextSaga } from "core/session/translation/createUseTranslation";
import {
  createDefaultData,
  getErrors,
  getSchema,
  getValidationData,
  validateChange,
} from "./utils";
import { errorsTranslation, formTranslation } from "../translation";
import { createWatcherSaga } from "core/utils/saga";

export function buildSaga(
  element: Form,
  actions: Actions,
  types: Types,
  selectors: Selectors,
) {
  const {
    dataSource: { viewName, identifierName, multiReference, stateFieldName },
    type,
  } = element.config;
  const currentSchema = getSchema(element.config.jsonSchema, identifierName);

  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token = yield select(sessionSelectors.token);

    if (!viewName?.length) {
      // Inside editor mode if element has just been created viewName can be empty
      // set error to warn user of viewName necessity
      const msg = yield call(
        getTranslatedTextSaga,
        editorTranslation,
        "viewNameError",
      );
      yield put(actions.loadError(msg));

      return;
    }

    if (type === "create") {
      let defaultData: Record<string, unknown> | null = null;
      try {
        defaultData = yield select(selectors.defaultData);
      } catch {
        // retry through watch saga
      }
      yield put(
        actions.loadSuccess(
          createDefaultData(element, defaultData ?? undefined),
        ),
      );

      if (stateFieldName) {
        const stateChanges = yield call(
          services.api.getViewCreateStateChanges,
          token,
          viewName,
        );

        yield put(actions.setAllowedStateChanges(stateChanges));

        if (stateChanges.length) {
          yield put(actions.setStateFieldValue(stateChanges[0].to));
        }
      }
    } else {
      // fetch initial data for editing
      try {
        let id = null;
        id = yield select(selectors.identifier);

        const data = yield call(services.api.loadViewData, token, viewName, {
          and: `(${identifierName}.eq.${id})`,
          offset: 0,
          limit: 1,
        });
        const row = data[0];

        const referenceData: Record<string, any[]> = {};

        if (multiReference) {
          for (const referenceField in multiReference) {
            const reference = multiReference[referenceField];
            referenceData[referenceField] = yield call(
              services.api.loadViewData,
              token,
              reference.viewName,
              {
                and: `(${reference.referencingFieldName}.eq.${id})`,
                offset: 0,
                limit: 10000,
              },
            );
          }
        }

        yield put(actions.loadSuccess({ ...row, ...referenceData }));
      } catch (error) {
        yield put(actions.loadError(error));
      }
    }
  }

  function* saveSaga(action: ReturnType<Actions["save"]>) {
    const hasChanges = yield select(selectors.hasChanges);
    const services: AllServices = yield getContext("services");
    const token = yield select(sessionSelectors.token);
    let data = yield select(selectors.data);
    let failedData = yield select(selectors.failedData);
    const originalData = yield select(selectors.originalData);
    const pages = yield select(routerSelectors.allPages);

    if (!hasChanges) {
      yield put(actions.saveError("Has no changes"));
      return;
    }

    if (currentSchema) {
      const stateFieldValue = yield select(selectors.stateFieldValue);

      const validationData =
        type === "create" && stateFieldName
          ? { ...data, [stateFieldName]: stateFieldValue }
          : data;

      const { isValid, values } = getValidationData({
        options: { useDefaults: "empty" },
        schema: currentSchema,
        values: cloneDeep(validationData),
      });

      if (!isValid) {
        yield put(actions.saveError("Form isn't valid"));
        return;
      }

      // set data with hard defaults
      data = values;
    }

    const referenceData: Record<string, any[]> = {};

    // remove references from data object
    if (multiReference) {
      // shallow copy to still have references in original data object
      data = cloneDeep(data);

      for (const referenceField in multiReference) {
        referenceData[referenceField] = data[referenceField];
        delete data[referenceField];
      }
    }

    const nextReferenceData: Record<string, any[]> = {};

    try {
      let nextData: any;
      const stateFieldValue = yield select(selectors.stateFieldValue);

      if (type === "create" && !failedData) {
        const sendData = { ...data };

        if (stateFieldName) {
          sendData[stateFieldName] = stateFieldValue;
        }

        // create
        nextData = yield call(
          services.api.createViewData,
          token,
          viewName,
          sendData,
        );

        failedData = { ...nextData };
      } else if (
        // edit
        identifierName &&
        (type === "edit" ||
          // if first "create" failed on referenced data creation -> update earlier created record
          (type === "create" && failedData?.[identifierName]))
      ) {
        nextData = yield call(
          services.api.updateViewData,
          token,
          viewName,
          data,
          identifierName,
          type === "create" && failedData?.[identifierName]
            ? failedData[identifierName]
            : originalData[identifierName],
        );
      } else {
        throw Error(
          "Neither create nor edit is available. Save should not have been called",
        );
      }

      let errors = {};

      if (identifierName) {
        const dataIdentifier = nextData[identifierName];

        for (const referenceField in multiReference) {
          try {
            const referenceConfig = multiReference[referenceField];
            const singleReferenceData = referenceData[referenceField];
            const originalSingleReferenceData = originalData[referenceField];

            // insert the rows that don't have a primary column set
            const insertData = singleReferenceData.filter(
              (d) => d[referenceConfig.identifierFieldName] === undefined,
            );

            // update the rows that have a primary column set
            const updateData = differenceWith(
              (a: any, b: any) => deepEqual(a, b),
              singleReferenceData.filter(
                (d) => d[referenceConfig.identifierFieldName] !== undefined,
              ),
              originalSingleReferenceData,
            );
            const deleteData = differenceWith(
              (a: any, b: any) =>
                a[referenceConfig.identifierFieldName] ===
                b[referenceConfig.identifierFieldName],
              originalSingleReferenceData,
              singleReferenceData,
            );

            if (insertData.length) {
              const insertDataWithReferenceSet = insertData.map((d) => ({
                ...d,
                [referenceConfig.referencingFieldName]: dataIdentifier,
              }));

              yield call(
                services.api.insertMultipleViewDataRows,
                token,
                referenceConfig.viewName,
                insertDataWithReferenceSet,
              );
            }

            if (updateData.length) {
              yield call(
                services.api.updateMultipleViewDataRows,
                token,
                referenceConfig.viewName,
                updateData,
              );
            }

            if (deleteData.length) {
              yield call(
                services.api.deleteMultipleViewDataRows,
                token,
                referenceConfig.viewName,
                deleteData.map((d) => d[referenceConfig.identifierFieldName]),
                referenceConfig.identifierFieldName,
              );
            }

            nextReferenceData[referenceField] = yield call(
              services.api.loadViewData,
              token,
              referenceConfig.viewName,
              {
                and: `(${referenceConfig.referencingFieldName}.eq.${dataIdentifier})`,
                offset: 0,
                limit: 10000,
              },
            );

            errors = omit(errors, referenceField);
          } catch (error) {
            errors = {
              [referenceField]: error.message,
            };
          }
        }

        nextData = { ...nextData, ...nextReferenceData };
      }

      const referenceErrors = Object.keys(errors).length;

      if (!referenceErrors) {
        yield put(actions.saveSuccess(nextData));
        yield put(
          actions.enqueueSnackbar({
            message: yield call(
              getTranslatedTextSaga,
              formTranslation,
              type === "edit" ? "messageUpdated" : "messageCreated",
            ),
            options: {
              variant: "success",
            },
          }),
        );

        if (action.payload.linkTo) {
          if (element.config.linkTo) {
            const linkTo = {
              pageId: yield select(element.config.linkTo.pageId),
              params: yield Object.keys(element.config.linkTo.params).reduce(
                function* (p, k) {
                  const paramSelector = element.config.linkTo!.params![k]!;
                  const param = yield select(paramSelector);
                  return {
                    ...p,
                    [k]: param,
                  };
                },
                {},
              ),
            };
            const page = pages[linkTo.pageId];

            if (page) {
              yield put(
                routerActions.push(...getPushArguments(page, linkTo.params)),
              );
            }
          } else {
            yield put(routerActions.goBack());
          }
        } else {
          // stay
          if (type === "create") {
            // reset form data, so the user can create a new entry
            yield loadSaga();
          }
        }
      } else {
        yield put(actions.saveErrors(errors, failedData));
      }
    } catch (error) {
      if (typeof error === "string") {
        yield put(actions.saveError(error));
      } else {
        const fieldErrors = getErrors(error);

        if (fieldErrors) {
          const errorObject = {};

          for (const fieldError of fieldErrors) {
            errorObject[fieldError.fieldPath] = yield call(
              getTranslatedTextSaga,
              errorsTranslation,
              fieldError.description,
            );
          }
          yield put(actions.saveErrors(errorObject));
        } else {
          yield put(actions.saveError(error));
        }
      }
    }
  }

  function* checkFieldChangeSaga({
    payload,
  }: ReturnType<Actions["changeFieldValue" | "changeFieldTouched"]>) {
    const data = yield select(selectors.data);

    const stateFieldValue = yield select(selectors.stateFieldValue);

    if (currentSchema) {
      const validationData =
        type === "create" && stateFieldName
          ? { ...data, [stateFieldName]: stateFieldValue }
          : data;

      const errors = validateChange(currentSchema)(validationData);

      yield put(actions.saveErrors(errors));
    } else {
      const errors = yield select(selectors.errors);
      const { fieldPath } = payload;
      const [fieldName] = fieldPath;

      if (errors[fieldName]) {
        yield put(actions.saveErrors(dissocPath(fieldPath, errors)));
      } else {
        for (const key in errors) {
          if (key.includes(String(fieldPath))) {
            yield put(actions.saveErrors(dissocPath([key], errors)));
          }
        }
      }
    }
  }

  function* callLoad() {
    yield put(actions.load());
  }

  return function* mainSaga() {
    yield all([
      yield takeLatest(types.LOAD, loadSaga),
      yield takeLatest(types.SAVE, saveSaga),
      yield takeLatest(
        [types.FIELD_TOUCHED_CHANGE, types.FIELD_VALUE_CHANGE],
        checkFieldChangeSaga,
      ),
      yield call(createWatcherSaga, selectors.identifier, {
        onChange: callLoad,
      }),
      yield call(createWatcherSaga, selectors.defaultData, {
        onChange: callLoad,
      }),
    ]);

    yield put(actions.load());
  };
}
