import React from "react";

import {
  AllowedFilter,
  ColumnEditor,
  DataSourceEditor,
  DefaultSort,
  FixedFilter,
  Interval,
  References,
  SelectionEditor,
  SimpleFilter,
} from "./components";
import { useElementEditorContext } from "core/editor";
import { UntransformedTableConfig } from "../types";

export const TableEditor = () => {
  const {
    elementModel,
    elementModel: {
      config: {
        dataSource,
        canSelectRow,
        firstRowSelected,
        defaultSort,
        interval,
        simpleFilter,
        filter,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTableConfig>();

  return (
    <>
      <DataSourceEditor />
      {!!dataSource.viewName?.length && (
        <>
          <References
            dataSource={dataSource}
            changeConfigValue={changeConfigValue}
          />
          <SelectionEditor
            canSelectRow={canSelectRow}
            firstRowSelected={firstRowSelected}
            changeConfigValue={changeConfigValue}
          />
          <ColumnEditor
            elementModel={elementModel}
            changeConfigValue={changeConfigValue}
          />
          <DefaultSort
            defaultSort={defaultSort}
            viewName={dataSource.viewName}
            changeConfigValue={changeConfigValue}
          />
          <Interval interval={interval} changeConfigValue={changeConfigValue} />
          <SimpleFilter
            simpleFilter={simpleFilter}
            viewName={dataSource.viewName}
            changeConfigValue={changeConfigValue}
          />
          <FixedFilter
            config={elementModel.config}
            changeConfigValue={changeConfigValue}
          />
          <AllowedFilter
            changeConfigValue={changeConfigValue}
            filter={filter}
            viewName={dataSource.viewName}
          />
        </>
      )}
    </>
  );
};
