import { Type } from "./Type";
import { TypeError } from "./TypeError";

export class FunctionType extends Type {
  public name = `function<${this.returnType.name}>`;

  constructor(public readonly returnType: Type, description?: string) {
    super(description);
  }

  public validate(value: any): true | TypeError {
    if (typeof value !== "function") {
      return new TypeError(value, this, "TODO: detailed error message");
    }
    return true;
  }
}
