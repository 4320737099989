import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import notFoundPath from "./notFound.svg";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
);

export const RouterNotFound = () => {
  const { root } = useStyles();
  return (
    <Card className={root}>
      <CardHeader
        title={
          <Typography variant="h2" align="center">
            Page not found
          </Typography>
        }
      />
      <CardContent>
        <img src={notFoundPath} alt="Not Found" />
      </CardContent>
    </Card>
  );
};
