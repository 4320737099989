import React, { ChangeEvent, memo } from "react";
import TextField from "@material-ui/core/TextField";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedExternalLinkFieldConfig } from "../../types";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { useExternalLinkFieldEditorTranslation } from "../translation";

export const LinkConfig = memo(() => {
  const {
    elementModel: {
      config,
      config: { link = "" },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedExternalLinkFieldConfig>();
  const i18n = useExternalLinkFieldEditorTranslation();

  const changeLinkInput = (newLink?: string) =>
    changeConfigValue("link", newLink);

  const customLinkInput = ({
    value: identifierValue,
    onChange: onIdentifierChange,
  }: {
    value: string;
    onChange: (value: string) => void;
  }) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onIdentifierChange(e.target.value);

    return (
      <TextField
        label={i18n.linkLabel}
        value={identifierValue}
        onChange={onCustomChange}
        fullWidth={true}
        placeholder={i18n.linkPlaceholder}
        helperText={i18n.linkHelperText}
      />
    );
  };

  return (
    <Section title={i18n.linkConfigTitle} wrapped={true}>
      <CustomExpressionEditor
        value={link}
        config={config}
        onChange={changeLinkInput}
        label={i18n.linkLabel}
        nonExpressionEditor={customLinkInput}
      />
    </Section>
  );
});
