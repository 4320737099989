import React, { memo, useEffect, useMemo, useState } from "react";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";
import { MuiIcon } from "elementTypes/common/MuiIcon";

import { iconsOptions } from "./icons";
import { useStyles } from "./styles";

type Props = {
  value?: string | null;
  onChange?: (iconName: string) => void;
  label?: string;
};

export const IconAutocomplete = memo<Props>(
  ({ onChange, value, label = "" }) => {
    const { root } = useStyles();
    const valueObject = useMemo(
      () => iconsOptions.find((o) => o.value === value),
      [value],
    );

    const [searchInputValue, setInputValue] = useState<string>("");

    useEffect(() => {
      !!searchInputValue.length && setInputValue("");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueObject]);

    const handleChange = (iconName: IAutocompleteValue) =>
      onChange?.(iconName as string);

    const handleInputChange = (newValue: string) => setInputValue(newValue);

    const customRenderOption = (option: SelectOption) => (
      <div className={root}>
        <ListItemIcon>
          <MuiIcon
            icon={option.value as string}
            fontSize="large"
            color="primary"
          />
        </ListItemIcon>
        <ListItemText primary={option.label} />
      </div>
    );

    return (
      <BaseAutocomplete
        options={iconsOptions}
        onChange={handleChange}
        valueObject={valueObject ?? ""}
        name="icons"
        label={label}
        isLoading={false}
        searchInputValue={searchInputValue}
        onInputChange={handleInputChange}
        customRenderOption={customRenderOption}
        defaultItemSize={48}
        virtualizedList={true}
        isClearable={true}
        {...(value && {
          startAdornment: <MuiIcon icon={value} fontSize="default" />,
        })}
      />
    );
  },
);
