import React from "react";
import { Editor, EditorChange } from "codemirror";
import FormControl from "@material-ui/core/FormControl";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { ConnectedReduxModuleProps } from "core";
import { CodeEditor } from "../common/CodeEditor";
import { ReduxModule } from "./reduxModule";
import { CodeInput } from "./types";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
  }),
);

const DefaultCodeInput = React.memo<
  ConnectedReduxModuleProps<ReduxModule, CodeInput>
>(
  ({
    value,
    element: {
      i18n: { label },
      config: { language, disabled },
    },
    changeValue,
  }) => {
    const { root } = useStyles();

    const handleInputChange = (
      _editor: Editor,
      _data: EditorChange,
      newValue: string,
    ) => changeValue(newValue || null);

    return (
      <FormControl fullWidth={true} className={root}>
        <CodeEditor
          value={value}
          label={label}
          onChange={handleInputChange}
          options={{ readOnly: disabled }}
          language={language}
          onAutoComplete={false}
          stylesAsMuiInput
        />
      </FormControl>
    );
  },
);

export default DefaultCodeInput;
