import React, { CSSProperties, memo } from "react";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import clsx from "classnames";

import { Translation } from "core";
import { useEditorTranslation } from "core/editor";

import IconButton from "elementTypes/common/IconButton";
import { FieldConfig } from "../../types";
import { useStyles } from "./styles";

type Props = {
  onClick: (params: FieldConfig) => void;
  onDelete: (name: string) => void;
  translateTitle: (i18n: Translation<"title">) => string | undefined;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  style?: CSSProperties;
} & FieldConfig;

export const FieldItem = memo<Props>(
  ({
    style,
    onClick,
    onDelete,
    translateTitle,
    provided,
    snapshot,
    ...props
  }) => {
    const { name, i18n } = props;
    const title = translateTitle(i18n) ?? name;
    const { editButton, deleteButton } = useEditorTranslation();
    const { draggingItem } = useStyles();
    const { isDragging } = snapshot;

    const handleEditClick = () => onClick(props as FieldConfig);
    const handleDelete = () => onDelete(name);

    return (
      <ListItem
        divider
        className={clsx({ [draggingItem]: isDragging })}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...style,
          ...provided.draggableProps.style,
        }}
        innerRef={provided.innerRef}
        selected={isDragging}
      >
        <Box overflow="auto" clone>
          <ListItemText primary={title} />
        </Box>
        <ListItemIcon>
          <IconButton
            icon="delete_outline"
            onClick={handleDelete}
            tooltip={deleteButton}
            color="error.main"
          />
          <IconButton
            icon="edit"
            onClick={handleEditClick}
            tooltip={editButton}
            edge="end"
          />
        </ListItemIcon>
      </ListItem>
    );
  },
);
