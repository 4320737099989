import { buildCustomExpressionValue } from "core";
import { IDefaultElement } from "core/types/element";
import { UntransformedDataDisplayConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedDataDisplayConfig> = {
  config: {
    dataSource: {
      viewName: "",
    },
    format: buildCustomExpressionValue("element.data"),
    color: "info",
  },
  i18n: {
    en: {
      label: "Data Display",
    },
  },
};
