import React, { ChangeEvent, memo, useCallback } from "react";
import TextField from "@material-ui/core/TextField";

import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { UntransformedCallButtonConfig } from "../../types";
import { useCallButtonEditorTranslation } from "../translation";
import { Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

type ConfigType = "callArgs" | "callName";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { callName, callArgs },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCallButtonConfig>();
  const { configTitle } = useEditorTranslation();
  const translation = useCallButtonEditorTranslation();

  const handleCallArgsChange = useCallback(
    (newValue: string) => changeConfigValue("callArgs", newValue),
    [changeConfigValue],
  );
  const handleCallNameChange = useCallback(
    (newValue: string) => changeConfigValue("callName", newValue),
    [changeConfigValue],
  );

  const handleExpressionValueChange = (configType: ConfigType) => (
    newValue: string,
  ) => {
    switch (configType) {
      case "callArgs":
        return handleCallArgsChange(newValue);
      case "callName":
        return handleCallNameChange(newValue);
    }
  };

  const customTextInput = ({
    value: textValue,
    onChange: onTextChange,
  }: NonExpressionEditorProps) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onTextChange(e.target.value);

    return (
      <TextField value={textValue} onChange={onCustomChange} fullWidth={true} />
    );
  };

  return (
    <Section title={configTitle} wrapped={true}>
      <CustomExpressionEditor
        label={translation.callNameLabel}
        value={callName}
        config={config}
        onChange={handleExpressionValueChange("callName")}
        nonExpressionEditor={customTextInput}
      />
      <CustomExpressionEditor
        label={translation.callArgsLabel}
        value={callArgs}
        config={config}
        onChange={handleExpressionValueChange("callArgs")}
        disableSwitcher={true}
      />
      <Alert severity="info">
        <Link
          href="http://postgrest.org/en/v7.0.0/api.html#stored-procedures"
          target="_blank"
        >
          {translation.functionDefinitionInfo}
        </Link>
      </Alert>
    </Section>
  );
});
