import { buildCustomExpressionValue } from "core";
import { IDefaultElement } from "core/types/element";
import { UntransformedInternalLinkFieldConfig } from "../types";

/* eslint-disable quotes */
export const defaultElement: IDefaultElement<UntransformedInternalLinkFieldConfig> = {
  config: {
    linkTo: {
      pageId: buildCustomExpressionValue('""'),
      params: {},
    },
    label: buildCustomExpressionValue('"Label"'),
  },
};
