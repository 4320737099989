import React, { memo } from "react";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule/types";
import { ProcedureButton } from "./types";

import Button from "../common/Button";

type Props = ConnectedReduxModuleProps<ReduxModule, ProcedureButton>;

const DefaultProcedureButton = memo<Props>(({ element, runProcedure }) => (
  <Button label={element.i18n.label} onClick={runProcedure} fullWidth={true} />
));

export default DefaultProcedureButton;
