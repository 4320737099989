import * as t from "io-ts";
import { IElement, UntransformedConfig, customExpression } from "core/types";

export const CircularProgressConfig = t.intersection([
  t.type({
    value: customExpression(t.union([t.string, t.number])),
  }),
  t.partial({
    size: t.union([t.string, t.number]),
    thickness: t.number,
    color: t.keyof({
      primary: null,
      secondary: null,
      inherit: null,
    }),
    variant: t.keyof({
      h1: null,
      h2: null,
      h3: null,
      h4: null,
      h5: null,
      h6: null,
      subtitle1: null,
      subtitle2: null,
      body1: null,
      caption: null,
      button: null,
      overline: null,
    }),
  }),
]);

export type CircularProgressConfig = t.TypeOf<typeof CircularProgressConfig>;

export type UntransformedCircularProgressConfig = UntransformedConfig<
  CircularProgressConfig
>;

export const CircularProgressTranslationKeys = ["label"] as const;

export type CircularProgressTranslationKeys = typeof CircularProgressTranslationKeys[number];

export type CircularProgress = IElement<
  CircularProgressConfig,
  {},
  CircularProgressTranslationKeys
>;
