import React, { memo } from "react";

import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";

import { Element } from "core";

import useStyles from "./styles";
import { Props } from "./container";
import { LoadingComponent } from "layouts/common/Loading";
import { FormActions, FormErrors } from "./components";

const DefaultForm = memo<Props>(
  ({
    element: {
      children: { content },
      config: { type, linkTo, hideSaveAndStayButton },
    },
    loadState,
    saveState,
    data,
    isValid,
    hasChanges,
    save: handleSave,
    reset: handleReset,
    goBack,
    allowedStateChanges,
    stateFieldValue,
  }) => {
    const classes = useStyles();
    const loading =
      loadState.inProgress || saveState.inProgress || data === null;

    return (
      <Paper className={classes.root} variant={"outlined"}>
        {!loadState.inProgress ? (
          <>
            <div className={classes.body}>
              {content.elements.map((el) => (
                <Element key={el.id} element={el} />
              ))}
            </div>
            <FormErrors
              errors={{ load: loadState.error, save: saveState.error }}
            />
          </>
        ) : (
          <LoadingComponent />
        )}

        {type !== "detail" && (
          <div>
            <Divider className={classes.divider} />
            <FormActions
              isValid={isValid}
              hasChanges={hasChanges}
              handleSave={handleSave}
              handleReset={handleReset}
              goBack={goBack}
              loading={loading}
              canRedirect={!!linkTo}
              type={type}
              allowedStateChanges={allowedStateChanges}
              stateFieldValue={stateFieldValue}
              hideSaveAndStayButton={hideSaveAndStayButton}
            />
          </div>
        )}
      </Paper>
    );
  },
);

DefaultForm.displayName = "DefaultForm";

export default DefaultForm;
