import { createContext, useContext } from "react";

import { IReduxModuleContext } from "./types";

const ReduxModuleContext = createContext<IReduxModuleContext>({
  context: {},
  scope: "",
  idMaps: [{}],
  getModule: () => null as any,
});

export function useReduxModuleContext() {
  const context = useContext<IReduxModuleContext>(ReduxModuleContext);
  if (context === undefined) {
    throw new Error(
      "useReduxModuleContext must be used within a ReduxModuleContext.Provider",
    );
  }
  return context;
}

export default ReduxModuleContext;
