import React, { ChangeEvent, memo, useCallback, useMemo } from "react";
import TablePagination from "@material-ui/core/TablePagination";

import { ITableParams } from "../reduxModule";
import { useStyles } from "../style";
import { useTableTranslation } from "../translation";
import IconButton from "../../common/IconButton";

function labelDisplayedRows({ from, to }: { from: number; to: number }) {
  return `${from} - ${to}`;
}

type TableFooterProps = {
  params: ITableParams;
  data: Record<string, unknown>[];
  onRefresh: () => void;
  nextPageAvailable: boolean;
  onPageSizeChange: (size: number) => void;
  onChangePage: (page: number) => void;
  rowsPerPageOptions?: number[];
  loading?: boolean;
};

export const TableFooter = memo<TableFooterProps>(
  ({
    data,
    params: { limit, offset },
    onPageSizeChange,
    onRefresh,
    onChangePage,
    nextPageAvailable,
    rowsPerPageOptions,
    loading,
  }) => {
    const {
      buttonWrapper,
      footerWrapper,
      paginationClass,
      footerToolbar,
    } = useStyles();
    const rowCount = useMemo(() => (data ? data.length : 0), [data]);
    const page = useMemo(() => offset / limit, [offset, limit]);
    const paginationMaxNum = useMemo(
      () =>
        nextPageAvailable
          ? rowCount < limit
            ? limit * offset + (rowCount + 1)
            : Infinity
          : rowCount + offset,
      [rowCount, offset, limit, nextPageAvailable],
    );

    const handleChangePage = useCallback(
      (_: unknown, newPage: number) =>
        newPage > page
          ? onChangePage(offset + limit)
          : onChangePage(Math.max(0, offset - limit)),
      [limit, offset, onChangePage, page],
    );

    const handlePageSizeChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) =>
        onPageSizeChange(parseInt(e.target.value, 10)),
      [onPageSizeChange],
    );

    const handleRefresh = useCallback(() => onRefresh(), [onRefresh]);

    const {
      buttonNextPage,
      buttonPreviousPage,
      buttonRefresh,
    } = useTableTranslation();

    const refreshBtn = useMemo(
      () => (
        <IconButton
          icon="refresh"
          data-test-selector="table-refresh"
          tooltip={buttonRefresh}
          onClick={handleRefresh}
          color="secondary"
          processing={loading}
        />
      ),
      [buttonRefresh, handleRefresh, loading],
    );

    return (
      <footer className={footerWrapper}>
        <section className={paginationClass}>
          <TablePagination
            className={footerToolbar}
            labelDisplayedRows={labelDisplayedRows}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={paginationMaxNum}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              "aria-label": buttonPreviousPage,
            }}
            nextIconButtonProps={{
              "aria-label": buttonNextPage,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handlePageSizeChange}
          />
          <div className={buttonWrapper}>{refreshBtn}</div>
        </section>
      </footer>
    );
  },
);

TableFooter.displayName = "TableFooter";
