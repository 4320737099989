import { IStaticRouteConfig } from "core/router/types";

import { TablesAuditPage } from "./pages/tables/container";

import * as reduxModule from "./pages/tables/reduxModule";

import { TablesAuditPageRoute, UsersAuditPageRoute } from "./pages";

export const route: IStaticRouteConfig = {
  reduxModule,
  Component: TablesAuditPage,
  auth: true,
  isAdmin: true,
  routes: {
    "/admin/audits/tables": TablesAuditPageRoute,
    "/admin/audits/users": UsersAuditPageRoute,
  },
};
