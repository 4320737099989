import Component from "./container";
import {
  ExternalLinkFieldConfig,
  ExternalLinkFieldTranslationKeys,
} from "./types";
import {
  ExternalLinkFieldEditor,
  defaultElement,
  editorMetadata,
} from "./editor";

export default {
  name: "default_external_link_field",
  component: Component,
  editable: true,
  configType: ExternalLinkFieldConfig,
  editorComponent: ExternalLinkFieldEditor,
  translationKeys: ExternalLinkFieldTranslationKeys,
  defaultElement,
  editorMetadata,
};
