import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  EchartsPieChartEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { PieChartConfig, selectorTypes } from "./types";
import { ElementGroup, IRawElementType } from "core";
import { EChartsTranslationKeys } from "../common/Echarts";

const elementType: IRawElementType = {
  name: "echarts_pie_chart",
  component: Component,
  group: ElementGroup.Basic,
  defaultElement,
  editorComponent: EchartsPieChartEditor,
  reduxModuleFactory,
  editable: true,
  configType: PieChartConfig,
  editorMetadata,
  translationKeys: EChartsTranslationKeys,
  selectorTypes,
};

export default elementType;
