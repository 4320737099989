import React, {
  ComponentClass,
  FunctionComponent,
  createElement,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import ErrorIcon from "@material-ui/icons/ErrorOutline";

import { useIsMounted } from "utils/hooks";
import { toUpperCamelCase } from "utils/string";
import iconMap from "./iconMap";

export type IMuiIconProps = {
  icon: string;
  className?: string;
} & Partial<SvgIconProps>;

export const MuiIcon = memo<IMuiIconProps>(({ icon, ...rest }) => {
  const [iconComponent, setComponent] = useState<
    FunctionComponent | ComponentClass | null
  >(null);
  const isMounted = useIsMounted();

  const getIcon = useCallback(async () => {
    try {
      const icons = await require("@material-ui/icons");
      if (isMounted()) {
        setComponent(
          icons[iconMap[icon] || toUpperCamelCase(icon)] || ErrorIcon,
        );
      }
    } catch (error) {
      if (isMounted()) {
        setComponent(ErrorIcon);
      }
    }
  }, [icon, isMounted]);

  useEffect(() => {
    getIcon();
  }, [getIcon]);

  if (iconComponent) {
    iconComponent.displayName = "IconComponent";
    return createElement(iconComponent, { fontSize: "inherit", ...rest });
  }

  return <CircularProgress size={24} className={rest.className} />;
});

MuiIcon.displayName = "MuiIcon";
