import React, { memo, useMemo } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { AdminUsersPage } from "./types";
import { ConnectedStaticReduxModuleActionsProps } from "core/types/react";
import { RouterReduxModule } from "core/router/types";
import Button from "elementTypes/common/Button";
import Link from "elementTypes/common/Link";
import { IUser } from "./reduxModule/types";
import useStyles from "../../styles";
import { Table, TableRow } from "../../common";

type Props = ConnectedStaticReduxModuleActionsProps<RouterReduxModule> &
  AdminUsersPage;

interface RowProps {
  user: IUser;
  deleteUser: (id: IUser["id"]) => void;
  isCurrentUser: boolean;
}

const Row = memo<RowProps>(({ user, deleteUser, isCurrentUser }) => {
  const onDeleteClick = () => {
    deleteUser(user.id);
  };

  return (
    <TableRow rowId={user.id}>
      <Link
        variant="body2"
        title="View user info"
        href={`/admin/users/view/${user.id}`}
      >
        {user.email}
      </Link>
      <Typography>{user.userName}</Typography>
      <Typography>{user.role}</Typography>
      <Typography>{user.language}</Typography>
      <Checkbox disabled checked={user.isAdmin} />
      <Checkbox disabled checked={user.isActive} />
      <>
        <Button
          color="secondary"
          label="Edit"
          iconLeft="create"
          href={`/admin/users/edit/${user.id}`}
        />
        <Button
          color="secondary"
          label="Delete"
          iconLeft="delete"
          onClick={onDeleteClick}
          disabled={isCurrentUser}
        />
      </>
    </TableRow>
  );
});

export const UsersPage = memo<Props>(
  ({ userList, load, loading, error, deleteUser, currentEmail }) => {
    const titles = [
      "Email",
      "Username",
      "Role",
      "Language",
      "Admin",
      "Active",
      "Actions",
    ];

    const rows = useMemo(
      () =>
        userList?.map((user) => (
          <Row
            key={user.id}
            user={user}
            deleteUser={deleteUser}
            isCurrentUser={currentEmail === user.email}
          />
        )),
      [userList, currentEmail, deleteUser],
    );
    const { horizontallyCenter } = useStyles();

    return (
      <Grid container spacing={2} className={horizontallyCenter}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h5">Users</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{ display: "inline-flex", justifyContent: "flex-end" }}
        >
          <Button
            color="primary"
            href="/admin/users/create"
            iconLeft="add"
            label="Create"
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            rows={rows}
            headers={titles.map((title) => ({
              name: title.toLowerCase(),
              title,
            }))}
            onDataReload={load}
            loading={loading}
            error={error}
          />
        </Grid>
      </Grid>
    );
  },
);
