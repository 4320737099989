import React, { memo } from "react";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";
import { BarChart } from "./types";

import {
  EchartsContainerDataset,
  IEchartsContainerMultiProps,
  IOptionEchartProps,
} from "../common/Echarts";
import { LoadingComponent } from "layouts/common/Loading";
import { AlertBox } from "elementTypes/common/AlertBox";

type Props = ConnectedReduxModuleProps<ReduxModule, BarChart>;

const EchartsBarChart = memo<Props>(
  ({
    element: {
      config: { dataSource, hideLegend, hideLabel, showBackground, isStacked },
      i18n: { title },
    },
    error,
    data,
  }) => {
    const propsEchartsContainer = {
      title,
      type: "bar",
      defaultOptions: {
        xAxis: {
          axisTick: {
            alignWithLabel: true,
          },
        },
        tooltip: {
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: !hideLegend,
        },
        series: [
          {
            avoidLabelOverlap: true,
            label: {
              show: !hideLabel,
            },
            ...(isStacked ? { stack: "stack1" } : {}),
          },
        ],
      } as IOptionEchartProps,
      showBackground,
    } as IEchartsContainerMultiProps;

    if (!dataSource.viewName) {
      return (
        <AlertBox title="Config error" message={"View Name is required"} />
      );
    }

    if (error) {
      return <AlertBox title="Error" message={error} />;
    }

    if (!data) {
      return <LoadingComponent />;
    }

    return (
      <EchartsContainerDataset
        {...propsEchartsContainer}
        data={data}
        dataSource={dataSource}
      />
    );
  },
);

export default EchartsBarChart;
