import { selectorScoper } from "core/utils/redux";
import { IState } from "./types";
import { CustomChart } from "../types";

export function buildSelectors(path: string[], element: CustomChart) {
  const scopeSelector = selectorScoper<IState>(path);

  const loading = (state: any) => scopeSelector(state).loading;
  const data = (state: any) => scopeSelector(state).data;
  const error = (state: any) => scopeSelector(state).error;
  const option = (state: any) => element.config.option(state);

  return {
    loading,
    data,
    error,
    option,
  };
}
