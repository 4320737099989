import * as t from "io-ts";
import {
  IElement,
  SelectorTypes,
  TypeFactory,
  UntransformedConfig,
  arrayChild,
  customExpression,
} from "core/types";
import { Type, types } from "core/runtime-typing";
import { IAsyncActionState } from "core/utils/types";
import { selectors } from "core/editor/reduxModule";
import { buildNullableObjectViewType } from "core/runtime-typing/utils";

export const FormMultiReferenceConfig = t.type({
  viewName: t.string,
  identifierFieldName: t.string,
  referencingFieldName: t.string,
});

export type FormMultiReferenceConfig = t.TypeOf<
  typeof FormMultiReferenceConfig
>;

export const FormConfig = t.intersection([
  t.type({
    dataSource: t.intersection([
      t.type({
        viewName: t.string,
      }),
      t.partial({
        identifierName: t.string,
        identifierValue: customExpression(t.union([t.number, t.string])),
        multiReference: t.record(t.string, FormMultiReferenceConfig),
        stateFieldName: t.string,
      }),
    ]),
    type: t.union([
      t.literal("edit"),
      t.literal("create"),
      t.literal("detail"),
    ]),
  }),
  t.partial({
    linkTo: t.type({
      pageId: customExpression(t.string),
      params: t.record(t.string, customExpression(t.unknown)),
    }),
    jsonSchema: t.object,
    validation: customExpression(t.boolean),
    defaultData: t.record(t.string, customExpression(t.unknown)),
    hideSaveAndStayButton: t.boolean,
  }),
]);

export const FormChildren = t.type({
  content: arrayChild("*", {
    positioned: true,
  }),
});

const dataSelector: TypeFactory<FormConfig> = ({ config, state }) => {
  let type: Type;
  const { multiReference, viewName } = config.dataSource;
  const viewList = selectors.viewList(state);
  if (!viewName || !viewList) {
    type = types.any();
  } else {
    const view = viewList.find((v) => v.name === viewName);
    if (!view) {
      throw new Error(`Invalid view ${viewName}`);
    }
    const referenceTypes: Record<PropertyKey, Type> = {};
    if (multiReference) {
      for (const referenceField in multiReference) {
        const referenceViewName = multiReference[referenceField].viewName;
        const referenceView = viewList.find(
          (v) => v.name === referenceViewName,
        );
        if (!referenceView) {
          throw new Error(`Invalid view ${referenceViewName}`);
        }
        referenceTypes[referenceField] = types.array(
          buildNullableObjectViewType(referenceView, true),
        );
      }
    }
    type = buildNullableObjectViewType(view, true, undefined, referenceTypes);
    type = types.nullable(type);
  }
  return type;
};

export const formSelectors: SelectorTypes<FormConfig> = {
  loadState: IAsyncActionState,
  saveState: IAsyncActionState,
  data: dataSelector,
  errors: types.anyRecord(),
  originalData: dataSelector,
  touched: types.record(
    types.union([types.string(), types.number()]),
    types.boolean(),
  ),
  hasChanges: types.optional(types.boolean()),
  isValid: types.optional(types.boolean()),
  identifier: types.optional(types.union([types.string(), types.number()])),
};

export type FormConfig = t.TypeOf<typeof FormConfig>;

export type UntransformedFormConfig = UntransformedConfig<FormConfig>;

export type FormChildren = t.TypeOf<typeof FormChildren>;

export type Form = IElement<FormConfig, FormChildren>;
