import { ConnectedProps, connect } from "react-redux";
import { selectors, actions as sessionActions } from "core/session/reduxModule";
import { LanguageSwitch as LSComponent } from "./component";

const mapStateToProps = (state: any) => ({
  language: selectors.currentLanguage(state),
});

const connector = connect(mapStateToProps, sessionActions);

export type ReduxProps = ConnectedProps<typeof connector>;

export const LanguageSwitch = connector(LSComponent);
