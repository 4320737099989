import { all, put, takeLatest } from "redux-saga/effects";
import { stringify } from "query-string";

import { LOGIN_URL, actions as routerActions } from "core/router/reduxModule";
import history from "core/router/reduxModule/history";
import { parseQueries } from "core/router/reduxModule/utils";
import { types } from "./actions";

function* redirectSaga() {
  const { next, ui } = parseQueries(history.location.search);

  const queries = { next, ui };

  yield put(routerActions.push(`${LOGIN_URL}?${stringify(queries)}`));
}

export function* saga() {
  yield all([yield takeLatest(types.REDIRECT, redirectSaga)]);
}
