import React, { memo } from "react";

import { Controller, useForm } from "react-hook-form";

import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Button from "elementTypes/common/Button";

import { AdminEditUsersPage, IEditUserForm } from "../types";

import useStyles from "../styles";

export const Form = memo<
  Pick<Required<AdminEditUsersPage>, "editUser" | "rolesList" | "user">
>(({ editUser, rolesList, user }) => {
  const { formPaper, footer, dividerFooter } = useStyles();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isSubmitted, isValid, dirty },
    control,
  } = useForm<IEditUserForm>({
    mode: "onBlur",
    defaultValues: {
      isActive: user!.isActive,
      password: "",
      role: user!.role,
    },
  });

  const submit = (data: IEditUserForm) => {
    const userData: IEditUserForm = {};

    if (data.isActive !== user!.isActive) {
      userData.isActive = data.isActive;
    }

    if (data.role !== user!.role) {
      userData.role = data.role;
    }

    if (data.password) {
      userData.password = data.password;
    }

    editUser(user!.id, userData);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(submit)}>
      <Paper variant="outlined" className={formPaper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              defaultValue={{ name: user!.role }}
              options={rolesList}
              getOptionLabel={(option: { name: string }) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  name="role"
                  inputRef={register({})}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={<FormControlLabel control={<Checkbox />} label={"Active"} />}
              name="isActive"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              label="Password"
              type="password"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({})}
            />
          </Grid>

          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              disabled={isSubmitting || isSubmitted || !isValid || !dirty}
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Edit"
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
