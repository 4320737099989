import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  NumberInputConfig,
  NumberInputTranslationKeys,
  numberInputSelectors,
} from "./types";
import { NumberInputEditor, defaultElement, editorMetadata } from "./editor";
import { ElementGroup, IRawElementType } from "core";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_number_input",
  group: ElementGroup.Inputs,
  component: Component,
  editable: true,
  configType: NumberInputConfig,
  selectorTypes: numberInputSelectors,
  translationKeys: NumberInputTranslationKeys,
  editorComponent: NumberInputEditor,
  defaultElement,
  editorMetadata,
};

export default elementType;
