import Component from "./container";
import { MarkdownFieldEditor, defaultElement, editorMetadata } from "./editor";
import { MarkdownFieldConfig, MarkdownFieldTranslationKeys } from "./types";
import { ElementGroup, IRawElementType } from "core";

const elementType: IRawElementType = {
  name: "default_markdown_field",
  component: Component,
  group: ElementGroup.Basic,
  configType: MarkdownFieldConfig,
  editorComponent: MarkdownFieldEditor,
  translationKeys: MarkdownFieldTranslationKeys,
  defaultElement,
  editorMetadata,
};

export default elementType;
