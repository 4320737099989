import * as t from "io-ts";
import { IElement, customExpression } from "core/types";
import { WithDataSourceConfig } from "elementInterfaces/FormDataSource";

export const DeleteButtonConfig = t.intersection([
  WithDataSourceConfig,
  t.partial({
    showWarning: t.boolean,
    icon: t.string,
    disabled: t.union([t.boolean, customExpression(t.boolean)]),
    color: t.keyof({
      primary: null,
      secondary: null,
      default: null,
    }),
    size: t.keyof({
      small: null,
      medium: null,
    }),
    placement: t.keyof({
      top: null,
      bottom: null,
      left: null,
      right: null,
    }),
  }),
]);

export type DeleteButtonConfig = t.TypeOf<typeof DeleteButtonConfig>;

export const DeleteButtonTranslationKeys = [
  "label",
  "iconRight",
  "iconLeft",
  "title",
  "warning",
  "submitTitle",
  "cancelTitle",
] as const;

export type DeleteButtonTranslationKeys = typeof DeleteButtonTranslationKeys[number];

export type DeleteButton = IElement<
  DeleteButtonConfig,
  {},
  DeleteButtonTranslationKeys
>;
