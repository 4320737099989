import { connectElement } from "core/utils/react-redux";
import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { ClipboardCopyButton } from "./types";
import { ConnectedReduxModuleProps } from "core/types/react";

export type ClipboardCopyButtonProps = ConnectedReduxModuleProps<
  ReduxModule,
  ClipboardCopyButton
>;

export default connectElement<ReduxModule, ClipboardCopyButton>()(Component);
