import React, { memo } from "react";
import { Controller, useForm } from "react-hook-form";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import { LANGUAGES } from "core/types/i18n";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import BackButton from "elementTypes/common/BackButton";
import { AdminCreateUsersPage, IUserForm } from "./types";
import useStyles from "./styles";

export const CreateUsersPage = memo<AdminCreateUsersPage>(
  ({ addUser, rolesList, language, getRoles }) => {
    const { root, footer, dividerFooter } = useStyles();
    const {
      handleSubmit,
      register,
      formState: { isSubmitting, isSubmitted, isValid },
      control,
    } = useForm<IUserForm>({ mode: "onChange" });

    const submit = (data: IUserForm) => {
      addUser(data);
    };

    return (
      <form onSubmit={handleSubmit(submit)}>
        <Paper variant="outlined" className={root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email"
                type="email"
                fullWidth={true}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="userName"
                label="Username"
                fullWidth={true}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                label="Password"
                type="password"
                fullWidth={true}
                inputRef={register({
                  required: true,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    select
                    label="Owner"
                    margin="dense"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          size="small"
                          icon="refresh"
                          tooltip="Refresh"
                          onClick={getRoles}
                        />
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {rolesList.map(({ name }) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="role"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel shrink={true} id="language">
                Language
              </InputLabel>
              <Controller
                as={
                  <Select value={language.code} fullWidth={true}>
                    {LANGUAGES.map((lang) => (
                      <MenuItem key={lang.code} value={lang.code}>
                        {lang.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="language"
                rules={{ required: true }}
                control={control}
                defaultValue={language.code}
              />
            </Grid>

            <Grid item xs={12} className={footer}>
              <Divider className={dividerFooter} />
              <Button
                color="secondary"
                disabled={isSubmitting || isSubmitted || !isValid}
                processing={isSubmitting}
                iconRight="forward"
                type="submit"
                label="Create"
              />
            </Grid>
          </Grid>
        </Paper>
        <BackButton />
      </form>
    );
  },
);
