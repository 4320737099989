import { ConnectedProps, connect } from "react-redux";

import { IElementComponentProps } from "core";
import { connectErrorHandlerUtils } from "core/utils/react-redux";
import Component from "./component";
import { GeoJSONField } from "./types";

const mapStateToProps = (
  state: any,
  {
    element: { config },
  }: IElementComponentProps<Record<string, unknown>, GeoJSONField>,
) => ({
  value: config.value(state),
  getStyle: config.styleFunction?.(state),
  getTooltip: config.tooltipFunction?.(state),
  getMarkerBackgroundColor:
    typeof config.markerBackgroundColorFunction === "string"
      ? config.markerBackgroundColorFunction
      : config.markerBackgroundColorFunction?.(state),
  getMarkerDisplayText: config.markerDisplayTextFunction?.(state),
});

const connector = connect(
  connectErrorHandlerUtils.enhanceMapStateToProps(mapStateToProps),
);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(connectErrorHandlerUtils.enhanceComponent(Component));
