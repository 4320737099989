import { buildCustomExpressionValue } from "core";
import { IDefaultElement } from "core/types/element";
import { UntransformedCallButtonConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedCallButtonConfig> = {
  i18n: {
    en: {
      label: "",
    },
  },
  config: {
    callArgs: buildCustomExpressionValue("{}"),
    callName: "",
  },
};
