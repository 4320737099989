import { createAction, createActionTypeScoper } from "core/utils/redux";

import { FieldPath } from "elementInterfaces";

import { actions as sessionActions } from "core/session/reduxModule";
import { IApiError } from "core/types";
import { TableMetadataStateChange } from "elementTypes/default_table/types";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    LOAD: scopeActionType("LOAD"),
    LOAD_SUCCESS: scopeActionType("LOAD_SUCCESS"),
    LOAD_ERROR: scopeActionType("LOAD_ERROR"),
    FIELD_VALUE_CHANGE: scopeActionType("FIELD_VALUE_CHANGE"),
    FIELD_TOUCHED_CHANGE: scopeActionType("FIELD_TOUCHED_CHANGE"),
    SUB_FORM_ADD: scopeActionType("SUB_FORM_ADD"),
    SUB_FORM_REMOVE: scopeActionType("SUB_FORM_REMOVE"),
    SAVE: scopeActionType("SAVE"),
    SAVE_SUCCESS: scopeActionType("SAVE_SUCCESS"),
    SAVE_ERROR: scopeActionType("SAVE_ERROR"),
    RESET: scopeActionType("RESET"),
    SAVE_ERRORS: scopeActionType("SAVE_ERRORS"),
    SET_ALLOWED_STATE_CHANGES: scopeActionType("SET_ALLOWED_STATE_CHANGES"),
    SET_STATE_FIELD_VALUE: scopeActionType("SET_STATE_FIELD_VALUE"),
  };

  const actions = {
    load: createAction(types.LOAD),
    loadSuccess: createAction(types.LOAD_SUCCESS, (data: any) => ({ data })),
    loadError: createAction(types.LOAD_ERROR, (error: string | IApiError) => ({
      error,
    })),
    save: createAction(types.SAVE, (linkTo?: boolean) => ({
      linkTo: linkTo ?? false,
    })),
    saveSuccess: createAction(types.SAVE_SUCCESS, (data: any) => ({ data })),
    saveError: createAction(types.SAVE_ERROR, (error: string | IApiError) => ({
      error,
    })),
    reset: createAction(types.RESET),
    changeFieldValue: createAction(
      types.FIELD_VALUE_CHANGE,
      (fieldPath: FieldPath, value: any) => ({ fieldPath, value }),
    ),
    changeFieldTouched: createAction(
      types.FIELD_TOUCHED_CHANGE,
      (fieldPath: FieldPath, value: boolean) => ({ fieldPath, value }),
    ),
    saveErrors: createAction(
      types.SAVE_ERRORS,
      (errors: Record<string, unknown>, failedData?: any) => ({
        errors,
        failedData,
      }),
    ),
    addSubForm: createAction(types.SUB_FORM_ADD, (fieldPath: FieldPath) => ({
      fieldPath,
    })),
    removeSubForm: createAction(
      types.SUB_FORM_REMOVE,
      (subFormPath: FieldPath) => ({ subFormPath }),
    ),
    enqueueSnackbar: sessionActions.enqueueSnackbar,
    setAllowedStateChanges: createAction(
      types.SET_ALLOWED_STATE_CHANGES,
      (allowedStateChanges: TableMetadataStateChange[]) => ({
        allowedStateChanges,
      }),
    ),
    setStateFieldValue: createAction(
      types.SET_STATE_FIELD_VALUE,
      (stateFieldValue: string) => ({
        stateFieldValue,
      }),
    ),
  };

  return { types, actions };
}
