import { createUseTranslation } from "core/session/translation";

export const dateTimeFieldEditorTranslation = {
  en: {
    valueLabel: "Date Time",
    isRelativeLabel: "Is Relative",
    showDatePartLabel: "Show Date",
    showTimePartLabel: "Show Time",
  },
};

export const useDateTimeFieldEditorTranslation = createUseTranslation(
  dateTimeFieldEditorTranslation,
);
