import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import { FormEditor, defaultElement, editorMetadata } from "./editor";
import { FormChildren, FormConfig, formSelectors } from "./types";
import { IRawElementType } from "core";

const elementType: IRawElementType = {
  defaultElement,
  name: "default_form",
  reduxModuleFactory,
  component: Component,
  editable: true,
  configType: FormConfig,
  childrenType: FormChildren,
  selectorTypes: formSelectors,
  editorComponent: FormEditor,
  editorMetadata,
};

export default elementType;
