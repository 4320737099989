import React from "react";
import { useDispatch } from "react-redux";
import clsx from "classnames";

import { actions as routerActions } from "core/router/reduxModule";
import history from "core/router/reduxModule/history";
import Button from "elementTypes/common/Button";
import useStyles from "./styles";

type BackButtonProps = {
  className?: string;
};

export const BackButton = React.memo<BackButtonProps>(({ className }) => {
  const { backButton } = useStyles();
  const dispatch = useDispatch();

  function handleBackButtonClick() {
    dispatch(routerActions.goBack());
  }

  if (history.length <= 1) {
    return null;
  }

  return (
    <Button
      className={clsx(backButton, className)}
      label="Back"
      iconLeft="arrow_back"
      onClick={handleBackButtonClick}
      color="secondary"
    />
  );
});
