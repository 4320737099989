import { IDefaultElement } from "core";
import { UntransformedFormConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedFormConfig> = {
  config: {
    dataSource: {
      viewName: "",
    },
    type: "create",
  },
  children: {
    content: {
      elements: [],
    },
  },
};
