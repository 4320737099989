import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import { actions as sessionActions } from "core/session/reduxModule/actions";
import { actions as routerActions } from "core/router/reduxModule";
import { actions, types } from "./actions";
import AdminService from "services/admin";
import { getLoginConfig } from "services/api";

const services = AdminService.getInstance();

function* loadSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const data = yield call(getLoginConfig, token);
    yield put(actions.loadSuccess(data));
  } catch (error) {
    yield put(actions.loadError(error.message ?? error.toString()));
  }
}

function* editConfigSaga(action: ReturnType<typeof actions["editConfig"]>) {
  const token = yield select(sessionSelectors.token);

  try {
    yield call(services.editLoginConfig, action.payload.config, token);
    yield put(
      sessionActions.enqueueSnackbar({
        message: "Settings Configuration edited successfully",
        options: {
          variant: "success",
        },
      }),
    );
    yield put(routerActions.push("/admin/settings"));
  } catch (error) {
    yield put(actions.editConfigError(error.message ?? error.toString()));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "Settings Configuration was not edited",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

export function* saga() {
  yield all([
    yield takeLatest(types.LOAD, loadSaga),
    yield takeLatest(types.EDIT_CONFIG, editConfigSaga),
  ]);

  yield put(actions.load());
}
