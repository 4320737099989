import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";

import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import { UntransformedStorageImageConfig } from "../../types";
import { useEditorStorageImageTranslation } from "../translation";

export const ConfigComponent = React.memo(() => {
  const {
    elementModel: {
      id,
      config,
      config: { path, fullSizeOnClick, isUsingFileStorage },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedStorageImageConfig>();
  const translation = useEditorStorageImageTranslation();

  const wrappedChangePath = React.useCallback(
    (newValue: string) => {
      changeConfigValue("path", newValue);
    },
    [changeConfigValue],
  );

  const handleFullSizeChange = React.useCallback(
    () => changeConfigValue("fullSizeOnClick", !fullSizeOnClick),
    [changeConfigValue, fullSizeOnClick],
  );

  const handleUsingFileStorage = React.useCallback(
    () => changeConfigValue("isUsingFileStorage", !isUsingFileStorage),
    [changeConfigValue, isUsingFileStorage],
  );

  const pathNonExpressionEditor: React.ComponentProps<
    typeof CustomExpressionEditor
  >["nonExpressionEditor"] = React.useCallback(
    ({ value, onChange }) => (
      <TextField
        data-cypress-selector="storage-image-non-expression-path"
        fullWidth={true}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    ),
    [],
  );

  return (
    <Section title={translation.configSectionTitle} wrapped={true}>
      <TableColumnEditor
        id={id}
        value={path}
        onChange={wrappedChangePath}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text"]}
      />
      <CustomExpressionEditor
        value={path}
        config={config}
        onChange={wrappedChangePath}
        label="Path"
        nonExpressionEditor={pathNonExpressionEditor}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(isUsingFileStorage)}
            onChange={handleUsingFileStorage}
          />
        }
        label={translation.isUsingFileStorageLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(fullSizeOnClick)}
            onChange={handleFullSizeChange}
          />
        }
        label={translation.fullSizeLabel}
      />
    </Section>
  );
});
