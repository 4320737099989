import { createSelector } from "reselect";

import {
  compiledRoutes,
  creatingPage,
  error,
  isAdminPage,
  isStaticPage,
  keysByKeyAliases,
  loadingPage,
  locationWithParams,
  notFound,
  page,
  pageElement,
  params,
  preventLocationChange,
  staticPageId,
} from "./baseSelectors";

import { selectors as sessionSelectors } from "../../session/reduxModule/selectors";
import { selectors as editorSelectors } from "../../editor/reduxModule/selectors";
import { IPage } from "../../types";

const allCompiledRoutes = createSelector(
  [compiledRoutes, editorSelectors.newRoutes],
  (routesCompiled, newRoutes) =>
    newRoutes?.length ? newRoutes : routesCompiled || [],
);

const allPages = createSelector(
  [sessionSelectors.appMetadata, editorSelectors.newPages],
  (appMetadata, newPages) =>
    Object.keys(newPages).length
      ? newPages
      : (appMetadata?.release.definition.pages as Record<string, IPage>),
);

export const selectors = {
  loadingPage,
  page,
  creatingPage,
  pageElement,
  staticPageId,
  isAdminPage,
  isStaticPage,
  error,
  notFound,
  preventLocationChange,
  keysByKeyAliases,
  compiledRoutes: allCompiledRoutes,
  allPages,
  params,
  location: locationWithParams,
};
