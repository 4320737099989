import { IDefaultElement } from "core/types/element";
import { AdvancedGoogleMapsConfig } from "../types";

export const defaultElement: IDefaultElement<AdvancedGoogleMapsConfig> = {
  config: {
    dataSource: {
      viewName: "",
      latColumnName: "",
      lngColumnName: "",
      placeName: "",
    },
    key: "",
    defaultZoom: 2,
    defaultCenter: {
      lat: 0,
      lng: 0,
    },
  },
};
