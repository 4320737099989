import { createUseTranslation } from "../session/translation";

export const editorTranslation = {
  en: {
    configTab: "Configuration",
    elementsTab: "Elements",
    descriptionTitle: "Description",
    elementsTitle: "Elements",
    menuTitle: "Menu Entries",
    historyTitle: "History",
    newPageTitle: "New Page",
    editPageTitle: "Edit Page",
    newPageSubTitle: "Create an empty page",
    openPageSubTitle: "To open this page, please enter required params:",
    pagesTitle: "Pages",
    previewTitle: "This is a Preview Mode!",
    stylesTitle: "Styles",
    selectedTitle: "Selected Element:",
    applyButton: "Apply",
    revertButton: "Revert",
    editButton: "Edit",
    previewButton: "Changes Preview",
    cancelButton: "Cancel",
    closeButton: "Close",
    createPageButton: "Create New Page",
    duplicateButton: "Duplicate",
    duplicateWarning:
      "Duplicating an element with nested elements currently is not supported",
    deleteButton: "Delete",
    backButton: "Back to the Edit Mode",
    createButton: "Create",
    releaseButton: "Create Release",
    saveButton: "Save",
    submitButton: "Submit",
    updateButton: "Update",
    addPage: "Add Page",
    nameField: "Page Name",
    autoNameField: "Automatically set name",
    hiddenFieldLabel: "Field Hidden",
    titleField: "Title",
    generateMenuField: "Generate Menu Entry",
    pageParamsWarning:
      "Page with parameters will be unreachable in the menu list",
    copyTooltip: "Click To Copy",
    copiedTooltip: "Copied",
    helpTooltip: "Help",
    topTooltip: "Move to Top",
    bottomTooltip: "Move To Bottom",
    leftTooltip: "Move To Left",
    rightTooltip: "Move To Right",
    leftPanelTooltip:
      "Left Panel: Setup the Application. Pages, Menu, History, and Styles.",
    rightPanelTooltip: "Right Panel: Create and Edit Elements",
    createElementTooltip: "Create Element",
    showMoreTooltip: "Show More",
    requiredTooltip: "Required",
    notEditableMsg: "The selected element is not editable",
    notSelectedMsg: "Select an element in the page to edit",
    requiredError: "Value is Required",
    invalidError: "Invalid Value",
    notMatchError:
      "Value can only contain numbers, letters, hyphens and underscores",
    notMatchPathError:
      "URL can only contain numbers, letters, hyphens, underscores and slashes",
    notUniqueError: "Value Should be Unique",
    existingUrlError: "A page with URL * already exists",
    deleteConfirmation: "Delete the element",
    exitConfirmation:
      "The layout has unsaved changes that will not be applied!",
    saveDialogTitle: "Save",
    releaseDialogTitle: "New Release",
    descriptionDialogTextFieldPlaceholder:
      "Short description of the current changes",
    descriptionDialogErrorMessage:
      "Please set a short description of the current changes",
    discardChangesButton: "Discard",
    discardChangesPopupButton: "Discard",
    cancelDiscardChangesPopupButton: "Cancel",
    discardChangesConfirmation: "Discard all changes",
    paramButton: "Parameter",
    deleteParamTooltip: "Delete Parameter",
    noPageLabel: "No Page Found",
    viewsTitle: "Views",
    refreshButton: "Refresh",
    noViewsLabel: "No Views Found",
    pageCreateSuccess: "Page Created",
    pageDeleteSuccess: "Page Deleted",

    // commonly used titles & labels
    configTitle: "Setup",
    dataSourceTitle: "Data Source",
    viewLabel: "View Name",
    identifierNameLabel: "Identifier Name",
    identifierValueLabel: "Identifier Value",
    stateColumnNameLabel: "State Column Name",
    nameLabel: "Name",
    translationTitle: "Translation",
    searchInputLabel: "Search...",
    typeLabel: "Type",
    titleTooltipGroup: "Filter by Group",
    titleTooltipAlphabetically: "Filter Alphabetically",
    noItemsLabel: "No items founded with value: ",
    elementIdLabel: "Element Id",
    fieldPathLabel: "Field Path",
    inputConfigTitle: "Input",
    disabledLabel: "Disabled",
    nullableLabel: "Nullable",
    text: "Text",
    number: "Number",
    addDataSourceTooltip: "Add Data Source",
    removeDataSourceTooltip: "Remove Data Source",
    viewNameError: "View Name is Required",
    defaultLabel: "Default",
    filledLabel: "Filled",
    outlinedLabel: "Outlined",
    smallLabel: "Small",
    mediumLabel: "Medium",
    defaultValueInputLabel: "Default value",
    filterTitle: "Filter",
  },
  de: {},
  es: {},
};

export const useEditorTranslation = createUseTranslation(editorTranslation);
