import * as t from "io-ts";
import { types } from "core/runtime-typing";
import { IElement, IElementArrayChild, IElementSingleChild } from "core/types";
import { WithFieldDataSourceConfig } from "elementInterfaces/FormDataSource";
import { storageFileMetadataType } from "services/api/types/FileStorage";
import { FormInputConfig } from "../common";
import { SelectorTypes } from "core/types/element";

export const fileInputSelectors: SelectorTypes<FileInputConfig> = {
  value: types.nullable(types.string()), // TODO: is this type string?
  errors: types.any(),
  touched: types.boolean(),
  loading: types.boolean(),
  metadata: types.nullable(storageFileMetadataType),
  file: types.nullable(types.file()),
  uploadError: types.nullable(types.string()),
  metadataError: types.nullable(types.string()),
};

export const FileInputConfig = t.intersection([
  WithFieldDataSourceConfig,
  FormInputConfig,
  t.type({
    accessGroupName: t.string,
    typeGroupName: t.string,
  }),
  t.partial({
    accept: t.string,
  }),
]);

export type FileInputConfig = t.TypeOf<typeof FileInputConfig>;

export const FileInputTranslationKeys = ["label"] as const;

export type FileInputTranslationKeys = typeof FileInputTranslationKeys[number];

export type FileInput = IElement<
  FileInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  FileInputTranslationKeys
>;
