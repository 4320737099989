import { isRight } from "fp-ts/lib/Either";
import * as t from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";
import { Type } from "../runtime-typing";
import {
  IElement,
  IElementModel,
  IElementType,
  TCssGridConfig,
  TElementModelWithPosition,
  TElementWithPosition,
  TypeFactory,
} from "./element";

interface IExclude {
  exclude: string | string[];
}

type ElementType = IElement["type"];
type AnyElement = IElement<any, any, any>;
export type AnyElementWithPosition = TElementWithPosition<any, any, any>;

type ChildType = string | string[] | IExclude;

export interface IElementModelSingleChild {
  element: IElementModel;
}

export interface IElementSingleChild {
  element: IElement;
}

export interface IElementModelArrayChild {
  elements: IElementModel[];
}

export interface IElementArrayChild {
  elements: IElement[];
}

const SINGLE_CHILD = Symbol();
const ARRAY_CHILD = Symbol();
const PROP_TYPES = Symbol();
export const GET_VALID_ELEMENT_TYPES = Symbol();

export function singleChild(
  type: ChildType,
  options?: { positioned?: false; propTypes?: Record<string, Type> },
): t.Type<{ element: AnyElement }>;
export function singleChild(
  type: ChildType,
  options?: { positioned?: true; propTypes?: Record<string, Type> },
): t.Type<{ element: AnyElementWithPosition }>;
export function singleChild(
  type: ChildType,
  {
    positioned = false,
    /* eslint-disable react/forbid-foreign-prop-types */
    propTypes,
  }: { positioned?: boolean; propTypes?: Record<string, Type> } = {},
):
  | t.Type<{ element: AnyElement }>
  | t.Type<{ element: AnyElementWithPosition }> {
  const ChildType = t.type({
    element: createSingleChildrenType(type, positioned),
  });
  (ChildType as any)[SINGLE_CHILD] = true;
  (ChildType as any)[GET_VALID_ELEMENT_TYPES] = createValidElementTypesGetter(
    type,
  );
  (ChildType as any)[PROP_TYPES] = propTypes;
  return ChildType;
}

export function isSingleChildType(type: t.Mixed) {
  return !!(type as any)[SINGLE_CHILD];
}

export function arrayChild(
  type: ChildType,
  options?: {
    positioned?: false;
    propTypes?: Record<string, Type | TypeFactory>;
  },
): t.Type<{ elements: AnyElement[] }>;
export function arrayChild(
  type: ChildType,
  options?: {
    positioned?: true;
    propTypes?: Record<string, Type | TypeFactory>;
  },
): t.Type<{ elements: AnyElementWithPosition[] }>;
export function arrayChild(
  type: ChildType,
  {
    positioned = false,
    /* eslint-disable react/forbid-foreign-prop-types */
    propTypes,
  }: {
    positioned?: boolean;
    propTypes?: Record<string, Type | TypeFactory>;
  } = {},
):
  | t.Type<{ elements: AnyElement[] }>
  | t.Type<{ elements: AnyElementWithPosition[] }> {
  const ChildType = t.type({
    elements: createArrayChildrenType(type, positioned),
  });
  (ChildType as any)[ARRAY_CHILD] = true;
  (ChildType as any)[GET_VALID_ELEMENT_TYPES] = createValidElementTypesGetter(
    type,
  );
  (ChildType as any)[PROP_TYPES] = propTypes;
  return ChildType;
}

export function isArrayChildType(type: t.Mixed) {
  return !!(type as any)[ARRAY_CHILD];
}

export function getPropTypes(type: t.Mixed) {
  return (type as any)[PROP_TYPES];
}

/**
 * TODO:
 * The children could be fully checked with an io-ts Element type. The problem would be a performance problem,
 * since each Element would recursively check itself and it's children, and then be checked again when rendering
 * the children. One solution is to include a `checked` flag. A boolean value wouldn't be enough, since one could
 * copy and modify an element with a spread operator. The best thing would be to have `checked` be a string, and
 * when the element is checked include the id in the `checked` property, and then later on consider the element
 * as validated only if the `checked` prop matches the element's id.
 */

function createSingleChildrenType(type: ChildType, positioned: boolean) {
  const checkType = buildTypeChecker(type);
  return new t.Type<AnyElement, AnyElement, unknown>(
    "ElementChild",
    (u): u is AnyElement => !!(u as any).type.name,
    (i: unknown, c: t.Context) => {
      if (!quickElementCheck(i)) {
        return t.failure(
          i,
          c,
          `Invalid child element. Expected a single child got ${i}.`,
        );
      }
      const error =
        checkType((i as any).type) ||
        (positioned ? checkPosition(i as IElementModel) : null);
      if (error) {
        return t.failure(i, c, error);
      }
      return t.success(i as AnyElement);
    },
    (e: AnyElement) => e,
  );
}

function createArrayChildrenType(type: ChildType, positioned: boolean) {
  const checkType = buildTypeChecker(type);
  return new t.Type<AnyElement[], AnyElement[], unknown>(
    "ElementChildArray",
    (u): u is AnyElement[] =>
      Array.isArray(u) && u.every((e) => !!(e as any).type.name),
    (input: unknown, context: t.Context) => {
      if (!Array.isArray(input)) {
        return t.failure(
          input,
          context,
          `Invalid child element. Expected an array but got ${input}.`,
        );
      }
      let error: string | null;
      let el: any;
      for (let i = 0; i < input.length; i++) {
        el = input[i];
        if (!quickElementCheck(el)) {
          error = `Invalid child element, expected an object with a "type" prop, but got ${el}`;
        } else {
          error = checkType(el.type) || (positioned ? checkPosition(el) : null);
        }
        if (error) {
          return t.failure(
            input,
            context,
            `Error in position ${i} of child element array: ${error}`,
          );
        }
      }
      return t.success(input as AnyElement[]);
    },
    (e: AnyElement[]) => e,
  );
}

function quickElementCheck(el: any): boolean {
  return typeof el === "object" && !!el.type && !!el.type.name;
}

function buildTypeChecker(
  arg: ChildType,
): (type: ElementType) => string | null {
  if (arg === "*") {
    return () => null;
  }
  if (typeof arg === "string") {
    return (type: ElementType) =>
      type.name === arg ? null : typeErrorMessage(arg, type.name);
  }
  if (Array.isArray(arg)) {
    return (type: ElementType) =>
      arg.includes(type.name) ? null : typeArrayErrorMessage(arg, type.name);
  }
  return (type: ElementType) =>
    typeof arg.exclude === "string"
      ? arg.exclude !== type.name
        ? null
        : `Invalid type for element, expected any type except "${arg.exclude}", but got "${type.name}"`
      : arg.exclude.includes(type.name)
      ? `Invalid type for element, expected any type except ${arg.exclude
          .map((a) => `"${a}"`)
          .join(", ")}, but got "${type.name}"`
      : null;
}

function checkPosition(element: IElementModel | TElementModelWithPosition) {
  if (!("position" in element)) {
    return "Invalid element. Expected an element with 'position'";
  }
  const result = TCssGridConfig.decode(element.position);
  return isRight(result)
    ? null
    : `Invalid position in element: ${PathReporter.report(result).join(". ")}`;
}

function createValidElementTypesGetter(type: ChildType) {
  if (type === "*") {
    return (types: Record<string, IElementType>) =>
      Object.keys(types).map((n) => types[n]);
  }
  if (typeof type === "string") {
    return (types: Record<string, IElementType>) => {
      const validType = types[type];
      return validType ? [validType] : [];
    };
  }
  if (Array.isArray(type)) {
    return (types: Record<string, IElementType>) =>
      Object.keys(types)
        .map((n) => types[n])
        .filter((ty) => type.includes(ty.name));
  }
  return (types: Record<string, IElementType>) =>
    Object.keys(types)
      .map((n) => types[n])
      .filter((ty) => !type.exclude.includes(ty.name));
}

const typeErrorMessage = (expected: string, got: string) =>
  `Invalid type for element, expected "${expected}", but got "${got}"`;
const typeArrayErrorMessage = (expected: string[], got: string) =>
  `Invalid type for element, expected one of ${expected
    .map((a) => `"${a}"`)
    .join(", ")}, but got "${got}"`;
