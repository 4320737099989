import * as R from "ramda";

import {
  FixedFilterGroup,
  FixedFilterRule,
  IFilterGroup,
  IFilterRule,
} from "./types";

import { IFilterGroupWithPath, IFilterRuleWithPath } from "./types";

export function isFilterRule(
  ruleOrGroup: IFilterRule | IFilterGroup | FixedFilterGroup | FixedFilterRule,
): ruleOrGroup is IFilterRule | FixedFilterRule {
  return "field" in ruleOrGroup;
}

export function isFilterGroup(
  ruleOrGroup: IFilterRule | IFilterGroup | FixedFilterGroup | FixedFilterRule,
): ruleOrGroup is IFilterGroup | FixedFilterGroup {
  return "filters" in ruleOrGroup;
}

export function addPathToGroup(
  group: IFilterGroup,
  path: number[] = [],
): IFilterGroupWithPath {
  return {
    ...group,
    path,
    filters: group.filters
      .filter(Boolean)
      .map((f, i) =>
        isFilterGroup(f!)
          ? addPathToGroup(f, [...path, i])
          : addPathToRule(f!, [...path, i]),
      ),
  };
}

export function addPathToRule(
  rule: IFilterRule,
  path: number[] = [],
): IFilterRuleWithPath {
  return {
    ...rule,
    path,
  };
}

export const addFilter = (
  currentFilter: any,
  path: number[],
  filter: IFilterGroup | IFilterRule,
) => {
  const filterPath = [...getFilterPath(path), "filters"];
  const filters = R.path(filterPath, currentFilter) as any[];
  const nextFilter = R.assocPath(
    filterPath,
    [...filters, filter],
    currentFilter,
  );

  return addPathToGroup(nextFilter);
};

export const getFilterPath = (p: number[]) =>
  p.reduce((f, i) => [...f, "filters", i], [] as Array<string | number>);

export const removePath = (
  group: IFilterGroupWithPath | IFilterRuleWithPath,
): IFilterGroup | IFilterRule => ({
  ...(R.omit(["path"], group) as IFilterGroup | IFilterRule),
  ...(!!(group as IFilterGroupWithPath)?.filters?.length && {
    filters: (group as IFilterGroupWithPath).filters.map(
      (f: IFilterGroupWithPath | IFilterRuleWithPath) => removePath(f),
    ),
  }),
});
