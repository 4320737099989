import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  UPDATE_PROFILE: scopeActionType("UPDATE_PROFILE"),
  UPDATE_PROFILE_ERROR: scopeActionType("UPDATE_PROFILE_ERROR"),
};

export const actions = {
  updateProfile: createAction(
    types.UPDATE_PROFILE,
    (profile: { password: string }) => ({
      profile,
    }),
  ),
  updateProfileError: createAction(
    types.UPDATE_PROFILE_ERROR,
    (error: any) => ({
      error,
    }),
  ),
};
