import { types as sessionTypes } from "../../session/reduxModule";
import { handleActions } from "../../utils/redux";
import { types } from "./actions";
import { IState } from "./types";

const INITIAL_STATE: IState = {
  location: null,
  loadingPage: false,
  notFound: false,
  page: null,
  staticPageId: null,
  isAdminPage: false,
  error: null,
  preventLocationChange: false,
  params: {},
  keysByKeyAliases: {},
  compiledRoutes: null,
  creatingPage: false,
};

export const reducer = handleActions<IState>(INITIAL_STATE, {
  [types.LOCATION_CHANGE]: (state, action: any) => ({
    ...state,
    location: action.payload,
    notFound: false,
  }),
  [types.NOT_FOUND]: (state) => ({
    ...state,
    notFound: true,
    page: null,
    staticPageId: null,
  }),
  [types.PAGE_LOAD]: (state) => ({
    ...state,
    params: null,
    loadingPage: true,
    error: null,
  }),
  /**
   * TODO:
   * This reducer fixes a bug that must be better fixed in the future. This reducer makes sure the page is
   * cleared when loading a new app metadata. Otherwise, between the app metadata load and the page load,
   * there's a brief moment where the router has no loading state and renders the page that has just been
   * unmounted. This shouldn't be a problem, but react-redux is re-rendering components after they are
   * unmounted, or it might have to do with React's unmounting order (my best guess, the parents unmount
   * first), thus the state selectors are throwing because of the attempt to access props of `undefined`.
   * If we remove this, we can see the bug in action when logging in. The default_static_login_form
   * component unmounts, and after that a react-redux connect is fired on that element (because of the
   * LOCATION_CHANGE action), meaning that if any action happens to fire when the element is unmounting
   * it would produce this bug.
   */
  [sessionTypes.APP_METADATA_LOAD]: (state) => ({
    ...state,
    page: null,
    compiledRoutes: null,
  }),
  [types.PAGE_LOAD_SUCCESS]: (state, action: any) => ({
    ...state,
    loadingPage: false,
    params: action.payload.params,
    page: action.payload.page,
    staticPageId: null,
    isAdminPage: false,
  }),
  [types.PAGE_LOAD_ERROR]: (state, action: any) => ({
    ...state,
    loadingPage: false,
    error: action.payload.error,
  }),
  [types.STATIC_PAGE_LOAD_SUCCESS]: (state, action) => ({
    ...state,
    params: action.payload.params,
    page: null,
    staticPageId: action.payload.id,
    isAdminPage: action.payload.isAdmin,
  }),
  [types.PREVENT_LOCATION_CHANGE_SET]: (state, { payload }) => ({
    ...state,
    preventLocationChange: payload.value,
    keysByKeyAliases:
      payload.addKeyAlias && state.location
        ? {
            ...state.keysByKeyAliases,
            [payload.addKeyAlias]: state.location.key,
          }
        : state.keysByKeyAliases,
  }),
  [types.COMPILED_ROUTES_SET]: (state, { payload }) => ({
    ...state,
    compiledRoutes: payload,
  }),
  [types.PAGES_REPLACE]: (state, { payload }) => ({
    ...state,
    page: payload[state.page!.id],
  }),
});
