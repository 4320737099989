import { buildActions } from "./actions";
import { buildReducer } from "./reducer";
import { buildSelectors } from "./selectors";
import { IElement } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces";
import { FormInputConfig } from "elementTypes/common";

type InitialState<V> = {
  value: V;
  errors?: any;
};

type NonUndefined<T> = T extends undefined ? never : T;

export function buildSimpleUncontrolledInputModule<
  T extends IElement<WithOptionalFieldDataSourceConfig & FormInputConfig>
>(
  element: T,
  path: string[],
  initialState: InitialState<NonUndefined<T["config"]["defaultValue"]>>,
) {
  const scope = path.join(".");
  const { actions, types } = buildActions<
    NonUndefined<T["config"]["defaultValue"]>
  >(scope);
  const reducer = buildReducer(element, types, initialState);
  const selectors = buildSelectors<
    NonUndefined<T["config"]["defaultValue"]>,
    T
  >(path, element);

  return {
    actions,
    reducer,
    selectors,
    types,
  };
}
