import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IUser } from "./types";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  LOAD: scopeActionType("LOAD"),
  LOAD_SUCCESS: scopeActionType("LOAD_SUCCESS"),
  LOAD_ERROR: scopeActionType("LOAD_ERROR"),
  DELETE_USER: scopeActionType("DELETE_USER"),
  DELETE_USER_SUCCESS: scopeActionType("DELETE_USER_SUCCESS"),
  DELETE_USER_ERROR: scopeActionType("DELETE_USER_ERROR"),
};

export const actions = {
  load: createAction(types.LOAD),
  loadSuccess: createAction(types.LOAD_SUCCESS, (userList: any) => ({
    userList,
  })),
  loadError: createAction(types.LOAD_ERROR, (error: any) => ({ error })),
  deleteUser: createAction(types.DELETE_USER, (id: IUser["id"]) => ({ id })),
  deleteUserSuccess: createAction(
    types.DELETE_USER_SUCCESS,
    (id: IUser["id"]) => ({ id }),
  ),
  deleteUserError: createAction(
    types.DELETE_USER_ERROR,
    (id: IUser["id"], error: string) => ({
      id,
      error,
    }),
  ),
};

export type Actions = typeof actions;
