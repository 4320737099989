import React, { CSSProperties, memo } from "react";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import IconButton from "elementTypes/common/IconButton";
import { useTableEditorTranslation } from "../../../translation";
import { Reference } from "./References";

type Props = {
  onClick: (params: Reference) => void;
  onDelete: (name: string) => void;
  style?: CSSProperties;
} & Reference;

export const ReferenceItem = memo<Props>(
  ({ style, onClick, onDelete, ...props }) => {
    const {
      editReferenceTooltip,
      deleteReferenceTooltip,
    } = useTableEditorTranslation();
    const { fieldName, targetView, referencedColumnName } = props;
    const handleEditClick = () => onClick(props as Reference);
    const handleDelete = () => onDelete(fieldName);

    return (
      <ListItem divider style={style}>
        <Box overflow="auto" clone>
          <ListItemText
            primary={<Typography variant="subtitle2">{fieldName}</Typography>}
            secondaryTypographyProps={{ color: "primary" }}
            secondary={
              <>
                {targetView}.
                <Typography color="secondary" component="span">
                  {referencedColumnName}
                </Typography>
              </>
            }
          />
        </Box>

        <ListItemIcon>
          <IconButton
            icon="delete_outline"
            color="error.main"
            onClick={handleDelete}
            tooltip={deleteReferenceTooltip}
          />
          <IconButton
            icon="edit"
            onClick={handleEditClick}
            tooltip={editReferenceTooltip}
            edge="end"
          />
        </ListItemIcon>
      </ListItem>
    );
  },
);
