import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { omit } from "ramda";
import { v4 as uuidv4 } from "uuid";

import { AllServices } from "core/buildStore";
import AdminService from "services/admin";
import { buildElementTypeGetter } from "../../getElementType";
import { createNewEntry } from "../EditorLayout/components/MenuPanel/utils";
import {
  DEFAULT_APP_URL,
  createPage,
  getSampleUrl,
  actions as routerActions,
  selectors as routerSelectors,
  types as routerTypes,
  withLeadingSlash,
} from "../../router/reduxModule";
import { getPushArguments } from "../../router";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "../../session/reduxModule";
import { getTranslatedTextSaga } from "../../session/translation/createUseTranslation";
import { IMenuItem } from "../../types";
import { editorTranslation } from "../translation";
import { actions, types } from "./actions";
import { selectors } from "./selectors";
import { createElement, getUpdatedUiDefinition } from "./utils";
import {
  Definition,
  IPage,
  IUi,
  IUiReleaseOverview,
  IUiSavePoint,
} from "../../types/app";
import { prefixPageUrl } from "core/router/reduxModule/utils";
import { APP_URL } from "core/router/reduxModule/constants";

function* loadViewsSaga() {
  const services: AllServices = yield getContext("services");
  const token = yield select(sessionSelectors.token);
  const ui: IUi | null = yield select(sessionSelectors.ui);

  try {
    const res = yield call(services.api.loadViewList, {
      token,
      role: ui?.role,
    });
    yield put(actions.loadViewsSuccess(res));
  } catch (error) {
    yield put(actions.loadViewsError(error.message ?? error.toString()));
  }
}

function* createPageSaga(action: ReturnType<typeof actions.createPage>) {
  const {
    elementTypes,
    name,
    i18n,
    generateMenu,
    params = {},
  } = action.payload;
  if (!name) {
    return;
  }

  const url = withLeadingSlash(name);

  const usedUrls = yield select(selectors.usedUrls);
  const prefixedUrl = prefixPageUrl(url);
  const sampleUrl = getSampleUrl(prefixedUrl);
  const urlExists = usedUrls.includes(sampleUrl);

  if (urlExists) {
    const message = yield call(
      getTranslatedTextSaga,
      editorTranslation,
      "existingUrlError",
    );

    yield put(actions.createPageError(message.replace("*", `"${url}"`)));

    yield put(
      sessionActions.enqueueSnackbar({
        message,
        options: {
          variant: "error",
        },
      }),
    );
  } else {
    const getElementType = buildElementTypeGetter(elementTypes);
    const elementId = yield select(selectors.nextElementId);
    const [element, nextElementId] = createElement(
      getElementType,
      elementTypes.default_grid,
      elementId,
    );

    const language = yield select(sessionSelectors.currentLanguage);
    const pageId = uuidv4().split("-")[0];

    const page = createPage({
      id: pageId,
      i18n,
      url,
      element,
      language,
    });
    const allPages: ReturnType<typeof routerSelectors.allPages> = yield select(
      routerSelectors.allPages,
    );

    yield put(
      actions.createPageSuccess({ ...page, params }, sampleUrl, allPages),
    );

    if (generateMenu && !Object.keys(params).length) {
      const updatedMenu: ReturnType<typeof selectors.updatedMenu> = yield select(
        selectors.updatedMenu,
      );
      const appMetadata: ReturnType<typeof sessionSelectors.appMetadata> = yield select(
        sessionSelectors.appMetadata,
      );
      const {
        release: {
          definition: { menu: appMenu },
        },
      } = appMetadata!;

      const menuTitle = i18n[language.code]?.label;
      const newMenuItem = omit(["isNew"], {
        ...createNewEntry(0, language, menuTitle),
        pageId,
        name,
      });

      yield put(
        actions.updateMenuEntries([
          newMenuItem,
          ...(updatedMenu ?? appMenu).map((menuItem: IMenuItem) => ({
            ...menuItem,
            order: menuItem.order + 1,
          })),
        ]),
      );
    }
    yield put(
      routerActions.push(
        !Object.keys(params).length
          ? prefixedUrl
          : getPushArguments(page, params).toString(),
      ),
    );

    yield put(actions.updateNextElementId(nextElementId));

    yield put(
      sessionActions.enqueueSnackbar({
        message: yield call(
          getTranslatedTextSaga,
          editorTranslation,
          "pageCreateSuccess",
        ),
        options: {
          variant: "success",
        },
      }),
    );
  }
}

function* updatePageSaga(action: ReturnType<typeof actions.updatePage>) {
  const {
    page,
    url: pageUrl,
    i18n,
    params = {},
    generateMenu,
  } = action.payload;
  const url =
    page.url.replace(/\//g, "") === "" ? page.url : withLeadingSlash(pageUrl);
  if (!url) {
    return;
  }

  const language = yield select(sessionSelectors.currentLanguage);

  const appMetadata: ReturnType<typeof sessionSelectors.appMetadata> = yield select(
    sessionSelectors.appMetadata,
  );
  const updatedMenu: ReturnType<typeof selectors.updatedMenu> = yield select(
    selectors.updatedMenu,
  );
  const {
    release: {
      definition: { menu },
    },
  } = appMetadata!;
  const appMenu = updatedMenu ?? menu;
  const pageMenuItem = appMenu.find((menuItem) => menuItem.pageId === page.id);

  if (generateMenu) {
    const menuTitle = i18n[language.code]?.label;
    const newMenuItem = omit(["isNew"], {
      ...createNewEntry(0, language, menuTitle),
      pageId: page.id,
    });

    const nextMenu = pageMenuItem
      ? appMenu.map((menuItem: IMenuItem) =>
          menuItem.id === pageMenuItem.id
            ? {
                ...pageMenuItem,
                i18n: {
                  ...pageMenuItem.i18n,
                  [language.code]: { label: menuTitle },
                },
              }
            : { ...menuItem },
        )
      : [
          newMenuItem,
          ...appMenu.map((menuItem: IMenuItem) => ({
            ...menuItem,
            order: menuItem.order + 1,
          })),
        ];
    yield put(actions.updateMenuEntries(nextMenu));
  }

  if (pageMenuItem && !!Object.keys(params).length) {
    yield put(
      actions.updateMenuEntries(
        appMenu.filter((menuItem) => !(menuItem.pageId === page.id)),
      ),
    );
  }

  const updatedPage = {
    ...page,
    url,
    params,
    i18n: {
      ...page.i18n,
      ...i18n,
    },
  };

  const sampleUrl = getSampleUrl(prefixPageUrl(url));
  const prevSampleUrl = getSampleUrl(prefixPageUrl(page.url));

  const allPages: ReturnType<typeof routerSelectors.allPages> = yield select(
    routerSelectors.allPages,
  );
  const usedUrls = yield select(selectors.usedUrls);
  const nextUrls =
    sampleUrl !== prevSampleUrl
      ? usedUrls.reduce(
          (result: string[], usedUrl: string) =>
            usedUrl === prevSampleUrl ? [...result] : [...result, usedUrl],
          [sampleUrl],
        )
      : usedUrls;

  yield put(actions.updatePageSuccess(updatedPage, nextUrls, allPages));
  yield put(
    routerActions.replace(
      !Object.keys(updatedPage.params).length
        ? prefixPageUrl(updatedPage.url)
        : getPushArguments(updatedPage, updatedPage.params).toString(),
    ),
  );
}

function* deletePageSaga({
  payload: { page },
}: ReturnType<typeof actions.deletePage>) {
  const prefixedUrl = prefixPageUrl(page.url);
  const sampleUrl = getSampleUrl(prefixedUrl);
  const allPages: ReturnType<typeof routerSelectors.allPages> = yield select(
    routerSelectors.allPages,
  );
  const location: ReturnType<typeof routerSelectors.location> = yield select(
    routerSelectors.location,
  );

  const appMetadata: ReturnType<typeof sessionSelectors.appMetadata> = yield select(
    sessionSelectors.appMetadata,
  );
  const updatedMenu: ReturnType<typeof selectors.updatedMenu> = yield select(
    selectors.updatedMenu,
  );
  const {
    release: {
      definition: { menu },
    },
  } = appMetadata!;
  const appMenu = updatedMenu ?? menu;
  const pageMenuItem = appMenu.find((menuItem) => menuItem.pageId === page.id);
  const pathname = location.pathname.replace(`/${APP_URL}`, "");

  if (pageMenuItem) {
    yield put(
      actions.updateMenuEntries(
        appMenu.filter((menuItem) => !(menuItem.pageId === page.id)),
      ),
    );
  }

  yield put(actions.deletePageSuccess(page, allPages, sampleUrl));

  if (page.url === pathname) {
    yield put(routerActions.replace(DEFAULT_APP_URL));
  }

  yield put(
    sessionActions.enqueueSnackbar({
      message: yield call(
        getTranslatedTextSaga,
        editorTranslation,
        "pageDeleteSuccess",
      ),
      options: {
        variant: "success",
      },
    }),
  );
}

function* loadUiReleasesSaga(
  action: ReturnType<typeof actions.loadUiReleases>,
) {
  const { uiName } = action.payload;
  const services: AllServices = yield getContext("services");
  const token = yield select(sessionSelectors.token);

  try {
    const data = yield call(services.api.getUIReleases, token, uiName);
    yield put(actions.loadUiReleasesSuccess(data));
  } catch (error) {
    yield put(actions.loadUiReleasesError(error.message ?? error.toString()));
  }
}

function* loadUiSavePointsSaga(
  action: ReturnType<typeof actions.loadUiSavePoints>,
) {
  const { uiName } = action.payload;
  const services: AllServices = yield getContext("services");
  const token = yield select(sessionSelectors.token);

  try {
    const data = yield call(services.api.getUISavePoints, token, uiName);
    yield put(actions.loadUiSavePointsSuccess(data));
  } catch (error) {
    yield put(actions.loadUiSavePointsError(error.message ?? error.toString()));
  }
}

function* restoreSavePointSaga(
  action: ReturnType<typeof actions.restoreSavePoint>,
) {
  const { uiName, savePointId } = action.payload;
  const adminService = AdminService.getInstance();
  const token = yield select(sessionSelectors.token);

  try {
    const { definition: nextDefinition, ...nextSavePoint } = yield call(
      [adminService, "restoreUi"],
      uiName,
      savePointId,
      { token },
    );

    yield updateAppUi({
      nextDefinition,
      uiName,
      nextSavePoint,
    });
  } catch (error) {
    yield put(
      sessionActions.enqueueSnackbar({
        message: error.message.toString(),
        options: {
          variant: "error",
        },
      }),
    );
  }
}

function* replacePagesSaga(nextPages: Record<"string", IPage>) {
  const currentPage: IPage = yield select(routerSelectors.page);

  if (!Object.keys(nextPages).some((pageId) => pageId === currentPage?.id)) {
    yield put(routerActions.replace(DEFAULT_APP_URL));
  } else {
    yield put(routerActions.replacePages(nextPages));
  }
}

function* releaseSaga({ payload }: ReturnType<typeof actions.release>) {
  const adminService = AdminService.getInstance();
  const token = yield select(sessionSelectors.token);

  const ui: ReturnType<typeof sessionSelectors.ui> = yield select(
    sessionSelectors.ui,
  );

  if (!ui) {
    return;
  }

  try {
    const nextRelease = yield call(
      [adminService, "releaseUi"],
      ui.name,
      payload,
      { token },
    );

    const uiReleases: ReturnType<typeof selectors.uiReleases> = yield select(
      selectors.uiReleases,
    );

    yield put(
      actions.loadUiReleasesSuccess([nextRelease, ...(uiReleases ?? [])]),
    );
    yield put(actions.releaseSuccess());
  } catch (error) {
    yield put(actions.releaseError(error.message ?? error.toString()));
  }
}

function* publishReleaseSaga({
  payload: { uiName, releaseName },
}: ReturnType<typeof actions.publishRelease>) {
  const adminService = AdminService.getInstance();
  const token = yield select(sessionSelectors.token);

  try {
    const publishedRelease = yield call(
      [adminService, "publishReleaseUi"],
      uiName,
      releaseName,
      { token },
    );
    const uiReleases: ReturnType<typeof selectors.uiReleases> = yield select(
      selectors.uiReleases,
    );
    const nextReleases = uiReleases?.map((release: IUiReleaseOverview) =>
      release.id === publishedRelease.id
        ? { ...release, ...publishedRelease }
        : { ...release, published: false },
    );
    if (nextReleases) {
      yield put(actions.loadUiReleasesSuccess(nextReleases));
    }
  } catch (error) {
    yield put(
      sessionActions.enqueueSnackbar({
        message: error.message.toString(),
        options: {
          variant: "error",
        },
      }),
    );
  }
}

function* saveSaga(action: ReturnType<typeof actions.save>) {
  const ui: ReturnType<typeof sessionSelectors.ui> = yield select(
    sessionSelectors.ui,
  );
  const appMetadata: ReturnType<typeof sessionSelectors.appMetadata> = yield select(
    sessionSelectors.appMetadata,
  );
  const updatedLayoutDefinition: ReturnType<typeof selectors.updatedLayoutDefinition> = yield select(
    selectors.updatedLayoutDefinition,
  );
  const updatedMenu: ReturnType<typeof selectors.updatedMenu> = yield select(
    selectors.updatedMenu,
  );
  const allPages: ReturnType<typeof routerSelectors.allPages> = yield select(
    routerSelectors.allPages,
  );
  const newPages: ReturnType<typeof selectors.newPages> = yield select(
    selectors.newPages,
  );
  const updatedElements: ReturnType<typeof selectors.updatedElements> = yield select(
    selectors.updatedElements,
  );
  let compiledRoutes: ReturnType<typeof routerSelectors.compiledRoutes> = yield select(
    routerSelectors.compiledRoutes,
  );
  compiledRoutes = compiledRoutes ?? [];

  if (!ui || !appMetadata) {
    return;
  }

  const pages = Object.keys(newPages).length ? newPages : allPages;

  const nextDefinition = getUpdatedUiDefinition(
    appMetadata.release.definition,
    pages,
    updatedElements,
    updatedLayoutDefinition,
    updatedMenu,
  );

  const adminService = AdminService.getInstance();
  const token = yield select(sessionSelectors.token);

  try {
    const nextSavePoint = yield call(
      [adminService, "saveUi"],
      ui.name,
      nextDefinition,
      action.payload,
      { token },
    );

    yield updateAppUi({
      nextDefinition,
      uiName: ui.name,
      nextSavePoint,
    });

    yield put(routerActions.setCompiledRoutes(compiledRoutes));
  } catch (error) {
    yield put(actions.saveError(error.message ?? error.toString()));
  }
}

function* updateAppUi({
  uiName,
  nextSavePoint,
  nextDefinition,
}: {
  uiName: string;
  nextSavePoint: IUiSavePoint;
  nextDefinition: Definition;
}) {
  yield put(
    sessionActions.replaceAppMetadata({
      name: uiName,
      nextSavePoint,
      nextDefinition,
    }),
  );
  const uiSavePoints: ReturnType<typeof selectors.uiSavePoints> = yield select(
    selectors.uiSavePoints,
  );

  yield put(
    actions.loadUiSavePointsSuccess([nextSavePoint, ...(uiSavePoints ?? [])]),
  );

  yield put(actions.loadUiReleases(uiName));

  yield replacePagesSaga(nextDefinition.pages);
  yield put(actions.saveSuccess());
}

function* editModeToggleSaga() {
  const editModeOn: boolean = yield select(selectors.editModeOn);

  if (editModeOn) {
    yield put(actions.loadViews());

    const compiledRoutes: ReturnType<typeof routerSelectors.compiledRoutes> = yield select(
      routerSelectors.compiledRoutes,
    );
    if (compiledRoutes) {
      yield put(actions.setRoutes(compiledRoutes));
    }
  } else {
    const isLayoutChanged: boolean = yield select(selectors.isLayoutChanged);
    if (!isLayoutChanged) {
      // remove the originally set routes, so there are no problems when switching apps
      yield put(actions.setRoutes([]));
    }
  }
}

function* discardChangesSaga() {
  const allPages = yield select(routerSelectors.allPages);
  yield replacePagesSaga(allPages);
}

function* initializeSelectionSaga(
  action: ReturnType<typeof routerActions.loadPageSuccess>,
) {
  const page = action.payload.page;
  yield put(actions.initializeSelection(page));
}

export function* saga() {
  yield all([
    yield takeLatest(types.LOAD_VIEWS, loadViewsSaga),
    yield takeLatest(types.EDIT_MODE_TOGGLE, editModeToggleSaga),
    yield takeLatest(types.PAGE_CREATE, createPageSaga),
    yield takeLatest(types.PAGE_UPDATE, updatePageSaga),
    yield takeLatest(types.PAGE_DELETE, deletePageSaga),
    yield takeLatest(types.LOAD_UI_RELEASES, loadUiReleasesSaga),
    yield takeLatest(types.LOAD_UI_SAVE_POINTS, loadUiSavePointsSaga),
    yield takeLatest(types.RESTORE_SAVE_POINT, restoreSavePointSaga),
    yield takeLatest(types.SAVE, saveSaga),
    yield takeLatest(types.RELEASE, releaseSaga),
    yield takeLatest(types.PUBLISH_RELEASE, publishReleaseSaga),
    yield takeLatest(types.DISCARD_CHANGES, discardChangesSaga),
    yield takeLatest(routerTypes.PAGE_LOAD_SUCCESS, initializeSelectionSaga),
  ]);
}
