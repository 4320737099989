import { FormDataSource } from "elementInterfaces";
import { StaticSelect } from "../../types";
import { buildActions } from "./actions";
import { buildSelectors } from "./selectors";

export function controlledReduxModuleFactory(element: StaticSelect) {
  const { elementId, fieldPath } = element.config.dataSource!;

  const dataSource = FormDataSource.get(elementId);

  const { actions } = buildActions(fieldPath, dataSource);
  const selectors = buildSelectors(fieldPath, dataSource);

  return {
    actions,
    selectors,
  };
}
