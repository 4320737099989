import React from "react";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import { NON_BREAKING_SPACE } from "../common/utils";
import { PropsFromRedux } from "./container";
import { useStyles } from "./styles";
import { clampFractionValues } from "../common/clampFractionValues";

const DefaultNumberField = React.memo<PropsFromRedux>(
  ({
    value,
    highlight,
    element: {
      config: { precision, isPercentage, prefix, suffix },
    },
  }) => {
    const classes = useStyles();
    const clampedPrecision = clampFractionValues(precision);

    return (
      <Typography
        className={clsx({ [classes.highlight]: highlight })}
        color={highlight ? "error" : undefined}
      >
        {prefix}
        {value !== null
          ? Intl.NumberFormat(undefined, {
              minimumFractionDigits:
                precision !== null ? clampedPrecision : undefined,
              maximumFractionDigits:
                precision !== null ? clampedPrecision : undefined,
              style: isPercentage ? "percent" : "decimal",
            }).format(value)
          : NON_BREAKING_SPACE}
        {suffix}
      </Typography>
    );
  },
);

export default DefaultNumberField;
