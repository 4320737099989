import { connectElement } from "core/utils/react-redux";
import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { DeleteButton } from "./types";

import { IElementComponentProps } from "core";
import { Dispatch, bindActionCreators } from "redux";
import { ConnectedProps } from "react-redux";

const mapStateToProps = (
  state: any,
  {
    element: {
      config: { disabled },
    },
  }: IElementComponentProps<{}, DeleteButton>,
) => ({
  disabled: typeof disabled === "function" ? disabled(state) : disabled,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { module }: IElementComponentProps<ReduxModule, DeleteButton>,
) => bindActionCreators(module.actions, dispatch);

const connector = connectElement<
  ReduxModule,
  DeleteButton,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Component);
