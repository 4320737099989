import React, { memo, useMemo } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { IObjectViewField } from "core";
import { gridTemplateColumns } from "./component";

export type Column = {
  field: string;
  title: string;
  generalType: IObjectViewField["generalType"];
  validate: (field: IObjectViewField) => boolean;
  required?: boolean;
};

type Props = {
  columns: Column[];
  actionsDisabled?: boolean;
};

export const TableHeader = memo<Props>(({ columns, actionsDisabled }) => {
  const items = useMemo(
    () =>
      columns.map((column) => (
        <Box key={column.field}>
          <Typography variant="subtitle2">{column.title}</Typography>
        </Box>
      )),
    [columns],
  );

  return (
    <Box
      display="grid"
      gridGap={8}
      gridTemplateColumns={gridTemplateColumns(items.length, actionsDisabled)}
      bgcolor="grey.200"
      px={1.5}
      py={2}
    >
      {!actionsDisabled && (
        <Box>
          <Typography variant="subtitle2">{"Actions"}</Typography>
        </Box>
      )}
      {items}
    </Box>
  );
});
