import { EditSettingsPage } from "./component";

import * as reduxModule from "./reduxModule";

export const route = {
  reduxModule,
  auth: true,
  isAdmin: true,
  Component: EditSettingsPage,
};
