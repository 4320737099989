import { createContext } from "react";
import { CellAlignment } from "../types";

interface ITableCellContext {
  alignment: CellAlignment;
}

export const TableCellContext = createContext<ITableCellContext>({
  alignment: "left",
});
