import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import useStyles from "./styles";
import LoginForm from "./LoginForm";
import verticalLogoPath from "../common/verticalLogo.svg";
import { AppBar } from "layouts/common/AppBar";
import { actions as sessionActions } from "core/session/reduxModule";
import { selectors as loginSelectors } from "./reduxModule";
import { Helmet } from "react-helmet";
import { Box } from "@material-ui/core";

export const LoginPage = memo(() => {
  const config = useSelector(loginSelectors.config);
  const dispatch = useDispatch();

  const { paper, root } = useStyles();

  const handleSubmit = (values: any) => dispatch(sessionActions.login(values));

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <AppBar
        label=""
        menuWidth=""
        handleDrawerChange={() => null}
        open={false}
        toolbarVariant="dense"
        headerButtonsColor={"inherit"}
        hidden
      />
      <div className={root}>
        <Paper className={paper}>
          <Grid container justify="center">
            <Grid item>
              <img
                style={{ maxHeight: "250px" }}
                src={config?.logoPath ?? verticalLogoPath}
                alt="logo"
              />
            </Grid>
          </Grid>

          <Box marginY={1}>
            <Divider />
          </Box>

          <LoginForm onSubmit={handleSubmit} />
        </Paper>
      </div>
    </>
  );
});
