import * as t from "io-ts";
import {
  AnyElement,
  DEFAULT_LANGUAGE_CODE,
  GET_VALID_ELEMENT_TYPES,
  IChildMetadata,
  IElementType,
  IRawElementType,
  LANGUAGES,
  isArrayChildType,
  isSingleChildType,
} from "./types";
import { getDidYouMean } from "./utils/didYouMean";

/**
 * The element types are augmented with metadata about types that will be used by the Element Editor.
 */
export function compileElementTypes(
  rawElementTypes: Record<string, IRawElementType>,
): Record<string, IElementType> {
  return Object.keys(rawElementTypes).reduce(
    (c, n) => ({ ...c, [n]: compileElementType(rawElementTypes[n]) }),
    {},
  );
}

function compileElementType(rawElementType: IRawElementType): IElementType {
  const { childrenType } = rawElementType;
  const childrenMetadata = childrenType
    ? buildChildrenMetadata(rawElementType, childrenType)
    : {};
  const translationType =
    rawElementType.translationKeys && rawElementType.translationKeys.length
      ? buildTranslationType(rawElementType.translationKeys)
      : undefined;
  return {
    ...rawElementType,
    childrenMetadata,
    translationType,
  };
}

function buildChildrenMetadata(
  rawElementType: IRawElementType,
  childrenType: IRawElementType["childrenType"],
): Record<string, IChildMetadata> {
  const childrenMetadata: IElementType["childrenMetadata"] = {};

  if (
    childrenType instanceof t.InterfaceType ||
    childrenType instanceof t.PartialType
  ) {
    const required = childrenType instanceof t.InterfaceType;
    let type;
    for (const name of Object.keys(childrenType.props)) {
      type = childrenType.props[name];
      if (isSingleChildType(type)) {
        childrenMetadata[name] = {
          required,
          isArray: false,
          getValidElementTypes: type[GET_VALID_ELEMENT_TYPES],
        };
      } else if (isArrayChildType(type)) {
        childrenMetadata[name] = {
          required,
          isArray: true,
          getValidElementTypes: type[GET_VALID_ELEMENT_TYPES],
        };
      } else {
        throw new Error(
          `Invalid child type for key "${name}" of type "${rawElementType.name}"`,
        );
      }
    }
  } else if (childrenType instanceof t.IntersectionType) {
    for (const type of childrenType.types) {
      Object.assign(
        childrenMetadata,
        buildChildrenMetadata(rawElementType, type),
      );
    }
  } else {
    throw new Error(
      `Invalid children type for element type "${rawElementType.name}"`,
    );
  }

  return childrenMetadata;
}

function buildTranslationType(translationKeys: readonly string[]) {
  const props = translationKeys.reduce((p, k) => ({ ...p, [k]: t.string }), {});
  const partialType = t.partial(props);
  const optionalLanguages = LANGUAGES.filter(
    (l) => l.code !== DEFAULT_LANGUAGE_CODE,
  ).reduce((all, l) => ({ ...all, [l.code]: partialType }), {});
  return t.intersection([
    t.type({ [DEFAULT_LANGUAGE_CODE]: t.type(props) }),
    t.partial(optionalLanguages),
  ]);
}

/*
 * A factory to create an element type getter function. The function returned throws if the element type
 * is not found and makes a suggestion if a similar element type name exists, to help spot typeos.
 */
export function buildElementTypeGetter(
  elementTypes: Record<string, IElementType>,
) {
  const elementTypesNames = Object.keys(elementTypes);
  return function getElementType(
    elementOrType: AnyElement | string,
  ): IElementType {
    const typeName =
      typeof elementOrType === "string"
        ? elementOrType
        : elementOrType.type.name;
    const type = elementTypes[typeName];
    if (!type) {
      const didYouMean = getDidYouMean(elementTypesNames, typeName);
      throw new Error(`Element type "${typeName}" not supported.${didYouMean}`);
    }
    return type;
  };
}
