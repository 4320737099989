import { IReduxModuleFactoryArgs } from "core";
import { AdvancedConditional } from "../types";
import { buildSelectors } from "./selectors";

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<AdvancedConditional>) {
  const selectors = buildSelectors(path, element);

  return {
    selectors,
  };
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
