import { connectElement } from "core/utils/react-redux";
import { ConnectedReduxModuleProps } from "core";
import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { CallButton } from "./types";

export type CallButtonProps = ConnectedReduxModuleProps<
  ReduxModule,
  CallButton
>;

export default connectElement<ReduxModule, CallButton>()(Component);
