import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { ProcedureButton } from "../types";
import { Actions, Types } from "./types";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: ProcedureButton,
) {
  function* runProcedureSaga() {
    const services: AllServices = yield getContext("services");
    const token = yield select(sessionSelectors.token);

    try {
      const { func_name, func_schema, func_args } = element.config;
      yield call(
        services.api.runProcedure,
        token,
        func_name,
        func_schema,
        func_args,
      );
      yield put(actions.runProcedureSuccess());
    } catch (error) {
      yield put(actions.runProcedureError(error));
    }
  }

  return function* saga() {
    yield all([yield takeLatest(types.RUN_PROCEDURE, runProcedureSaga)]);
  };
}
