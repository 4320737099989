import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useElementTypesContext } from "core/ElementTypesContext";
import { IElementModelArrayChild, TElementModelWithPosition } from "core/types";

import { useElementEditorContext } from "core/editor";
import { actions as editorActions } from "core/editor/reduxModule";
import { selectors as routerSelectors } from "core/router/reduxModule";

type ElementChildren = { content: IElementModelArrayChild };

export const useCreateInitialGridElement = <T>(gridName: string) => {
  const page = useSelector(routerSelectors.page);
  const { elementModel } = useElementEditorContext<T>();
  const {
    content: { elements },
  } = elementModel.children as ElementChildren;
  const { elementTypes } = useElementTypesContext();
  const dispatch = useDispatch();

  const createGridElement = React.useCallback(() => {
    dispatch(
      editorActions.createElement(
        elementTypes,
        elementTypes.default_grid,
        page!,
        {
          row: 1,
          column: 1,
          width: 12,
          height: (elementModel as TElementModelWithPosition).position.height,
        },
        elementModel!,
        undefined,
        gridName,
      ),
    );
  }, [dispatch, elementModel, elementTypes, gridName, page]);

  React.useEffect(() => {
    if (!elements.length) {
      createGridElement();
    }
  }, [elements, createGridElement]);
};
