import { connect } from "react-redux";

import { actions, selectors } from "core/router/reduxModule";
import { MenuItem as MIComponent } from "./component";

export const mapStateToProps = (state: any) => ({
  getPageById: (id: string) => selectors.allPages(state)[id],
});

export const MenuItem = connect(mapStateToProps, actions)(MIComponent);
