import { getExpression } from "core";
import { getTranslatedText } from "core/utils/element";
import { IObjectView, Language } from "core/types";

export type UnifiedDataType =
  | "text"
  | "number"
  | "dateTime"
  | "date"
  | "time"
  | "json"
  | "boolean"
  | "fallback";

const typeToOperatorMapping: Record<UnifiedDataType, string[]> = {
  text: ["eq", "ilike", "is_null", "is_not_null"],
  boolean: ["is_true", "is_false", "is_null", "is_not_null"],
  number: ["eq", "lt", "gt", "is_null", "is_not_null"],
  date: ["eq", "lt", "gt", "is_null", "is_not_null"],
  time: ["eq", "lt", "gt", "is_null", "is_not_null"],
  dateTime: ["eq", "lt", "gt", "is_null", "is_not_null"],
  json: ["eq", "is_null", "is_not_null"],
  fallback: ["is_null", "is_not_null"],
};

export const intervalSliderMarks = [0, 5, 10, 20, 30, 45, 60].map((v) => ({
  value: v,
  label: v.toString(),
}));

export const generateFilterFieldConfig = (
  fields: IObjectView["fields"],
  lang: Language,
) =>
  fields.map((field) => ({
    input: {
      type: field.generalType.type,
    },
    label: getTranslatedText(lang, field.i18n, "title") ?? field.name,
    name: field.name,
    operators: typeToOperatorMapping[field.generalType.type],
  }));

export const getBooleanExpressionValue = (
  value?: string | null,
): boolean | undefined => {
  if (!value) {
    return undefined;
  }

  switch (getExpression(value)) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return undefined;
  }
};
