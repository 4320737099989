import { IDefaultElement } from "core/types/element";
import { TableHeaderCellConfig } from "./types";

export const defaultElement: IDefaultElement<TableHeaderCellConfig> = {
  i18n: {
    en: {
      label: "",
    },
  },
  config: {
    dataSource: {
      fieldName: "",
      sortable: false,
    },
    align: "center",
  },
  position: {
    column: 1,
    row: 1,
    width: 1,
    height: 1,
  },
};
