import React, { memo } from "react";

import { ConnectedReduxModuleProps } from "core";
import { ColorInput as ColorInputComponent } from "../common/ColorInput";
import { ReduxModule } from "./reduxModule";
import { ColorInput } from "./types";

const DefaultColorInput = memo<
  ConnectedReduxModuleProps<ReduxModule, ColorInput>
>(
  ({
    value,
    element: {
      i18n: { label },
      config: { placement, nullable },
    },
    changeValue,
    disabled,
  }) => (
    <ColorInputComponent
      value={value}
      changeValue={changeValue}
      label={label}
      disabled={disabled}
      placement={placement}
      nullable={nullable}
    />
  ),
);

export default DefaultColorInput;
