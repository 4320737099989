import { MouseEvent, createContext, useContext } from "react";

type IDialogWrapperContext = {
  onSubmit: (e: MouseEvent) => void;
};

export const DialogWrapperContext = createContext<IDialogWrapperContext>({
  onSubmit: () => {
    throw new Error("Not Implemented");
  },
});

export const DialogWrapperProvider = DialogWrapperContext.Provider;
export const DialogWrapperConsumer = DialogWrapperContext.Consumer;

export function useDialogContext() {
  const context = useContext(DialogWrapperContext);
  if (context === undefined) {
    throw new Error(
      "useDialogContext must be used within a DialogWrapperProvider",
    );
  }
  return context;
}
