import React, { ReactNode, memo, useMemo } from "react";

import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { default as MuiTable, TableProps } from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { EmptyRow } from "../EmptyRow";
import { TableFooter } from "./TableFooter";
import { TableContextProvider } from "./context";

export type Align = TableCellProps["align"];
type CellAlign = {
  name: string;
  align: Align;
};
type Props = {
  headers: {
    title: string;
    name: string;
  }[];
  onDataReload: () => void;
  error?: string | null;
  loading?: boolean;
  rows?: ReactNode[];
  alignment?: CellAlign[];
} & TableProps;

const getCellAlign = (
  cellName: string,
  alignment?: CellAlign[],
  index?: number,
): Align =>
  alignment?.find((cell) => cell.name === cellName)?.align ??
  (index === 0 ? "left" : "center");

export const Table = memo<Props>(
  ({ rows, headers, onDataReload, alignment, loading, error, ...rest }) => {
    const header = useMemo(() => {
      let cells: ReactNode[] = [];
      let cellsAlignment: Align[] = [];
      for (const [index, { title, name }] of headers.entries()) {
        const align = getCellAlign(name, alignment, index);
        cells = [
          ...cells,
          <TableCell key={name} align={align}>
            {title}
          </TableCell>,
        ];
        cellsAlignment = [...cellsAlignment, align];
      }
      return { cells, cellsAlignment };
    }, [headers, alignment]);
    const cellsAlignment = useMemo(() => header.cellsAlignment, [
      header.cellsAlignment,
    ]);

    return (
      <TableContainer component={Paper} variant="outlined">
        <TableContextProvider value={{ alignment: cellsAlignment }}>
          <MuiTable size="small" {...rest}>
            <TableHead>
              <TableRow>{header.cells}</TableRow>
            </TableHead>
            <TableBody>
              {rows?.length ? (
                rows
              ) : (
                <EmptyRow title={loading ? "Loading..." : "No Data"} />
              )}
            </TableBody>
            {error && (
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <Typography color="error" variant="h5">
                    {error}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {onDataReload && (
              <TableFooter
                colSpan={headers.length}
                onClick={onDataReload}
                loading={loading}
              />
            )}
          </MuiTable>
        </TableContextProvider>
      </TableContainer>
    );
  },
);
