import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemClass: {
      cursor: "grab",
      transition: theme.transitions.create(["color", "fontWeight"]),
      willChange: "color fontWeight",
      padding: `0 0 0 ${theme.spacing(1.5)}px`,
      "& > [class*='MuiCardHeader-action']": {
        margin: 0,
      },
      "&:hover [class*='MuiCardHeader-subheader']": {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    contentPadding: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px !important`,
    },
    panelContent: {
      overflowY: "auto",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    inputClass: {
      margin: `0 ${theme.spacing()}px 0 0`,
    },
  }),
);

export default useStyles;
