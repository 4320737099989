import React, { ChangeEvent, memo, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedAutocompleteInputConfig } from "../../types";
import { useAutocompleteEditorTranslation } from "../translation";

export const AdvancedComponent = memo(() => {
  const {
    elementModel: {
      config: {
        isClearable = true,
        fullTextSearch,
        autosuggestHighlight,
        virtualizedList,
        nullable,
        disabled,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedAutocompleteInputConfig>();
  const translation = useAutocompleteEditorTranslation();

  useEffect(() => {
    if (nullable && !isClearable) {
      changeConfigValue("isClearable", true);
    }
  }, [nullable, isClearable, changeConfigValue]);

  const onChange = (
    { target: { name } }: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    switch (name) {
      case "withCheckbox":
        if (checked) {
          changeConfigValue("allowSameValue", undefined);
        }
        break;

      case "allowSameValue":
        if (checked) {
          changeConfigValue("withCheckbox", undefined);
        }
        break;
    }

    changeConfigValue(
      name as keyof UntransformedAutocompleteInputConfig,
      checked,
    );
  };

  return (
    <Section title={translation.advancedTitle} wrapped={true}>
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(isClearable)}
              onChange={onChange}
              disabled={nullable || disabled}
            />
          }
          label={translation.isClearableLabel}
          name="isClearable"
        />
        <FormHelperText disabled={!nullable} variant="outlined">
          {translation.nullableFlagHelperText}
        </FormHelperText>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(fullTextSearch)}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={translation.fullTextSearchLabel}
        name="fullTextSearch"
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(autosuggestHighlight)}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={translation.autoSuggestHighlightLabel}
        name="autosuggestHighlight"
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(virtualizedList)}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={translation.virtualizedListLabel}
        name="virtualizedList"
      />
    </Section>
  );
});
