import React, { memo, useCallback, useState } from "react";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { AppBar } from "layouts/common/AppBar";
import { Footer } from "layouts/common/Footer";
import { Menu, MenuProps } from "layouts/common/Menu";
import useStyles from "layouts/default_layout/styles";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { useCommonStaticPagesTranslation } from "./translation";

const DEFAULT_MENU_WIDTH = "240px";
const DEFAULT_TOOLBAR_VARIANT = "dense";

export const StaticPagesLayout = memo(({ children }) => {
  const [openState, setOpenState] = useState(true);
  const location = useSelector(routerSelectors.location);
  const translation = useCommonStaticPagesTranslation();

  const menuWidth = DEFAULT_MENU_WIDTH;
  const toolbarVariant = DEFAULT_TOOLBAR_VARIANT;

  const classes = useStyles({ menuWidth, toolbarVariant });

  const toggleOpen = useCallback(
    () => setOpenState((prevOpen: boolean) => !prevOpen),
    [setOpenState],
  );

  const menuProps: MenuProps = {
    openState,
    logo: {
      url: "https://www.cybertec-postgresql.com",
    },
    uiName: "",
    menu: [],
    classes,
    menuItemSelected: "",
  };

  const pageContent = (
    <Paper
      square={true}
      elevation={0}
      component="main"
      className={classNames(classes.content, classes["content-left"], {
        [classes.contentShift]: openState,
        [classes["contentShift-left"]]: openState,
      })}
    >
      {children}
    </Paper>
  );

  const getAppBarLabel = () => {
    switch (location.pathname) {
      case "/settings":
        return translation.settingsAppBarLabel;
      default:
        return translation.noAppsAvailableAppBarLabel;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <AppBar
          open={openState}
          label={getAppBarLabel()}
          handleDrawerChange={toggleOpen}
          menuWidth={menuWidth}
          toolbarVariant={toolbarVariant}
          headerButtonsColor="inherit"
        />

        <Menu {...menuProps} />

        {pageContent}

        <Footer openState={openState} />
      </div>
    </div>
  );
});
