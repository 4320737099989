import { UntransformedDateTimeFieldConfig } from "../types";
import { IDefaultElement } from "core";

export const defaultElement: IDefaultElement<UntransformedDateTimeFieldConfig> = {
  config: {
    value: "01/01/2020 00:00:00",
    showDatePart: true,
    showTimePart: true,
  },
};
