import { call, put, select } from "redux-saga/effects";

import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import {
  DEFAULT_APP_URL,
  actions as routerActions,
} from "core/router/reduxModule";
import { actions } from "./actions";

import * as ApiService from "services/api";

function* bootstrap() {
  const token = yield select(sessionSelectors.token);
  const isLoggedIn = yield select(sessionSelectors.isLoggedIn);

  try {
    if (isLoggedIn) {
      yield put(routerActions.replace(DEFAULT_APP_URL));

      return;
    }

    const config = yield call(ApiService.getLoginConfig, token);
    yield put(actions.loadLoginConfigSuccess(config));
  } catch (error) {
    yield put(actions.loadLoginConfigError(error.message ?? error.toString()));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "Login config could not be retrieved",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

export function* saga() {
  yield bootstrap();
}
