import React, { memo } from "react";
import { Helmet } from "react-helmet";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import notFoundPath from "layouts/common/notFound.svg";
import { useNoAppsAvailablePageTranslation } from "./translation";
import useStyles from "./styles";

export const NoAppsAvailablePage = memo(() => {
  const { root } = useStyles();
  const translation = useNoAppsAvailablePageTranslation();

  return (
    <>
      <Helmet>
        <title>{translation.noAppsAvailableText}</title>
      </Helmet>

      <Card className={root}>
        <CardHeader
          title={
            <Typography variant="h2" align="center">
              {translation.noAppsAvailableText}
            </Typography>
          }
        />
        <CardContent>
          <img src={notFoundPath} alt="Not Found" />
        </CardContent>
      </Card>
    </>
  );
});
