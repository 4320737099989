import React, { memo, useCallback, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";

import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import Button from "elementTypes/common/Button";
import { TableChildren, TableConfig } from "../../types";
import { useTableEditorTranslation } from "../../translation";
import Popover from "elementTypes/common/Popover";

export const DataSourceEditor = memo(() => {
  const { elementModel, changeConfigValue } = useElementEditorContext<
    TableConfig
  >();

  const {
    config: { dataSource },
    children,
  } = elementModel;

  const {
    header: { elements: headerElements },
  } = children as TableChildren;

  const {
    cancelButton,
    dataSourceTitle,
    identifierNameLabel,
    viewLabel,
    stateColumnNameLabel,
  } = useEditorTranslation();
  const {
    changeViewButton,
    changeViewConfirmation,
  } = useTableEditorTranslation();

  const elementsExist = !!headerElements?.length;

  const changeDataSource = useCallback(
    (newDataSource: TableConfig["dataSource"]) =>
      changeConfigValue("dataSource", newDataSource),
    [changeConfigValue],
  );

  const {
    viewName = "",
    identifierName = "",
    stateColumnName = "",
  } = dataSource;

  const { getViewByName } = useObjectViewList();

  const sectionRef = useRef<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [nextViewName, setNextViewName] = useState<string | null>(null);

  const handlePopupClose = () => {
    if (nextViewName) {
      setNextViewName(null);
    }
    setAnchorEl(null);
  };

  const handleChange = (newViewName: string) => {
    changeConfigValue("fixedFilter", undefined);
    changeConfigValue("filter", undefined);
    changeConfigValue("defaultSort", undefined);
    const nextObjectView = getViewByName(newViewName);

    changeDataSource({
      viewName: newViewName,
      identifierName: nextObjectView?.identifyingField?.name,
      stateColumnName: undefined,
    });

    if (anchorEl) {
      handlePopupClose();
    }
  };

  const handleViewNameChange = (newViewName: string) => {
    if (elementsExist) {
      setNextViewName(newViewName);
      setAnchorEl(sectionRef.current);
    } else {
      handleChange(newViewName);
    }
  };

  const handleClick = () => handleChange(nextViewName!);

  const handleFieldChange = (
    key: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) => {
    changeDataSource({
      ...dataSource,
      [key]: fieldValue as string,
    });
  };

  const fields = [
    {
      label: identifierNameLabel,
      value: identifierName,
      name: "identifierName",
      isClearable: true,
    },
    {
      label: stateColumnNameLabel,
      value: stateColumnName,
      name: "stateColumnName",
      isClearable: true,
    },
  ];

  return (
    <Section title={dataSourceTitle} wrapped={true} innerRef={sectionRef}>
      <ViewAutocomplete
        viewValue={viewName}
        viewLabel={viewLabel}
        onViewNameChange={handleViewNameChange}
        onViewFieldChange={handleFieldChange}
        fields={fields}
      />
      <Popover
        anchorEl={anchorEl}
        onClose={handlePopupClose}
        actionsAlign="center"
        actions={
          <>
            <Button
              id="change-view-pop-up-confirm"
              label={changeViewButton}
              color="secondary"
              onClick={handleClick}
            />
            <Button
              label={cancelButton}
              onClick={handlePopupClose}
              id="change-view-pop-up-cancel"
            />
          </>
        }
      >
        <>
          <Typography component="span">{changeViewConfirmation}</Typography>
        </>
      </Popover>
    </Section>
  );
});
