import { selectorScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IState } from "./types";

const scopeSelector = selectorScoper<IState>([
  "staticPages",
  constants.MODULE_NAME,
]);

const loading = (state: any) => scopeSelector(state).loading;
const error = (state: any) => scopeSelector(state).error;
const fileTypes = (state: any) => scopeSelector(state).fileTypes;
const fileGroups = (state: any) => scopeSelector(state).fileGroups;

export const selectors = {
  loading,
  error,
  fileTypes,
  fileGroups,
};
