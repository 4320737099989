import { IDefaultElement } from "core/types/element";
import { UntransformedExternalLinkFieldConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedExternalLinkFieldConfig> = {
  i18n: {
    en: {
      label: "",
    },
  },
  config: {
    link: "",
  },
};
