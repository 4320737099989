import * as t from "io-ts";
import { IElement, UntransformedConfig, customExpression } from "core/types";

export const ExternalLinkFieldConfig = t.intersection([
  t.type({
    link: customExpression(t.string),
  }),
  t.partial({
    isButton: t.boolean,
  }),
]);

export type ExternalLinkFieldConfig = t.TypeOf<typeof ExternalLinkFieldConfig>;

export type UntransformedExternalLinkFieldConfig = UntransformedConfig<
  ExternalLinkFieldConfig
>;

export const ExternalLinkFieldTranslationKeys = ["label"] as const;

export type ExternalLinkFieldTranslationKeys = typeof ExternalLinkFieldTranslationKeys[number];

export type ExternalLinkField = IElement<
  ExternalLinkFieldConfig,
  Record<string, any>,
  ExternalLinkFieldTranslationKeys
>;
