import { withLazyLoading } from "../../helpers/HOC/LazyLoading";
import { lazy } from "react";

export * from "./EchartsContainer";

export * from "./types";

export const Echarts = withLazyLoading(
  lazy(() => import("./Echarts")),
  true,
);
