import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import {
  ClipboardCopyButtonConfig,
  ClipboardCopyButtonTranslationKeys,
} from "./types";
import {
  ClipboardCopyButtonEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { ElementGroup, IRawElementType } from "core";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_clipboard_copy_button",
  component: Component,
  group: ElementGroup.Various,
  editable: true,
  configType: ClipboardCopyButtonConfig,
  translationKeys: ClipboardCopyButtonTranslationKeys,
  defaultElement,
  editorComponent: ClipboardCopyButtonEditor,
  editorMetadata,
};

export default elementType;
