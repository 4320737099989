import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(-0.25),
    },
    chip: {
      margin: theme.spacing(0.25),
    },
  }),
);
