import { createContext, useContext } from "react";

import { DEFAULT_LANGUAGE, Language } from "../../../types";

interface ILanguageSectionContext {
  lang: Language;
  changeLanguage: (lang: Language) => void;
}

export const LanguageSectionContext = createContext<ILanguageSectionContext>({
  lang: DEFAULT_LANGUAGE,
  changeLanguage: (_lang: Language) => {
    throw new Error("Not implemented");
  },
});

export function useLanguageSectionContext() {
  const context = useContext(LanguageSectionContext);
  if (context === undefined) {
    throw new Error(
      "useLanguageSectionContext must be used within a LanguageSectionContext.Provider",
    );
  }
  return context;
}

export const LanguageSectionProvider = LanguageSectionContext.Provider;
