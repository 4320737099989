import React, { memo, useCallback, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import { Language, Translation } from "core/types";
import { UntransformedAutocompleteInputConfig } from "../../types";
import { useStyles } from "./styles";
import { useAutocompleteEditorTranslation } from "../translation";
import { Options } from "./Options";
import { IAutocompleteValue } from "../../components";
import { Filter } from "./Filter";
import FormLabel from "@material-ui/core/FormLabel";

type Props = {
  language: Language;
  handleChangeLanguage: (lang: Language) => void;
};

export const DataSourceComponent = memo<Props>(
  ({ language, handleChangeLanguage }) => {
    const classes = useStyles();
    const {
      elementModel: {
        config: { reference, options = [] },
      },
      changeConfigValue,
    } = useElementEditorContext<UntransformedAutocompleteInputConfig>();
    const editorTranslation = useEditorTranslation();
    const translation = useAutocompleteEditorTranslation();
    const { getViewByName } = useObjectViewList();
    const [mode, setMode] = useState(reference ? "reference" : "options");

    const handleChangeReference = useCallback(
      (newValue: UntransformedAutocompleteInputConfig["reference"]) =>
        changeConfigValue("reference", newValue),
      [changeConfigValue],
    );

    const { viewName, columnName, columnLabel } = reference ?? {
      viewName: "",
      columnName: "",
      columnLabel: "",
    };

    const getDefaultColumn = useCallback(
      (nextViewName: string) =>
        getViewByName(nextViewName)?.identifyingField?.name,
      [getViewByName],
    );

    const handleViewNameChange = (newViewName: string) =>
      handleChangeReference({
        viewName: newViewName,
        columnName: getDefaultColumn(newViewName) ?? "",
        columnLabel: getDefaultColumn(newViewName) ?? "",
      });

    const handleFieldChange = (key: string, fieldValue: IAutocompleteValue) => {
      handleChangeReference({
        viewName,
        columnName,
        columnLabel,
        [key]: fieldValue as string,
      });
    };

    const handleChangeOptions = useCallback(
      (
        newValue: ({
          value: number | string | boolean;
          i18n: Translation<"label">;
        } | null)[],
      ) => changeConfigValue("options", newValue),
      [changeConfigValue],
    );

    const handleModeChange = (_: any, newValue: string) => {
      const isReference = newValue === "reference";
      changeConfigValue(isReference ? "options" : "reference", undefined);
      if (isReference) {
        changeConfigValue("reference", reference);
      } else {
        changeConfigValue("options", options);
      }

      setMode(newValue);
    };

    const fields = [
      {
        label: editorTranslation.identifierNameLabel,
        value: columnName ?? "",
        name: "columnName",
      },
      {
        label: translation.labelLabel,
        value: columnLabel ?? "",
        name: "columnLabel",
      },
    ];

    return (
      <>
        <Section title={translation.referenceLabel} wrapped={true}>
          <FormControl fullWidth={true} className={classes.controlClass}>
            <FormLabel component="p">{translation.sourceTitle}</FormLabel>
            <RadioGroup row={true} value={mode} onChange={handleModeChange}>
              <FormControlLabel
                control={<Radio color="primary" />}
                label={translation.referenceLabel}
                value="reference"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label={translation.optionsLabel}
                value="options"
              />
            </RadioGroup>
          </FormControl>
          {mode === "reference" ? (
            <ViewAutocomplete
              viewValue={viewName}
              viewLabel={editorTranslation.viewLabel}
              onViewNameChange={handleViewNameChange}
              onViewFieldChange={handleFieldChange}
              fields={fields}
            />
          ) : (
            <Options
              options={options as any}
              onChange={handleChangeOptions}
              language={language}
              handleChangeLanguage={handleChangeLanguage}
            />
          )}
        </Section>
        {mode === "reference" && <Filter />}
      </>
    );
  },
);
