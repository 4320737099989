import * as t from "io-ts";
import { IElement, customExpression } from "core/types";
import { types } from "core/runtime-typing";
import { UntransformedConfig } from "core/types/react";
import { SelectorTypes } from "core/types/element";

export const StateChangeDropDownConfig = t.intersection([
  t.type({
    dataSource: t.type({
      elementId: t.string,
      fieldName: t.string,
    }),
    value: customExpression(t.string),
  }),
  t.partial({ disabled: t.boolean }),
]);

export type StateChangeDropDownConfig = t.TypeOf<
  typeof StateChangeDropDownConfig
>;

export type UntransformedStateChangeDropDownConfig = UntransformedConfig<
  StateChangeDropDownConfig
>;

export const stateChangeDropDownSelectors: SelectorTypes<StateChangeDropDownConfig> = {
  value: types.string(),
  language: types.string(),
};

export type StateChangeDropDown = IElement<StateChangeDropDownConfig>;
