import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Placement } from "./index";

type Props = {
  isLabel: boolean;
  bgColor: string;
  placement: Placement;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorControl: {
      minWidth: "70px",
      width: "100%",
      height: "30px",
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      outline: "none !important",
      marginRight: ({ isLabel, placement }: Props) =>
        isLabel && placement === "end" ? theme.spacing() : 0,
      marginLeft: ({ isLabel, placement }: Props) =>
        isLabel && placement === "start" ? theme.spacing() : 0,
      backgroundColor: ({ bgColor }: Props) => bgColor,
    },
    labelControl: {
      margin: 0,
      padding: `0 ${theme.spacing(0.1)}px`,
    },
  }),
);
