import React, { memo, useCallback } from "react";

import {
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";
import {
  InternalLinkButtonConfig,
  UntransformedInternalLinkButtonConfig,
} from "../../types";
import { useInternalLinkButtonEditorTranslation } from "../translation";

export const DisplayComponent = memo(() => {
  const { displaySectionTitle } = useInternalLinkButtonEditorTranslation();
  const {
    elementModel: {
      config: { icon },
    },
    changeConfigValue,
  } = useElementEditorContext<InternalLinkButtonConfig>();

  const changeIcon = useCallback(
    (newIcon: UntransformedInternalLinkButtonConfig["icon"]) =>
      changeConfigValue("icon", newIcon),
    [changeConfigValue],
  );

  return (
    <Section title={displaySectionTitle} wrapped={true}>
      <IconAutocomplete label="Icon" value={icon} onChange={changeIcon} />
    </Section>
  );
});
