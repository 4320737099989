import React, { ChangeEvent, memo } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import {
  Color,
  Colors,
  StyledTypography,
} from "elementTypes/common/StyledTypography";
import { useColorsTranslation } from "./translation";

export type IColor = Partial<Color> | "transparent";

type IColorSelect = {
  value: string;
  onChange: (value: string) => void;
  colorItems: IColor[];
  defaultValue: string;
  outlined?: boolean;
  background?: boolean;
};

export const colors = Object.keys(Colors) as Color[];

export const ColorSelect = memo<IColorSelect>(
  ({ colorItems, defaultValue, outlined, value, onChange, background }) => {
    const translation = useColorsTranslation();
    const isBgColor = background || defaultValue === "transparent";
    const getBoxProps = (color: IColor) => ({
      ...(isBgColor && {
        ...(color !== "transparent" && {
          ...(outlined
            ? {
                border: 1.5,
                borderColor: color,
              }
            : {
                bgcolor: color,
              }),
        }),
        px: 1,
        py: 0.5,
        borderRadius: "borderRadius",
      }),
    });

    const items = colorItems.map((item) => (
      <MenuItem key={item} value={item}>
        <StyledTypography
          text={translation[item]}
          color={
            item === "transparent" || (isBgColor && !outlined)
              ? "default"
              : item
          }
          boxProps={getBoxProps(item)}
        />
      </MenuItem>
    ));
    const onColorChange = (e: ChangeEvent<HTMLInputElement>) =>
      onChange(e.target.value);

    return (
      <TextField
        select
        fullWidth
        value={value ?? defaultValue}
        onChange={onColorChange}
        InputProps={{
          style: {
            paddingRight: 16,
          },
        }}
      >
        {items}
      </TextField>
    );
  },
);
