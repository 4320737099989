import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: "auto",
    },
    content: {
      overflow: "visible",
    },
    formClass: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    handlerIcon: {
      cursor: "move",
      transform: "rotate(45deg)",
      margin: `0 ${theme.spacing(0.5)}px 0 -${theme.spacing()}px`,
      padding: theme.spacing(),
    },
    titleClass: {
      display: "flex",
      alignItems: "center",
    },
  }),
);
