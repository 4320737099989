import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { actions as sessionActions } from "core/session/reduxModule";
import { getTranslatedTextSaga } from "core/session/translation/createUseTranslation";
import { sessionTranslation } from "core/session/translations";
import { ClipboardCopyButton } from "../types";
import { Types } from "./types";

export function buildSaga(types: Types, element: ClipboardCopyButton) {
  function* copySaga() {
    const { value: configValue } = element.config;

    const value = (yield select(configValue)) as ReturnType<typeof configValue>;

    try {
      yield navigator.clipboard.writeText(value ?? "");

      yield put(
        sessionActions.enqueueSnackbar({
          message: "Copied",
          options: {
            variant: "success",
          },
        }),
      );
    } catch (error) {
      yield put(
        sessionActions.enqueueSnackbar({
          message: yield call(
            getTranslatedTextSaga,
            sessionTranslation,
            "error",
          ),
          options: {
            variant: "error",
          },
        }),
      );
    }
  }

  return function* mainSaga() {
    yield all([yield takeLatest(types.COPY, copySaga)]);
  };
}
