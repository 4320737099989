import React, { lazy, memo } from "react";

import { useHookFormContext } from "../../../../common/HookForm";
import { useTableTranslation } from "../../../translation";

import { withLazyLoading } from "../../../../helpers/HOC/LazyLoading";
import { GeneralTypes } from "core";

type Props = {
  value: any;
  type: GeneralTypes;
  path: number[];
  error?: boolean;
};

const getErrorType = (type: GeneralTypes) => {
  switch (type) {
    case "text":
      return "required";
    case "number":
      return "notANumber";
    default:
      return "required";
  }
};

const getErrorMsg = (
  type: GeneralTypes,
  translation: { [k: string]: string },
) => {
  const errorType = getErrorType(type);
  return translation[`${errorType}Error`];
};

const InputComponent = withLazyLoading(
  lazy(() => import("./InputField")),
  true,
);

export const Value = memo<Props>(({ error, path, type, value }) => {
  const { control, clearError, setError } = useHookFormContext();
  const translations = useTableTranslation();

  // /*
  //  * Since `react-hook-form` minimizes the number of re-renders
  //  * there is a need to trigger `setValue` when new `Rule` has been added or deleted
  //  */
  // useEffect(() => {
  //   setValue(`[${path}].value`, value);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [path, value]);

  const handleValidateValue = (newValue: any, valueType: GeneralTypes) => {
    const validate = () => {
      switch (valueType) {
        case "text":
          return true;
        case "number":
          return !isNaN(Number(newValue));
        default:
          return true;
      }
    };
    const isValid = validate();
    if (isValid) {
      clearError(`[${path}].value`);
    } else {
      setError(
        `[${path}].value`,
        getErrorType(type),
        getErrorMsg(type, translations),
      );
    }
  };

  const handleChange = ([ev]: any) => {
    if (type.includes("date") || type.includes("time")) {
      return ev.toISOString();
    } else {
      const {
        target: { value: newValue },
      } = ev;
      handleValidateValue(newValue, type);

      return newValue;
    }
  };

  const commonProps = {
    errors: error,
    name: `[${path}].value`,
    control,
    defaultValue: value,
    onChange: handleChange,
    rules: {
      required: true,
    },
  };

  return <InputComponent type={type} {...commonProps} />;
});
