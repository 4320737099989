import { createUseTranslation } from "core/session/translation";

export const formTranslation = {
  en: {
    loading: "Loading...",
    saving: "Saving...",
    messageCreated: "Successfully created",
    messageUpdated: "Successfully updated",
  },
  es: {
    loading: "Cargando...",
    saving: "Guardando...",
    messageCreated: "Creado con éxito",
    messageUpdated: "Actualizado con éxito",
  },
};

export const actionTranslation = {
  en: {
    saveAndStay: "Save and new",
    saveAndGo: "Save",
    reset: "Reset",
    back: "Back",
    stateFieldNameLabel: "Action",
  },
  de: {
    saveAndStay: "Speichern und neu",
    saveAndGo: "Speichern",
    reset: "Zurücksetzen",
    back: "Zurück",
  },
};

export const errorsTranslation = {
  en: {
    alertTitle: "Error",
    uniqueViolation: "This value already exists - please choose another one",
  },
  de: {},
};

export const useFormTranslation = createUseTranslation(formTranslation);

export const useActionTranslation = createUseTranslation(actionTranslation);

export const useErrorsTranslation = createUseTranslation(errorsTranslation);
