import * as t from "io-ts";
import { IElement } from "core/types";
import { UntransformedConfig } from "core/types/react";
import { types } from "core/runtime-typing";
import {
  EChartsTranslationKeys,
  EchartsContainerSingleConfig,
} from "../common/Echarts";

export const PieChartConfig = t.intersection([
  EchartsContainerSingleConfig,
  t.partial({
    hideLabel: t.boolean,
    hideLegend: t.boolean,
    isDonut: t.boolean,
  }),
]);

export type PieChartConfig = t.TypeOf<typeof PieChartConfig>;
export type UntransformedPieChartConfig = UntransformedConfig<PieChartConfig>;

export type PieChart = IElement<PieChartConfig, {}, EChartsTranslationKeys>;

export const selectorTypes = {
  loading: types.boolean(),
  data: types.any(),
  error: types.nullable(types.string()),
};
