import { ElementEditorMetadata } from "core";

export const editorMetadata: ElementEditorMetadata = {
  i18n: {
    en: {
      title: "Google Maps",
      description: "Display locations on a map",
    },
    de: {
      description: "Stelle Standpunkte auf einer Karte dar",
    },
  },
  minSize: {
    height: 4,
    width: 4,
  },
};
