import React, { memo, useCallback, useMemo, useState } from "react";

import { Section, useElementEditorContext } from "core/editor";
import { ColorInputConfig } from "../../types";
import { useColorInputEditorTranslation } from "../translation";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "../../../default_autocomplete_input/components";
import { SelectOption } from "../../../default_autocomplete_input/types";

const placementOptions = ["bottom", "top", "end", "start"];

const placementAutocompleteOptions = placementOptions.map((colorOption) => ({
  value: colorOption,
  label: colorOption,
}));

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: { placement = "end" },
    },
    changeConfigValue,
  } = useElementEditorContext<ColorInputConfig>();
  const translation = useColorInputEditorTranslation();
  const [searchPlacementInputValue, setSearchPlacementInputValue] = useState<
    string
  >("");

  const handlePlacementChange = useCallback(
    (newValue: ColorInputConfig["placement"]) =>
      changeConfigValue("placement", newValue),
    [changeConfigValue],
  );

  const selectedPlacementValue = useMemo(
    () =>
      placementAutocompleteOptions.find(
        (o: SelectOption) => o.value === placement,
      ),
    [placement],
  );

  const handlePlacementInputChange = (value: IAutocompleteValue) => {
    setSearchPlacementInputValue("");
    handlePlacementChange(
      value as Exclude<ColorInputConfig["placement"], undefined>,
    );
  };

  return (
    <Section title={translation.stylingTitle} wrapped={true}>
      <BaseAutocomplete
        options={placementAutocompleteOptions}
        onChange={handlePlacementInputChange}
        valueObject={selectedPlacementValue ?? ""}
        name="placement"
        label={translation.placementInputLabel}
        isLoading={false}
        searchInputValue={searchPlacementInputValue}
        onInputChange={setSearchPlacementInputValue}
        virtualizedList={true}
        isClearable={false}
      />
    </Section>
  );
});
