import Component from "./container";
import { reduxModuleFactory } from "./reduxModule";
import { TabsChildren, TabsConfig, selectorTypes } from "./types";
import { ElementGroup, IRawElementType } from "core";
import { DefaultTabsEditor, defaultElement, editorMetadata } from "./editor";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_tabs",
  group: ElementGroup.Various,
  component: Component,
  editable: true,
  configType: TabsConfig,
  childrenType: TabsChildren,
  editorComponent: DefaultTabsEditor,
  defaultElement,
  editorMetadata,
  selectorTypes,
};

export default elementType;

export * from "./reduxModule/types";
