import { UsersPage } from "./container";

import * as reduxModule from "./reduxModule";

import {
  CreateUsersPageRoute,
  EditUsersPageRoute,
  ViewUsersPageRoute,
} from "./pages";

export const route = {
  reduxModule,
  Component: UsersPage,
  auth: true,
  isAdmin: true,
  routes: {
    "/admin/users/create": CreateUsersPageRoute,
    "/admin/users/edit/:userId": EditUsersPageRoute,
    "/admin/users/view/:userId": ViewUsersPageRoute,
  },
};
