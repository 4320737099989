import { handleActions } from "core/utils/redux";
import { ActionTypes, IReducerState, Types } from "./types";

const INITIAL_STATE: IReducerState = {
  loading: false,
  error: null,
  file: null,
  metadata: null,
  metadataError: null,
};

export function buildReducer(types: Types) {
  return handleActions<IReducerState, ActionTypes>(INITIAL_STATE, {
    [types.UPLOAD]: (state, action: ActionTypes["upload"]) => ({
      ...state,
      loading: true,
      error: null,
      metadataError: null,
      file: action.payload.file,
    }),
    [types.FETCH_METADATA]: (state) => ({
      ...state,
      loading: true,
      error: null,
      metadataError: null,
    }),
    [types.UPLOAD_SUCCESS]: (state, action: ActionTypes["uploadSuccess"]) => ({
      ...state,
      loading: false,
      metadataError: null,
      metadata: action.payload.metadata,
    }),
    [types.FETCH_METADATA_SUCCESS]: (
      state,
      action: ActionTypes["fetchMetadataSuccess"],
    ) => ({
      ...state,
      loading: false,
      metadataError: null,
      metadata: action.payload.metadata,
    }),
    [types.UPLOAD_ERROR]: (state, action: ActionTypes["uploadError"]) => ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
    [types.FETCH_METADATA_ERROR]: (
      state,
      action: ActionTypes["fetchMetadataError"],
    ) => ({
      ...state,
      loading: false,
      metadataError: action.payload.error,
    }),
    [types.CLEAR]: (state) => ({
      ...state,
      loading: false,
      metadata: null,
      file: null,
      metadataError: null,
    }),
  });
}
