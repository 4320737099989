import React, { memo } from "react";

import BackButton from "elementTypes/common/BackButton";
import { UserProfile } from "./components";
import useStyles from "./styles";

export const SettingsPage = memo(() => {
  const { root } = useStyles();

  return (
    <div className={root}>
      <UserProfile />
      <BackButton />
    </div>
  );
});
