import React from "react";
import { useSelector } from "react-redux";

import { selectors as editorSelectors } from "../../reduxModule";
import { selectors as routerSelectors } from "../../../router/reduxModule";
import { TableModel } from "elementTypes/default_table/types";

import { getNearestParentElement, useObjectViewList } from "../..";
import { buildCustomExpressionValue } from "../../..";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GeneralTypes, IObjectViewField } from "../../../types";

interface Props {
  id: string;
  value: string;
  onChange: (value: string) => void;
  allowedDataTypes?: GeneralTypes[];
  allowedDataTypeIsArray?: boolean;
}

function filterNonNullable<T>(t: T): t is NonNullable<T> {
  return t !== null && t !== undefined;
}

export function TableColumnEditor(props: Props) {
  const page = useSelector(routerSelectors.page);
  const updatedElements = useSelector(editorSelectors.updatedElements);
  const { getViewByName } = useObjectViewList();

  const nearestTable = getNearestParentElement(
    page!.element,
    updatedElements,
    props.id,
    "default_table",
  ) as TableModel | null;

  if (!nearestTable) {
    return null;
  }
  const { viewName, references } = nearestTable.config.dataSource;

  const sourceView = getViewByName(viewName);

  if (!sourceView) {
    return null;
  }

  const referenceViewMapping = references
    ? Object.keys(references)
        .map((sourceFieldName) => {
          const referencedViewName = references[sourceFieldName].viewName;
          const referencedView = getViewByName(referencedViewName);

          const sourceField = sourceView.fields.find(
            (f) => f.name === sourceFieldName,
          );

          if (!referencedView || !sourceField) {
            return null;
          }

          return {
            sourceField,
            view: referencedView,
          };
        })
        .filter(filterNonNullable)
    : [];

  const isCorrectFieldType = (field: IObjectViewField) =>
    !props.allowedDataTypes ||
    (props.allowedDataTypes.includes(field.generalType.type) &&
      (props.allowedDataTypeIsArray === undefined ||
        props.allowedDataTypeIsArray === field.generalType.isArray));

  const options = sourceView.fields.map((f) => ({
    value: buildCustomExpressionValue(`props.data[${JSON.stringify(f.name)}]`),
    label: f.name,
    name: f.name,
    referenceField: null as string | null,
    disabled: !isCorrectFieldType(f),
  }));

  referenceViewMapping.forEach((v) => {
    options.push(
      ...v.view.fields.map((f) => {
        const referencesExpressionTextWithoutAccessor = `props.references[${JSON.stringify(
          v.sourceField.name,
        )}]`;

        const referencesExpressionText = `${referencesExpressionTextWithoutAccessor}[${JSON.stringify(
          f.name,
        )}]`;

        // used when the source field is nullable
        const nullableReferencesExpressionText = `${referencesExpressionTextWithoutAccessor} ? ${referencesExpressionText} : null`;

        return {
          value: buildCustomExpressionValue(
            v.sourceField.nullable
              ? nullableReferencesExpressionText
              : referencesExpressionText,
          ),
          name: f.name,
          label: f.name,
          referenceField: v.sourceField.name,
          disabled: !isCorrectFieldType(f),
        };
      }),
    );
  });

  const handleChange = (
    _e: React.ChangeEvent<Record<string, unknown>>,
    valueOption: typeof options[number],
  ) => {
    if (valueOption !== null) {
      props.onChange(valueOption.value);
    }
  };

  const currentValue = options.find((o) => o.value === props.value.trim()) ?? {
    label: "CUSTOM EXPRESSION",
    name: "",
    referenceField: null,
    value: "",
    disabled: false,
  };

  return (
    <Autocomplete
      options={options}
      groupBy={(o) => o.referenceField ?? ""}
      getOptionLabel={(o) =>
        (o.referenceField ? o.referenceField + " → " : "") + o.label
      }
      getOptionDisabled={(o) => o.disabled}
      value={currentValue}
      disableClearable
      onChange={handleChange}
      renderOption={(o) => o.label}
      renderInput={(params) => (
        <TextField
          {...params}
          className="editor-table-column"
          label="Table Field"
          variant="outlined"
          helperText="Use this to autogenerate the expression"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}
