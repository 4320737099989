import React, { ComponentType, Suspense } from "react";

import { LoadingComponent } from "layouts/common/Loading";

export const withLazyLoading = <P extends object>(
  Component: ComponentType<P>,
  hideLoader?: boolean,
): ComponentType<P> => ({ ...props }: any) => (
  <Suspense fallback={hideLoader ? null : <LoadingComponent />}>
    <Component {...(props as P)} />
  </Suspense>
);
