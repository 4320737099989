import { createUseTranslation } from "core/session/translation";

export const fileInputEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    placementInputLabel: "Placement",
    labelLabel: "Label",
    advancedTitle: "Advanced",
    accessGroupNameInputLabel: "Access Group Name",
    typeGroupNameInputLabel: "Type Group Name",
    acceptInputLabel: "Accept",
  },
};

export const useFileInputEditorTranslation = createUseTranslation(
  fileInputEditorTranslation,
);
