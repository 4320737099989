import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedDateTimeFieldConfig } from "../../types";
import { useDateTimeFieldEditorTranslation } from "../translation";

export const FormattingComponent = React.memo(() => {
  const {
    elementModel: {
      config: { isRelative },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeFieldConfig>();
  const translation = useDateTimeFieldEditorTranslation();
  const handleisRelativeInputChange = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, newValue: boolean) =>
      changeConfigValue("isRelative", newValue),
    [changeConfigValue],
  );

  return (
    <Section title={"Formatting"} wrapped={true}>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={isRelative}
              onChange={handleisRelativeInputChange}
            />
          }
          label={translation.isRelativeLabel}
        />
      </FormGroup>
    </Section>
  );
});
