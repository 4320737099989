import { createUseTranslation } from "core/session/translation";

export const editorTranslation = {
  en: {
    tileLayerUrlLabel: "Tile Layer URL",
    tileLayerUrlDescription: "leave empty to use OpenStreetMaps",
    updateMapViewLabel: "Update map view on data change",
    stylingFunctionLabel: "Styling Function",
    stylingFunctionDescription: "return a leaflet style object",
    tooltipFunctionLabel: "Tooltip Function",
    tooltipFunctionDescription: "return a tooltip text",
    markerDisplayTextFunctionLabel: "Marker Label Function",
    markerDisplayTextFunctionDescription:
      "return the label for a marker; tip: you can use emojis ✨",
    markerBackgroundColorFunctionLabel: "Marker Background",
    markerBackgroundColorFunctionDescription:
      "the background color of the marker",
  },
};

export const useDefaultGeoJSONFieldEditorTranslation = createUseTranslation(
  editorTranslation,
);
