import React, { memo, useCallback } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { Section, useElementEditorContext } from "core/editor";
import { useEditorTranslation } from "../../translation";
import { FormInputConfig } from "elementTypes/common";

export const FormInputConfigEditorComponent = memo(({ children }) => {
  const {
    elementModel: {
      config: { disabled, nullable },
    },
    changeConfigValue,
  } = useElementEditorContext<FormInputConfig>();
  const editorTranslation = useEditorTranslation();

  const handleDisabledChange = useCallback(
    (newValue: FormInputConfig["disabled"]) =>
      changeConfigValue("disabled", newValue),
    [changeConfigValue],
  );
  const handleNullableChange = useCallback(
    (newValue: FormInputConfig["nullable"]) =>
      changeConfigValue("nullable", newValue),
    [changeConfigValue],
  );

  const handleDisabledInputChange = () => handleDisabledChange(!disabled);
  const handleNullableInputChange = () => handleNullableChange(!nullable);

  return (
    <Section title={editorTranslation.inputConfigTitle} wrapped={true}>
      <div>{children}</div>
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(disabled)}
            onChange={handleDisabledInputChange}
          />
        }
        label={editorTranslation.disabledLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(nullable)}
            onChange={handleNullableInputChange}
          />
        }
        label={editorTranslation.nullableLabel}
      />
    </Section>
  );
});
