import { IReduxModuleFactoryArgs } from "core/types";
import { TableDataSource } from "elementInterfaces";
import { StateChangeDropDown } from "../types";
import { buildActions } from "./actions";
import { buildSelectors } from "./selectors";

export function reduxModuleFactory({
  element,
}: IReduxModuleFactoryArgs<StateChangeDropDown>) {
  const { elementId } = element.config.dataSource;

  const dataSource = TableDataSource.get(elementId);

  const { actions } = buildActions(element, dataSource);
  const selectors = buildSelectors(element);

  return {
    actions,
    selectors,
  };
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
