import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import { actions as sessionActions } from "core/session/reduxModule/actions";
import { actions, types } from "./actions";

import * as ApiService from "services/api";

function* updateProfileSaga(
  action: ReturnType<typeof actions["updateProfile"]>,
) {
  const token = yield select(sessionSelectors.token);

  try {
    yield call(ApiService.updateUser, token, action.payload.profile);
    yield put(
      sessionActions.enqueueSnackbar({
        message: "User updated successfully",
        options: {
          variant: "success",
        },
      }),
    );
  } catch (error) {
    yield put(actions.updateProfileError(error.message ?? error.toString()));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "User was not updated",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

export function* saga() {
  yield all([yield takeLatest(types.UPDATE_PROFILE, updateProfileSaga)]);
}
