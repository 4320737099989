import * as t from "io-ts";
import { types } from "core/runtime-typing";
import { GeneralTypes } from "core";

export enum FilterGroupCombinator {
  AND = "AND",
  OR = "OR",
}

export interface IFilterGroup {
  combinator: FilterGroupCombinator;
  filters: Array<IFilterGroup | IFilterRule | null>;
}

export interface IFilterField {
  name: string;
  label: string;
  operators: string[];
  input: {
    type: GeneralTypes;
  };
}

/**
 * TODO:
 * We can make IFilterField be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const filterFieldType = types.interface({
  name: types.string(),
  label: types.string(),
  operators: types.array(types.string()),
  input: types.interface({ type: types.string() }),
});

export interface IFilterRule {
  field: IFilterField;
  operator: string;
  value: any;
  hidden?: boolean;
}

/**
 * TODO:
 * We can make IFilterRule be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
const filterRuleType = types.interface({
  field: filterFieldType,
  operator: types.string(),
  value: types.any(),
  hidden: types.optional(types.boolean()),
});

/**
 * TODO:
 * We can make IFilterGroup be an io-ts type and have a io-ts-to-selector-types helper function, to avoid duplication
 */
export const filterGroupType = types.interface({
  combinator: types.enum(FilterGroupCombinator),
  filters: types.array(
    types.nullable(
      types.union([types.defer(() => filterGroupType), filterRuleType]),
    ),
  ),
});

export type IFilterGroupWithPath = IFilterGroup & {
  path: number[];
  filters: Array<IFilterGroupWithPath | IFilterRuleWithPath>;
};

export type IFilterRuleWithPath = IFilterRule & {
  path: number[];
};

export type IData = {
  [k: string]: any;
};

export type UpdateParams = {
  path: number[];
  values: IData;
};

export interface ITableToolsContext {
  changeSearchValue: (value: string) => void;
  applyFilter: () => void;
  fields: IFilterField[];
  filter: IFilterGroup | null;
  openDialogFilter: boolean;
  handleToggleDialogFilter: () => void;
  searchInputValue: string;
  loading: boolean;
  filterDisabled: boolean;
  simpleFilter?: string[];
}

/**
 * Types for fixed filter config coming from the server. The types are similar to the ones above, except
 * they're declared with io-ts to be able to validate them, and the field only requires name operator
 * and value.
 */

export const FixedFilterGroupCombinator = t.keyof({
  [FilterGroupCombinator.AND]: null,
  [FilterGroupCombinator.OR]: null,
});

export type FixedFilterGroupCombinator = t.TypeOf<
  typeof FixedFilterGroupCombinator
>;

export const FixedFilterRule = t.type({
  field: t.string,
  operator: t.string, // TODO: type valid operators
  value: t.unknown,
});

export type FixedFilterRule = t.TypeOf<typeof FixedFilterRule>;

export interface FixedFilterGroup {
  combinator: FixedFilterGroupCombinator;
  filters: Array<FixedFilterGroup | FixedFilterRule | null>;
}

export const FixedFilterGroup: t.Type<FixedFilterGroup> = t.recursion(
  "FixedFilterGroup",
  () =>
    t.type({
      combinator: FixedFilterGroupCombinator,
      filters: t.array(t.union([FixedFilterGroup, FixedFilterRule, t.null])),
    }),
);
