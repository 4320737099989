import React, { memo } from "react";

import Markdown from "react-markdown";

import { IElementComponentProps } from "core";
import { MarkdownField } from "./types";
import { useStyles } from "./styles";
import { Code, Heading, InlineCode, Paragraph } from "./components";

type IProps = IElementComponentProps<{}, MarkdownField> & {
  text: string | null;
};

const DefaultMarkdownField = memo<IProps>(({ text, element: { config } }) => {
  const { root } = useStyles({ visibility: config.overflow });
  return (
    <span className={root}>
      <Markdown
        source={text || ""}
        escapeHtml={!config.htmlAllowed}
        renderers={{
          code: Code,
          inlineCode: InlineCode,
          heading: Heading(config.align),
          paragraph: Paragraph(config.align),
        }}
      />
    </span>
  );
});

export default DefaultMarkdownField;
