import React, { memo, useCallback } from "react";

import { Section, useElementEditorContext } from "core/editor";
import { GeoJSONInputConfig } from "../../types";
import { useGeoJSONInputEditorTranslation } from "../translation";
import { FormControlLabel, Switch, TextField } from "@material-ui/core";
import { DEFAULT_CONFIG } from "elementTypes/default_geojson_input/constants";

export const Modes = memo(() => {
  const {
    modeTitle,
    deleteLabel,
    dragLabel,
    lineLabel,
    polygonLabel,
    rectangleLabel,
    tileLayerUrlLabel,
    tileLayerUrlDescription,
    maximumFeaturesLabel,
    maximumFeaturesDescription,
  } = useGeoJSONInputEditorTranslation();

  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<GeoJSONInputConfig>();

  const handleSwitchValueChange = useCallback(
    (
      key: keyof GeoJSONInputConfig,
    ): React.ComponentProps<typeof Switch>["onChange"] => (_event, checked) => {
      changeConfigValue(key, checked);
    },
    [changeConfigValue],
  );

  const handleTileLayerUrlChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("tileLayerUrl", e.target.value || null),
    [changeConfigValue],
  );

  const handleMaximumFeatureChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      changeConfigValue(
        "maximumFeatures",
        e.target.value ? parseInt(e.target.value, 10) : null,
      ),
    [changeConfigValue],
  );

  return (
    <Section title={modeTitle} wrapped={true}>
      <TextField
        value={config.tileLayerUrl}
        onChange={handleTileLayerUrlChange}
        label={tileLayerUrlLabel}
        helperText={tileLayerUrlDescription}
      />
      <TextField
        value={config.maximumFeatures}
        type="number"
        onChange={handleMaximumFeatureChange}
        label={maximumFeaturesLabel}
        helperText={maximumFeaturesDescription}
      />

      <FormControlLabel
        control={
          <Switch
            checked={config.allowDelete ?? DEFAULT_CONFIG.allowDelete}
            onChange={handleSwitchValueChange("allowDelete")}
          />
        }
        label={deleteLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={config.allowDrag ?? DEFAULT_CONFIG.allowDrag}
            onChange={handleSwitchValueChange("allowDrag")}
          />
        }
        label={dragLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={config.allowLine ?? DEFAULT_CONFIG.allowLine}
            onChange={handleSwitchValueChange("allowLine")}
          />
        }
        label={lineLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={config.allowPolygon ?? DEFAULT_CONFIG.allowPolygon}
            onChange={handleSwitchValueChange("allowPolygon")}
          />
        }
        label={polygonLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={config.allowRectangle ?? DEFAULT_CONFIG.allowRectangle}
            onChange={handleSwitchValueChange("allowRectangle")}
          />
        }
        label={rectangleLabel}
      />
    </Section>
  );
});
