import React, { MouseEvent, lazy, memo, useState } from "react";

import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { actions } from "core/editor/reduxModule";
import { BoundActions } from "core/types";
import { useEditorTranslation } from "core/editor/translation";
import IconButton, { ButtonProps } from "elementTypes/common/IconButton";
import Button from "elementTypes/common/Button";

import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";

import { mapStateToProps } from "./container";
import { useEditorSwitchTranslation } from "./translations";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

type Props = ReturnType<typeof mapStateToProps> &
  BoundActions<typeof actions> &
  ButtonProps & { toggleDrawer: () => void; drawerOpen: boolean };

export const EditorSwitch = memo<Props>(
  ({
    editModeOn,
    toggleEditMode,
    color,
    toggleDrawer,
    drawerOpen,
    layoutChanged,
    exitEditMode,
    currentAppIsModelBuilder,
  }) => {
    const { exitLabel, label } = useEditorSwitchTranslation();
    const {
      cancelButton,
      exitConfirmation,
      backButton,
      previewTitle,
    } = useEditorTranslation();

    const cancelEditing = (event: MouseEvent<HTMLButtonElement>) => {
      if (editModeOn && layoutChanged) {
        setAnchorEl(event.currentTarget);
      } else {
        toggleEditMode();
        toggleDrawer();
      }
    };

    const enableEditing = () => {
      if (drawerOpen) {
        toggleDrawer();
        setTimeout(() => toggleEditMode(), 200);
      } else {
        toggleEditMode();
      }
    };

    const buttonProps = {
      icon: editModeOn ? "flip_to_front" : "border_inner",
      tooltip: currentAppIsModelBuilder
        ? "Can't edit the model builder"
        : editModeOn
        ? exitLabel
        : label,
      color,
      onClick: editModeOn ? cancelEditing : enableEditing,
      placement: "bottom" as const,
      fontSize: "large" as const,
      id: "toggle-editor",
      disabled: currentAppIsModelBuilder,
    };
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleExit = () => {
      handleClose();
      exitEditMode();
      toggleDrawer();
    };

    const action = (
      <Button
        color="inherit"
        label={backButton}
        size="small"
        onClick={enableEditing}
      />
    );

    return (
      <>
        {!editModeOn && layoutChanged ? (
          <Snackbar
            open={layoutChanged}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="warning"
              action={action}
            >
              {previewTitle}
            </Alert>
          </Snackbar>
        ) : (
          <IconButton {...buttonProps} />
        )}
        {editModeOn && (
          <Popover
            onClose={handleClose}
            actionsAlign="center"
            actions={
              <>
                <Button
                  label={exitLabel}
                  color="secondary"
                  onClick={handleExit}
                />
                <Button label={cancelButton} onClick={handleClose} />
              </>
            }
            anchorEl={anchorEl}
          >
            <Typography align="center">{exitConfirmation}</Typography>
          </Popover>
        )}
      </>
    );
  },
);
