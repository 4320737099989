import { IDefaultElement } from "core";
import { TabsConfig } from "../types";

export const defaultElement: IDefaultElement<TabsConfig> = {
  i18n: {
    en: {},
  },
  children: {
    content: {
      elements: [],
    },
  },
  config: {
    labels: [],
    default: 0,
    variant: "standard",
  },
};
