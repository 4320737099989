import React, { memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Switch from "@material-ui/core/Switch";

import { ColorSelector, Section, useElementEditorContext } from "core/editor";
import {
  InternalLinkButtonConfig,
  UntransformedInternalLinkButtonConfig,
} from "../../types";
import { useInternalLinkButtonEditorTranslation } from "../translation";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { defaultElement } from "../defaultElement";

enum Sizes {
  large = "large",
  medium = "medium",
  small = "small",
}

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { fullWidth = false, size = Sizes.medium, disabled },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedInternalLinkButtonConfig>();
  const translation = useInternalLinkButtonEditorTranslation();

  const sizes = Object.keys(Sizes).map((itemSize) => (
    <FormControlLabel
      key={itemSize}
      control={<Radio color="primary" />}
      label={translation[`${itemSize}Label`]}
      value={itemSize}
    />
  ));

  const handleDisabledChange = (newValue: string) =>
    changeConfigValue("disabled", newValue);
  const handleFullWidthChange = (
    newValue: InternalLinkButtonConfig["fullWidth"],
  ) => changeConfigValue("fullWidth", newValue);
  const handleSizeChange = (_e: React.ChangeEvent, value: string) => {
    changeConfigValue("size", value);
  };
  const handleFullWidthInputChange = () => {
    handleFullWidthChange(!fullWidth);
  };
  const handleExpressionValueChange = (newValue: string) => {
    handleDisabledChange(newValue);
  };
  const handleColorChange = (newColor: string) =>
    changeConfigValue("color", newColor);

  return (
    <Section title={translation.stylingSectionTitle} wrapped={true}>
      <FormLabel>{translation.colorInputLabel}</FormLabel>
      <ColorSelector config={config} onChange={handleColorChange} />
      <FormLabel>{translation.sizeInputLabel}</FormLabel>
      <RadioGroup row={true} value={size} onChange={handleSizeChange}>
        {sizes}
      </RadioGroup>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(fullWidth)}
              onChange={handleFullWidthInputChange}
            />
          }
          label={translation.fullWidthInputLabel}
        />
      </div>
      <CustomExpressionEditor
        value={disabled ?? String(defaultElement.config?.disabled)}
        config={config}
        label="Disabled"
        onChange={handleExpressionValueChange}
        disableSwitcher={true}
      />
    </Section>
  );
});
