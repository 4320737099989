import React, { memo, useState } from "react";
import classNames from "classnames";
import { MuiThemeProvider } from "@material-ui/core";

import { AdminFooter } from "./Footer";
import { AdminHeader } from "./Header";
import { AdminMenu } from "./Menu";
import useStyles from "../styles";
import { adminTheme } from "./adminTheme";
import logoTextPath from "./logo-text.svg";
import logoPath from "./logo.svg";

const AdminThemeProvider = memo(({ children }) => (
  <MuiThemeProvider theme={adminTheme}>{children}</MuiThemeProvider>
));

const AdminLayoutComponent = memo(({ children }) => {
  const {
    root,
    content,
    logo,
    withClosedMenu,
    logoText,
    invisible,
    contentWrapper,
  } = useStyles();
  const [open, setOpen] = useState<boolean>(true);
  const toggleMenu = () => setOpen((prevOpen) => !prevOpen);

  return (
    <div
      className={classNames(root, {
        [withClosedMenu]: !open,
      })}
    >
      <AdminHeader />
      <AdminMenu open={open} toggleMenu={toggleMenu} />

      <div className={logo}>
        <img src={logoPath} alt="CYPEX" />
        <img
          src={logoTextPath}
          alt="CYPEX"
          className={classNames(logoText, {
            [invisible]: !open,
          })}
        />
      </div>
      <main className={content}>
        <div className={contentWrapper}>{children}</div>
        <AdminFooter />
      </main>
    </div>
  );
});

export const AdminLayout = memo(({ children }) => (
  <AdminThemeProvider>
    <AdminLayoutComponent>{children}</AdminLayoutComponent>
  </AdminThemeProvider>
));
