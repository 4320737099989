import { ReactEchartsPropsTypes } from "echarts-for-react";

export type IOptionEchartProps = Record<string, any>;

export type EchartsProps = ReactEchartsPropsTypes & {
  showBackground?: boolean;
};

export const EChartsTranslationKeys = ["title"] as const;

export type EChartsTranslationKeys = typeof EChartsTranslationKeys[number];
