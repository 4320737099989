import { selectorScoper } from "core/utils/redux";
import { IState } from "./types";

export function buildSelectors(path: string[]) {
  const scopeSelector = selectorScoper<IState>(path);

  const loading = (state: any) => scopeSelector(state).loading;
  const data = (state: any) => scopeSelector(state).data;
  const error = (state: any) => scopeSelector(state).error;
  const bufferData = (state: any) => scopeSelector(state).bufferData;

  return {
    loading,
    data,
    error,
    bufferData,
  };
}
