import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import Button from "elementTypes/common/Button";
import { CodeEditor } from "elementTypes/common/CodeEditor";
import IconButton from "elementTypes/common/IconButton";
import { IUICreateForm } from "./types";
import useStyles from "./styles";
import { actions, selectors } from "./reduxModule";
import { Definition } from "core";
import BackButton from "elementTypes/common/BackButton";

const codeEditorOptions = {
  mode: {
    name: "javascript",
    json: true,
    statementIndent: 2,
  },
  lineNumbers: true,
  lineWrapping: true,
  indentWithTabs: false,
  tabSize: 2,
};
const INITIAL_DEFINITION: Definition = {
  i18n: {
    en: {
      label: "Definition label",
    },
  },
  menu: [],
  pages: {},
  layout: {
    name: "default_layout",
    definition: {},
  },
  parserVersion: "11",
};

export const CreateAppsPage = memo(() => {
  const dispatch = useDispatch();
  const rolesList = useSelector(selectors.rolesList);
  const { root, footer, dividerFooter, codeEditor } = useStyles();
  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, isSubmitted, isValid },
    setValue,
    watch,
  } = useForm<IUICreateForm>({
    mode: "onChange",
    defaultValues: {
      role: "",
      name: "",
      definition: JSON.stringify(INITIAL_DEFINITION, null, 2),
    },
  });
  const definitionValue = watch("definition");

  const submit = (data: IUICreateForm) => {
    data.definition = JSON.parse(data.definition as string);

    dispatch(actions.addUI(data));
  };
  const handleRefreshClick = () => dispatch(actions.getRoles());

  const handleCodeChange = ([, , newValue]: [any, any, string]) => {
    setValue("definition", newValue);
    return newValue;
  };
  const handleDefinitionValidation = (val: any) => {
    try {
      return !!JSON.parse(val);
    } catch (_error) {
      return false;
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <Grid item={true} xs={12}>
            <TextField
              name="name"
              label="Application name"
              fullWidth={true}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              name="description"
              label="Description"
              fullWidth={true}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Controller
              as={
                <TextField
                  select
                  label="Owner"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        size="small"
                        icon="refresh"
                        tooltip="Refresh"
                        onClick={handleRefreshClick}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {rolesList.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="role"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true}>
              <FormLabel component="p">Definition</FormLabel>
              <Controller
                as={
                  <CodeEditor
                    className={codeEditor}
                    value={definitionValue as string}
                    language="json"
                    onAutoComplete="autocomplete"
                    onChange={() => ({})}
                    options={codeEditorOptions}
                  />
                }
                control={control}
                rules={{
                  required: true,
                  validate: (val: any) => handleDefinitionValidation(val),
                }}
                name="definition"
                onChange={handleCodeChange as any}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              disabled={isSubmitting || isSubmitted || !isValid}
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Create"
            />
          </Grid>
        </Grid>
      </Paper>
      <BackButton />
    </form>
  );
});
