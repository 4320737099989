import React, { lazy, memo, useMemo, useState } from "react";
import { FixedSizeList, ListChildComponentProps, areEqual } from "react-window";
import omit from "lodash/omit";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";

import { IObjectViewField, Language, Translation } from "core";
import { Section } from "core/editor";
import { getTranslatedText } from "core/utils/element";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { FieldConfig, SubFormTableConfig } from "../../types";
import { FieldItem } from "./FieldItem";
import IconButton from "elementTypes/common/IconButton";
import { moveItemInList } from "utils/list";

type Props = {
  language: Language;
  fields: SubFormTableConfig["fields"];
  changeConfigValue: (key: keyof SubFormTableConfig, value: any) => void;
  viewFields?: IObjectViewField[];
};

export type IField = FieldConfig & { isNew?: true };

const DialogContent = withLazyLoading(lazy(() => import("./DialogContent")));

const Row = memo<ListChildComponentProps>(({ data, index, style }) => {
  const { fields, setFieldDetails, onItemDelete, translateTitle } = data;
  const item = fields[index];

  return (
    <Draggable draggableId={item.name} index={index} key={item.name}>
      {(provided, snapshot) => (
        <FieldItem
          {...item}
          snapshot={snapshot}
          provided={provided}
          style={style}
          onClick={setFieldDetails}
          onDelete={onItemDelete}
          translateTitle={translateTitle}
        />
      )}
    </Draggable>
  );
}, areEqual);

export const FieldsComponent = memo<Props>(
  ({ language, fields, changeConfigValue, viewFields }) => {
    const [fieldDetails, setFieldDetails] = useState<IField | null>(null);
    const [lang, setLang] = useState<Language>(language);

    const handleClose = () => setFieldDetails(null);

    const translateTitle = (i18n: Translation<"title">) =>
      getTranslatedText(language, i18n, "title");

    const onItemDelete = (deletedName: string) =>
      changeConfigValue(
        "fields",
        fields.filter((f) => f.name !== deletedName),
      );

    const handleSubmit = (data: { [k: string]: any }) => {
      const nextField = {
        ...omit(fieldDetails, "isNew"),
        i18n: {
          ...fieldDetails?.i18n,
          [lang.code]: {
            title: data.title.trim(),
          },
        },
        nullable: !data.required,
      };
      if (fieldDetails?.isNew) {
        changeConfigValue("fields", [...fields, nextField]);
      } else {
        changeConfigValue("fields", [
          ...fields.map((field) =>
            field.name === nextField.name ? nextField : field,
          ),
        ]);
      }
      handleClose();
    };

    const onDragEnd = ({ destination, source }: DropResult) => {
      if (!destination || source.index === destination.index) {
        return;
      }

      const fieldsReordered = moveItemInList(
        fields,
        source.index,
        destination.index,
      );

      changeConfigValue("fields", fieldsReordered);
    };

    const availableFields = viewFields?.filter(
      (vField) => !fields.some((f) => f.name === vField.name),
    );

    const itemSize = 48;

    const defaultRequired = useMemo(
      () =>
        fieldDetails &&
        viewFields?.find((f) => f.name === fieldDetails?.name)?.nullable ===
          false,
      [fieldDetails, viewFields],
    );

    const addField = () =>
      viewFields?.length &&
      setFieldDetails({
        isNew: true,
        ...viewFields[0],
      });

    return (
      <>
        <Section
          title={"Fields"}
          wrapped={false}
          headerAction={
            <IconButton
              icon="add"
              data-testid="editor-default_sub_form_table-column-add"
              onClick={addField}
              disabled={!availableFields?.length}
            />
          }
        >
          {fields.length ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                mode="virtual"
                renderClone={(provided, snapshot, { source: { index } }) => {
                  const item = fields[index];

                  return (
                    <FieldItem
                      {...item}
                      snapshot={snapshot}
                      provided={provided}
                      onClick={setFieldDetails}
                      onDelete={onItemDelete}
                      translateTitle={translateTitle}
                    />
                  );
                }}
              >
                {(droppableProvided: DroppableProvided) => (
                  <FixedSizeList
                    height={itemSize * Math.min(fields.length, 5)}
                    itemCount={fields.length}
                    itemSize={itemSize}
                    width="100%"
                    outerRef={droppableProvided.innerRef}
                    itemData={{
                      fields,
                      setFieldDetails,
                      onItemDelete,
                      translateTitle,
                    }}
                  >
                    {Row}
                  </FixedSizeList>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </Section>
        <DialogWrapper
          open={Boolean(fieldDetails)}
          isForm
          title={"Edit Field"}
          handleSubmit={handleSubmit}
          submitTitle="Save"
          cancelTitle="Cancel"
          handleClose={handleClose}
        >
          {fieldDetails && (
            <DialogContent
              {...fieldDetails}
              setFieldDetails={setFieldDetails}
              defaultRequired={Boolean(defaultRequired)}
              availableFields={availableFields}
              lang={lang}
              setLang={setLang}
            />
          )}
        </DialogWrapper>
      </>
    );
  },
);
