import { reduxModuleFactory } from "./reduxModule";
import Component from "./container";
import { IRawElementType } from "core/types/element";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_multiple_sub_form",
  component: Component,
  editable: true,
};

export default elementType;
