import { connect } from "react-redux";

import { FilesPageComponent as FPComponent } from "./component";
import { actions, selectors } from "./reduxModule";

export const mapStateToProps = (state: any) => ({
  fileList: selectors.fileList(state),
  loading: selectors.loading(state),
  error: selectors.error(state),
});

export const mapDispatchToProps = {
  ...actions,
};

export const FilesPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FPComponent);
