import React, { memo } from "react";

import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { useColorSelectorTranslation } from "./translation";
import ColorSelect, { IColor, colors as defaultColors } from "../ColorSelect";

type Props = {
  config: Record<string, any>;
  onChange: (value: string) => void;
  colors?: IColor[];
};

export const ColorSelector = memo<Props>(({ config, onChange, colors }) => {
  const { textColorLabel } = useColorSelectorTranslation();

  const customColorSelect = ({
    value: textValue,
    onChange: onColorChange,
  }: NonExpressionEditorProps) => (
    <ColorSelect
      value={textValue}
      onChange={onColorChange}
      defaultValue="default"
      colorItems={colors ?? defaultColors}
    />
  );

  return (
    <CustomExpressionEditor
      label={textColorLabel}
      value={config.color}
      config={config}
      onChange={onChange}
      nonExpressionEditor={customColorSelect}
    />
  );
});
