import { IStaticRouteConfig } from "core/router/types";

import * as reduxModule from "./reduxModule";
import { LoginPage } from "./component";

export const route: IStaticRouteConfig = {
  auth: false,
  reduxModule,
  Component: LoginPage,
};
