import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  xAxis: { width: "100%" },
  yAxis: { height: "100%" },
  stretch: {
    width: "100%",
    height: "100%",
  },
  contain: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  fullScreenImage: {
    height: `calc(100% - ${theme.spacing(6)}px)`,
    width: "100%",
    objectFit: "contain",
  },
  root: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  skeletonWrapper: {
    padding: theme.spacing(),
  },
}));
