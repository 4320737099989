import { createContext, useContext } from "react";
import { DEFAULT_LANGUAGE, Language } from "../types";

interface ISessionContext {
  language: Language;
}

export const SessionContext = createContext<ISessionContext>({
  language: DEFAULT_LANGUAGE,
});

export function useSessionContext() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error(
      "useSessionContext must be used within a SessionContext.Provider",
    );
  }
  return context;
}
