import React, { memo, useCallback, useEffect, useState } from "react";

import Paper from "@material-ui/core/Paper";

import classNames from "classnames";

import CookieConsent from "react-cookie-consent";

import { LayoutProps } from "core";
import { useTranslation } from "core/session";
import { EditorPanel, useEditorContext } from "core/editor";
import { AppBar } from "../common/AppBar";
import { Footer } from "../common/Footer";
import { Menu, MenuProps } from "../common/Menu";
import { mapDispatchToProps, mapStateToProps } from "./container";

import useStyles from "./styles";
import { LOGIN_URL } from "../../core/router/reduxModule/constants";

type DefaultLayoutProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  LayoutProps;

const DEFAULT_MENU_WIDTH = "240px";
const DEFAULT_TOOLBAR_VARIANT = "dense";

const COOKIE_CONSENT_MESSAGE =
  "I consent that this application uses cookies and similar technologies to save e.g. application-critical data.";

export const DefaultLayout = memo<DefaultLayoutProps>(
  ({
    appMetadata: {
      release: {
        definition: { layout, menu },
      },
    },
    children,
    ui,
    location,
    isAdminPage,
    isStaticPage,
    toggleEditMode,
    push,
  }) => {
    const menuWidth = layout.definition.menu?.width ?? DEFAULT_MENU_WIDTH;
    const toolbarVariant =
      layout.definition.toolbar?.variant ?? DEFAULT_TOOLBAR_VARIANT;
    const menuClosed = Boolean(layout.definition.menu?.defaultClosed);

    const classes = useStyles({ menuWidth, toolbarVariant });
    const [open, setOpen] = useState(!menuClosed);

    const menuHidden = Boolean(layout.definition.menu?.hidden);
    const headerButtonsColor =
      layout.definition.headerButtons?.color ?? "inherit";
    const openState = !menuHidden ? open : false;
    const menuProps: MenuProps = {
      openState,
      logo: layout.definition.logo,
      uiName: ui?.name ?? "",
      menu,
      classes,
      menuItemSelected: location.pathname,
    };

    const toggleOpen = useCallback(
      () => setOpen((prevOpen: boolean) => !prevOpen),
      [setOpen],
    );
    const handleLoginClick = useCallback(() => push(LOGIN_URL), [push]);

    const { editModeOn } = useEditorContext();
    const dontShowMenu = isAdminPage || editModeOn;

    useEffect(() => {
      if (dontShowMenu && openState) {
        toggleOpen();
      }
    }, [dontShowMenu, openState, toggleOpen]);

    useEffect(() => {
      if (isStaticPage && editModeOn) {
        toggleEditMode();
      }
    }, [isStaticPage, editModeOn, toggleEditMode]);

    const i18n = ui?.i18n ?? {
      en: {
        label: "Cypex",
      },
    };

    const label = useTranslation(i18n).label;

    const pageContent = (
      <Paper
        className={classNames(classes.content, classes["content-left"], {
          [classes.contentShift]: openState,
          [classes["contentShift-left"]]: openState,
          [classes.editMode]: editModeOn,
        })}
        square={true}
        elevation={0}
        component="main"
      >
        {editModeOn ? <EditorPanel>{children}</EditorPanel> : children}
      </Paper>
    );

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            label={label}
            handleDrawerChange={toggleOpen}
            open={openState}
            hidden={menuHidden || dontShowMenu}
            menuWidth={menuWidth}
            toolbarVariant={toolbarVariant}
            headerButtonsColor={headerButtonsColor}
            handleLoginClick={handleLoginClick}
          />

          {!menuHidden && !dontShowMenu && <Menu {...menuProps} />}

          {pageContent}

          {Boolean(layout.definition.cookieBanner?.active) && (
            <CookieConsent
              location="bottom"
              cookieName="cookieBannerAccepted"
              disableStyles={true}
              sameSite="lax"
              containerClasses={classes.cookieConsentContainer}
              buttonClasses={classes.cookieConsentButton}
              buttonId="cookie-accept-button"
              buttonText={layout.definition.cookieBanner?.buttonText}
            >
              {layout.definition.cookieBanner?.message ??
                COOKIE_CONSENT_MESSAGE}
            </CookieConsent>
          )}

          <Footer openState={openState} layout={layout} />
        </div>
      </div>
    );
  },
);

DefaultLayout.displayName = "DefaultLayout";
