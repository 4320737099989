import React, { ChangeEvent, memo, useCallback } from "react";
import TextField from "@material-ui/core/TextField";

import { Section, useElementEditorContext } from "core/editor";
import { useFileInputEditorTranslation } from "../translation";
import { FileInputConfig } from "../../types";
import { useStyles } from "../styles";

enum Fields {
  accessGroupName = "accessGroupName",
  typeGroupName = "typeGroupName",
  accept = "accept",
}

export const AdvancedComponent = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<FileInputConfig>();
  const translation = useFileInputEditorTranslation();
  const classes = useStyles();

  const handleChange = useCallback(
    (prop, newValue) => changeConfigValue(prop, newValue),
    [changeConfigValue],
  );

  const handleInputChange = (
    numberInputName: "accessGroupName" | "typeGroupName" | "accept",
  ) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    handleChange(numberInputName, value);

  const items = Object.values(Fields).map((field) => (
    <TextField
      key={field}
      className={
        field !== Fields.accessGroupName ? classes.topMargin : undefined
      }
      value={config[field] ?? ""}
      name={field}
      label={translation[`${field}InputLabel`]}
      fullWidth={true}
      onChange={handleInputChange(field)}
    />
  ));

  return (
    <Section title={translation.advancedTitle} wrapped={true}>
      {items}
    </Section>
  );
});
