import React from "react";
import { Controller } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";

import { useEditorTranslation, useElementEditorContext } from "core/editor";
import { useElementTypesContext } from "core/ElementTypesContext";
import { TElementModelWithPosition } from "core/types/element";
import { allowedCharactersPattern } from "utils/string";
import { useHookFormContext } from "elementTypes/common/HookForm";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import Button from "elementTypes/common/Button";
import { useDefaultTabsEditorTranslation } from "../translation";
import { TabConfigProps } from "./TabConfig";
import { useTabsContentContext } from "./TabsContext";
import { UntransformedTabsConfig } from "../../types";

type Props = TabConfigProps;

export const TabDialog = React.memo(() => {
  const { createTabTitle, editTabTitle } = useDefaultTabsEditorTranslation();
  const {
    cancelButton,
    createButton,
    deleteButton,
    updateButton,
  } = useEditorTranslation();
  const {
    tabDetails,
    handleDialogClose,
    deleteTab,
    createElement,
    page,
  } = useTabsContentContext();
  const {
    elementModel,
    elementModel: {
      config: { labels },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTabsConfig>();
  const { elementTypes } = useElementTypesContext();

  const onSubmit = (data: { [k: string]: any }) => {
    if (tabDetails?.isNew) {
      createElement(
        elementTypes,
        elementTypes.default_grid,
        page!,
        {
          row: 1,
          column: 1,
          width: 12,
          height: (elementModel as TElementModelWithPosition).position.height,
        },
        elementModel,
        undefined,
        `${data.name}`,
      );

      changeConfigValue("labels", [...labels, data.name]);
    } else {
      const newLabels = [...labels];

      if (tabDetails) {
        newLabels[tabDetails.index] = data.name;
      }

      changeConfigValue("labels", newLabels);
    }

    if (data.default && tabDetails) {
      changeConfigValue("default", tabDetails.index);
    }

    handleDialogClose();
  };

  const handleDeleteClick = () => {
    if (tabDetails) {
      deleteTab(tabDetails?.index);
      handleDialogClose();
    }
  };

  return (
    <DialogWrapper
      isForm={true}
      keepMounted={false}
      open={Boolean(tabDetails)}
      title={tabDetails?.isNew ? createTabTitle : editTabTitle}
      submitTitle={tabDetails?.isNew ? createButton : updateButton}
      cancelTitle={cancelButton}
      handleClose={handleDialogClose}
      handleSubmit={onSubmit}
      submitDisabled={true}
      subActions={
        !tabDetails?.isNew && (
          <Button
            label={deleteButton}
            color="error.main"
            onClick={handleDeleteClick}
          />
        )
      }
    >
      {tabDetails && <TabDialogContent {...tabDetails} />}
    </DialogWrapper>
  );
});

const TabDialogContent = React.memo<Props>(({ name, defaultTab }) => {
  const {
    invalidError,
    notMatchError,
    requiredError,
    nameLabel,
    defaultTabLabel,
  } = useDefaultTabsEditorTranslation();
  const { control, errors } = useHookFormContext();

  const error = (errorName?: { type: string }) => {
    switch (errorName?.type) {
      case "validate":
        return invalidError;
      case "pattern":
        return notMatchError;
      case "required":
        return requiredError;
      default:
        return undefined;
    }
  };

  return (
    <>
      <FormControl fullWidth={true}>
        <Controller
          as={
            <TextField
              margin="dense"
              label={nameLabel}
              helperText={error(errors.name as any)}
            />
          }
          error={Boolean(errors.name)}
          name="name"
          control={control}
          defaultValue={name}
          rules={{
            required: true,
            validate: (val: any) => !!val.toString().trim().length,
            pattern: allowedCharactersPattern,
          }}
        />
      </FormControl>
      <FormControl fullWidth={true}>
        <Controller
          as={
            <FormControlLabel control={<Checkbox />} label={defaultTabLabel} />
          }
          name="default"
          control={control}
          defaultValue={defaultTab}
        />
      </FormControl>
    </>
  );
});
