import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IUIGenerateForm } from "../types";
import { IRole } from "./types";
import { IObjectView } from "core";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  GENERATE_UI_ERROR: scopeActionType("GENERATE_UI_ERROR"),
  GENERATE_UI: scopeActionType("GENERATE_UI"),
  GET_ROLES_SUCCESS: scopeActionType("GET_ROLES_SUCCESS"),
  GET_ROLES_ERROR: scopeActionType("GET_ROLES_ERROR"),
  GET_ROLES: scopeActionType("GET_ROLES"),
  GET_OBJECT_VIEWS: scopeActionType("GET_OBJECT_VIEWS"),
  GET_OBJECT_VIEWS_SUCCESS: scopeActionType("GET_OBJECT_VIEWS_SUCCESS"),
  GET_OBJECT_VIEWS_ERROR: scopeActionType("GET_OBJECT_VIEWS_ERROR"),
};

export const actions = {
  generateUIError: createAction(types.GENERATE_UI_ERROR, (error: any) => ({
    error,
  })),
  generateUI: createAction(types.GENERATE_UI, (ui: IUIGenerateForm) => ({
    ui,
  })),
  getRolesSuccess: createAction(
    types.GET_ROLES_SUCCESS,
    (rolesList: IRole[]) => ({
      rolesList,
    }),
  ),
  getRolesError: createAction(types.GET_ROLES_ERROR, (error: any) => ({
    error,
  })),
  getRoles: createAction(types.GET_ROLES, () => ({})),
  getObjectViewsSuccess: createAction(
    types.GET_OBJECT_VIEWS_SUCCESS,
    (objectViewList: IObjectView[]) => ({
      objectViewList,
    }),
  ),
  getObjectViewsError: createAction(
    types.GET_OBJECT_VIEWS_ERROR,
    (error: any) => ({
      error,
    }),
  ),
  getObjectViews: createAction(types.GET_OBJECT_VIEWS, (role?: string) => ({
    role,
  })),
};
