import React, { memo } from "react";

import Chip, { ChipProps } from "@material-ui/core/Chip";
import { useStyles } from "./style";

export type ITag = ChipProps;

export const Tag = memo<Partial<ITag>>(({ label, color, variant, ...rest }) => {
  const { chip } = useStyles();
  return (
    <Chip
      className={chip}
      label={label}
      color={color}
      variant={variant}
      {...rest}
    />
  );
});
