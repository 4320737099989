import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
) {
  const value = (state: any) =>
    dataSource.createFieldValueSelector(fieldPath, {
      defaultValue: "",
    })(state);

  return {
    value,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
