import React, { memo } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { useForm } from "react-hook-form";

import { useLoginFormTranslation } from "./translation";
import { IUserTypeForm, IUserTypeFormData } from "./types";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: "10px",
    },
    item: {
      marginBottom: "10px",
    },
    buttonContainer: {
      display: "flex",
    },
  }),
);

const LoginForm = memo<IUserTypeForm>(({ onSubmit }) => {
  const { root, item, buttonContainer } = useStyles();
  const { register, handleSubmit, errors } = useForm<IUserTypeFormData>();
  const { buttonLogin, requiredInput } = useLoginFormTranslation();

  const handleFormSubmit = (data: any, e?: React.BaseSyntheticEvent<any>) => {
    e?.preventDefault();

    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container={true} spacing={2} className={root}>
        <Grid item={true} xs={12} className={item}>
          <TextField
            name="identifier"
            label="Username / Email"
            required={true}
            fullWidth={true}
            inputRef={register({
              required: requiredInput,
            })}
          />
          {errors.identifier && (
            <Typography variant="caption" color="error">
              {errors.identifier.message}
            </Typography>
          )}
        </Grid>
        <Grid item={true} xs={12} className={item}>
          <TextField
            name="password"
            label="Password"
            type="password"
            required={true}
            fullWidth={true}
            inputRef={register({
              required: true,
            })}
          />
          {errors.password && (
            <Typography variant="caption" color="error">
              {errors.password.message}
            </Typography>
          )}
        </Grid>
        <Grid
          container={true}
          item={true}
          xs={12}
          justify="flex-end"
          className={buttonContainer}
        >
          <Button type="submit" variant="outlined" color="primary">
            {buttonLogin}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});

export default LoginForm;
