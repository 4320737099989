import Component from "./container";
import {
  InternalLinkButtonEditor,
  defaultElement,
  editorMetadata,
} from "./editor";
import { IRawElementType } from "core";
import {
  InternalLinkButtonConfig,
  InternalLinkButtonTranslationKeys,
} from "./types";

export const elementType: IRawElementType = {
  name: "default_internal_link_button",
  editorMetadata,
  defaultElement,
  component: Component,
  configType: InternalLinkButtonConfig,
  editorComponent: InternalLinkButtonEditor,
  translationKeys: InternalLinkButtonTranslationKeys,
};

export default elementType;
