import { handleActions } from "core/utils/redux";
import { IState } from "./types";
import { types } from "./actions";

const INITIAL_STATE: IState = {
  error: null,
  loading: false,
  fileTypes: [],
  fileGroups: [],
};

export const reducer = handleActions<IState>(INITIAL_STATE, {
  [types.UPLOAD_FILE]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [types.UPLOAD_FILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    fileList: action.payload.fileList,
  }),
  [types.UPLOAD_FILE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [types.GET_FILE_TYPES]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [types.GET_FILE_TYPES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    fileTypes: action.payload.fileTypes,
  }),
  [types.GET_FILE_TYPES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [types.GET_FILE_GROUPS]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [types.GET_FILE_GROUPS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    fileGroups: action.payload.fileGroups,
  }),
  [types.GET_FILE_GROUPS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
});
