import { lazy } from "react";

import { IRawElementType } from "core";
import { withLazyLoading } from "../helpers/HOC/LazyLoading";

import { reduxModuleFactory } from "./reduxModule";
import {
  AdminStateChangeGraphConfig,
  adminStateChangeGraphSelectorTypes,
} from "./types";

export const Component = withLazyLoading(
  lazy(() => import("./container")),
  true,
);

export const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "admin_state_change_graph",
  component: Component,
  configType: AdminStateChangeGraphConfig,
  selectorTypes: adminStateChangeGraphSelectorTypes,
};

export default elementType;
