import { IReduxModuleFactoryArgs } from "core";
import { getElementDisplay } from "core/utils/element";
import { SubFormTable } from "../types";
import { controlledReduxModuleFactory } from "./controlledReduxModule";

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<SubFormTable>) {
  if (!element.config.dataSource) {
    throw new Error(
      `Uncontrolled mode not supported for default_sub_form_table. In element ${getElementDisplay(
        element,
      )}`,
    );
  }
  return controlledReduxModuleFactory(path, element);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
