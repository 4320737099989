import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  topMargin: {
    marginTop: theme.spacing(),
  },
  controlClass: {
    flexDirection: "row",
    marginBottom: theme.spacing(),

    "& [class*='MuiFormLabel-root']": {
      marginRight: theme.spacing(),
    },
  },
}));
