import { IStaticRouteConfig } from "core/router/types";

import { CreateAppsPage } from "./component";

import * as reduxModule from "./reduxModule";

export const route: IStaticRouteConfig = {
  reduxModule,
  auth: true,
  isAdmin: true,
  Component: CreateAppsPage,
};
