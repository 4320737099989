import * as t from "io-ts";
import { IElement } from "core/types";
import { types } from "core/runtime-typing";

export const AdminStateChangeGraphConfig = t.intersection([
  t.type({
    objectId: t.string,
    dataSource: t.type({
      stateChanges: t.type({
        viewName: t.string,
        identifier: t.string,
      }),
      states: t.type({
        viewName: t.string,
        identifier: t.string,
      }),
      users: t.type({
        viewName: t.string,
        identifier: t.string,
      }),
    }),
  }),
  t.partial({
    readonly: t.boolean,
    defaultExpanded: t.boolean,
  }),
]);

export type AdminStateChangeGraphConfig = t.TypeOf<
  typeof AdminStateChangeGraphConfig
>;

export const adminStateChangeGraphSelectorTypes = {
  loading: types.boolean(),
  data: types.any(),
  error: types.nullable(types.string()),
  bufferData: types.nullable(types.anyRecord()),
};

export type AdminStateChangeGraph = IElement<AdminStateChangeGraphConfig>;
