import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-block",
    },
    btnClass: {
      cursor: "default",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "& > [class*='MuiTouchRipple-root']": {
        display: "none",
      },
    },
    itemClass: {
      "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
);
