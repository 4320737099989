import React, { ChangeEvent, KeyboardEvent, memo, useCallback } from "react";

import { IObjectViewField } from "core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import { DatePicker, DateTimePicker, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { getDateValue } from "utils/date";
import IconButton from "../IconButton";
import { JsonView } from "../JsonView";

type Props = {
  generalType: IObjectViewField["generalType"];
  value: any;
  name: string;
  onChange: any;
  error?: string;
  checked?: boolean;
  clearable?: boolean;
  disabled?: boolean;
};

export const CustomInput = memo<Props>(
  ({ generalType, clearable, ...rest }) => {
    const { value, onChange, error, ...inputProps } = rest;

    const onCustomChange = useCallback(
      (ev: ChangeEvent<HTMLInputElement>, nextValue: any) =>
        onChange({
          ...ev,
          target: { ...ev.target, name: rest.name, value: nextValue },
        }),
      [onChange, rest.name],
    );

    const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) =>
      e.target.value !== null && e.target.value !== ""
        ? onCustomChange(e, Number(e.target.value))
        : null;

    const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
      if (
        !String.fromCharCode(e.which ?? e.keyCode).match(/^[0-9]+$/) &&
        e.key !== "Enter"
      ) {
        e.preventDefault();
      }
    };

    const onBoolChange = (_: any, checked: boolean) => onChange(checked);

    const handleDateChange = (date: MaterialUiPickersDate | null) =>
      onChange(date ? date.toISOString() : null);

    const cleanValue = () => onChange("");

    const handleArrayChange = (_: any, newData: string[] | number[] | null) => {
      const nextArray =
        newData && generalType.type === "number"
          ? [...newData].map((val) => Number(val))
          : newData;
      onChange(nextArray);
    };

    const isDisabled = rest.disabled;

    if (generalType.isArray) {
      return (
        <Autocomplete
          renderInput={(params: any) => (
            <TextField
              {...params}
              error={Boolean(error)}
              helperText={error}
              type={generalType.type}
              {...(generalType.type === "number" && {
                onKeyPress,
              })}
            />
          )}
          disableClearable={clearable === false}
          onChange={handleArrayChange}
          value={value}
          multiple
          freeSolo
          options={[]}
          {...inputProps}
          size="small"
        />
      );
    } else {
      switch (generalType.type) {
        case "json":
          return (
            <JsonView
              {...rest}
              value={value}
              nullable={clearable}
              error={error}
              popup={true}
              {...(!isDisabled && {
                onValueChange: onChange,
              })}
            />
          );
        case "boolean":
          return (
            <>
              <Box
                display="flex"
                justifyContent="center"
                alignContent="center"
                mt={1}
              >
                <Checkbox {...rest} onChange={onBoolChange} />
              </Box>
              {Boolean(error) && (
                <Box clone textAlign="center">
                  <FormHelperText error>Required</FormHelperText>
                </Box>
              )}
            </>
          );
        case "number":
          return (
            <TextField
              type="number"
              {...inputProps}
              value={value}
              onChange={handleNumberChange}
              error={Boolean(error)}
              helperText={error}
              onKeyPress={onKeyPress}
              InputProps={
                clearable && !isDisabled
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            icon="close"
                            onClick={cleanValue}
                            edge="start"
                          />
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
            />
          );
        case "dateTime":
          return (
            <DateTimePicker
              {...(rest as any)}
              value={getDateValue(value)}
              onChange={handleDateChange}
              fullWidth={true}
              variant="dialog"
              error={Boolean(error)}
              helperText={error}
              clearable={clearable}
            />
          );
        case "date":
          return (
            <DatePicker
              {...(rest as any)}
              value={getDateValue(value)}
              onChange={handleDateChange}
              fullWidth={true}
              variant="dialog"
              error={Boolean(error)}
              helperText={error}
              clearable={clearable}
            />
          );
        case "time":
          return (
            <TimePicker
              {...(rest as any)}
              value={getDateValue(value)}
              onChange={handleDateChange}
              fullWidth={true}
              variant="dialog"
              error={Boolean(error)}
              helperText={error}
              clearable={clearable}
            />
          );
        default:
          return (
            <TextField
              {...inputProps}
              value={value}
              onChange={onChange}
              error={Boolean(error)}
              helperText={error}
            />
          );
      }
    }
  },
);
