import React, { lazy, memo, useCallback, useMemo, useState } from "react";

import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { Parser } from "json2csv";

import IconButton from "../../common/IconButton";
import { MuiIcon } from "../../common/MuiIcon";
import { withLazyLoading } from "../../helpers/HOC/LazyLoading";
import { TableToolsProvider } from "./TableToolsContext";
import { IFilterField, IFilterGroup } from "./types";
import { useTableTranslation } from "../translation";
import { openSaveFileDialog } from "utils/csv";

import { useStyles } from "./style";

interface IProps {
  filter: IFilterGroup;
  applyFilter: () => void;
  fields: IFilterField[];
  changeSearchValue: (value: string) => void;
  searchInputValue: string;
  data: any[] | null;
  filterDisabled?: boolean;
  loading?: boolean;
  simpleFilter?: string[];
  isPaper?: boolean;
}

const DefaultTableFilter = withLazyLoading(
  lazy(() => import("./DefaultTableFilter")),
  true,
);
const SimpleSearch = withLazyLoading(
  lazy(() => import("./SimpleSearch")),
  true,
);

const json2csvParser = new Parser();

export const getFilterLength = (filter: IFilterGroup | null) =>
  filter?.filters?.filter((f: any) => !f.hidden).length ?? 0;

const ToolsPanel = memo<IProps>(
  ({
    filter,
    fields,
    applyFilter,
    filterDisabled,
    simpleFilter,
    searchInputValue,
    changeSearchValue,
    loading,
    data,
  }) => {
    const [openDialogFilter, setOpenDialogFilter] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
      actionsTitleButton,
      filterTitle,
      exportTitle,
    } = useTableTranslation();

    const handleToggle = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        setAnchorEl(event.currentTarget),
      [],
    );

    const handleClose = useCallback(() => setAnchorEl(null), []);

    const handleToggleDialogFilter = useCallback(() => {
      handleClose();

      setOpenDialogFilter((prevOpen) => !prevOpen);
    }, [handleClose]);

    const handleExportData = useCallback(() => {
      if (data) {
        const csv = json2csvParser.parse(data);

        openSaveFileDialog(csv, "download.csv");
        handleClose();
      }
    }, [data, handleClose]);

    const filterLength = getFilterLength(filter);

    const { root, menuIcon, marginRight } = useStyles();

    const filterMenuItem = useMemo(
      () => (
        <MenuItem
          onClick={handleToggleDialogFilter}
          disabled={filterDisabled}
          selected={Boolean(filterLength)}
        >
          <MuiIcon icon="filter_list" fontSize="small" className={menuIcon} />
          <ListItemText
            primary={filterTitle}
            className={filterLength ? marginRight : ""}
          />
          {!!filterLength && (
            <Chip label={filterLength} color="secondary" size="small" />
          )}
        </MenuItem>
      ),
      [
        filterDisabled,
        filterLength,
        filterTitle,
        handleToggleDialogFilter,
        marginRight,
        menuIcon,
      ],
    );

    const exportMenuItem = useMemo(
      () => (
        <MenuItem disabled={!data?.length} onClick={handleExportData}>
          <MuiIcon
            icon="cloud_download"
            fontSize="small"
            className={menuIcon}
          />
          <ListItemText
            primary={exportTitle}
            className={filterLength ? marginRight : ""}
          />
        </MenuItem>
      ),
      [
        data,
        exportTitle,
        filterLength,
        handleExportData,
        marginRight,
        menuIcon,
      ],
    );

    return (
      <TableToolsProvider
        value={{
          applyFilter,
          fields,
          filter,
          openDialogFilter,
          handleToggleDialogFilter,
          searchInputValue,
          changeSearchValue,
          loading: Boolean(loading),
          filterDisabled: Boolean(filterDisabled),
          simpleFilter,
        }}
      >
        <div className={root}>
          {simpleFilter && <SimpleSearch />}
          <IconButton
            icon="more_horiz"
            aria-controls={anchorEl ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            tooltip={actionsTitleButton}
            placement="top"
            badgeProps={{
              color: "secondary",
              variant: "dot",
              invisible: !filterLength,
            }}
          />
          <Menu
            id="menu-list-grow"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            keepMounted={true}
          >
            {filterMenuItem}
            {exportMenuItem}
          </Menu>
        </div>
        {filter && <DefaultTableFilter />}
      </TableToolsProvider>
    );
  },
);

ToolsPanel.displayName = "ToolsPanel";

export default ToolsPanel;
