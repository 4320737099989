import React, { memo } from "react";

import Typography, { TypographyProps } from "@material-ui/core/Typography";

import { MarkdownFieldConfig } from "../types";

export type IProps = TypographyProps;

export const Paragraph = (align: MarkdownFieldConfig["align"] = "left") =>
  memo<IProps>(({ children }) => (
    <Typography align={align}>{children}</Typography>
  ));
