import React, { memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { formatDistanceToNow, formatRelative } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { ConnectedStaticReduxModuleActionsProps } from "core/types/react";
import { RouterReduxModule } from "core/router/types";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { AdminFilePage, IFile } from "./types";
import { Table, TableRow } from "../../common";
import useStyles from "../../styles";
import { actions } from "./reduxModule";
import { FILE_STORAGE_PREFIX } from "services/api/constants";

type Props = ConnectedStaticReduxModuleActionsProps<RouterReduxModule> &
  AdminFilePage;

type Row = IFile;

const Row = memo<Row>(
  ({
    id,
    realName,
    fileType,
    createdAt,
    typeGroup: { typeName },
    fileGroup: { name },
    fileName,
  }) => {
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const { inlineAndEndFlex } = useStyles();
    const dispatch = useDispatch();

    const handleCloseDialog = () => setOpenDeleteDialog(false);
    const handleOpenDialog = () => setOpenDeleteDialog(true);
    const handleDeleteFile = () => {
      dispatch(actions.delete(fileName));
      handleCloseDialog();
    };

    return (
      <>
        <TableRow rowId={id}>
          <Typography>{realName}</Typography>
          <Typography>{fileType}</Typography>
          <Tooltip title={formatRelative(new Date(createdAt), new Date())}>
            <Typography>
              {formatDistanceToNow(new Date(createdAt), {
                addSuffix: true,
              })}
            </Typography>
          </Tooltip>
          <Typography>{typeName}</Typography>
          <Typography>{name}</Typography>
          <Grid item={true} className={inlineAndEndFlex}>
            <Link href={`${FILE_STORAGE_PREFIX}${fileName}`} target="_blank">
              <IconButton icon="open_in_new" color="secondary" tooltip="Open" />
            </Link>
            <IconButton
              icon="delete"
              color="secondary"
              tooltip="Delete"
              onClick={handleOpenDialog}
            />
          </Grid>
        </TableRow>
        <DialogWrapper
          open={openDeleteDialog}
          title="Delete file"
          contentText={`Are you sure you want to delete the file ${realName}?`}
          submitTitle="Delete"
          handleSubmit={handleDeleteFile}
          cancelTitle="Cancel"
          handleClose={handleCloseDialog}
          fullWidth={true}
        />
      </>
    );
  },
);

export const FilesPageComponent = memo<Props>(
  ({ fileList, load, loading, error }) => {
    const { horizontallyCenter, inlineAndEndFlex } = useStyles();

    const titles = [
      "Name",
      "File Type",
      "Uploaded",
      "Type Group",
      "Access Group",
      "Actions",
    ];

    const rows = useMemo(
      () => fileList.map((file: IFile) => <Row key={file.id} {...file} />),
      [fileList],
    );

    return (
      <Grid container spacing={2} className={horizontallyCenter}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h5">Files</Typography>
        </Grid>
        <Grid item xs={12} sm={4} className={inlineAndEndFlex}>
          <Button
            color="primary"
            label="UPLOAD"
            iconLeft="cloud_upload"
            href="/admin/files/upload"
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            rows={rows}
            headers={titles.map((title) => ({
              name: title.toLowerCase(),
              title,
            }))}
            onDataReload={load}
            loading={loading}
            error={error}
          />
        </Grid>
      </Grid>
    );
  },
);
