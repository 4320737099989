import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { AdminEditUsersPage } from "./types";

import useStyles from "./styles";
import { Form } from "./components/Form";
import BackButton from "elementTypes/common/BackButton";

export const EditUsersPage = memo<AdminEditUsersPage>((props) => {
  const { headerPaper } = useStyles();

  const { user, rolesList } = props;

  return (
    <>
      <Paper variant="outlined" className={headerPaper}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Typography variant="h5">Editing {user?.email}</Typography>
          </Grid>
        </Grid>
      </Paper>
      {user && rolesList && <Form {...props} />}
      <BackButton />
    </>
  );
});
