import { createContext, useContext } from "react";

import { actions as editorActions } from "core/editor/reduxModule";
import { IPage } from "core/types/app";
import { TabConfigProps } from "./TabConfig";

export type TabsContentContext = {
  tabDetails: TabConfigProps | null;
  page: IPage | null;
  setTabDetails: React.Dispatch<React.SetStateAction<TabConfigProps | null>>;
  handleDialogClose: () => void;
  handleTabButtonClick: (
    type: "edit" | "delete",
    params: TabConfigProps | number,
  ) => void;
  deleteTab: (index: number) => void;
  updateChildren: (
    ...params: Parameters<typeof editorActions.updateElementChildren>
  ) => void;
  createElement: (
    ...params: Parameters<typeof editorActions.createElement>
  ) => void;
};

export const TabsContentContext = createContext<TabsContentContext>(
  {} as TabsContentContext,
);

export function useTabsContentContext() {
  const context = useContext(TabsContentContext);
  if (context === undefined) {
    throw new Error(
      "useTabsContext must be used within a TabsContentContext.Provider",
    );
  }
  return context;
}

export const TabsContentProvider = TabsContentContext.Provider;
