import { IDefaultElement } from "core";
import { UntransformedTableConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedTableConfig> = {
  config: {
    dataSource: {
      viewName: "",
    },
    filter: { fields: [] },
  },
  children: {
    header: {
      elements: [],
    },
    body: {
      elements: [],
    },
  },
};
