import * as t from "io-ts";

import { IElement, UntransformedConfig, customExpression } from "core/types";
import { types } from "core/runtime-typing";
import { SelectorTypes } from "core/types/element";

export const NumberFieldConfig = t.type({
  value: customExpression(t.union([t.number, t.null])),
  precision: t.union([t.number, t.null]),
  isPercentage: t.boolean,
  prefix: t.string,
  suffix: t.string,
  highlight: customExpression(t.boolean), // write as bold, e.g. value < 0
});

export type NumberFieldConfig = t.TypeOf<typeof NumberFieldConfig>;

export type UntransformedNumberFieldConfig = UntransformedConfig<
  NumberFieldConfig
>;

export const numberFieldSelectors: SelectorTypes<NumberFieldConfig> = {
  value: types.nullable(types.number(), "the current value"),
};

export type NumberField = IElement<NumberFieldConfig>;
