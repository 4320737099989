import React, { memo } from "react";
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps,
} from "@material-ui/lab";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { Theme, withStyles } from "@material-ui/core/styles";

type Props = {
  tooltip?: string;
  placement?: TooltipProps["placement"];
} & ToggleButtonProps;

export const StyledToggleButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    "&[class*='Mui-selected']": {
      color: theme.palette.primary.main,
    },
  },
}))(MuiToggleButton);

export const ToggleButton = memo<Props>(({ tooltip, placement, ...rest }) =>
  tooltip ? (
    <Tooltip title={tooltip} placement={placement}>
      <StyledToggleButton {...rest} />
    </Tooltip>
  ) : (
    <StyledToggleButton {...rest} />
  ),
);
