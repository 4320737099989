import React, { ComponentType, memo } from "react";
import { MapDispatchToProps, MapStateToProps, connect } from "react-redux";
import { ActionCreatorsMapObject, Dispatch, bindActionCreators } from "redux";

import {
  BoundActions,
  IElement,
  IElementComponentProps,
  IReduxModule,
  SelectorValues,
  SelectorsMap,
} from "../types";
import { ElementWithError } from "./element";

type InferExtraStateProps<T> = T extends MapStateToProps<infer R, any, any>
  ? R
  : {};

type InferExtraActionProps<T> = T extends MapDispatchToProps<infer R, any>
  ? R
  : {};

/**
 * This utility function automatically maps the actions and selectors of your
 * ReduxModule.
 *
 * Additionally it accepts extra MapStateToProps and MapDispatchToProps
 *
 *
 * @example
 * const mapDispatchToProps = {
 *   goBack: routerActions.goBack,
 * };
 *
 * const connector = connectElement<
 *   ReduxModule,
 *   Form,
 *   undefined,
 *   typeof mapDispatchToProps
 * >(undefined, mapDispatchToProps);
 *
 * export type Props = PropsFromConnector<typeof connector>;
 *
 * export default connector(Component);
 */
export function connectElement<
  M extends IReduxModule = IReduxModule,
  E extends IElement = IElement,
  ExtraMapStateToPropsValue extends
    | MapStateToProps<any, IElementComponentProps<M, E>, any>
    | undefined = undefined,
  ExtraMapDispatchToPropsValue extends
    | MapDispatchToProps<any, IElementComponentProps<M, E>>
    | undefined = undefined
>(
  extraMapStateToProps?: ExtraMapStateToPropsValue | null,
  extraMapDispatchToProps?: ExtraMapDispatchToPropsValue | null,
) {
  type OwnProps = IElementComponentProps<M, E>;
  type ExtraStateProps = InferExtraStateProps<ExtraMapStateToPropsValue>;
  type StateProps = SelectorValues<
    M["selectors"] extends SelectorsMap ? M["selectors"] : {}
  > &
    ExtraStateProps;

  type ExtraActionProps = InferExtraActionProps<ExtraMapDispatchToPropsValue>;

  type ActionProps = BoundActions<
    M["actions"] extends ActionCreatorsMapObject ? M["actions"] : {}
  > &
    ExtraActionProps;

  const mapStateToProps = (state: any, ownProps: OwnProps) => {
    const {
      module: { selectors = {} },
    } = ownProps;
    return Object.keys(selectors).reduce(
      (props, key) => ({ ...props, [key]: selectors[key](state) }),
      extraMapStateToProps ? extraMapStateToProps(state, ownProps) : {},
    ) as StateProps;
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) =>
    ({
      ...bindActionCreators(ownProps.module.actions || {}, dispatch),
      ...(typeof extraMapDispatchToProps === "function"
        ? extraMapDispatchToProps(dispatch, ownProps)
        : bindActionCreators((extraMapDispatchToProps as any) || {}, dispatch)),
    } as ActionProps);

  const connector = connect(
    connectErrorHandlerUtils.enhanceMapStateToProps(mapStateToProps),
    mapDispatchToProps,
  );

  const callback: typeof connector = (Component) =>
    connector(
      (connectErrorHandlerUtils.enhanceComponent(
        Component as any,
      ) as unknown) as typeof Component,
    );

  return callback;
}

export function buildMapStateToProps<S extends SelectorsMap>(selectors: S) {
  return (state: any) =>
    Object.keys(selectors).reduce(
      (props, key) => ({ ...props, [key]: selectors[key](state) }),
      {} as SelectorValues<S>,
    );
}

/**
 * TODO:
 * This two functions could be merged into one function that wraps react-redux connect(...), and subsecuentially
 * wraps mapStateToProps and the connected Component. The problem is the typing might get tricky for handling
 * ALL the overloads for connect(...).
 */

const ERROR = "@@react-redux/connectError";

export const connectErrorHandlerUtils = {
  /**
   * Enhancer for handling errors in mapStateToProps. The connected component should use the enhanceComponent HOC.
   */
  enhanceMapStateToProps<P, Args extends any[]>(
    mapStateToProps: (...a: Args) => P,
  ) {
    return function enhancedMapStateToProps(...args: Args) {
      try {
        return mapStateToProps(...args);
      } catch (error) {
        return ({ [ERROR]: error } as unknown) as P;
      }
    };
  },

  /**
   * Component enhancer to handle errors caught in connect with `enhanceMapStateToProps`.
   */
  enhanceComponent<P extends object>(Component: ComponentType<P>) {
    return memo<P>((props) =>
      props[ERROR] ? (
        <ElementWithError error={props[ERROR]} />
      ) : (
        <Component {...props} />
      ),
    );
  },
};

// TODO: why doesn't this work in typescript?
// export function connectReduxModule<M extends IReduxModule>(reduxModule: M) {
//   const { selectors = {}, actions = {} } = reduxModule;
//
//   type SelectorsProps = ConnectedStaticReduxModuleSelectorsProps<M>;
//   type ActionsProps = ConnectedStaticReduxModuleActionsProps<M>;
//   type Props = SelectorsProps & ActionsProps;
//
//   return <P extends Props>(Component: ComponentType<P>) => {
//     const mapStateToProps = (state: any) => Object.keys(reduxModule.selectors || {}).reduce(
//       (props, key) => ({ ...props, [key]: selectors[key](state) }),
//       {},
//     ) as SelectorsProps;
//
//     return connect<SelectorsProps, ActionsProps, Omit<P, keyof Props>, any>(
//       mapStateToProps,
//       actions as any,
//     )(Component);
//   }
// }
