import { createAction, createActionTypeScoper } from "core/utils/redux";
import { constants } from "./constants";
import { IUser } from "../../../reduxModule/types";

const scopeActionType = createActionTypeScoper(constants.MODULE_NAME);

export const types = {
  GET_USER: scopeActionType("GET_USER"),
  GET_USER_SUCCESS: scopeActionType("GET_USER_SUCCESS"),
  GET_USER_ERROR: scopeActionType("GET_USER_ERROR"),
};

export const actions = {
  getUser: createAction(types.GET_USER, (userId: string) => ({
    userId,
  })),
  getUserSuccess: createAction(types.GET_USER_SUCCESS, (user: IUser) => ({
    user,
  })),
  getUserError: createAction(types.GET_USER_ERROR, (error: string) => ({
    error,
  })),
};
