import React, { memo, useEffect } from "react";
import { Controller, EventFunction } from "react-hook-form";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import { useHookFormContext } from "../../../common/HookForm";
import { useFilterContext } from "../FilterContext";
import { useStyles } from "../style";

type Props = {
  name: string;
  path: number[];
  onChange: EventFunction;
};

export const Field = memo<Props>(({ onChange, path, name }) => {
  const { marginRight } = useStyles();
  const { control, setValue } = useHookFormContext();
  const { fields } = useFilterContext();

  /*
   * Since `react-hook-form` minimizes the number of re-renders
   * there is a need to trigger `setValue` when new `Rule` has been added or deleted
   */
  useEffect(() => {
    setValue(`[${path}].fieldName`, name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, path]);
  return (
    <Controller
      as={
        <TextField select={true} className={marginRight} margin="dense">
          {fields.map(({ name: fieldName, label }) => (
            <MenuItem key={fieldName} value={fieldName}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      }
      name={`[${path}].fieldName`}
      control={control}
      defaultValue={name}
      onChange={onChange}
    />
  );
});
