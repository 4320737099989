import React from "react";

import { Filter } from "elementTypes/common/Echarts/editor";
import {
  ModeComponent,
  SetupComponent,
  TranslationComponent,
} from "./components";

export const EchartsPieChartEditor = React.memo(() => {
  return (
    <>
      <SetupComponent />
      <ModeComponent />
      <TranslationComponent />
      <Filter />
    </>
  );
});
