import React, { memo } from "react";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";
import { LineChart } from "./types";

import { IOptionEchartProps } from "../common/Echarts";
import { IEchartsContainerMultiProps } from "../common/Echarts/EchartsContainer";
import EchartsContainerDataset from "../common/Echarts/EchartsContainerDataset";
import { LoadingComponent } from "layouts/common/Loading";
import { AlertBox } from "elementTypes/common/AlertBox";

type Props = ConnectedReduxModuleProps<ReduxModule, LineChart>;

const EchartsLineChart = memo<Props>(
  ({
    element: {
      config: {
        dataSource,
        hideLegend,
        hideLabel,
        hideXAxis,
        hideYAxis,
        isArea,
        showBackground,
      },
      i18n: { title },
    },
    error,
    data,
  }) => {
    const propsEchartsContainer = {
      title,
      type: "line",
      defaultOptions: {
        title: {
          x: "center",
        },
        legend: {
          show: !hideLegend,
        },
        xAxis: { show: !hideXAxis },
        yAxis: { show: !hideYAxis },
        series: [
          {
            avoidLabelOverlap: true,
            label: {
              show: !hideLabel,
            },
            ...(isArea && { areaStyle: {} }),
          },
        ],
      } as IOptionEchartProps,
      showBackground,
    } as IEchartsContainerMultiProps;

    if (!dataSource.viewName) {
      return (
        <AlertBox title="Config error" message={"View Name is required"} />
      );
    }

    if (error) {
      return <AlertBox title="Error" message={error} />;
    }

    if (!data) {
      return <LoadingComponent />;
    }

    return (
      <EchartsContainerDataset
        {...propsEchartsContainer}
        dataSource={dataSource}
        data={data}
      />
    );
  },
);

export default EchartsLineChart;
