import { createUseTranslation } from "core/session/translation";

const defaultTabsEditorTranslation = {
  en: {
    stylingTitle: "Styling",
    standardLabel: "Standard",
    scrollableLabel: "Scrollable",
    fullWidthLabel: "Full Width",
    editTabTooltip: "Edit Tab",
    deleteTabTooltip: "Delete Tab",
    addTabTooltip: "Add Tab",
    tabsTitle: "Tabs",
    createTabTitle: "Create Tab",
    editTabTitle: "Edit Tab",
    invalidError: "Invalid Value",
    notMatchError:
      "Name can only contain numbers, letters, hyphens and underscores",
    requiredError: "Value is Required",
    nameLabel: "Name",
    defaultTabLabel: "Default Tab",
  },
};

export const useDefaultTabsEditorTranslation = createUseTranslation(
  defaultTabsEditorTranslation,
);
