import React, { memo } from "react";

import { useStyles } from "./style";
import { Tag } from "./Tag";
import { PropsFromRedux } from "./container";

type Props = PropsFromRedux;

const DefaultArrayTextField = memo<Props>(
  ({
    values,
    element: {
      config: { color, size, variant },
    },
  }) => {
    const { root } = useStyles();
    const items = values?.map((v, i) => (
      <Tag key={i} label={v} color={color} size={size} variant={variant} />
    ));
    return <div className={root}>{items}</div>;
  },
);

export default DefaultArrayTextField;
