import { OptionalType } from "./OptionalType";
import { Type } from "./Type";
import { TypeError } from "./TypeError";

export class RecordType extends Type {
  public name = `Record<${this.keyType.name}, ${this.valueType.name}>`;

  constructor(
    public readonly keyType: Type,
    public readonly valueType: Type,
    description?: string,
  ) {
    super(description);
  }

  public validate(value: any): true | TypeError {
    if (typeof value !== "object") {
      return new TypeError(value, this, "TODO: detailed error message");
    }
    let result: true | TypeError;
    for (const key of Object.keys(value)) {
      result = this.keyType.validate(key);
      if (result !== true) {
        return new TypeError(
          value,
          this,
          `Invalid key "${key}": ${result.message}`,
        );
      }
      result = this.valueType.validate(value[key]);
      if (result !== true) {
        return new TypeError(
          value,
          this,
          `Invalid value "${value}" (for key "${key}"): ${result.message}`,
        );
      }
    }
    return true;
  }

  public getKeyType(key: string): Type | undefined {
    return super.getKeyType(key) || new OptionalType(this.valueType);
  }
}
