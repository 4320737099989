import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    header: {
      margin: 0,
      padding: theme.spacing(0.5, 1),
    },
    action: {
      margin: 0,
    },
    content: {
      paddingTop: 0,
    },
    specificGap: {
      "&:last-child": {
        margin: 0,
      },
    },
    card: {
      borderLeft: "unset",
      borderRight: "unset",
    },
  }),
);
