import React, { ReactNode, lazy, memo, useState } from "react";
import { Box, Card, Paper } from "@material-ui/core";
import clsx from "classnames";

import { RIGHT_ASIDE, useGridStyles, useStyles } from "../style";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";

import { EditorThemeProvider } from "../ThemeProvider/component";

const ElementsPanel = withLazyLoading(lazy(() => import("../ElementsPanel")));
const SetupPanel = withLazyLoading(lazy(() => import("../SetupPanel")));
const TopPanel = withLazyLoading(lazy(() => import("../TopPanel")));
const ActionsPanel = withLazyLoading(lazy(() => import("../ActionsPanel")));

type Props = {
  children: ReactNode;
};

const initialState = { left: true, right: true };
export type AsideState = typeof initialState;
export type Aside = "left" | "right";

export const Panel = memo<Props>(({ children }) => {
  const [aside, setAside] = useState<AsideState>(initialState);
  const {
    root,
    topBlock,
    asideLeft,
    asideRight,
    asideLeftHidden,
    asideRightHidden,
  } = useGridStyles(aside);
  const { contentSection } = useStyles();

  const togglePanels = (nextAside: Aside) =>
    setAside((prevAside) => ({
      ...prevAside,
      [nextAside]: !prevAside[nextAside],
    }));

  return (
    <main className={root}>
      <EditorThemeProvider>
        <Box
          className={topBlock}
          display="grid"
          gridTemplateColumns={`1fr ${RIGHT_ASIDE}`}
          gridGap="inherit"
        >
          <Card elevation={0} square>
            <TopPanel aside={aside} togglePanels={togglePanels} />
          </Card>
          <Card elevation={0} square>
            <ActionsPanel />
          </Card>
        </Box>
      </EditorThemeProvider>
      <div
        style={{
          height: "calc(100% - 48px)",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <EditorThemeProvider>
          <Card
            className={clsx(asideLeft, { [asideLeftHidden]: !aside.left })}
            elevation={0}
            square
            id="editor-left-panel"
          >
            <SetupPanel />
          </Card>
        </EditorThemeProvider>
        <Paper
          className={contentSection}
          elevation={0}
          square
          id="editor-content"
        >
          {children}
        </Paper>
        <EditorThemeProvider>
          <Card
            className={clsx(asideRight, { [asideRightHidden]: !aside.right })}
            elevation={0}
            square
            id="editor-right-panel"
          >
            <ElementsPanel />
          </Card>
        </EditorThemeProvider>
      </div>
    </main>
  );
});
