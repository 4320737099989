import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Section, useElementEditorContext, useParentForm } from "../..";
import { getTranslatedText } from "../../../utils/element";
import { selectors as editorSelectors } from "../../reduxModule";
import { selectors as routerSelectors } from "../../../router/reduxModule";
import { Language } from "../../../types";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";
import { useEditorTranslation } from "../../translation";
import { getPageElementsByType } from "../../utils";
import IconButton from "elementTypes/common/IconButton";
import { Form } from "elementTypes/default_form/types";

type DataSourceEditorComponentProps = {
  language: Language;
  showMultiReferenceFields?: boolean;
};

export const FormInputDataSourceEditorComponent = memo<
  DataSourceEditorComponentProps
>(({ language, showMultiReferenceFields }) => {
  const {
    elementModel: {
      config: { dataSource },
    },
    changeConfigValue,
  } = useElementEditorContext<WithOptionalFieldDataSourceConfig>();
  const editorTranslation = useEditorTranslation();
  const [searchFieldPathInputValue, setSearchFieldPathInputValue] = useState<
    string
  >("");
  const [searchElementIdInputValue, setSearchElementIdInputValue] = useState<
    string
  >("");

  const updatedElements = useSelector(editorSelectors.updatedElements);
  const page = useSelector(routerSelectors.page);

  const rootElement = page!.element;
  const { parentView, parentDataSource } = useParentForm();

  const parentMultiReferenceConfig =
    showMultiReferenceFields && parentDataSource?.multiReference;

  const options = useMemo(() => {
    let items;
    if (showMultiReferenceFields) {
      if (parentMultiReferenceConfig) {
        items = Object.keys(parentMultiReferenceConfig).map((referenceKey) => ({
          value: referenceKey,
          label: referenceKey,
        }));
      } else {
        items = undefined;
      }
    } else {
      items = parentView?.fields.map((viewField) => ({
        value: viewField.name,
        label: `${getTranslatedText(language, viewField.i18n, "title")} (${
          viewField.name
        })`,
      }));
    }
    return items;
  }, [
    parentView,
    language,
    parentMultiReferenceConfig,
    showMultiReferenceFields,
  ]);

  const dataSourceIdOptions = useMemo(() => {
    let forms = getPageElementsByType(
      rootElement,
      updatedElements,
      "default_form",
    );
    if (parentMultiReferenceConfig) {
      forms = forms.filter(
        (form) => (form as Form)?.config?.dataSource?.multiReference,
      );
    }
    return forms.map((element) => ({
      value: element.id,
      label: element.id,
    }));
  }, [rootElement, updatedElements, parentMultiReferenceConfig]);

  const changeDataSource = useCallback(
    (newDataSource: WithOptionalFieldDataSourceConfig["dataSource"]) =>
      changeConfigValue("dataSource", newDataSource),
    [changeConfigValue],
  );

  const handleFieldPathInputChange = (value: IAutocompleteValue) => {
    setSearchFieldPathInputValue("");
    changeDataSource({
      elementId: dataSource!.elementId,
      fieldPath: [value] as string[],
    });
  };

  const handleElementIdInputChange = (value: IAutocompleteValue) => {
    setSearchElementIdInputValue("");
    changeDataSource({
      elementId: value as string,
      fieldPath: dataSource!.fieldPath,
    });
  };

  const createDataSource = () => {
    const defaultDataSource: WithOptionalFieldDataSourceConfig["dataSource"] = {
      elementId: dataSourceIdOptions?.[0]?.value,
      fieldPath: [],
    };

    if (parentView) {
      const defaultOption = parentView.fields[0];

      defaultDataSource.fieldPath = [defaultOption.name];
    }

    changeDataSource(defaultDataSource);
  };

  const deleteDataSource = () => {
    changeDataSource(undefined);
  };

  // for now use view metadata
  // TODO: use data object
  const selectedFieldPathValue = useMemo(
    () =>
      options?.find(
        (o: SelectOption) =>
          o.value === (dataSource && dataSource.fieldPath[0]),
      ),
    [options, dataSource],
  );

  const selectedElementIdValue = useMemo(
    () =>
      dataSourceIdOptions?.find(
        (o: SelectOption) => o.value === (dataSource && dataSource.elementId),
      ),
    [dataSourceIdOptions, dataSource],
  );

  return (
    <Section
      title={editorTranslation.dataSourceTitle}
      wrapped={Boolean(dataSource)}
      defaultOpened={Boolean(dataSource)}
      headerAction={
        <IconButton
          icon={dataSource ? "delete_outline" : "add"}
          onClick={dataSource ? deleteDataSource : createDataSource}
          disabled={!dataSourceIdOptions?.length}
          tooltip={
            dataSource
              ? editorTranslation.removeDataSourceTooltip
              : editorTranslation.addDataSourceTooltip
          }
        />
      }
    >
      {dataSource && (
        <>
          <BaseAutocomplete
            options={dataSourceIdOptions ?? []}
            onChange={handleElementIdInputChange}
            valueObject={selectedElementIdValue ?? ""}
            name="elementId"
            label={editorTranslation.elementIdLabel}
            isLoading={false}
            searchInputValue={searchElementIdInputValue}
            onInputChange={setSearchElementIdInputValue}
            virtualizedList={true}
          />
          <BaseAutocomplete
            options={options ?? []}
            onChange={handleFieldPathInputChange}
            valueObject={selectedFieldPathValue ?? ""}
            name="fieldPath"
            label={editorTranslation.fieldPathLabel}
            isLoading={false}
            searchInputValue={searchFieldPathInputValue}
            onInputChange={setSearchFieldPathInputValue}
            virtualizedList={true}
          />
        </>
      )}
    </Section>
  );
});
