import * as t from "io-ts";

import { IElement, arrayChild } from "core/types";

export const GridConfig = t.partial({
  style: t.UnknownRecord,
});

export const GridChildren = t.type({
  content: arrayChild("*", { positioned: true }),
});

export type GridConfig = t.TypeOf<typeof GridConfig>;

export type GridChildren = t.TypeOf<typeof GridChildren>;

export type Grid = IElement<GridConfig, GridChildren>;
