import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    image: {
      height: "500px",
    },
    cardContent: {
      textAlign: "center",
    },
    logo: {
      maxWidth: "150px",
      marginBottom: theme.spacing(2),
    },
  }),
);

export default useStyles;
