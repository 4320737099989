import React, { memo } from "react";
import { useBeforeunload } from "react-beforeunload";

import { BoundActions } from "../../types";
import { EditorProvider } from "../EditorContext";
import { actions } from "../reduxModule";
import { mapStateToProps } from "./container";

type Props = ReturnType<typeof mapStateToProps> & BoundActions<typeof actions>;

export const EditorLayout = memo<Props>(
  ({
    editModeOn,
    changeConfigValue,
    changePositionValue,
    changeTranslationValue,
    setDraggableElement,
    children,
    unselectElement,
    updatedElements,
  }) => {
    useBeforeunload((event: Event) => {
      if (Object.keys(updatedElements).length) {
        return event.preventDefault();
      }
    });

    return (
      <EditorProvider
        value={{
          editModeOn,
          changeConfigValue,
          changePositionValue,
          changeTranslationValue,
          setDraggableElement,
          unselectElement,
        }}
      >
        {children}
      </EditorProvider>
    );
  },
);

EditorLayout.displayName = "EditorLayout";
