import { IStaticRouteConfig } from "core/router/types";

import { TablesAuditPage } from "./container";

import * as reduxModule from "./reduxModule";

export const route: IStaticRouteConfig = {
  reduxModule,
  auth: true,
  isAdmin: true,
  Component: TablesAuditPage,
};
