import { createAction, createActionTypeScoper } from "core/utils/redux";
import { IState } from "./types";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    LOAD_DATA: scopeActionType("LOAD_DATA"),
    LOAD_DATA_SUCCESS: scopeActionType("LOAD_DATA_SUCCESS"),
    LOAD_DATA_ERROR: scopeActionType("LOAD_DATA_ERROR"),
    CREATE_DATA: scopeActionType("CREATE_DATA"),
    CREATE_DATA_SUCCESS: scopeActionType("CREATE_DATA_SUCCESS"),
    CREATE_DATA_ERROR: scopeActionType("CREATE_DATA_ERROR"),
    EDIT_DATA: scopeActionType("EDIT_DATA"),
    EDIT_DATA_SUCCESS: scopeActionType("EDIT_DATA_SUCCESS"),
    EDIT_DATA_ERROR: scopeActionType("EDIT_DATA_ERROR"),
    DELETE_DATA: scopeActionType("DELETE_DATA"),
    DELETE_DATA_SUCCESS: scopeActionType("DELETE_DATA_SUCCESS"),
    DELETE_DATA_ERROR: scopeActionType("DELETE_DATA_ERROR"),
  };

  const actions = {
    loadData: createAction(types.LOAD_DATA),
    loadDataSuccess: createAction(
      types.LOAD_DATA_SUCCESS,
      (data: Record<string, unknown>) => ({
        data,
      }),
    ),
    loadDataError: createAction(
      types.LOAD_DATA_ERROR,
      (error: IState["error"]) => ({
        error,
      }),
    ),
    createData: createAction(
      types.CREATE_DATA,
      (bufferData: IState["bufferData"]) => ({
        bufferData,
      }),
    ),
    createDataSuccess: createAction(
      types.CREATE_DATA_SUCCESS,
      (data: IState["data"]) => ({
        data,
      }),
    ),
    createDataError: createAction(
      types.CREATE_DATA_ERROR,
      (error: IState["error"]) => ({
        error,
      }),
    ),
    editData: createAction(types.EDIT_DATA, (bufferData: any) => ({
      bufferData,
    })),
    editDataSuccess: createAction(
      types.EDIT_DATA_SUCCESS,
      (data: IState["data"]) => ({
        data,
      }),
    ),
    editDataError: createAction(
      types.EDIT_DATA_ERROR,
      (error: IState["error"]) => ({
        error,
      }),
    ),
    deleteData: createAction(
      types.DELETE_DATA,
      (bufferData: IState["data"]) => ({
        bufferData,
      }),
    ),
    deleteDataSuccess: createAction(
      types.DELETE_DATA_SUCCESS,
      (data: IState["data"]) => ({
        data,
      }),
    ),
    deleteDataError: createAction(
      types.DELETE_DATA_ERROR,
      (error: IState["error"]) => ({
        error,
      }),
    ),
  };
  return { types, actions };
}
