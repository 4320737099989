import { TypeError } from "./TypeError";
import { IAutocompleteKey } from "./types";
import * as FunctionTypeModule from "./FunctionType";
import * as StringTypeModule from "./StringType";

export abstract class Type {
  public abstract get name(): string;

  constructor(public description?: string) {}

  public getAutocompleteRecord(): Record<string, IAutocompleteKey> {
    // the `require` in here is needed to circumvent circular dependency problems
    // because the `FunctionType` and `StringType` extend this `Type` class
    const {
      FunctionType,
    } = require("./FunctionType") as typeof FunctionTypeModule;
    const { StringType } = require("./StringType") as typeof StringTypeModule;
    return {
      toString: {
        type: new FunctionType(
          new StringType("A string representation of the object"),
        ),
        isBuiltin: true,
      },
    };
  }

  public getKeyType(key: string): Type | undefined {
    return this.getAutocompleteRecord()[key]?.type;
  }

  public abstract validate(value: any): true | TypeError;
}
