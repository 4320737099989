import React, { memo } from "react";

import Checkbox from "@material-ui/core/Checkbox";

import { IElementComponentProps } from "core";
import { useStyles } from "./style";

interface IProps extends IElementComponentProps {
  value: boolean | null;
}

const DefaultBoolField = memo<IProps>(({ value }) => {
  const { root } = useStyles();
  return (
    <Checkbox
      disabled={true}
      indeterminate={value === null}
      checked={value ?? undefined}
      className={root}
    />
  );
});

export default DefaultBoolField;
