import { handleActions } from "core/utils/redux";
import { IState, Types } from "./types";

const INITIAL_STATE: IState = {
  loading: false,
  error: null,
  data: {},
  bufferData: null,
};

export function buildReducer(types: Types) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.LOAD_DATA]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [types.LOAD_DATA_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      data: {
        ...state.data,
        ...action.payload.data,
      },
    }),
    [types.LOAD_DATA_ERROR]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
    [types.CREATE_DATA]: (state, action) => ({
      ...state,
      loading: true,
      error: null,
      bufferData: action.payload.bufferData,
    }),
    [types.CREATE_DATA_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      bufferData: null,
      data: {
        ...state.data,
        ...action.payload.data,
      },
    }),
    [types.CREATE_DATA_ERROR]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
    [types.EDIT_DATA]: (state, action) => ({
      ...state,
      loading: true,
      error: null,
      bufferData: action.payload.bufferData,
    }),
    [types.EDIT_DATA_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      bufferData: null,
      data: {
        ...state.data,
        ...action.payload.data,
      },
    }),
    [types.EDIT_DATA_ERROR]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
    [types.DELETE_DATA]: (state, action) => ({
      ...state,
      loading: true,
      error: null,
      bufferData: action.payload.bufferData,
    }),
    [types.DELETE_DATA_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      bufferData: null,
      data: {
        ...state.data,
        ...action.payload.data,
      },
    }),
    [types.DELETE_DATA_ERROR]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
  });
}
