import * as t from "io-ts";
import {
  ColorConfig,
  IElement,
  UntransformedConfig,
  customExpression,
} from "core/types";

export const ClipboardCopyButtonConfig = t.intersection([
  t.type({
    value: customExpression(t.union([t.string, t.null])),
  }),
  t.partial({
    disabled: t.boolean,
    icon: customExpression(t.string),
    color: ColorConfig,
    variant: t.keyof({
      outlined: null,
      contained: null,
    }),
  }),
]);

export type ClipboardCopyButtonConfig = t.TypeOf<
  typeof ClipboardCopyButtonConfig
>;

export type UntransformedClipboardCopyButtonConfig = UntransformedConfig<
  ClipboardCopyButtonConfig
>;

export const ClipboardCopyButtonTranslationKeys = ["label"] as const;

export type ClipboardCopyButtonTranslationKeys = typeof ClipboardCopyButtonTranslationKeys[number];

export type ClipboardCopyButton = IElement<
  ClipboardCopyButtonConfig,
  {},
  ClipboardCopyButtonTranslationKeys
>;
