import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import get from "lodash/get";

import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";

import { actions, types } from "./actions";
import { selectors } from "./selectors";

import AdminService from "services/admin";
import { loadAppMetadata } from "services/api";
import { exportToJsonFile } from "utils/json";
import { IUi } from "core";
import { AllServices } from "core/buildStore";

const services = AdminService.getInstance();

function* loadSaga() {
  const token = yield select(sessionSelectors.token);

  try {
    const data = yield call(services.getAllUi, token);
    yield put(actions.loadSuccess(data));
  } catch (error) {
    yield put(actions.loadError(error.message ?? JSON.stringify(error)));
  }
}

function* deleteUiSaga({
  payload: { uiName },
}: ReturnType<typeof actions.deleteUi>) {
  const token = yield select(sessionSelectors.token);
  try {
    yield call(services.deleteUI, uiName, {
      token,
    });
    yield put(
      sessionActions.enqueueSnackbar({
        message: "UI deleted successfully",
        options: {
          variant: "success",
        },
      }),
    );
    const uiList: ReturnType<typeof selectors.uiList> = yield select(
      selectors.uiList,
    );
    yield put(
      actions.loadSuccess(uiList?.filter((ui) => ui.name !== uiName) ?? []),
    );

    // update global app list - used for app switching as end-user
    const allServices: AllServices = yield getContext("services");
    const generalUiList: IUi[] = yield call(allServices.api.loadUIList, {
      token,
    });
    yield put(sessionActions.setUiList(generalUiList));
  } catch (error) {
    yield put(actions.loadError(error.message ?? JSON.stringify(error)));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "UI was not deleted",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

function* exportUiSaga({
  payload: { uiName },
}: ReturnType<typeof actions.exportUi>) {
  const token = yield select(sessionSelectors.token);

  try {
    const data = yield call(loadAppMetadata, uiName, { token });
    yield call(
      exportToJsonFile,
      get(data, ["release", "definition"]),
      `${uiName}.json`,
    );
  } catch (error) {
    yield put(actions.loadError(error.message ?? JSON.stringify(error)));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "UI was not exported",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

function* changeUiSaga({
  payload: { ui },
}: ReturnType<typeof actions.changeUi>) {
  const token = yield select(sessionSelectors.token);
  const allServices: AllServices = yield getContext("services");

  try {
    const generalUiList: IUi[] = yield call(allServices.api.loadUIList, {
      token,
    });

    const uiSelected = generalUiList.find(
      (generalUi) => generalUi.id === ui.id,
    );

    if (uiSelected) {
      yield put(sessionActions.changeUi(uiSelected));
    }
  } catch (error) {
    yield put(actions.loadError(error.message ?? JSON.stringify(error)));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "UI was not changed",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

export function* saga() {
  yield all([
    yield takeLatest(types.LOAD, loadSaga),
    yield takeLatest(types.DELETE_UI, deleteUiSaga),
    yield takeLatest(types.EXPORT_UI, exportUiSaga),
    yield takeLatest(types.CHANGE_UI, changeUiSaga),
  ]);

  yield put(actions.load());
}
