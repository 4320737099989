import { UntransformedDateTimeInputConfig } from "../types";
import { IDefaultElement } from "core";

export const defaultElement: IDefaultElement<UntransformedDateTimeInputConfig> = {
  i18n: {
    en: {
      label: "",
    },
  },
  config: {
    showDatePart: true,
    showTimePart: true,
  },
};
